import { EventEmitter, Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Constant } from "../../../util/constant";

@Injectable({
    providedIn: "root"
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
    onUnauthorizedError = new EventEmitter<void>();
    onTokenRefreshed = new EventEmitter<string>();

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event) => {
                if (event instanceof HttpResponse) {
                    let headers: HttpHeaders = event.headers;

                    if (headers.has('Token')) {
                        let token = headers.get('Token');
                        if (token)
                            this.onTokenRefreshed.emit(token);
                    }

                    return event.clone();
                }
                return event;
            }),
            catchError((error) => {
                let errorMsg = "";

                if (error.status === 401) {
                    this.onUnauthorizedError.emit();
                }

                try {
                    const response = error?.error?.ErrorList || error?.ErrorList;

                    if (response === undefined) {
                        errorMsg = Constant.GENERIC_ERROR_MSG;
                    } else {
                        errorMsg = (response as Array<string>).join("\n");
                    }
                } catch (Error) {
                    errorMsg = Constant.GENERIC_ERROR_MSG;
                }

                return throwError({ message: errorMsg });
            })
        );
    }
}
