<section
  [@visibilityChanged]
  class="h-100 text-center modal"
  [ngClass]="{ 'full-width fullscreen' : fullscreen, 'd-flex justify-content-center align-items-center': !fullscreen }">
  <div class="modal-body text-center w-100">
    <div *ngIf="showCloseInHeader" class="ion-text-end">
      <ion-icon name="close-circle-outline" (click)="closeModal()" size="large"></ion-icon>
    </div>
    <ion-thumbnail class="mb-3" *ngIf="modalType">
      <img
        *ngIf="modalType === 'confirmation'"
        src="../assets/icon/confirmation_icon.png"
      />
      <img *ngIf="modalType === 'error'" src="../assets/icon/error_icon.png" />
    </ion-thumbnail>
    <div class="content">
      <img class="img-fluid {{customImageClasses}}" *ngIf="!modalType && customImageUrl" [src]="customImageUrl | safeUrl" />
      <h3 *ngIf="headerText">{{ headerText }}</h3>
      <p *ngIf="bodyText" [ngClass]="{ 'text-left': textAlign === 'left', 'text-right': textAlign === 'right' }" class="body-text">{{ bodyText }}</p>
    </div>
    <ng-content></ng-content>
    <div *ngIf="dismissable" class="buttons">
      <ion-button *ngIf="primaryAction" [ngClass]="{'elongated': !fullscreen}" (click)="primaryAction()">{{ primaryCtaText }}</ion-button>
      <div *ngIf="secondaryCtaText" class="text-center">
        <ion-text class="custom-button button-block mt-2" color="primary" (click)="secondaryAction()">
          <a *ngIf="secondaryLink" [href]="secondaryLink">
            {{secondaryCtaText}}
          </a>
          <span *ngIf="!secondaryLink">
            {{ secondaryCtaText }}
          </span>
        </ion-text>
      </div>
    </div>
  </div>
</section>
