/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { firstValueFrom, forkJoin, Observable, Subscription } from "rxjs";
import { CartService, LoadingService } from "src/app/core/services";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { CheckoutWorkflowService } from "src/app/modules/order/services/checkout-workflow.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { Cart } from "src/app/shared/model/cart";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { StringUtilities } from "src/app/util/string-utilities";
import { UserSegmentService } from "src/app/core/services/user-segment.service";
import { SwiperContainer } from "swiper/element";

type SliderContact = Contact & {
    requireReturnAddress: boolean;
    facilityName: string;
};

@Component({
    selector: "app-contact-slider",
    templateUrl: "./contact-slider.component.html",
    styleUrls: ["./contact-slider.component.scss"]
})
export class ContactSliderComponent
    extends BasePage
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input() refreshContacts: Observable<void>;
    @Input() displayAlternateHomeScreen: boolean;
    @ViewChild("contactSlider") contactSlider: ElementRef<SwiperContainer>;

    loading = true;
    contactSliderState: SliderState;
    contacts: Array<SliderContact> = new Array<SliderContact>();
    currentContact: Contact;
    currentCart: Cart;
    contactOptions = {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    };
    subscriptions: Subscription[] = [];
    disableOrderCtas = false;

    constructor(
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private contactService: ContactService,
        private facilityService: FacilityService,
        private contactContextService: ContactContextService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private cartService: CartService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private loadingService: LoadingService,
        private userSegmentService: UserSegmentService
    ) {
        super();
        this.contactSliderState = {
            isBeginningSlide: true,
            isEndSlide: false
        };
    }

    ngAfterViewInit(): void {
        this.contactSlider.nativeElement.initialize();
    }

    async ngOnInit(): Promise<void> {
        const contactSub = this.contactContextService.contact$.subscribe(
            (r) => (this.currentContact = r)
        );
        const cartSub = this.cartService.cart$.subscribe(
            (c) => (this.currentCart = c?.Cart)
        );
        const refreshContactsSub = this.refreshContacts.subscribe(() => {
            void this.getContacts();
        });
        this.subscriptions.push(contactSub, cartSub, refreshContactsSub);
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    // TODO: We may need to reload this after creating a new contact
    private async getContacts(): Promise<void> {
        const response = await firstValueFrom(
            this.contactService.getAllContacts()
        );
        // TODO: This isn't great as we're enablining modification of an object provided from the api.
        this.contacts = response.Data as Array<SliderContact>;

        for (const contact of this.contacts) {
            try {
                // TODO: Implement memoization (or short-term caching) to eliminate redundant calls
                const facilityResponse = await firstValueFrom(
                    this.facilityService.getFacilityById(contact.FacilityId)
                );
                contact.facilityName = facilityResponse.Data.Name;
                contact.requireReturnAddress =
                    facilityResponse.Data.RequireReturnAddress;
            } catch {
                //TODO: Confirm this is how we want to handle this (PEL-559)
                contact.facilityName = "Unknown Facility";
            }
        }
        this.loading = false;
    }

    getInitials(contact: Contact): string {
        return StringUtilities.getInitials(contact.FirstName, contact.LastName);
    }

    async selectContact(contact: SliderContact): Promise<void> {
        this.disableOrderCtas = true;
        await this.loadingService.show();
        try {
            // If an order for another contact is in progress and has already
            // made it past the Upload page, show warning modal
            if (
                this.currentContact &&
                this.currentContact.Id !== contact.Id &&
                this.currentCart?.Items &&
                this.currentCart.Items.length
            ) {
                await this.showCartResetWarningModal(
                    this.modalCtrl,
                    "contact",
                    async () => {
                        void this.modalCtrl.dismiss();
                        await this.continueCurrentOrder();
                    },
                    async () => {
                        void this.modalCtrl.dismiss();
                        await this.resetShoppingCart(contact);
                    }
                );
            }
            // If an order for another contact was started, but did not make
            // it to the Upload page, reset cart without prompting
            else if (
                this.currentContact &&
                this.currentContact.Id !== contact.Id
            ) {
                await this.resetCart();
                await this.startOrder(contact);
            }
            // If no order is in progress, start a new order
            else {
                await this.startOrder(contact);
            }
        } finally {
            this.disableOrderCtas = false;
            await this.loadingService.dismiss();
        }
    }

    private async continueCurrentOrder(): Promise<void> {
        await this.loadingService.show();
        try {
            await this.checkoutWorkflowService.continueCheckoutWorkflow();
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private async resetShoppingCart(contact: SliderContact): Promise<void> {
        await this.loadingService.show();
        try {
            await this.resetCart();
            await this.startOrder(contact);
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private async resetCart(): Promise<void> {
        await this.contactContextService.clearContactContext();
        await firstValueFrom(this.cartService.clearCart());
        await this.selectPhotosWorkflowService.resetWorkflow();
        await this.checkoutWorkflowService.resetCheckoutAttributes();
    }

    private async startOrder(contact: SliderContact): Promise<void> {
        await this.contactContextService.setContactContext(contact.Id);

        if (
            this.currentContact &&
            this.currentContact.Id === contact.Id &&
            this.currentCart?.Items &&
            this.currentCart.Items.length
        ) {
            await this.checkoutWorkflowService.continueCheckoutWorkflow();
            return;
        }

        await this.userSegmentService.logOrderStartedEvent();
        if (contact.requireReturnAddress) {
            await this.navCtrl.navigateRoot(`order/return-address`);
        } else {
            await this.navCtrl.navigateRoot(`order/select-product/photos`);
        }
    }

    slideNext(): void {
        this.contactSlider.nativeElement.swiper.slideNext(500);
        this.checkIfNavDisabled();
    }

    slidePrev(): void {
        this.contactSlider.nativeElement.swiper.slidePrev(500);
        this.checkIfNavDisabled();
    }

    slideDidChange(): void {
        this.checkIfNavDisabled();
    }

    checkIfNavDisabled(): void {
        this.checkisBeginning();
        this.checkisEnd();
    }

    checkisBeginning(): void {
        const isBeginning = this.contactSlider.nativeElement.swiper.isBeginning;
        this.contactSliderState.isBeginningSlide = isBeginning;
    }

    checkisEnd(): void {
        const isEnd = this.contactSlider.nativeElement.swiper.isEnd;
        this.contactSliderState.isEndSlide = isEnd;
    }
}

type SliderState = { isBeginningSlide: boolean; isEndSlide: boolean };
