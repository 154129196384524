import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdvSearcData } from "../../../shared/model/search";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class ProductListService {
    constructor(private baseService: BasePelipostService) { }

    public advSearchData: AdvSearcData = null;

    fetchProductByCategory(
        categoryId: number,
        page: number,
        orderBy: any,
        specs: any
    ) {
        let query = new HttpParams()
            .set("PageNumber", page.toString())
            .set("PageSize", "9");

        if (orderBy != null) {
            query = query.set("orderby", orderBy.toString());
        }

        if (specs !== null) {
            query = query.set("specs", specs.toString());
        }

        const url = this.baseService.BASE_PEL_URL + `catalog/category/${categoryId}`;
        return this.baseService.getRequestWithParam(url, query);
    }

    searchProduct(
        userQuery: string,
        page: number,
        orderBy: string,
        specs: any,
        advSearchData: AdvSearcData
    ) {
        let query = new HttpParams()
            .set("PageNumber", page.toString())
            .set("PageSize", "9")
            .set("q", userQuery);

        if (orderBy != null) {
            query = query.set("orderby", orderBy.toString());
        }
        if (specs !== null) {
            query = query.set("specs", specs.toString());
        }
        if (advSearchData !== null && advSearchData?.adv === true) {
            query = query.set("adv", advSearchData?.adv?.toString());
            query = query.set("isc", advSearchData?.isc?.toString());
            query = query.set("asv", advSearchData?.asv?.toString());
            query = query.set("pf", advSearchData?.pf?.toString());
            query = query.set("pt", advSearchData?.pt?.toString());
            query = query.set("sid", advSearchData?.sid?.toString());
            query = query.set("cid", advSearchData?.cid?.toString());
            query = query.set("mid", advSearchData?.mid?.toString());
            query = query.set("vid", advSearchData?.vid?.toString());
        }

        const url = this.baseService.BASE_URL + "catalog/search";
        return this.baseService.getRequestWithParam(url, query);
    }

    fetchProductByManufacturer(
        categoryId: number,
        page: number,
        orderBy: string,
        specs: any
    ) {
        let query = new HttpParams()
            .set("PageNumber", page.toString())
            .set("PageSize", "9");

        if (orderBy != null) {
            query = query.set("orderby", orderBy.toString());
        }
        if (specs !== null) {
            query = query.set("specs", specs.toString());
        }

        const url =
            this.baseService.BASE_URL + `catalog/manufacturer/${categoryId}`;
        return this.baseService.getRequestWithParam(url, query);
    }

    fetchProductByVendor(
        categoryId: number,
        page: number,
        orderBy: string,
        specs: any
    ) {
        let query = new HttpParams()
            .set("PageNumber", page.toString())
            .set("PageSize", "9");

        if (orderBy != null) {
            query = query.set("orderby", orderBy.toString());
        }
        if (specs !== null) {
            query = query.set("specs", specs.toString());
        }

        const url = this.baseService.BASE_URL + `catalog/vendor/${categoryId}`;
        return this.baseService.getRequestWithParam(url, query);
    }

    fetchProductByTag(
        categoryId: number,
        page: number,
        orderBy: string,
        specs: any
    ) {
        let query = new HttpParams()
            .set("PageNumber", page.toString())
            .set("PageSize", "9");

        if (orderBy != null) {
            query = query.set("orderby", orderBy.toString());
        }
        if (specs !== null) {
            query = query.set("specs", specs.toString());
        }

        const url = this.baseService.BASE_URL + `catalog/producttag/${categoryId}`;
        return this.baseService.getRequestWithParam(url, query);
    }

    fetchProductByUrl(url: string) {
        return this.baseService.getRequest(url);
    }

    fetchAdvancedSearchModel() {
        const url = this.baseService.BASE_URL + `catalog/search`;
        return this.baseService.getRequest(url);
    }

    fetchAllProductTags() {
        const url = this.baseService.BASE_URL + "catalog/producttag/all";
        return this.baseService.getRequest(url);
    }

    fetchProductCategories() {
        const url = this.baseService.BASE_URL + "catalog/categories";
        return this.baseService.getRequest(url);
    }
}
