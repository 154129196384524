import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { ShippingOptionModel } from "src/app/shared/model/more/shipping-option-model";
import { MyOrdersModel } from "src/app/shared/model/order";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class PelipostOrderService {
    private url = `${this.baseService.BASE_PEL_URL}order`;

    constructor(private baseService: BasePelipostService) {}

    cancelOrder(orderId: number): Observable<void> {
        return this.baseService.postRequest(
            `${this.url}/cancel/${orderId}`,
            {}
        );
    }

    getShippingMethod(
        orderId: number
    ): Observable<BaseResponseModel<ShippingOptionModel>> {
        return this.baseService.getRequest<
            BaseResponseModel<ShippingOptionModel>
        >(`${this.url}/shippingmethod/${orderId}`);
    }

    getMyOrders(
        page: number = 1
    ): Observable<BaseResponseModel<MyOrdersModel>> {
        return this.baseService.getRequest<BaseResponseModel<MyOrdersModel>>(
            `${this.url}/myorders/${page}`
        );
    }
}
