/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NavController } from "@ionic/angular";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-name",
    templateUrl: "./name.page.html",
    styleUrls: ["./name.page.scss"]
})
export class NamePage extends BasePage implements OnInit {
    public contactNameForm: UntypedFormGroup;

    get firstName(): AbstractControl {
        return this.contactNameForm.get("firstName");
    }
    get lastName(): AbstractControl {
        return this.contactNameForm.get("lastName");
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private contactWorkFlowService: ContactWorkflowService,
        private navCtrl: NavController
    ) {
        super();
    }

    ngOnInit(): void {
        this.pageLoad();
    }

    pageLoad(): void {
        this.contactNameForm = this.formBuilder.group({
            firstName: [null, [Validators.required, Validators.minLength(2)]],
            lastName: [null, [Validators.required, Validators.minLength(2)]]
        });
    }

    async continue(): Promise<void> {
        const contact: Contact = {
            FirstName: (this.firstName.value as string).trim(),
            LastName: (this.lastName.value as string).trim(),
            Id: 0,
            InmateId: null,
            ImageUrl: null,
            FacilityId: null,
            PeliPalsNumber: null,
            Attributes: null
        };
        await this.contactWorkFlowService.StoreContactModel(contact);
        await this.navCtrl.navigateForward("/contacts/new-contact/facility");
    }
}
