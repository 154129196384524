import { Injectable } from "@angular/core";
import { Observable, catchError, filter, of, shareReplay, tap } from "rxjs";
import { CheckForUpdateRes } from "src/app/shared/model/checkForUpdateRes";
import { BasePelipostService } from "../base.pelipost.service";
import { StartupSettings } from "src/app/shared/model/startup-settings";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";

@Injectable({
    providedIn: "root"
})
export class StartupService {
    private settingsCache$: Observable<StartupSettings>;

    constructor(private baseService: BasePelipostService) {}

    checkForUpdate(
        version: string,
        platform: string
    ): Observable<CheckForUpdateRes> {
        const url =
            this.baseService.BASE_PEL_URL +
            `startup/checkforupdate?platform=${platform}`;
        return this.baseService.postRequest(url, version);
    }

    getSettings(): Observable<StartupSettings> {
        if (!this.settingsCache$) {
            const url = this.baseService.BASE_PEL_URL + "startup/settings";
            this.settingsCache$ = this.baseService
                .getRequest<StartupSettings>(url)
                .pipe(
                    shareReplay({
                        bufferSize: 1,
                        refCount: false,
                        windowTime: 3600000
                    })
                );
        }

        return this.settingsCache$;
    }
}
