<div class="no-header-page"></div>
<ion-content>
    <ion-grid class="h-100">
        <ion-row class="ion-justify-content-center h-100">
            <ion-col size="12" sizeMd="9" sizeXl="6">
                <swiper-container #welcomeSlider
                            [initialSlide]="sliderOptions.initialSlide"
                            [slidesPerView]="sliderOptions.slidesPerView"
                            [autoHeight]="sliderOptions.autoHeight"
                            [autoplay]="sliderOptions.autoplay"
                            [pagination]="sliderOptions.pagination"
                            class="welcome">
                    <swiper-slide>
                        <div
                            class="d-flex flex-column justify-content-around first-slide">
                            <div class="px-3">
                                <h3>Welcome to</h3>
                                <img
                                    class="light-mode"
                                    src="../assets/images/pelipost_logo.png"
                                    loading="lazy"
                                />
                                <img
                                    class="dark-mode"
                                    src="../assets/images/pelipost_logo_white.png"
                                    loading="lazy"
                                />
                                <div class="light-mode swiper-lazy-preloader"></div>
                                <div class="dark-mode swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="d-flex flex-column justify-content-start">
                            <ion-thumbnail class="mx-auto">
                                <img
                                    src="../assets/images/animate-smartphone.gif"
                                    loading="lazy"
                                />
                                <div class="light-mode swiper-lazy-preloader"></div>
                                <div class="dark-mode swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </ion-thumbnail>
                            <div class="px-3 align-self-end">
                                <h3>
                                    Upload your photos
                                    <br />
                                    and you’re done.
                                </h3>
                                <p>
                                    Add pictures from your device, Instagram,
                                    Google, and more. Hit SEND and
                                    you’re finished. We do the rest.
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="d-flex flex-column justify-content-start">
                            <ion-thumbnail class="mx-auto">
                                <img
                                    src="../assets/images/animate-printer.gif"
                                    loading="lazy"
                                />
                                <div class="light-mode swiper-lazy-preloader"></div>
                                <div class="dark-mode swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </ion-thumbnail>
                            <div class="px-3 align-self-end">
                                <h3>
                                    We print and ship
                                    <br />
                                    directly to the facility.
                                </h3>
                                <p>
                                    Once your photos are uploaded, Pelipost will
                                    print, package, and ship your photos to your
                                    loved one’s correctional facility. All
                                    photos are printed in vibrant full-color,
                                    high definition, on our commercial-grade
                                    photo printers.
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="d-flex flex-column justify-content-start">
                            <ion-thumbnail class="mx-auto">
                                <img
                                    src="../assets/images/animate-envelope.gif"
                                    loading="lazy"
                                />
                                <div class="light-mode swiper-lazy-preloader"></div>
                                <div class="dark-mode swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </ion-thumbnail>
                            <div class="px-3 align-self-end">
                                <h3>
                                    Photos arrive.
                                    <br />
                                    Your loved one enjoys.
                                </h3>
                                <p>
                                    In 3-5 business days, your order will arrive
                                    to the facility and to your incarcerated
                                    loved one, shortly after. Our photos will
                                    truly bring joy to your incarcerated loved
                                    one.
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<div class="text-center">
    <div class="bullet-container"></div>
</div>
<div class="p-3">
    <ion-grid>
        <ion-row class="ion-justify-content-center">
            <ion-col size="12" sizeMd="6" sizeXl="3">
                <ion-button expand="block" (click)="createAccount()" id="btn-landing-sign-up">
                    Create Account
                </ion-button>
                <ion-button expand="block" fill="clear" (click)="goToLogin()">
                    Log In
                </ion-button>
                <ion-text color="primary" class="ion-text-center d-block mt-3" (click)="onPrivacyPolicyClick()">Privacy Policy</ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
