<ion-header>
    <app-page-header>
        <app-contact-identity
            [contact]="contact"
            [facility]="facility"
            [isHeader]="true"
        ></app-contact-identity>
    </app-page-header>
</ion-header>

<ion-content>
    <app-page-template [fullWidthContent]="true" [hideCtaSection]="false">
        <section id="page-content" class="full-width">
            <div class="row justify-content-around mx-3 gx-0 image-sources">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <ion-button
                        expand="block"
                        fill="outline"
                        (click)="addPhotoFromGallery()"
                        [disabled]="selectingPhotos || photos.length >= maxPhotos"
                    >
                        <div>
                            <ion-img *ngIf="!android" src="../assets/icon/photos_icon.png"></ion-img>
                            <ion-img *ngIf="android" src="../assets/icon/gallery_icon.png"></ion-img>
                            {{ isLetter ? "Upload Letter" : "Photos" }}
                        </div>
                    </ion-button>
                </div>
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <ion-button
                        expand="block"
                        fill="outline"
                        (click)="addPhotoFromCamera()"
                        [disabled]="selectingPhotos || photos.length >= maxPhotos"
                    >
                        <div>
                            <ion-img class="camera-icon" src="../assets/icon/camera_icon@2x.svg"></ion-img>
                            {{ isLetter ? "Scan Now" : "Camera" }}
                        </div>
                    </ion-button>
                </div>
            </div>
            <app-photos-gallery
                [photos]="photos | filterBy: ['source']: photoSource.Gallery"
                [source]="photoSource.Gallery"
                [isEditMode]="editPhotosMode"
                (deleteClick)="removePhoto($event)"
            ></app-photos-gallery>
            <app-photos-gallery
                [photos]="photos | filterBy: ['source']: photoSource.Camera"
                [source]="photoSource.Camera"
                [isEditMode]="editPhotosMode"
                (deleteClick)="removePhoto($event)"
            ></app-photos-gallery>
        </section>
        <section id="cta-buttons" class="full-width">
            <div class="row pt-2 pb-1 mx-0 w-100 flex-nowrap align-items-center">
                <div *ngIf="photos.length" class="col-auto px-0">
                    <div class="custom-button button-block button-outline" (click)="editPhotosMode = !editPhotosMode">
                        <ion-text color="primary">
                            {{ editPhotosMode ? "Exit edit mode" : "Edit cart" }}
                        </ion-text>
                    </div>
                </div>
                <div class="col pl-2 pr-0">
                    <ion-button
                        expand="block"
                        (click)="submit()"
                        [disabled]="!photos.length || photos.length > maxPhotos"
                        id="btn-select-photos-send"
                    >
                        <div class="row align-items-center justify-content-between w-100">
                            <div class="col text-truncate px-0" [ngClass]="{ 'text-left': photos.length }">
                                <span *ngIf="photos.length">
                                    Send {{photos.length}} photos
                                </span>
                                <span *ngIf="!photos.length">
                                    Select photos to continue
                                </span>
                            </div>
                            <div *ngIf="photos.length" class="col-auto price-col">
                                {{ subtotal | currency }}
                            </div>
                        </div>
                    </ion-button>
                </div>
            </div>
            <div class="text-center">
                <ion-text class="custom-button button-block"
                    color="primary"
                    (click)="resetCart()"
                >Reset cart and start over</ion-text>
            </div>
        </section>
    </app-page-template>
</ion-content>
