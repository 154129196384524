import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { PhotoDataResponseModel } from "src/app/shared/model/photos/photo-data-response-model";
import { BasePelipostService } from "../base.pelipost.service";
import { PelipostPhoto } from "../photos.service";

@Injectable({
    providedIn: "root"
})
export class GooglePhotoService {
    constructor(private baseService: BasePelipostService) { }

    convertUrlsToData(
        photos: Array<PelipostPhoto>
    ): Observable<BaseResponseModel<PhotoDataResponseModel>> {
        return this.baseService.postRequest(
            `${this.baseService.BASE_PEL_URL}googlephoto/converturlstodata`,
            {
                Photos: photos.map((p) => {
                    return {
                        PhotoId: p.id,
                        PhotoUrl: p.data
                    };
                })
            }
        );
    }
}
