<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Print Preview"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <p>Take a moment and verify that this is how your photos are to be printed.</p>
    </section>
    <section id="page-content">
      <div class="photo-container mb-5" *ngFor="let photo of photos; index as i">
        <!--stub for low res warning-->
        <div class="image-container mb-3">
          <div
            class="image-wrap"
            [ngClass]="{'portrait': photo.dimension.height >= photo.dimension.width, 'landscape': photo.dimension.width > photo.dimension.height}"
            style="--image-aspect-ratio: {{photo.ratio}}"
          >
            <div class="image-box">
              <ion-img [src]="photo.data"></ion-img>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <ion-button fill="clear"
            (click)="cropImage(i)">
              <div>
                <ion-icon src="../assets/icon/crop_icon.svg"></ion-icon>
                <div>Edit</div>
              </div>
            </ion-button>
          </div>
          <div>
            <ion-button
              fill="clear"
              *ngIf="photo.history?.length"
              (click)="resetImage(i)"
            >
              <div>
                <ion-icon name="sync-outline"></ion-icon>
                <div>Undo</div>
              </div>
            </ion-button>
          </div>
          <div>
            <ion-button fill="clear" (click)="removeImage(i)">
              <div>
                <ion-icon src="../assets/icon/remove_icon.svg"></ion-icon>
                <div>Remove</div>
              </div>
            </ion-button>
          </div>
        </div>
      </div>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      primaryCtaId="btn-print-preview-continue"
      (primaryCtaClick)="submit()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
