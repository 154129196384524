import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PelipointsContextService } from "src/app/core/services/pelipoints-context.service";

@Component({
    templateUrl: "./earn.page.html",
    styleUrls: ["./earn.page.scss"]
})
export class EarnPage implements OnInit, OnDestroy {
    public currentPointsBalance: number;
    private subscriptions: Subscription[] = [];

    constructor(private pelipointsContextService: PelipointsContextService) {}

    ngOnInit() {
        this.subscribeToPelipointsContext();
    }

    subscribeToPelipointsContext() {
        this.subscriptions.push(
            this.pelipointsContextService.pelipoints$.subscribe((res) => {
                this.currentPointsBalance = res?.Data?.RewardPointsBalance;
            })
        );
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    async refreshEarnPage($event: any): Promise<void> {
        await this.pelipointsContextService.getPelipointsContext().toPromise();
        $event.target.complete();
    }
}
