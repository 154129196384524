import { Component, Input } from "@angular/core";
import { OrderTotals } from "src/app/shared/model/cart";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";
import { LineItem } from "src/app/shared/model/order";

@Component({
    selector: "app-order-overview",
    templateUrl: "./order-overview.component.html",
    styleUrls: ["./order-overview.component.scss"]
})
export class OrderOverviewComponent {
    @Input() lineItems: Array<LineItem>;
    @Input() orderTotals: OrderTotals;
    @Input() contact: Contact;
    @Input() facility: Facility;
}
