/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OrderTotals } from "src/app/shared/model/cart";
import { LineItem } from "src/app/shared/model/order";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { AbstractWorkflowService } from "src/app/shared/workflows/abstract-workflow.service";
import {
    PelipostPhoto,
    PhotoService
} from "../../../core/services/photos.service";
import { StorageService } from "../../../core/services/storage.service";

@Injectable({
    providedIn: null
})
export class SelectPhotosWorkflowService extends AbstractWorkflowService {
    static WorkflowId = "SelectPhotosWorkflow";
    static SELECTED_PHOTO_KEY = "selectedPhoto";
    static STORED_PHOTO_IDS_KEY = "storedPhotoIds";
    static LINE_ITEMS_KEY = "lineItems";
    static ORDER_TOTALS_KEY = "orderTotals";
    static MESSAGE_PHOTO_KEY = "messagePhoto";
    static MESSAGE_PRODUCT_KEY = "messageProduct";
    static SKIP_ADD_MESSAGE = "skipAddMessage";

    protected get exitWorkflowUrl(): string {
        return "/";
    }

    constructor(
        private storageService: StorageService,
        private photoService: PhotoService,
        router: Router
    ) {
        super(router);
    }

    public navigateNext(): void {
        throw new Error("Method not implemented.");
    }
    public navigatePrevious(): void {
        throw new Error("Method not implemented.");
    }
    protected initializeWorkflowRoutes(): void {
        throw new Error("Method not implemented.");
    }

    async storeSelectedPhotos(...photos: PelipostPhoto[]): Promise<void> {
        const storedPhotoIds = await this.getSelectedPhotoIds();
        for (const photo of photos) {
            if (!storedPhotoIds.includes(photo.id)) {
                storedPhotoIds.push(photo.id);
            }
            await this.storageService.setObject(
                `${SelectPhotosWorkflowService.SELECTED_PHOTO_KEY}-${photo.id}`,
                photo
            );
        }
        await this.setSelectedPhotoIds(storedPhotoIds);
    }

    async removeSelectedPhoto(photoId: string): Promise<void> {
        const storedPhotoIds = await this.getSelectedPhotoIds();
        const index = storedPhotoIds.indexOf(photoId);
        if (index === -1) {
            return;
        }

        storedPhotoIds.splice(index, 1);
        await this.setSelectedPhotoIds(storedPhotoIds);
        await this.storageService.removeItem(
            `${SelectPhotosWorkflowService.SELECTED_PHOTO_KEY}-${photoId}`
        );
    }

    async getSelectedPhotos(): Promise<Array<PelipostPhoto>> {
        const photos: PelipostPhoto[] = [];
        const storedPhotoIds = await this.getSelectedPhotoIds();
        for (const photoId of storedPhotoIds) {
            const photo = await this.storageService.getObject<PelipostPhoto>(
                `${SelectPhotosWorkflowService.SELECTED_PHOTO_KEY}-${photoId}`
            );
            if (photo) {
                photos.push(photo);
            } else {
                await this.removeSelectedPhoto(photoId);
            }
        }
        return photos;
    }

    async clearSelectedPhotos(): Promise<void> {
        const selectedPhotos = await this.getSelectedPhotos();
        for (const selectedPhoto of selectedPhotos) {
            await this.photoService.removeOriginalPhotoData(
                selectedPhoto.id,
                selectedPhoto.source
            );
            await this.storageService.removeItem(
                `${SelectPhotosWorkflowService.SELECTED_PHOTO_KEY}-${selectedPhoto.id}`
            );
        }
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.STORED_PHOTO_IDS_KEY
        );
    }

    private async getSelectedPhotoIds(): Promise<string[]> {
        const res = await this.storageService.getObject<string[]>(
            SelectPhotosWorkflowService.STORED_PHOTO_IDS_KEY
        );
        return res ?? [];
    }

    private async setSelectedPhotoIds(photoIds: string[]): Promise<void> {
        await this.storageService.setObject(
            SelectPhotosWorkflowService.STORED_PHOTO_IDS_KEY,
            photoIds
        );
    }

    getSubtotal(product: ProductDetail, photoCount: number): number {
        return product.ProductPrice.PriceValue * photoCount;
    }

    async storeMessagePhoto(message: PelipostPhoto): Promise<void> {
        await this.photoService.assignPhotoThumbnails(message);
        await this.storageService.setObject(
            SelectPhotosWorkflowService.MESSAGE_PHOTO_KEY,
            message
        );
    }

    async getMessagePhoto(): Promise<PelipostPhoto> {
        return this.storageService.getObject(
            SelectPhotosWorkflowService.MESSAGE_PHOTO_KEY
        );
    }

    async removeMessagePhoto(): Promise<void> {
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.MESSAGE_PHOTO_KEY
        );
    }

    async storeMessageProduct(message: ProductDetail): Promise<void> {
        await this.storageService.setObject(
            SelectPhotosWorkflowService.MESSAGE_PRODUCT_KEY,
            message
        );
    }

    async getMessageProduct(): Promise<ProductDetail> {
        return this.storageService.getObject(
            SelectPhotosWorkflowService.MESSAGE_PRODUCT_KEY
        );
    }

    async setLineItems(lineItems: Array<LineItem>): Promise<void> {
        await this.storageService.setObject(
            SelectPhotosWorkflowService.LINE_ITEMS_KEY,
            lineItems
        );
    }

    async getLineItems(): Promise<Array<LineItem>> {
        return this.storageService.getObject<Array<LineItem>>(
            SelectPhotosWorkflowService.LINE_ITEMS_KEY
        );
    }

    async setOrderTotals(orderTotals: OrderTotals): Promise<void> {
        await this.storageService.setObject(
            SelectPhotosWorkflowService.ORDER_TOTALS_KEY,
            orderTotals
        );
    }

    async getOrderTotals(): Promise<OrderTotals> {
        return this.storageService.getObject<OrderTotals>(
            SelectPhotosWorkflowService.ORDER_TOTALS_KEY
        );
    }

    async setSkipAddMessage(skipAddMessage: boolean): Promise<void> {
        await this.storageService.setObject(
            SelectPhotosWorkflowService.SKIP_ADD_MESSAGE,
            skipAddMessage
        );
    }

    async getSkipAddMessage(): Promise<boolean> {
        return this.storageService.getObject<boolean>(
            SelectPhotosWorkflowService.SKIP_ADD_MESSAGE
        );
    }

    async resetWorkflow(): Promise<void> {
        await this.clearSelectedPhotos();
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.LINE_ITEMS_KEY
        );
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.ORDER_TOTALS_KEY
        );
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.MESSAGE_PHOTO_KEY
        );
        await this.storageService.removeItem(
            SelectPhotosWorkflowService.SKIP_ADD_MESSAGE
        );
    }
}
