import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChangePasswordPostBody } from "src/app/shared/model/account/account-info";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";
import { AccountInfoGetRes } from "../../../../shared/model/account/account-info";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: "root"
})
export class AccountInfoService {
    constructor(private baseService: BaseService) { }

    fetchAccountInfoRes(): Observable<AccountInfoGetRes> {
        const url = this.baseService.BASE_URL + "customer/info";
        return this.baseService.getRequest<AccountInfoGetRes>(url);
    }

    postAccountInfo(data: AccountInfoGetRes): Observable<AccountInfoGetRes> {
        const reqBody = new BaseReqBody();
        reqBody.Data = data.Data;
        reqBody.FormValues = data.FormValues;

        const url = this.baseService.BASE_URL + "customer/info";
        return this.baseService.postRequest<AccountInfoGetRes>(url, reqBody);
    }

    postChangePassword(data: ChangePasswordPostBody) {
        const reqBody = new BaseReqBody();
        reqBody.Data = data;

        const url = this.baseService.BASE_URL + "customer/changepassword";
        return this.baseService.postRequest(url, reqBody);
    }
}
