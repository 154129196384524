import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AddEditAddressGetRes,
    AddEditAddressPostBody,
    AddressGetRes
} from "src/app/shared/model/account/address";
import {
    ImportOldUserModel,
    PasswordRecoveryPostBody
} from "src/app/shared/model/account/login";
import {
    BaseReqBody,
    BaseResponseModel,
    FormValue
} from "src/app/shared/model/BaseResponse";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    constructor(private baseService: BasePelipostService) {}

    postPasswordRecoveryData(data: PasswordRecoveryPostBody) {
        const reqBody = new BaseReqBody();
        reqBody.Data = data;

        const url = this.baseService.BASE_PEL_URL + "account/passwordrecovery";
        return this.baseService.postRequest(url, reqBody);
    }

    checkAndMigrateOldUser(
        parameters: ImportOldUserModel
    ): Observable<CheckAndMigrateOldUserPostRes> {
        const reqBody = new BaseReqBody();
        reqBody.Data = parameters;

        const url = this.baseService.BASE_PEL_URL + "account/importolduser";
        return this.baseService.postRequest(url, reqBody);
    }

    fetchReturnAddress(): Observable<AddressGetRes> {
        const url = this.baseService.BASE_PEL_URL + "account/returnaddress";
        return this.baseService.getRequest(url);
    }

    saveReturnAddress(
        data: AddEditAddressGetRes,
        values: FormValue[]
    ): Observable<BaseResponseModel<number>> {
        const reqBody: AddEditAddressPostBody = {
            Data: {
                Address: data.Data.Address,
                CustomProperties: data.Data.CustomProperties
            },
            FormValues: values
        };
        const url = this.baseService.BASE_PEL_URL + "account/savereturnaddress";
        return this.baseService.postRequest<BaseResponseModel<number>>(
            url,
            reqBody
        );
    }

    deleteMyAccount(): Observable<void> {
        const url = this.baseService.BASE_PEL_URL + "account/deletemyaccount";
        return this.baseService.putRequest(url, new BaseReqBody());
    }
}

export interface CheckAndMigrateOldUserPostRes {
    Data: boolean;
    Message?: string;
    ErrorList: string[];
}
