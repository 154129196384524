export class BaseResponseModel<T> {
    Message?: string;
    ErrorList: string[];
    Data: T;

    getFormattedError(): string {
        let errors = "";

        if (this.ErrorList?.length > 0) {
            this.ErrorList.forEach((element) => {
                errors += "‣ " + element + "\n";
            });
        }
        return errors.trim();
    }
}

export class BaseReqBody {
    Data: any;
    FormValues: FormValue[];
}

export interface CustomProperties {
    CustomProperties: string;
}

export interface AvailableCurrency {
    Name: string;
    CurrencySymbol: string;
    Id: number;
    CustomProperties: CustomProperties;
}

export interface CurrencyNavSelector {
    AvailableCurrencies: AvailableCurrency[];
    CurrentCurrencyId: number;
    CustomProperties: CustomProperties;
}

export interface AvailableLanguage {
    Name: string;
    FlagImageFileName: string;
    Id: number;
    CustomProperties: CustomProperties;
}

export interface LanguageNavSelector {
    AvailableLanguages: AvailableLanguage[];
    CurrentLanguageId: number;
    UseImages: boolean;
    CustomProperties: CustomProperties;
}

export interface StringResource {
    Key: string;
    Value: string;
}

export interface Data {
    ShowHomepageSlider: boolean;
    ShowFeaturedProducts: boolean;
    ShowBestsellersOnHomepage: boolean;
    ShowHomepageCategoryProducts: boolean;
    ShowManufacturers: boolean;
    Rtl: boolean;
    AndroidVersion: string;
    AndriodForceUpdate: boolean;
    PlayStoreUrl: string;
    IOSVersion: string;
    IOSForceUpdate: boolean;
    AppStoreUrl: string;
    LogoUrl: string;
    TotalShoppingCartProducts: number;
    TotalWishListProducts: number;
    ShowAllVendors: boolean;
    AnonymousCheckoutAllowed: boolean;
    ShowChangeBaseUrlPanel: boolean;
    HasReturnRequests: boolean;
    PrimaryThemeColor: string;
    TopBarBackgroundColor: string;
    TopBarTextColor: string;
    BottomBarBackgroundColor: string;
    BottomBarActiveColor: string;
    BottomBarInactiveColor: string;
    CurrencyNavSelector: CurrencyNavSelector;
    LanguageNavSelector: LanguageNavSelector;
    StringResources: StringResource[];
}

export interface AppSettingsResponse {
    Data: Data;
    Message?: string;
    ErrorList: string[];
}

export interface Slider {
    ImageUrl: string;
    SliderType: number;
    EntityId: number;
    Id: number;
}

export interface SliderData {
    IsEnabled: boolean;
    Sliders: Slider[];
}

export interface SliderResponse {
    Data: SliderData;
    Message?: string;
    ErrorList: string[];
}

export interface FormValue {
    Key: string;
    Value: string;
}

export interface AppStartPostBody {
    Data: {
        DeviceTypeId: number;
        SubscriptionId: string;
    };
}
