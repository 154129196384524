import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {
    IonDatetime,
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import * as faker from "faker";
import { DateTime } from "luxon";
import {
    AuthService,
    CartService,
    LoadingService
} from "src/app/core/services";
import { FattMerchantService } from "src/app/core/services/pelipost-service-proxies/fatt-merchant.service";
import { LocationLookupService } from "src/app/core/services/pelipost-service-proxies/location-lookup.service";
import { PelipostCheckoutService } from "src/app/core/services/pelipost-service-proxies/pelipost-checkout.service";
import { AddressService } from "src/app/core/services/service-proxies/account/address.service";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { Address } from "src/app/shared/model/account/address";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";
import { NopStateProvince } from "src/app/shared/model/state";
import { BasePage } from "src/app/shared/pages/BasePage";
import { Constant } from "src/app/util/constant";
import { environment } from "src/environments/environment";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { FattJsProviderService } from "../../../services/fatt-js-provider.service";

@Component({
    templateUrl: "./card-payment-info.page.html",
    styleUrls: ["./card-payment-info.page.scss"]
})
export class CardPaymentInfoPage extends BasePage implements OnInit {
    @ViewChild("firstNameItem", { read: ElementRef })
    firstNameItem: ElementRef;
    @ViewChild("lastNameItem", { read: ElementRef })
    lastNameItem: ElementRef;
    @ViewChild("cardNumberItem", { read: ElementRef })
    cardNumberItem: ElementRef;
    @ViewChild("expDateItem", { read: ElementRef })
    expDateItem: ElementRef;
    @ViewChild("cvvItem", { read: ElementRef })
    cvvItem: ElementRef;

    public showErrors = false;
    public errors = [];
    public cardNumberValid = false;
    public cardCvvValid = false;
    public expirationDateEditAttempted = false;
    public submitAttempted = false;
    public paymentForm: UntypedFormGroup;
    public submitting = false;
    public minDate: string;
    public maxDate: string;
    public initialAddress = { CountryId: 1 } as Address;
    public expirationDatePickerValue: string = DateTime.now()
        .set({ day: 1 })
        .toISO();
    private orderTotal: string;

    private fattMerchantApi: FattMerchant.Api;

    get firstName(): AbstractControl {
        return this.paymentForm.get("firstName");
    }
    get lastName(): AbstractControl {
        return this.paymentForm.get("lastName");
    }
    get expirationDate(): AbstractControl {
        return this.paymentForm.get("expirationDate");
    }
    get saveCard(): AbstractControl {
        return this.paymentForm.get("saveCard");
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private fattJsProviderService: FattJsProviderService,
        private fattMerchantService: FattMerchantService,
        private toastController: ToastController,
        private navController: NavController,
        private modalController: ModalController,
        private checkoutService: CheckoutService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private loadingService: LoadingService,
        private authService: AuthService,
        private locationLookupService: LocationLookupService,
        private pelipostCheckoutService: PelipostCheckoutService,
        private addressService: AddressService,
        private changeDetectorRef: ChangeDetectorRef,
        private cartService: CartService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.paymentForm = this.formBuilder.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            expirationDate: [null, Validators.required],
            saveCard: [false]
        });

        this.setDatePickerLimits();
        await this.initializeFattJsForm();
        await this.getOrderTotal();
    }

    public async formInitialized(name: string, form: UntypedFormGroup): Promise<void> {
        this.paymentForm.setControl(name, form);
        await this.debugSetTestCustomerValues();
    }

    public async submitPaymentInfo(): Promise<void> {
        this.submitting = true;
        this.paymentForm.markAllAsTouched();
        this.submitAttempted = true;

        if (
            this.paymentForm.invalid ||
            !this.cardNumberValid ||
            !this.cardCvvValid
        ) {
            this.submitting = false;
            this.scrollToTopmostInvalidField();
            return;
        }

        await this.loadingService.show();
        const cardholderName = `${this.firstName?.value?.trim()} ${this.lastName?.value?.trim()}`;
        const splitCardholderName =
            this.fattJsProviderService.splitCardholderName(cardholderName);
        try {
            await this.checkoutWorkflowService.debugSaveBillingAddress({
                Email: this.authService.customer.Email,
                FirstName: splitCardholderName.firstName,
                LastName: splitCardholderName.lastName,
                PhoneNumber: this.authService.customer.Phone,
                ZipPostalCode: this.paymentForm
                    .get("address.zipPostalCode")
                    .value?.trim(),
                CountryId: parseInt(
                    this.paymentForm.get("address.country").value
                )
            } as Address);
            //TODO: This is only duplicated here because updating/saving addresses resets steps in the checkout flow in nopStation
            await this.pelipostCheckoutService
                .setShippingAddressToCurrentFacility()
                .toPromise();
            await this.checkoutWorkflowService.debugSetPelipostShippingMethod();
            await this.checkoutWorkflowService.debugSetFattMerchantStaxCartPaymentMethod();

            let staxCustomerId = null;
            const customer = await this.fattMerchantService
                .getCustomer()
                .toPromise();
            if (customer?.id) {
                staxCustomerId = customer.id;
            }
            const { id, card_last_four, card_type } = await this.tokenizeCard(
                staxCustomerId
            );
            await this.fattJsProviderService.savePaymentInfo(
                id,
                card_last_four,
                card_type,
                this.saveCard.value,
                this.orderTotal
            );

            const confirmOrderResponse =
                await this.checkoutWorkflowService.confirmOrder();

            await this.navController.navigateRoot([
                "/order/confirmation",
                confirmOrderResponse.Data.CompletedModel.CustomOrderNumber
            ]);
        } catch (error) {
            if (error.message === Constant.GENERIC_ERROR_MSG) {
                error.message = Constant.ORDER_CONFIRMATION_FAILED_ERROR_MSG;
                await this.showErrorModal(this.modalController, error);
                await this.navController.navigateRoot("/home");
            } else {
                await this.checkoutWorkflowService.showPaymentFailedModal(
                    error
                );
                await this.navController.navigateBack(["order/order-summary"]);
            }
        } finally {
            this.submitting = false;
            await this.loadingService.dismiss();
        }
    }

    public expirationDateChanged(ionDateTime: IonDatetime): void {
        if (typeof ionDateTime.value !== "string") {
            return;
        }
        this.expirationDatePickerValue = ionDateTime.value;
        const parsedDate = DateTime.fromISO(ionDateTime.value);
        this.paymentForm.patchValue({
            expirationDate: parsedDate.toFormat("MM/yyyy")
        });
    }

    private setDatePickerLimits() {
        this.minDate = DateTime.now().set({ month: 1, day: 1 }).toISO();
        this.maxDate = DateTime.now()
            .plus({ years: 50 })
            .set({ month: 12 })
            .toISO();
    }

    private async initializeFattJsForm() {
        const webPaymentsTokenResponse = await this.fattMerchantService
            .getWebPaymentsToken()
            .toPromise();

        if (!webPaymentsTokenResponse) {
            await this.showError(
                this.toastController,
                "Failed to initialize card payment service."
            );
            return;
        }

        const fattJsFieldStyle = this.getFattJsFieldStyle();
        this.fattMerchantApi =
            this.fattJsProviderService.initializeFattJsApiInstance(
                webPaymentsTokenResponse.Message,
                {
                    // eslint-disable-next-line id-blacklist
                    number: {
                        id: "fattjs-number",
                        placeholder: "0000 0000 0000 0000",
                        style: fattJsFieldStyle
                    },
                    cvv: {
                        id: "fattjs-cvv",
                        placeholder: "000",
                        style: fattJsFieldStyle
                    }
                }
            );
        const formHandler = await this.fattMerchantApi.showCardForm();
        if (!environment.production) {
            this.debugSetTestCardValues(formHandler);
        }
        this.fattMerchantApi.on("card_form_complete", (message) => {
            this.cardNumberValid = true;
            this.cardCvvValid = true;
            this.changeDetectorRef.detectChanges();
        });
        this.fattMerchantApi.on("card_form_incomplete", (message) => {
            this.cardNumberValid = message.validNumber;
            this.cardCvvValid = message.validCvv;
            this.changeDetectorRef.detectChanges();
        });
    }

    private async getOrderTotal(): Promise<void> {
        this.orderTotal = (
            await this.cartService.fetchCartRes().toPromise()
        ).Data.OrderTotals.OrderTotal;
    }

    private getFattJsFieldStyle(): string {
        const prefersDark = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;
        let style = "width: 100%;";
        style += "height: 35px;";
        style += 'font-family: "Helvetica";';
        style += "font-size: 22px;";
        style += `background-color: ${prefersDark ? "#000000" : "#fff"};`;
        style += `color: ${prefersDark ? "#fff" : "#000"};`;
        style += "padding: 2px 0;";
        return style;
    }

    private scrollToTopmostInvalidField(): void {
        if (this.firstName.invalid) {
            this.firstNameItem.nativeElement.scrollIntoView({
                behavior: "smooth"
            });
        } else if (this.lastName.invalid) {
            this.lastNameItem.nativeElement.scrollIntoView({
                behavior: "smooth"
            });
        } else if (!this.cardNumberValid) {
            this.cardNumberItem.nativeElement.scrollIntoView({
                behavior: "smooth"
            });
        } else if (this.expirationDate.invalid) {
            this.expDateItem.nativeElement.scrollIntoView({
                behavior: "smooth"
            });
        } else if (!this.cardCvvValid) {
            this.cvvItem.nativeElement.scrollIntoView({
                behavior: "smooth"
            });
        }
    }

    private async tokenizeCard(
        customerId: string = null
    ): Promise<FattMerchant.PaymentMethod> {
        this.showErrors = false;

        const extraDetails = await this.getExtraDetails();

        if (customerId) {
            extraDetails.customer_id = customerId;
        }

        try {
            const result = await this.fattMerchantApi.tokenize(extraDetails);

            return Promise.resolve(result);
        } catch (err) {
            this.errors.length = 0;
            this.errors.push(err);
            this.showErrors = true;
            return Promise.reject(err);
        }
    }

    private async getExtraDetails(): Promise<FattMerchant.ExtraDetails> {
        let year: string;
        let month: string;
        const expDate = this.expirationDate.value as string;
        if (expDate && expDate.split("/").length >= 2) {
            const vals = expDate.split("/");
            month = vals[0];
            year = vals[1];
        }

        const country = await this.locationLookupService
            .findCountryById(this.paymentForm.get("address.country").value)
            .toPromise();

        return {
            firstname: this.firstName.value,
            lastname: this.lastName.value,
            month: month ?? "",
            year: year ?? "",
            phone: null,
            address_zip: this.paymentForm.get("address.zipPostalCode").value,
            address_country: country.Data.ThreeLetterIsoCode,
            email: this.authService.customer.Email,
            method: "card",
            validate: false
        } as FattMerchant.ExtraDetails;
    }

    private debugSetTestCardValues(
        formHandler: FattMerchant.IFrameFormHandler
    ) {
        console.log("Setting debug values");
        formHandler.setTestPan("4111111111111111");
        formHandler.setTestCvv("123");
        const debugExprDate = DateTime.now().plus({ years: 3 }).set({ day: 1 });
        this.expirationDatePickerValue = debugExprDate.toISO();
        this.paymentForm.patchValue({
            expirationDate: debugExprDate.toFormat("MM/yyyy")
        });
    }

    private async debugSetTestCustomerValues() {
        if (environment.production || this.firstName?.value) {
            return;
        }
        const state = faker.address.stateAbbr();
        this.paymentForm.patchValue({
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName()
        });
        this.initialAddress = {
            ...this.initialAddress,
            ZipPostalCode: faker.address.zipCodeByState(state)
        };
    }
}
