import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incomplete-orders',
  templateUrl: './incomplete-orders.page.html',
  styleUrls: ['./incomplete-orders.page.scss'],
})
export class IncompleteOrdersPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
