import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BASE_URL } from "../../../../environments/environment";
import { APP_VERSION } from "src/environments/environment";
import { AuthService } from "../auth.service";
import { StringUtilities } from "src/app/util/string-utilities";
//import { encode, TAlgorithm } from 'jwt-simple';

@Injectable({
    providedIn: "root"
})
export class BaseService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    public BASE_URL = BASE_URL;

    headerDictWithToken = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Token: "",
        DeviceId: "",
        NST: "",
        AppVersion: StringUtilities.getSemanticVersion(APP_VERSION),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, ORIGIN",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": "true",
        FacilityId: ""
    };

    headerDictWithoutToken = {
        "Content-Type": "application/json",
        Accept: "application/json",
        DeviceId: "",
        NST: "",
        AppVersion: StringUtilities.getSemanticVersion(APP_VERSION),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, ORIGIN",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": "true"
    };

    headerDictFileWithToken = {
        "Content-Type": "text/plain",
        Accept: "*/*",
        Token: "",
        DeviceId: "",
        NST: "",
        AppVersion: StringUtilities.getSemanticVersion(APP_VERSION),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, ORIGIN",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": "true",
        FacilityId: ""
    };

    headerDictFileWithoutToken = {
        "Content-Type": "text/plain",
        Accept: "*/*",
        Token: "",
        DeviceId: "",
        NST: "",
        AppVersion: StringUtilities.getSemanticVersion(APP_VERSION),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, ORIGIN",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": "true"
    };

    headerDictImplicitWithToken = {
        Accept: "*/*",
        Token: "",
        DeviceId: "",
        NST: "",
        AppVersion: StringUtilities.getSemanticVersion(APP_VERSION),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, ORIGIN",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": "true",
        FacilityId: ""
    };

    requestOptions = {
        headers: new HttpHeaders(),
        params: new HttpParams()
    };

    requestOptionsFiles = {
        headers: new HttpHeaders(),
        params: new HttpParams(),
        responseType: "blob" as const,
        observe: "response" as const
    };

    requestOptionsImplicit = {
        headers: new HttpHeaders(),
        params: new HttpParams()
    };

    private updateHeader(param: HttpParams) {
        /* const jwtToken = this.encodeSession(Constant.NST_SECRET, Constant.NST_KEY);

    this.headerDictWithToken.NST = jwtToken;
    this.headerDictWithoutToken.NST = jwtToken;
    this.headerDictFileWithToken.NST = jwtToken;
    this.headerDictFileWithoutToken.NST = jwtToken; */

        this.headerDictWithToken.DeviceId = this.authService.deviceID;
        this.headerDictWithoutToken.DeviceId = this.authService.deviceID;
        this.headerDictFileWithToken.DeviceId = this.authService.deviceID;
        this.headerDictFileWithoutToken.DeviceId = this.authService.deviceID;
        this.headerDictImplicitWithToken.DeviceId = this.authService.deviceID;

        this.headerDictWithToken.FacilityId = this.authService.currentFacilityId.toString();
        this.headerDictFileWithToken.FacilityId = this.authService.currentFacilityId.toString();
        this.headerDictImplicitWithToken.FacilityId = this.authService.currentFacilityId.toString();

        if (this.authService.isLoggedIn) {
            this.headerDictWithToken.Token = this.authService.token;
            this.headerDictFileWithToken.Token = this.authService.token;
            this.headerDictImplicitWithToken.Token = this.authService.token;
        }

        this.requestOptions = {
            headers: new HttpHeaders(
                this.authService.isLoggedIn
                    ? this.headerDictWithToken
                    : this.headerDictWithoutToken
            ),
            params: param
        };

        this.requestOptionsFiles = {
            headers: new HttpHeaders(
                this.authService.isLoggedIn
                    ? this.headerDictFileWithToken
                    : this.headerDictFileWithoutToken
            ),
            params: new HttpParams(),
            responseType: "blob" as const,
            observe: "response" as const
        };

        this.requestOptionsImplicit = {
            headers: new HttpHeaders(
                this.authService.isLoggedIn
                    ? this.headerDictImplicitWithToken
                    : null
            ),
            params: new HttpParams()
        };
    }

    public getRequest<T = Object>(url: string) {
        this.updateHeader(null);
        return this.httpClient.get<T>(url, this.requestOptions);
    }

    public getRequestFiles(url: string) {
        this.updateHeader(null);
        return this.httpClient.get(url, this.requestOptionsFiles);
    }

    public getRequestWithParam<T = Object>(url: string, params: HttpParams) {
        this.updateHeader(params);
        return this.httpClient.get<T>(url, this.requestOptions);
    }

    public postRequest<T = Object>(url: string, body: any) {
        this.updateHeader(null);
        return this.httpClient.post<T>(
            url,
            JSON.stringify(body),
            this.requestOptions
        );
    }

    public postRequestWithFormData<T = Object>(
        url: string,
        formData: FormData
    ) {
        this.updateHeader(null);
        return this.httpClient.post<T>(
            url,
            formData,
            this.requestOptionsImplicit
        );
    }

    public putRequest<T = Object>(url: string, body: any) {
        this.updateHeader(null);
        return this.httpClient.put<T>(
            url,
            JSON.stringify(body),
            this.requestOptions
        );
    }

    public deleteRequest<T = Object>(url: string) {
        this.updateHeader(null);
        return this.httpClient.delete<T>(url, this.requestOptions);
    }

    //LOW: Leaving this commented out since we wont need NST keys initially. Need to find better solution to resolve the crypto dependency
    /*   public encodeSession(secretKey: string, nst: string): string {
    const algorithm: TAlgorithm = 'HS512'; // HS512 to sign the token

    const session: any = {
        NST_KEY : nst,
        iat : Math.round(Date.now() / 1000)
    };

    return encode(session, secretKey, algorithm);
  } */
}
