<ion-header>
    <app-page-header [hideBorder]="true" pageTitle="Sign up"></app-page-header>
</ion-header>
<ion-content>
    <section>
        <div class="text-center">
            <div class="mt-2 mb-4">
                <img
                  class="light-mode w-75"
                  src="../assets/images/pelipost_logo_horizontal_LightMode.svg"
                />
                <img
                  class="dark-mode w-75"
                  src="../assets/images/pelipost_logo_horizontal_DarkMode.svg"
                />
            </div>
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col size="12" sizeMd="6" sizeXl="3">
                        <app-create-account-form></app-create-account-form>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <p>
                <small>
                    By signing up, you agree to Pelipost’s Terms & Conditions
                    and Privacy Policy.
                </small>
            </p>
        </div>
    </section>
</ion-content>
