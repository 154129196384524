<section
    [@visibilityChanged]
    class="
        h-100
        text-center
        modal
        d-flex
        justify-content-center
        align-items-center
    "
>
    <div class="modal-body text-center w-100">
        <div class="ion-text-end">
            <ion-icon
                name="close-circle-outline"
                (click)="closeModal()"
                size="large"
            ></ion-icon>
        </div>
        <ion-thumbnail class="mb-3">
            <img src="../assets/icon/confirmation_icon.png" />
        </ion-thumbnail>
        <div class="content">
            <h3>Use Promo Code</h3>
            <p>
                Enter your promo code below and tap Apply Code. Limit one code
                per order.
            </p>
            <form [formGroup]="couponForm" novalidate>
                <ion-item lines="none">
                    <ion-label position="stacked">Enter Code</ion-label>
                    <ion-input
                        type="text"
                        formControlName="discountCode"
                        class="text-center"
                    ></ion-input>
                </ion-item>
            </form>
        </div>

        <div class="buttons">
            <ion-button class="elongated" (click)="applyCoupon()">
                Apply Code
            </ion-button>
            <ion-button
                class="mt-2"
                fill="clear"
                size="small"
                color="primary"
                (click)="closeModal()"
            >
                Cancel
            </ion-button>
        </div>
    </div>
</section>
