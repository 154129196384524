<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Forgot Password"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template *ngIf="forgotPasswordForm && !displayConfirmation">
    <section id="page-content">
      <div class="text-center">
        <p>We will send you an email with a new password</p>
        <form [formGroup]="forgotPasswordForm" novalidate>
          <ion-item lines="none">
            <ion-label position="stacked">EMAIL ADDRESS</ion-label>
            <ion-input
              type="email"
              placeholder="sample@pelipost.com"
              formControlName="email"
              required
            ></ion-input>
            <ion-text
              class="error-text"
              color="danger"
              *ngIf="forgotPasswordForm.get('email').invalid && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)"
            >
              <div *ngIf="forgotPasswordForm.get('email').hasError('required')">
                Email is required
              </div>
              <div *ngIf="forgotPasswordForm.get('email').hasError('email')">
                Invalid email format
              </div>
            </ion-text>
          </ion-item>
        </form>
      </div>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Email me a recovery link"
      (primaryCtaClick)="submit()"
      [primaryCtaDisabled]="!forgotPasswordForm.valid || isLoading"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
