<ion-header>
  <app-header
    [showPelipostLogo]="false"
    [showCloseButton]="true"
    [pageTitle]="'Order ' + order?.Id"
    (closeButtonClick)="close()"
  ></app-header>
</ion-header>
<ion-content>
  <app-page-template [stickyActionButtons]="false">
    <section id="page-title" class="py-2">
      <ion-row class="status-row px-4">
        <ion-col size="3" [ngClass]="{'active': customerOrderStatusEnum >= 2}">
          <img [src]="'assets/icon/processing' + (customerOrderStatusEnum >= 2 ? '_active' : '') + '.svg'" />
        </ion-col>
        <ion-col size="3" [ngClass]="{'active': customerOrderStatusEnum >= 3}">
          <img [src]="'assets/icon/printing' + (customerOrderStatusEnum >= 3 ? '_active' : '') + '.svg'" />
        </ion-col>
        <ion-col size="3" [ngClass]="{'active': customerOrderStatusEnum >= 4}">
          <img [src]="'assets/icon/shipped' + (customerOrderStatusEnum >= 4 ? '_active' : '') + '.svg'" />
        </ion-col>
        <ion-col size="3" [ngClass]="{'active': customerOrderStatusEnum >= 5}">
          <img [src]="'assets/icon/arrived' + (customerOrderStatusEnum === 5 ? '_active' : '') + '.svg'" />
        </ion-col>
      </ion-row>
      <h1 class="mt-2 mb-3">{{ order?.CustomerOrderStatus }}</h1>
      <p *ngIf="customerOrderStatusEnum === 2">We have received your order and it will be processed shortly.</p>
      <p *ngIf="customerOrderStatusEnum === 3">Your order is being printed by our staff and will be shipped soon.</p>
      <p *ngIf="customerOrderStatusEnum === 4">Your order has shipped and will arrive at the facility in {{shippingMethod?.MinTransitDays}}-{{shippingMethod?.MaxTransitDays}} business days.</p>
      <p *ngIf="customerOrderStatusEnum === 4" class="subtext">Note: Saturday, Sunday, and holidays are not business days.</p>
      <p *ngIf="customerOrderStatusEnum === 5">Your order has reached the facility and is undergoing correctional screening and inspection.</p>
      <p *ngIf="customerOrderStatusEnum === 5" class="subtext">NOTICE: Please allow up to 7 business days for correctional staff to screen & deliver your order to your loved one.</p>
    </section>
    <section id="page-content">
      <section *ngIf="orderStatusEnum === 20" id="questions" class="px-0 pt-2 pb-3 text-center mb-4">
        <h5 class="ion-text-uppercase my-2">Made a mistake?</h5>
        <div class="btn custom-button button-outline px-4 mt-1" (click)="cancelOrder()">
          <ion-text color="primary">
            Cancel Order
          </ion-text>
        </div>
      </section>
      <section
        *ngIf="trackingNumber"
        class="px-0 pt-2 pb-3 text-center mb-4"
      >
        <div
          class="btn custom-button button-outline d-block mx-3 mt-1 py-2 position-relative"
          (click)="onTrackMyPackageClick()"
        >
          <img src="assets/icon/shipped_active.svg" class="track-package-icon ml-3" />
          <ion-text color="primary">
            Track my package
          </ion-text>
        </div>
      </section>
      <app-order-overview
        [lineItems]="order?.LineItems"
        [orderTotals]="orderTotals"
        [contact]="contact"
        [facility]="facility"
      ></app-order-overview>
      <section class="mb-3 mt-4 px-0">
        <h5 class="mb-1 ion-text-uppercase">Your Photos</h5>
        <app-thumbnails [photos]="uploadedPhotos"></app-thumbnails>
      </section>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Return Home"
      (primaryCtaClick)="returnHome()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>