import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { defer, firstValueFrom, from, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { HomeService } from "src/app/core/services/service-proxies/home.service";
import { CustomerInfo } from "src/app/shared/model/account/login";
import { JwtUtils } from "../util/JwtUtils";
import { AuthService } from "./auth.service";
import { CartService } from "./cart.service";
import { StartupService } from "./pelipost-service-proxies/startup-service";
import { GoogleAuthService } from "./service-proxies/external-providers/google-auth.service";
import { StorageService } from "./storage.service";
import { UserSegmentService } from "./user-segment.service";
import { PostBootstrapService } from "./post-bootstrap.service";

export interface NotificationBody {
    bigPicture?: string;
    body: string;
    from?: string;
    id?: string;
    itemId?: string;
    itemType?: string;
    messageType?: string;
    sent_time?: string;
    show_notification?: string;
    title?: string;
    ttl?: string;
}

@Injectable({
    providedIn: "root"
})
export class AppStartService {
    public primaryThemeColor: string;
    public topBarBackgroundcolor: string;
    public topBarTextColor: string;
    public bottomBarBackgroundColor: string;
    public bottomBarActiveColor: string;
    public bottomBarInactiveColor: string;
    public appLogoUrl: string;
    public information: any[] = [];
    public fromNotification: boolean;
    public appResumed: boolean;

    constructor(
        private storageService: StorageService,
        private authService: AuthService,
        private homeService: HomeService,
        private platform: Platform,
        private cartService: CartService,
        private googleAuthService: GoogleAuthService,
        private startupService: StartupService,
        private userSegmentService: UserSegmentService,
        private postBootstrapService: PostBootstrapService
    ) {}

    async load() {
        const firstRun =
            (
                await this.storageService.getObject<{ val: boolean }>(
                    StorageService.FIRST_RUN
                )
            )?.val ?? true;

        if (firstRun) {
            // First run
            await this.storageService.setObject(StorageService.FIRST_RUN, {
                val: false
            });

            // Login
            this.authService.isLoggedIn = false;
            await this.storageService.setObject(StorageService.IS_LOGGED_IN, {
                val: false
            });

            // Device ID
            await this.storageService.setObject(StorageService.DeviceID, {
                val: this.deviceIDGenerator("4")
            });
            const deviceID = await this.storageService.getObject<{
                val: string;
            }>(StorageService.DeviceID);
            this.authService.deviceID = deviceID.val;
        } else {
            const token = await this.storageService.getObject<{ val: string }>(
                StorageService.KEY_TOKEN
            );
            if (JwtUtils.isJwtExpired(token?.val)) {
                await this.storageService.removeItem(StorageService.KEY_TOKEN);
                await this.storageService.setObject(
                    StorageService.IS_LOGGED_IN,
                    false
                );
                await this.storageService.removeItem(
                    StorageService.CUSTOMER_INFO
                );
            }
            const isLoggedIn = await this.storageService.getObject<{
                val: boolean;
            }>(StorageService.IS_LOGGED_IN);
            const deviceID = await this.storageService.getObject<{
                val: string;
            }>(StorageService.DeviceID);

            if (isLoggedIn?.val) {
                this.authService.isLoggedIn = true;
                this.authService.token = token.val;
                this.authService.customer =
                    await this.storageService.getObject<CustomerInfo>(
                        StorageService.CUSTOMER_INFO
                    );
            } else {
                this.authService.isLoggedIn = false;
            }
            this.authService.deviceID = deviceID.val;
        }

        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        this.toggleDarkTheme(prefersDark.matches);
        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addEventListener(
            "change",
            (mediaQuery: MediaQueryListEvent) =>
                this.toggleDarkTheme(mediaQuery.matches)
        );

        // Offload API calls to chained observable so that this method completes
        // and app component is loaded even if network connection is weak/lost
        this.postBootstrapService.append(
            defer(() =>
                this.homeService
                    .postAppStart({
                        Data: {
                            DeviceTypeId: this.platform.is("ios") ? 5 : 10,
                            SubscriptionId: this.authService.fcmToken
                        }
                    })
                    .pipe(
                        catchError(() => {
                            throw new Error("Failed to run post-app start");
                        })
                    )
            ),
            defer(() =>
                this.fetchAppLandingSettings().pipe(
                    catchError(() => {
                        throw new Error("Failed to fetch app landing settings");
                    })
                )
            ),
            defer(() =>
                this.fetchCategoryTree().pipe(
                    catchError(() => {
                        throw new Error("Failed to fetch category tree");
                    })
                )
            ),
            defer(() =>
                from(this.googleAuthService.initialize()).pipe(
                    catchError(() => {
                        throw new Error("Failed to fetch app landing settings");
                    })
                )
            ),
            defer(() =>
                from(this.refreshSmsNumber()).pipe(
                    catchError(() => {
                        throw new Error("Failed to refresh smsNumber");
                    })
                )
            )
        );

        this.platform.resume.subscribe(() => {
            this.appResumed = true;
        });

        if (this.authService.isLoggedIn && this.authService.customer?.Email) {
            await this.userSegmentService.populateUserSegments();
        }
    }

    private fetchAppLandingSettings(): Observable<Object> {
        return this.homeService.fetchAppLandingSettings().pipe(
            tap((res: any) => {
                this.homeService.appLandingData = res;
                this.homeService.stringResource = res.Data.StringResources;
                this.cartService.cartNumber =
                    res.Data.TotalShoppingCartProducts;

                this.primaryThemeColor = res.Data.PrimaryThemeColor;
                this.topBarBackgroundcolor = res.Data.TopBarBackgroundColor;
                this.topBarTextColor = res.Data.TopBarTextColor;
                this.bottomBarBackgroundColor =
                    res.Data.BottomBarBackgroundColor;
                this.bottomBarActiveColor = res.Data.BottomBarActiveColor;
                this.bottomBarInactiveColor = res.Data.BottomBarInactiveColor;

                this.appLogoUrl = res.Data.LogoUrl;
            })
        );
    }

    private fetchCategoryTree(): Observable<Object> {
        return this.homeService.fetchCategoryTree().pipe(
            tap((res: any) => {
                this.information = res.Data;
            })
        );
    }

    private deviceIDGenerator(char: string) {
        const str = "xxxxxxxx-xxxx-" + char + "xxx-yxxx-xxxxxxxxxxxx";
        return str.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    // Add or remove the "dark" class based on if the media query matches
    private toggleDarkTheme(shouldAdd) {
        document.body.classList.toggle("dark", shouldAdd);
    }

    private async refreshSmsNumber(): Promise<void> {
        let smsNumber = (
            await firstValueFrom(this.startupService.getSettings())
        ).SmsNumber;

        // Strip all formatting
        smsNumber = smsNumber?.replace(/\D/g, "");

        await this.storageService.setString(
            StorageService.SMS_NUMBER,
            smsNumber
        );
    }
}
