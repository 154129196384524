
<div class="selected-count pl-3" *ngIf="photos.length">
  {{photos.length}} {{(photos.length > 1) ? "photos" : "photo"}} selected ({{source}})
</div>
<ion-grid>
  <ion-row class="">
    <ion-col size="4" *ngFor="let photo of photos">
      <app-selected-photo [photo]="photo" [editEnabled]="isEditMode" (deleteClick)="deleteClick.emit(photo)"></app-selected-photo>
    </ion-col>
  </ion-row>
</ion-grid>