export abstract class StringUtilities {
    public static getInitials = (
        firstName: string,
        lastName: string
    ): string => {
        if (!firstName && !lastName) {
            return "N/A";
        }
        const firstInitial =
            firstName && firstName[0] ? firstName[0].toUpperCase() : " ";
        const lastInitial =
            lastName && lastName[0] ? lastName[0].toUpperCase() : " ";
        return firstInitial + lastInitial;
    };

    public static getSemanticVersion(version: string): string {
        if (!version) {
            return null;
        }
        const re = new RegExp(/v?([0-9]+\.[0-9]+\.[0-9]+).*/);
        const results = version.match(re);
        if (results?.length !== 2) {
            return null;
        }
        return results[1];
    }

    public static getBuildIdFromVersion(version: string): string {
        if (!version) {
            return null;
        }
        const results = version.split("-");
        if (results?.length !== 2) {
            return null;
        }
        return results[1];
    }

    public static stripCurrencyFormatting(value: string): number | null {
        if (!value) {
            return null;
        }

        // Some numbers user parenthesis instead of hyphens for negative values
        const hasParenthesis = value.startsWith("(") && value.endsWith(")");
        if (hasParenthesis) {
            value = "-" + value.replace(/[()]/g, "");
        }

        // Filter string based on numbers, periods, and hyphens
        const replacedString = value.replace(/[^0-9\.-]+/g, "");
        if (replacedString.length) {
            return parseFloat(replacedString);
        } else {
            return null;
        }
    }
}
