import { Component, Input } from "@angular/core";
import {
    SignInWithApple,
    AppleSignInErrorResponse,
    ASAuthorizationAppleIDRequest
} from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { ModalController } from "@ionic/angular";
import { LoadingService } from "src/app/core/services";
import { ErrorReason } from "src/app/core/services/analytics.service";
import { LoginWorkflowService } from "src/app/core/services/login-workflow.service";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import { ExternalAuthenticationParameters } from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-apple-login",
    templateUrl: "./apple-login.component.html",
    styleUrls: ["./apple-login.component.scss"]
})
export class AppleLoginComponent extends BasePage {
    @Input() providerSchemeName: string;
    @Input() type = "login";

    constructor(
        private accountService: AccountService,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private loginWorkflowService: LoginWorkflowService,
        private signInWithApple: SignInWithApple
    ) {
        super();
    }

    public async login(): Promise<void> {
        try {
            const res = await this.signInWithApple.signin({
                requestedScopes: [
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
                ]
            });

            const parameters = new ExternalAuthenticationParameters();
            parameters.providerSystemName = this.providerSchemeName;
            parameters.externalIdentifier = res.user;
            parameters.accessToken = res.authorizationCode;

            const name = `${res.fullName?.givenName} ${res.fullName?.familyName}`;
            if (name) {
                parameters.externalDisplayIdentifier = `${res.fullName?.givenName} ${res.fullName?.familyName}`;
            }

            await this.loadingService.show();

            await this.accountService
                .checkAndMigrateOldUser(parameters)
                .toPromise();

            const loginResponse = await this.loginService
                .postExternalAuthentication(parameters)
                .toPromise();
            if (loginResponse && loginResponse["ErrorList"]?.length) {
                this.loginWorkflowService.loginFailed("apple");
                await this.showErrorModal(
                    this.modalController,
                    loginResponse.ErrorList,
                    undefined,
                    ErrorReason.AuthenticationError
                );
            } else {
                await this.postLoginRedirect(loginResponse);
            }
        } catch (error) {
            if (!this.isUserCancelledError(error)) {
                await this.showErrorModal(
                    this.modalController,
                    error,
                    undefined,
                    ErrorReason.AuthenticationError
                );
            }
        }
        await this.loadingService.dismiss();
    }

    isUserCancelledError(x: any): x is AppleSignInErrorResponse {
        return x.code === 1001;
    }

    private postLoginRedirect = async (loginResponse) => {
        const regex = /creation/gi;
        if (loginResponse.Message && regex.test(loginResponse.Message)) {
            await this.loginWorkflowService.signupRedirect(
                loginResponse,
                "apple"
            );
        } else {
            await this.loginWorkflowService.loginRedirect(
                loginResponse,
                "apple"
            );
        }
    };
}
