import { Injectable } from "@angular/core";
import { SliderModel } from "src/app/shared/model/SliderModel";
import {
    AppSettingsResponse,
    AppStartPostBody,
    BaseResponseModel,
    StringResource
} from "../../../shared/model/BaseResponse";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class HomeService {
    constructor(private baseService: BasePelipostService) { }

    stringResource: StringResource[];
    appLandingData: AppSettingsResponse;

    fetchString(key: string): string {
        if (key === undefined) {
            return "";
        }

        const tmp: StringResource = this.stringResource.find(
            (it) => it.Key === key
        );

        if (tmp === undefined) {
            return key;
        } else {
            return tmp.Value;
        }
    }

    fetchStringWithNumber(key: string, ...num: number[]): string {
        let retVal = this.fetchString(key);

        if (num.length > 0) {
            retVal = retVal.replace("{0}", num[0].toString());
        }

        if (num.length > 1) {
            retVal = retVal.replace("{1}", num[1].toString());
        }

        return retVal;
    }

    // API's
    fetchAppLandingSettings() {
        const url = this.baseService.BASE_URL + "home/applandingsetting";
        return this.baseService.getRequest(url);
    }

    postAppStart(reqBody: AppStartPostBody) {
        const url = this.baseService.BASE_URL + "appstart";
        return this.baseService.postRequest(url, reqBody);
    }

    fetchHomePageSliders(isTestGroup: boolean) {
        const url = `${this.baseService.BASE_PEL_URL}slider/homepageslider?isTestGroup=${isTestGroup}`;
        return this.baseService.getRequest<
            BaseResponseModel<Array<SliderModel>>
        >(url);
    }

    fetchProductSliders() {
        const url = this.baseService.BASE_PEL_URL + "slider/productslider";
        return this.baseService.getRequest<BaseResponseModel<Array<SliderModel>>>(url);
    }

    fetchFeaturedProduts() {
        const url = this.baseService.BASE_URL + "home/featureproducts";
        return this.baseService.getRequest(url);
    }

    fetchBestSellers() {
        const url = this.baseService.BASE_URL + "home/bestsellerproducts";
        return this.baseService.getRequest(url);
    }

    fetchHomePageCategories() {
        const url =
            this.baseService.BASE_URL + "home/homepagecategorieswithproducts";
        return this.baseService.getRequest(url);
    }

    fetchManufacturers() {
        const url = this.baseService.BASE_URL + "home/manufacturers";
        return this.baseService.getRequest(url);
    }

    fetchCategoryTree() {
        const url = this.baseService.BASE_URL + "home/categorytree";
        return this.baseService.getRequest(url);
    }
}
