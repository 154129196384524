import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
    IonDatetime,
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { DateTime } from "luxon";
import { CartService, LoadingService } from "src/app/core/services";
import { FattMerchantService } from "src/app/core/services/pelipost-service-proxies/fatt-merchant.service";
import { LocationLookupService } from "src/app/core/services/pelipost-service-proxies/location-lookup.service";
import { Address } from "src/app/shared/model/account/address";
import { NopCountry } from "src/app/shared/model/country";
import { BasePage } from "src/app/shared/pages/BasePage";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { FattJsProviderService } from "../../../services/fatt-js-provider.service";

@Component({
    templateUrl: "./edit-credit-card.page.html",
    styleUrls: ["./edit-credit-card.page.scss"]
})
export class EditCreditCardPage extends BasePage implements OnInit {
    public editCardForm: UntypedFormGroup;
    public paymentMethod: FattMerchant.PaymentMethod;
    public address: Address;
    public minDate: string;
    public maxDate: string;
    public expirationDatePickerValue: string = DateTime.now()
        .set({ day: 1 })
        .toISO();

    get nameOnCard(): AbstractControl {
        return this.editCardForm.get("nameOnCard");
    }
    get expirationDate(): AbstractControl {
        return this.editCardForm.get("expirationDate");
    }

    constructor(
        private fattMerchantService: FattMerchantService,
        private locationLookupService: LocationLookupService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private navController: NavController,
        private toastController: ToastController,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private cartService: CartService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private fattJsProviderService: FattJsProviderService
    ) {
        super();

        this.editCardForm = this.formBuilder.group({
            nameOnCard: [null, Validators.required],
            expirationDate: [null, Validators.required]
        });
    }

    async ngOnInit(): Promise<void> {
        try {
            await this.loadingService.show();
            this.setDatePickerLimits();
            const paymentMethodId = this.route.snapshot.paramMap.get("id");
            this.paymentMethod = await this.fattMerchantService
                .getPaymentMethodById(paymentMethodId)
                .toPromise();
            this.address = await this.fattJsProviderService.getAddress(
                this.paymentMethod
            );
            this.editCardForm.patchValue({
                nameOnCard: this.paymentMethod.person_name,
                expirationDate: this.parseSavedExpirationDate(
                    this.paymentMethod.card_exp
                )
            });
        } catch (err) {
            await this.showError(this.toastController, "Failed to load card.");
            this.navController.back();
        } finally {
            await this.loadingService.dismiss();
        }
    }

    public formInitialized(name: string, form: UntypedFormGroup): void {
        this.editCardForm.setControl(name, form);
    }

    private parseSavedExpirationDate(savedDate: string): string {
        if (!savedDate) {
            return savedDate;
        }

        // savedDate is MMYYYY but needs to be YYYY-MM
        return `${savedDate.slice(2)}-${savedDate.slice(0, 2)}`;
    }

    public async updateCard(): Promise<void> {
        try {
            await this.loadingService.show();
            const country = await this.locationLookupService
                .findCountryById(this.editCardForm.get("address.country").value)
                .toPromise();

            await this.updateStaxPaymentMethod(country.Data);

            const orderTotal = await this.getOrderTotal();
            await this.checkoutWorkflowService.useExistingPaymentMethod(
                this.paymentMethod,
                orderTotal
            );

            await this.navController.navigateForward("/order/review-order");
        } catch (err) {
            await this.showError(
                this.toastController,
                "Failed to update card."
            );
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private async updateStaxPaymentMethod(country: NopCountry): Promise<void> {
        this.paymentMethod.person_name = this.nameOnCard?.value?.trim();
        this.paymentMethod.card_exp = this.parseSelectedExpirationDate();
        this.paymentMethod.address_zip = this.editCardForm
            .get("address.zipPostalCode")
            .value?.trim();
        this.paymentMethod.address_country = country.ThreeLetterIsoCode;

        await this.fattMerchantService
            .updatePaymentMethod(this.paymentMethod)
            .toPromise();
    }

    private parseSelectedExpirationDate(): string {
        const date = this.expirationDate?.value?.trim();
        if (!date) {
            return date;
        }

        // Form control is YYYY-MM but needs to be saved as MMYY
        const vals = date.split("-");
        return `${vals[1]}${vals[0].slice(2)}`;
    }

    private async getOrderTotal(): Promise<string> {
        return (await this.cartService.fetchCartRes().toPromise()).Data
            .OrderTotals.OrderTotal;
    }

    public async deleteCard(): Promise<void> {
        const props = {
            modalType: "confirmation",
            headerText: "Are you sure?",
            bodyText: "You are about to delete this saved payment method.",
            primaryCtaText: "Yes",
            primaryAction: async () => {
                await this.loadingService.show();
                try {
                    await this.fattMerchantService
                        .deletePaymentMethod(this.paymentMethod.id)
                        .toPromise();
                } finally {
                    await this.loadingService.dismiss();
                }
                await this.navController.navigateBack("/order/payment/select");
                await this.modalController.dismiss();
            },
            secondaryCtaText: "No"
        };
        await this.showModal(this.modalController, props);
    }

    public expirationDateChanged(ionDateTime: IonDatetime): void {
        if (typeof ionDateTime.value !== "string") {
            return;
        }
        this.expirationDatePickerValue = ionDateTime.value;
        const parsedDate = DateTime.fromISO(ionDateTime.value);
        this.editCardForm.patchValue({
            expirationDate: parsedDate.toFormat("MM/yyyy")
        });
    }

    private setDatePickerLimits(): void {
        this.minDate = DateTime.now().set({ month: 1, day: 1 }).toISO();
        this.maxDate = DateTime.now()
            .plus({ years: 50 })
            .set({ month: 12 })
            .toISO();
    }
}
