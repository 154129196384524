import { Component } from "@angular/core";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-my-contacts",
    templateUrl: "./my-contacts.page.html",
    styleUrls: ["./my-contacts.page.scss"]
})
export class MyContactsPage extends BasePage {
    contacts: Array<Contact> = new Array<Contact>();

    constructor(private contactService: ContactService) {
        super();
    }

    async ionViewWillEnter(): Promise<void> {
        this.getContacts();
    }

    private getContacts(): void {
        this.contactService.getAllContacts().subscribe({
            next: (response) => {
                this.contacts = response.Data;
            }
        });
    }
}
