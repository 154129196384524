/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BlockBlobClient } from "@azure/storage-blob";
import { ModalController, NavController } from "@ionic/angular";
import { DateTime } from "luxon";
import { forkJoin, Observable, Subscription, throwError, timer } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { CartService } from "src/app/core/services";
import { AzurePhotoService } from "src/app/core/services/pelipost-service-proxies/azure-photo.service";
import {
    PelipostPhoto,
    PhotoSource
} from "src/app/core/services/photos.service";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { ProductDetailsService } from "src/app/core/services/service-proxies/product-details.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { AddToCartPostBody } from "src/app/shared/model/cart";
import {
    ProductDetail,
    ProductDetailData
} from "src/app/shared/model/product-detail/product-detail";
import { Constant } from "src/app/util/constant";
import { environment } from "src/environments/environment";
import { BaseOrderPage } from "../../base-order-page";
import { AnalyticsService } from "src/app/core/services/analytics.service";

@Component({
    selector: "app-uploading",
    templateUrl: "./uploading.page.html",
    styleUrls: ["./uploading.page.scss"]
})
export class UploadingPage extends BaseOrderPage implements OnInit, OnDestroy {
    isDebug = !environment.production;

    attributeId: number;
    photos: PelipostPhoto[] = [];
    message: PelipostPhoto;
    messageProduct: ProductDetail;

    currentDataRate = 5000;
    uploadDataLength: number;
    uploadDataSent: number;
    perSecondObservable: Observable<number>;
    timerSubscription: Subscription;
    uploadInProgress: boolean;
    status: string;
    statusColor = "medium";
    timeRemaining: string;
    progress = 0;
    progressBarColor = "primary";
    hasError: boolean;
    startTime: DateTime;
    averageConnectionSpeed: number;
    estimatedEndTime: DateTime;
    blockBlobClient: BlockBlobClient;
    actualEndTime: DateTime;

    constructor(
        private route: ActivatedRoute,
        private cartService: CartService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService,
        private azurePhotoService: AzurePhotoService,
        private productDetailsService: ProductDetailsService,
        private navController: NavController,
        private analyticsService: AnalyticsService,
        private modalController: ModalController
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await super.onInit(
            +this.route.snapshot.paramMap.get("productid"),
            this.contactContextService,
            this.productContextService,
            this.modalController,
            this.navController
        );

        await this.runUploadProcess();
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    public navigateBack(): void {
        this.navController.back();
    }

    async handleUploaderError(error: Error): Promise<void> {
        this.hasError = true;
        this.updateStatus(
            error?.message ??
                "There was a problem trying to upload your photos. Please try again or contact support."
        );
        console.error(error);
        await this.analyticsService.logError(error);
    }

    updateStatus(status?: string): void {
        if (this.hasError) {
            this.statusColor = "danger";
            this.progressBarColor = "danger";
            this.status = status ? status : "Unknown error occurred";
            if (this.timerSubscription && !this.timerSubscription.closed) {
                this.timerSubscription.unsubscribe();
            }
            this.uploadInProgress = false;
        } else {
            this.statusColor = "medium";
            this.progressBarColor = "primary";
            this.status = this.uploadInProgress ? "Uploading" : "Preparing";
        }
    }

    public async runUploadProcess(): Promise<void> {
        this.hasError = false;
        this.updateStatus();
        this.progress = this.uploadDataSent = 0;

        try {
            const photosToUpload = await this.preparePhotos();

            if (!this.photos || !this.photos.length) {
                throw Error("No Photos Defined");
            }
            if (this.photos.length > this.facility.MaxNumberOfPhotos) {
                const props = {
                    headerText: "Too many photos selected",
                    bodyText: `This facility only supports ${this.facility.MaxNumberOfPhotos}. Please remove some photos.`,
                    primaryCtaText: "OK"
                };

                const modal = await this.showModal(this.modalController, props);
                await modal.onDidDismiss();
                await this.navController.navigateBack(
                    `/order/print-preview/${this.product.Id}`
                );
                return;
            }
            if (!photosToUpload || !photosToUpload.length) {
                this.progress = 1.0;
            } else {
                this.uploadDataLength = photosToUpload
                    .map((p) => p.data.length)
                    .reduce((acc, size) => {
                        return acc + size;
                    });
                await this.uploadFiles(photosToUpload);
            }
            //LOW: Update cart runs every time, even if all photos have already been uploaded;
            // this is necessary in case a user removes a photo, but can be optimized
            await this.updateCart();
            await this.navigateForward();
        } catch (error) {
            await this.handleUploaderError(error);
        }
    }

    private async preparePhotos(): Promise<Array<PelipostPhoto>> {
        this.photos =
            await this.selectPhotosWorkflowService.getSelectedPhotos();
        this.message = await this.selectPhotosWorkflowService.getMessagePhoto();
        const photosToUpload = this.photos.filter((p) => !p.downloadGuid);

        if (this.message) {
            if (!this.message.downloadGuid) {
                photosToUpload.push(this.message);
            }

            this.messageProduct = (
                await this.loadMessageProduct().toPromise()
            ).Data;
        }

        return photosToUpload;
    }

    private async uploadFiles(photosToUpload: Array<PelipostPhoto>) {
        this.perSecondObservable = timer(1000, 1000);
        this.hasError = false;
        this.uploadDataSent = 0;
        this.status = "Uploading";
        this.uploadInProgress = true;
        this.startTime = DateTime.local();
        this.evaluateCompletionEstimate();
        this.timerSubscription = this.perSecondObservable.subscribe((val) => {
            const diffRemaining = this.estimatedEndTime?.diffNow([
                "hours",
                "minutes",
                "seconds"
            ]);
            this.timeRemaining =
                diffRemaining !== null && diffRemaining.toMillis() >= 0
                    ? diffRemaining.toFormat("h:mm:ss")
                    : "-:--:--";
            this.progress = this.uploadDataSent / this.uploadDataLength;
            if (this.progress === 1.0) {
                this.actualEndTime = DateTime.local();
                this.timerSubscription.unsubscribe();
                this.status = "Upload Complete";
                this.uploadInProgress = false;
            }
        });
        // Begin upload to blobStorage
        for (const photo of photosToUpload) {
            const photoUploadRequest =
                await this.azurePhotoService.uploadProductPhoto(photo);
            const azurePhotoUploadResponse =
                await photoUploadRequest.toPromise();

            const uploadedPhoto = azurePhotoUploadResponse[0].sasResponse.Data;
            const uploadedThumbnail =
                azurePhotoUploadResponse[1].sasResponse.Data;
            photo.downloadGuid = uploadedPhoto.DownloadGUID;
            photo.remoteUrl = uploadedPhoto.BlobUri;
            photo.thumbnailUrl = uploadedThumbnail.BlobUri;

            if (photo.source === PhotoSource.TextEditor) {
                await this.selectPhotosWorkflowService.storeMessagePhoto(photo);
            } else {
                await this.selectPhotosWorkflowService.storeSelectedPhotos(
                    photo
                );
            }
            //TODO: Investigate possibility of upload progress events for each file for smoother upload bar on many large photos
            this.uploadDataSent += photo.data.length;
            this.evaluateCompletionEstimate();
        }
    }

    evaluateCompletionEstimate(): void {
        const elapsed = DateTime.now().diff(this.startTime, "seconds");
        this.currentDataRate =
            elapsed.seconds > 0 ? this.uploadDataSent / elapsed.seconds : 0;
        const remaining = this.uploadDataLength - this.uploadDataSent;
        const estimateInSeconds = remaining / this.currentDataRate;
        this.estimatedEndTime = DateTime.now().plus({
            seconds: estimateInSeconds
        });
    }

    async updateCart(): Promise<void> {
        const cartResponse = await this.cartService.fetchCartRes().toPromise();
        const cart = cartResponse.Data.Cart;
        this.attributeId = this.getAttributeId(
            this.product,
            Constant.IMAGE_ATTRIBUTE
        );

        if (cart.Items.length) {
            await this.cartService.clearCart().toPromise();
        }
        // TODO: What if there are no uploaded photos? (i.e. hard refresh or navigating back/directly after clearing session data)
        // Should probably just pop a toast and kick user back to home screen instructing them to start over otherwise nothing would be added to the cart after we have cleared it
        const uploadedPhotos = this.photos.filter((p) => p.downloadGuid);
        const photoUploadActions$ = uploadedPhotos.map((p) =>
            this.addPhotoToCart(p)
        );

        if (this.message) {
            photoUploadActions$.push(
                this.addMessageToCart(this.message, this.messageProduct)
            );
        }

        await forkJoin(photoUploadActions$).toPromise();
    }

    private loadMessageProduct(): Observable<ProductDetailData> {
        return this.productDetailsService
            .fetchRelatedProducts(this.product.Id)
            .pipe(
                concatMap((val: any) =>
                    val.Data.filter((p) =>
                        p.Sku.toLowerCase().startsWith("note_")
                    )
                ),
                concatMap((val: any) => {
                    const productDetail =
                        this.productDetailsService.fetchProductDetail(val.Id);

                    return productDetail;
                }),
                catchError((error) => throwError(error))
            );
    }

    // TODO: Set the photoId that we get from azurePhotoService
    private addPhotoToCart(photo: PelipostPhoto): Observable<Object> {
        const reqBody: AddToCartPostBody = { FormValues: [] };
        reqBody.FormValues.push({
            Key: `addtocart_${this.product.Id}.EnteredQuantity`,
            Value: "1"
        });
        reqBody.FormValues.push({
            Key: `product_attribute_${this.attributeId}`,
            Value: photo.downloadGuid.toString()
        });
        return this.cartService.addToCart(this.product.Id, reqBody);
    }

    private addMessageToCart(
        photo: PelipostPhoto,
        product: ProductDetail
    ): Observable<Object> {
        const imageAttributeId = this.getAttributeId(
            product,
            Constant.IMAGE_ATTRIBUTE
        );

        const reqBody: AddToCartPostBody = { FormValues: [] };
        reqBody.FormValues.push({
            Key: `addtocart_${product.Id}.EnteredQuantity`,
            Value: "1"
        });
        reqBody.FormValues.push({
            Key: `product_attribute_${imageAttributeId}`,
            Value: photo.downloadGuid.toString()
        });
        return this.cartService.addToCart(product.Id, reqBody);
    }

    private getAttributeId(
        product: ProductDetail,
        attributeName: string
    ): number {
        const attribute = product.ProductAttributes.filter(
            (pa) => pa.Name === attributeName
        );
        if (attribute.length === 0) {
            throw Error("Image Attribute not Found.");
        }

        return attribute[0].Id;
    }

    private async navigateForward(): Promise<void> {
        if (this.facility?.DeliveryAssuranceEligible) {
            await this.navController.navigateForward(
                "order/delivery-assurance",
                {
                    replaceUrl: true
                }
            );
        } else {
            await this.navController.navigateForward("order/order-summary", {
                replaceUrl: true
            });
        }
    }

    async resetCart(): Promise<void> {
        await this.cartService.clearCart().toPromise();
        await this.selectPhotosWorkflowService.resetWorkflow();
        await this.navController.navigateBack("order/select-product/photos");
    }
}
