import { ModalController, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { Facility } from "src/app/shared/model/facility";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";

// TODO: Rename as this no longer corresponds 1-to-1 with all order-related pages
// TODO: Should currentCart be added? Likely, but need to review
export class BaseOrderPage extends BasePage {
    contact: Contact;
    facility: Facility;
    product: ProductDetail;
    protected subscriptions: Array<Subscription> = new Array<Subscription>();

    async onInit(
        productId: number,
        contactContextService: ContactContextService,
        productContextService: ProductContextService,
        modalController: ModalController,
        navController: NavController
    ): Promise<void> {
        this.resolveContact(
            contactContextService,
            modalController,
            navController
        );
        this.resolveFacility(
            contactContextService,
            modalController,
            navController
        );
        this.resolveProduct(
            productContextService,
            modalController,
            navController
        );

        await contactContextService.getContactContext().toPromise();
        if (
            productId &&
            (productContextService.product$ === null ||
                productId !== this.product?.Id)
        ) {
            await productContextService.setProductId(productId);
        }
    }

    resolveContact(
        contactContextService: ContactContextService,
        modalController: ModalController,
        navController: NavController
    ): void {
        const subscription = contactContextService.contact$.subscribe({
            next: (r) => {
                this.contact = r;
            },
            error: () => {
                void this.showModal(modalController, {
                    modalType: "error",
                    headerText: "Unable to load contact",
                    bodyText:
                        "Please contact an administrator if this issue persists.",
                    primaryCtaText: "Okay",
                    actionType: "close"
                });
                void navController.navigateBack("/home");
            }
        });
        this.subscriptions.push(subscription);
    }

    resolveFacility(
        contactContextService: ContactContextService,
        modalController: ModalController,
        navController: NavController
    ): void {
        const subscription = contactContextService.facility$.subscribe({
            next: (f) => {
                this.facility = f;
            },
            error: () => {
                void this.showModal(modalController, {
                    modalType: "error",
                    headerText: `Unable to load facility for ${this.contact?.FirstName} ${this.contact?.LastName}`,
                    bodyText:
                        "Please contact an administrator if this issue persists.",
                    primaryCtaText: "Okay",
                    actionType: "close"
                });
                void navController.navigateBack("/home");
            }
        });
        this.subscriptions.push(subscription);
    }

    resolveProduct(
        productContextService: ProductContextService,
        modalController: ModalController,
        navController: NavController
    ): void {
        const subscription = productContextService.product$.subscribe({
            next: (p) => {
                this.product = p;
            },
            error: () => {
                void this.showModal(modalController, {
                    modalType: "error",
                    headerText: "Unable to load product",
                    bodyText:
                        "Please contact an administrator if this issue persists.",
                    primaryCtaText: "Okay",
                    actionType: "close"
                });
                void navController.navigateBack("/home");
            }
        });
        this.subscriptions.push(subscription);
    }

    onDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }
}
