import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class SmsService {
    private smsNumber: string | null;

    constructor(private storageService: StorageService) {}

    async getHrefValue(
        defaultMessage: string | undefined = undefined
    ): Promise<string | null> {
        const smsNumber = await this.getSmsNumber();
        if (!smsNumber) {
            return null;
        }

        let value = `sms:+1${this.smsNumber}`;
        if (defaultMessage) {
            const encodedDefaultMessage = encodeURIComponent(defaultMessage);
            value += `?&body=${encodedDefaultMessage}`;
        }

        return value;
    }

    private async getSmsNumber(): Promise<string | null> {
        this.smsNumber ??= await this.storageService.fetchString(
            StorageService.SMS_NUMBER
        );

        return this.smsNumber;
    }
}
