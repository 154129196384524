<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Inmate ID"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <p>This is given by your loved one's facility which can be a combination of letters and numbers.</p>
    </section>
    <section id="page-content">
      <form [formGroup]="contactInmateIdForm" novalidate>
        <ion-item lines="none" class="mb-5">
          <ion-label position="stacked">INMATE ID</ion-label>
          <ion-input type="text"
            placeholder="ABC123"
            formControlName="inmateId"
            required
            id="input-inmate-id"></ion-input>
          <ion-text class="error-text"
            color="danger"
            *ngIf="
            contactInmateIdForm.get('inmateId').invalid &&
            (contactInmateIdForm.get('inmateId').dirty ||
            contactInmateIdForm.get('inmateId').touched)
          ">
            <div>
              Inmate ID is required.
            </div>
          </ion-text>
        </ion-item>
      </form>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      primaryCtaId="btn-contact-id-continue"
      (primaryCtaClick)="continue()"
      [primaryCtaDisabled]="!contactInmateIdForm.valid"
      secondaryCtaLabel="I don't know their ID"
      (secondaryCtaClick)="findInmateId()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
