<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Inmate's Name"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <p>Please enter your loved one’s full name below:</p>
    </section>
    <section id="page-content">
      <form [formGroup]="contactNameForm"
        novalidate>
        <ion-item lines="none">
          <ion-label position="stacked">INMATE FIRST NAME</ion-label>
          <ion-input type="name"
            placeholder="First"
            formControlName="firstName"
            required
            id="input-contact-first-name"></ion-input>
          <ion-text class="error-text"
            color="danger"
            *ngIf="
            contactNameForm.get('firstName').invalid &&
            (contactNameForm.get('firstName').dirty ||
              contactNameForm.get('firstName').touched)
          ">
              Inmate first Name must be at least 2 characters.
          </ion-text>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">INMATE LAST NAME</ion-label>
          <ion-input type="family-name"
            placeholder="Last"
            formControlName="lastName"
            required
            id="input-contact-last-name"></ion-input>
          <ion-text class="error-text"
            color="danger"
            *ngIf="
            contactNameForm.get('lastName').invalid &&
            (contactNameForm.get('lastName').dirty ||
              contactNameForm.get('lastName').touched)
          ">
              Inmate last Name must be at least 2 characters.
          </ion-text>
        </ion-item>
        <ion-text class="error-text" color="danger">
          <ul>
            <li *ngFor="let item of ErrorList">{{ item }}</li>
          </ul>
        </ion-text>
      </form>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      primaryCtaId="btn-contact-name-continue"
      (primaryCtaClick)="continue()"
      [primaryCtaDisabled]="!contactNameForm.valid"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
