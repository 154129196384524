<ion-header>
  <app-header [showPelipostLogo]="false" pageTitle="My Contacts">
    <img
      slot="end"
      class="pr-2 add-contact"
      [src]="['../assets/icon/add_icon_3x.png']"
      [routerLink]="['../new-contact']"
      alt="Add Contact"
    >
  </app-header>
</ion-header>
<ion-content>
<section class="p-0 contacts">
  <ion-card
    *ngFor="let contact of contacts"
    class="mb-3"
    [routerLink]="['/contacts/edit-contact', contact.Id]"
  >
    <div class="d-flex align-items-center">
      <div class="px-3 py-2">
        <app-contact-identity
          [contact]="contact"
        ></app-contact-identity>
      </div>
      <div class="pr-3 ml-auto">
        <ion-icon name="pencil-sharp" size="large" color="primary"></ion-icon>
      </div>
    </div>
  </ion-card>
</section>
</ion-content>