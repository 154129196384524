import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { LoadingService } from "src/app/core/services";
import { ErrorReason } from "src/app/core/services/analytics.service";
import {
    PelipostPhoto,
    PhotoDimension,
    PhotoService,
    PhotoSource
} from "src/app/core/services/photos.service";
import { GooglePhotosApiService } from "src/app/core/services/service-proxies/external-providers/google-photos-api.service";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";

type SelectablePelipostPhoto = PelipostPhoto & { selected: boolean };

@Component({
    selector: "app-gallery-photo-modal",
    templateUrl: "./gallery-photo-modal.component.html",
    styleUrls: ["./gallery-photo-modal.component.scss"],
    animations: [
        trigger("visibilityChanged", [
            transition(":enter", [
                style({ transform: "translateY(100%)" }),
                animate("300ms ease-out", style({ transform: "translateY(0)" }))
            ]),
            transition(":leave", [
                style({ transform: "translateY(0)" }),
                animate(
                    "300ms ease-in",
                    style({ transform: "translateY(-100%)" })
                )
            ])
        ])
    ]
})
export class GalleryPhotoModalComponent implements OnInit {
    @Input() photos: Array<PelipostPhoto>;
    @Input() photoSource: PhotoSource;
    @Input() sourceCapacity: number;

    selectablePhotos = new Array<SelectablePelipostPhoto>();
    loaded = false;
    photoLimitReached = false;

    constructor(
        private loadingService: LoadingService,
        private googlePhotosApiService: GooglePhotosApiService,
        private photoService: PhotoService,
        private modalController: ModalController
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadingService.show();
        if (this.photoSource === PhotoSource.Facebook) {
            // TODO: Remove this photo as we no longer support facebook photos
        } else if (this.photoSource === PhotoSource.Google) {
            await this.loadGooglePhotos();
        }
    }

    private async loadGooglePhotos(): Promise<void> {
        try {
            const result = await this.googlePhotosApiService.getPhotos();
            if (!result?.mediaItems?.length) {
                return;
            }
            result.mediaItems.forEach((photo) => {
                this.loadExternalPhoto(
                    photo.baseUrl,
                    parseInt(photo.mediaMetadata.width, 10),
                    parseInt(photo.mediaMetadata.height, 10),
                    photo.id
                );
            });
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            console.error(err.message);
            await this.displayErrorModal();
        } finally {
            this.loaded = true;
            await this.loadingService.dismiss();
        }
    }

    private async displayErrorModal(): Promise<void> {
        await this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: "Unable to get photos",
                bodyText: `We could not load photos from ${this.photoSource}. Please try again later.`,
                primaryCtaText: "Okay",
                actionType: "close",
                errorReason: ErrorReason.ExternalProviderPhotos
            }
        });
        await modal.present();
    }

    private loadExternalPhoto(
        source: string,
        width: number,
        height: number,
        id: string
    ): void {
        const image = {
            data: source,
            dimension: {
                width,
                height
            } as PhotoDimension,
            source: this.photoSource,
            id
        } as SelectablePelipostPhoto;
        const photoIndex = this.photos.findIndex(
            (p) => p.source === this.photoSource && p.id && p.id === image.id
        );
        if (photoIndex !== -1) {
            image.selected = true;
        }
        this.selectablePhotos.push(image);
    }

    async dismiss(): Promise<void> {
        await this.loadingService.show();
        const selectedPhotos = this.selectablePhotos.filter(
            (p) => p.selected && p.source === this.photoSource
        );
        await this.modalController.dismiss(selectedPhotos);
        await this.loadingService.dismiss();
    }

    async toggleSelectPhoto(photo: SelectablePelipostPhoto): Promise<void> {
        if (!photo.selected) {
            await this.photoService.removeOriginalPhotoData(
                photo.id,
                photo.source
            );
        }
        if (!photo.selected && this.photoLimitReached) {
            return;
        }
        photo.selected = !photo.selected;
        const selectedCount = this.selectablePhotos.filter(
            (p) => p.selected
        ).length;
        this.photoLimitReached = selectedCount >= this.sourceCapacity;
    }
}
