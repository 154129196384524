import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { StringUtilities } from "src/app/util/string-utilities";
import { Contact } from "../../model/contact";

@Component({
    selector: "app-contact-avatar",
    templateUrl: "./contact-avatar.component.html",
    styleUrls: ["./contact-avatar.component.scss"]
})
export class ContactAvatarComponent implements OnChanges {
    @Input() contact: Contact;
    @Input() avatarSize: "small" | "medium" | "large" = "medium";
    @Input() isEditable = false;
    imageSrc: string;

    ngOnChanges(changes: SimpleChanges): void {
        this.setImageProperties();
    }

    private setImageProperties(): void {
        if (this.contact?.ImageUrl) {
            this.imageSrc = this.contact.ImageUrl;
        } else if (this.contact) {
            this.imageSrc = `https://placehold.co/90x90/555B6E/FFF?text=${this.getInitials()}&font=roboto`;
        } else {
            this.imageSrc = null;
        }
    }

    private getInitials(): string {
        return StringUtilities.getInitials(
            this.contact.FirstName,
            this.contact.LastName
        );
    }
}
