<ion-header>
  <app-page-header
    [hideBorder]="true"
    [showBackButton]="hasError"
    pageTitle="Uploading"
  ></app-page-header>
</ion-header>
<ion-content class="mb-4">
  <app-page-template [fullWidthContent]="true">
    <section id="page-content">
      <div class="text-center my-5">
        <p class="mb-4">
          Please wait while your photos are being transmitted to Pelipost.
        </p>
      </div>
      <div class="my-5">
        <ion-grid>
          <ion-row class="ion-justify-content-around ion-align-items-center pt-5">
            <ion-col class="ion-text-right">
              <div class="icon-stack" style="display:inline-flex">
                <ion-icon name="tablet-portrait-outline" size="large" class="primary-icon"></ion-icon>
                <ion-icon name="phone-portrait-outline" size="small" class="secondary-icon-o" style="bottom:25%"></ion-icon>
              </div>
            </ion-col>
            <ion-col class="ion-text-center" size="auto">
              <ion-spinner name="dots" style="transform: scale(1.5)" paused="{{!uploadInProgress}}"></ion-spinner>
            </ion-col>
            <ion-col class="ion-text-left">
              <img src="/assets/images/pelipost_logo_only.png" height="50px" style="margin-left:0.5rem" />
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-around ion-align-items-center">
            <ion-col class="ion-text-left">
              <ion-text class="ion-text-uppercase" [color]="statusColor"><small>{{status}}</small></ion-text>
            </ion-col>
            <ion-col class="ion-text-right" *ngIf="uploadInProgress && !hasError">
              <ion-text class="ion-text-uppercase" color="medium"><small>{{timeRemaining}}</small></ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-progress-bar color="{{progressBarColor}}" value="{{progress}}"></ion-progress-bar>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="hasError" class="ion-justify-content-center">
            <app-sms-link
              label="Having trouble? Text us for help"
              defaultMessage="I'm getting errors when I try to upload my photos."
            ></app-sms-link>
          </ion-row>
        </ion-grid>
      </div>
    </section>
    <app-cta-buttons *ngIf="hasError"
      primaryCtaLabel="Retry upload"
      (primaryCtaClick)="runUploadProcess()"
      secondaryCtaLabel="Reset cart"
      (secondaryCtaClick)="resetCart()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
