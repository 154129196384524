import { Component } from "@angular/core";
import { CheckboxCustomEvent, ModalController } from "@ionic/angular";

@Component({
    selector: "app-android-multiselect-modal",
    templateUrl: "./android-multiselect-modal.component.html",
    styleUrls: ["./android-multiselect-modal.component.scss"]
})
export class AndroidMultiselectModalComponent {
    private doNotShowAgain = false;

    constructor(private modalCtrl: ModalController) {}

    public primaryAction = async (): Promise<void> => {
        await this.proceed();
    };

    public async proceed(): Promise<void> {
        await this.modalCtrl.dismiss(this.doNotShowAgain);
    }

    public doNotShowAgainChanged(event: CheckboxCustomEvent): void {
        if (event.detail.checked) {
            this.doNotShowAgain = true;
        } else {
            this.doNotShowAgain = false;
        }
    }
}
