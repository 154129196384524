import { Component } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { CustomAttributeService } from "src/app/core/services/custom-attribute.service";
import { AzurePhotoService } from "src/app/core/services/pelipost-service-proxies/azure-photo.service";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { PhotoService } from "src/app/core/services/photos.service";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { ModalProps } from "src/app/shared/components/modal/modal.component";
import { FormValue } from "src/app/shared/model/BaseResponse";
import { Facility } from "src/app/shared/model/facility";
import { FacilityAttributeModel } from "src/app/shared/model/facilityAttributeModel";
import { AzureUploadType } from "src/app/shared/model/photos/azure-photo-models";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { StringUtilities } from "src/app/util/string-utilities";
import { firstValueFrom } from "rxjs";
import { PermissionsService } from "src/app/core/services/permissions.service";

@Component({
    selector: "app-edit-contact",
    templateUrl: "./edit-contact.page.html",
    styleUrls: ["./edit-contact.page.scss"]
})
export class EditContactPage extends BasePage {
    public contactForm: UntypedFormGroup;
    public backupImageUrl: string;
    contact: Contact;
    selectedFacility: Facility;
    private attributeIdMap = new Map();
    public attributes: FacilityAttributeModel[] = [];
    public isPeliPal: boolean;
    private hasFacilityEdits = false;

    constructor(
        private route: ActivatedRoute,
        private attributeService: CustomAttributeService,
        private formBuilder: UntypedFormBuilder,
        private contactService: ContactService,
        private facilityService: FacilityService,
        private modalController: ModalController,
        private contactWorkflowService: ContactWorkflowService,
        private toastController: ToastController,
        private azurePhotoService: AzurePhotoService,
        private photoService: PhotoService,
        private navController: NavController,
        private permissionsService: PermissionsService
    ) {
        super();
        this.contactForm = this.formBuilder.group({
            firstName: [null, [Validators.required, Validators.minLength(2)]],
            lastName: [null, [Validators.required, Validators.minLength(2)]],
            inmateId: [null],
            facility: [null, Validators.required]
        });
    }

    async ionViewWillEnter(): Promise<void> {
        try {
            await this.loadContact();
            this.isPeliPal = !!this.contact.PeliPalsNumber;
            if (this.isPeliPal) {
                this.contactForm.disable();
            }

            const initials = this.getInitials(this.contact);
            this.backupImageUrl = `https://placehold.co/50x50/555B6E/FFF?text=${initials}&font=roboto`;

            const contactInContext =
                await this.contactWorkflowService.GetContactModel();
            this.hasFacilityEdits =
                contactInContext && contactInContext.Id === this.contact.Id;
            if (this.hasFacilityEdits) {
                this.contact.FacilityId = contactInContext.FacilityId;
                this.contact.InmateId = contactInContext.InmateId;
                this.contact.Attributes = contactInContext.Attributes;
            }

            await this.loadFacility();

            this.contactForm.patchValue({
                firstName: this.contact.FirstName,
                lastName: this.contact.LastName,
                facility: this.selectedFacility.Name,
                inmateId: this.contact.InmateId
            });

            if (this.selectedFacility.RequireInmateId) {
                this.contactForm.controls.inmateId.setValidators(
                    Validators.required
                );
            }

            if (this.contact.Attributes && this.contact.Attributes.length) {
                await this.loadAttributes();
            }

            await this.contactWorkflowService.ClearAll();
            this.hasFacilityEdits = false;
        } catch (error) {
            await this.showErrorModal(this.modalController, error, "back");
        }
    }

    async selectPhoto(): Promise<void> {
        const hasPermissions =
            await this.permissionsService.checkPermissions("photos");
        if (!hasPermissions) {
            return;
        }

        const galleryPhoto = await this.photoService.getThumbnailFromGallery();
        const azurePhotoUploadResponse = await firstValueFrom(
            this.azurePhotoService.uploadImageToAzure(
                galleryPhoto.data,
                AzureUploadType.Image
            )
        );
        this.contact.ImageUrl =
            azurePhotoUploadResponse.sasResponse.Data.BlobUri;
    }

    async editFacility(): Promise<void> {
        this.contact.Attributes.length = 0;
        await this.contactWorkflowService.StoreFacility(this.selectedFacility);
        await this.contactWorkflowService.StoreContactModel(this.contact);
        await this.navController.navigateForward(
            `contacts/edit-contact/${this.contact.Id}/facility`
        );
    }

    async exitWorkFlow(): Promise<void> {
        await this.navController.navigateBack("contacts/my-contacts");
    }

    async save(): Promise<void> {
        const formValues: FormValue[] = [];

        for (const attributeId in this.contactForm.controls) {
            if (this.attributeIdMap.has(attributeId)) {
                formValues.push({
                    Key: `${attributeId}`,
                    Value: this.contactForm
                        .get(attributeId)
                        .value.toString()
                        .trim()
                });
            }
        }

        this.contact.FirstName = this.contactForm
            .get("firstName")
            .value?.trim();
        this.contact.LastName = this.contactForm.get("lastName").value?.trim();
        this.contact.InmateId = this.contactForm.get("inmateId")?.value?.trim();
        this.contact.FacilityId = this.selectedFacility.Id;
        await firstValueFrom(
            this.contactService.updateContact(this.contact, formValues)
        );
        await this.showToast(
            this.toastController,
            `${this.contact.FirstName} ${this.contact.LastName} saved successfully!`
        );
        await this.navController.navigateBack("contacts/my-contacts");
    }

    async onDelete(): Promise<void> {
        const props = {
            modalType: "confirmation",
            headerText: "Are You Sure?",
            bodyText: `Are you sure you wish to delete ${this.contact.FirstName} ${this.contact.LastName}?`,
            primaryCtaText: "Yes",
            primaryAction: async () => {
                if (this.isPeliPal) {
                    await firstValueFrom(
                        this.contactService.removePeliPalsContactMapping(
                            this.contact
                        )
                    );
                } else {
                    await firstValueFrom(
                        this.contactService.deleteContact(this.contact.Id)
                    );
                }
                await this.modalController.dismiss();
                this.navController.back();
            },
            secondaryCtaText: "No",
            secondaryAction: async () => {
                await this.modalController.dismiss();
            }
        } as ModalProps;
        await this.showModal(this.modalController, props);
    }

    private async loadContact(): Promise<void> {
        const contactId = +this.route.snapshot.paramMap.get("contactid");
        const contactResponse = await firstValueFrom(
            this.contactService.getContactById(contactId)
        );
        this.contact = contactResponse.Data;
    }

    private async loadFacility(): Promise<void> {
        const facilityResponse = await firstValueFrom(
            this.facilityService.getFacilityById(this.contact.FacilityId)
        );
        this.selectedFacility = facilityResponse.Data;
    }

    // TODO: The use of any here needs to be fixed
    private async loadAttributes(): Promise<void> {
        const attributes = this.contact.Attributes;
        const attributeValues = this.hasFacilityEdits
            ? await this.contactWorkflowService.GetAttributeValues()
            : [];
        for (const attribute of attributes) {
            const attributeValue = attributeValues?.find(
                (av) => av.Id === attribute.Id
            );
            if (attributeValue) {
                attribute.DefaultValue = attributeValue.Value;
            }
        }

        // TODO: Why are we initializing this as an object then referencing it linke an array further down?
        let group: any = {};
        group = this.attributeService.prepFormControls(
            group,
            "facility_attribute_",
            attributes,
            this.attributeIdMap
        );

        for (let control in group) {
            this.contactForm.addControl(control, group[control]);
        }

        this.attributes = attributes;
    }

    private getInitials(contact: Contact): string {
        return StringUtilities.getInitials(contact.FirstName, contact.LastName);
    }
}
