<ion-row *ngIf="!displayAlternateHomeScreen && (loading || contacts.length)">
  <ion-col>
    <div class="mx-1 text-center">
      <h4 class="ion-text-uppercase">
        SELECT A CONTACT TO START A NEW ORDER
      </h4>
      <h2 class="ion-text-capitalize">My Contacts</h2>
      <div class="navigation-container">
        <div
          class="swiper-button-prev"
          (click)="slidePrev()"
          [class.disabled]="contactSliderState.isBeginningSlide"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <div
          class="swiper-button-next"
          (click)="slideNext()"
          [class.disabled]="contactSliderState.isEndSlide"
        >
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    </div>
  </ion-col>
</ion-row>
<ion-row *ngIf="!displayAlternateHomeScreen && (loading || contacts.length)">
  <ion-col>
    <swiper-container
      class="contact-slides mx-1"
      [spaceBetween]="contactOptions.spaceBetween"
      [slidesPerView]="contactOptions.slidesPerView"
      #contactSlider
      (ionSlideDidChange)="slideDidChange()"
    >
      <!-- Skeleton text -->
      <swiper-slide
        *ngIf="loading"
        class="contact-slide"
      >
        <ion-card class="contact ion-no-margin">
          <app-contact-avatar
            class="image"
            [contact]="null"
            [avatarSize]="'large'"
          ></app-contact-avatar>
          <div class="d-flex flex-column align-items-center">
            <ion-skeleton-text
              animated
              class="skeleton-contact-name pb-1"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="skeleton-facility-name"
            ></ion-skeleton-text>
          </div>
          <div class="p-2">
            <ion-skeleton-text
              animated
              class="skeleton-order-cta"
            ></ion-skeleton-text>
          </div>
        </ion-card>
      </swiper-slide>

      <!-- Actual contacts -->
      <swiper-slide id="slider-contacts" class="contact-slide" *ngFor="let contact of contacts" (click)="disableOrderCtas ? null : selectContact(contact)">
        <ion-card class="contact ion-no-margin p-2">
          <app-contact-avatar
            class="image"
            [contact]="contact"
            [avatarSize]="'large'"
          ></app-contact-avatar>
          <div class="flex-fill">
            <ion-card-title class="title ion-text-capitalize">
              {{contact.FirstName}} {{contact.LastName}}
            </ion-card-title>
            <ion-card-subtitle
              color="medium"
              class="subtitle ion-text-capitalize"
            >
              {{contact.facilityName}}
            </ion-card-subtitle>
          </div>
          <ion-button expand="block" color="primary" id="btn-contact-{{contact?.Id}}-start-order">
            {{contact?.Id === currentContact?.Id && currentCart?.Items?.length
              ? 'Continue Order' : 'Start Order'}}
          </ion-button>
        </ion-card>
      </swiper-slide>
      <swiper-slide class="contact-slide" [routerLink]="['/contacts/new-contact']">
        <ion-card class="contact add-contact-card ion-no-margin">
          <ion-img
            class="image"
            src="/assets/icon/+_outline@3x.png"
          ></ion-img>
          <div></div>
          <a class="primary-link-btn">Add a contact</a>
        </ion-card>
      </swiper-slide>
    </swiper-container>
  </ion-col>
</ion-row>
<div class="add-contact p-4" *ngIf="!loading && !contacts.length && !displayAlternateHomeScreen">
  <ion-img class="image" src="/assets/icon/pelipost_icon@2x.png"></ion-img>
  <div>
    <ion-text><h2>Start A New Order</h2></ion-text>
    <ion-text>
      <p>You need to create a new contact to start a new order.</p>
    </ion-text>
  </div>
  <a class="cta-primary" [routerLink]="['/contacts/new-contact']">
    Get Started
  </a>
</div>
<div *ngIf="displayAlternateHomeScreen" class="d-flex justify-content-center align-items-center">
    <ion-button
      *ngIf="contacts[0]"
      color="primary"
      class="alt-primary-cta py-4"
      [disabled]="disableOrderCtas"
      (click)="selectContact(contacts[0])"
    >
      <span class="text-uppercase">{{currentCart?.Items?.length ? 'Continue Order' : 'Start Order'}}</span>
    </ion-button>
    <ion-button
      *ngIf="!contacts[0]"
      color="primary"
      class="alt-primary-cta py-4"
      [routerLink]="['/contacts/new-contact']"
    >
      <span class="text-uppercase">Get Started</span>
    </ion-button>
</div>