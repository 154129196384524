<ion-header>
    <app-page-header
        pageTitle="Edit Contact"
        (backButtonClick)="exitWorkFlow()"
    ></app-page-header>
</ion-header>
<ion-content>
    <app-page-template [stickyActionButtons]="false">
        <section id="page-content">
            <form [formGroup]="contactForm" novalidate>
                <div
                    class="d-flex flex-column align-items-center justify-content-center mt-4"
                    *ngIf="contact"
                >
                    <ion-avatar>
                        <img src="{{ contact.ImageUrl || backupImageUrl }}" />
                    </ion-avatar>
                    <a
                        *ngIf="!isPeliPal"
                        class="text-small mt-2"
                        href="javascript:void(0)"
                        (click)="selectPhoto()"
                    >
                        {{ contact.ImageUrl ? "Edit" : "Add" }} Photo
                    </a>
                    <div *ngIf="isPeliPal" class="w-50 my-2">
                        <img
                            class="light-mode"
                            src="../assets/images/pelipals_logo.png"
                        />
                        <img
                            class="dark-mode"
                            src="../assets/images/pelipals_logo_white.png"
                        />
                    </div>
                </div>
                <ion-item lines="none">
                    <ion-label position="stacked">INMATE FIRST NAME</ion-label>
                    <ion-input
                        type="name"
                        placeholder="First"
                        formControlName="firstName"
                        required
                    ></ion-input>
                    <ion-text
                        class="error-text"
                        color="danger"
                        *ngIf="contactForm.get('firstName').invalid && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)"
                    >
                        Inmate first Name must be at least 2 characters.
                    </ion-text>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">INMATE LAST NAME</ion-label>
                    <ion-input
                        type="family-name"
                        placeholder="Last"
                        formControlName="lastName"
                        required
                    ></ion-input>
                    <ion-text
                        class="error-text"
                        color="danger"
                        *ngIf="contactForm.get('lastName').invalid && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)"
                    >
                        Inmate last Name must be at least 2 characters.
                    </ion-text>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">INMATE ID</ion-label>
                    <ion-input
                        type="text"
                        placeholder="ABC123"
                        formControlName="inmateId"
                        required
                    ></ion-input>
                    <ion-text
                        class="error-text"
                        color="danger"
                        *ngIf="contactForm.get('inmateId').invalid && (contactForm.get('inmateId').dirty || contactForm.get('inmateId').touched)"
                    >
                        Inmate ID is required.
                    </ion-text>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">FACILITY</ion-label>
                    <ion-input
                        placeholder="No Facility Selected"
                        formControlName="facility"
                        readonly="true"
                    ></ion-input>
                    <ion-text
                        *ngIf="!isPeliPal"
                        color="primary"
                        class="edit-btn px-2"
                        (click)="editFacility()"
                    >
                        <ion-icon name="pencil-sharp" size="large"></ion-icon>
                    </ion-text>
                    <ion-text
                        class="error-text"
                        color="danger"
                        *ngIf="contactForm.get('facility').invalid && (contactForm.get('facility').dirty || contactForm.get('facility').touched)"
                    >
                        Facility is required.
                    </ion-text>
                </ion-item>

                <app-custom-attributes
                    *ngFor="let attribute of attributes"
                    [attribute]="attribute"
                    [form]="contactForm"
                    prefix="facility_attribute_"
                ></app-custom-attributes>
            </form>
        </section>
        <app-cta-buttons
            [primaryCtaLabel]="isPeliPal ? '' : 'Save'"
            (primaryCtaClick)="save()"
            [primaryCtaDisabled]="contactForm.invalid"
            secondaryCtaLabel="Delete Contact"
            (secondaryCtaClick)="onDelete()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
