<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Correctional Facility"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <p>Please search for your loved one's facility by name, city, or zip/postal code.</p>
      <ion-searchbar
        class="text-left"
        placeholder="City, Zip Code, or Facility Name"
        [debounce]="1000"
        (ionChange)="populateFacilities($event)"
        (ionClear)="clearSearch()"
      ></ion-searchbar>
    </section>
    <section id="page-content">
      <div class="text-center text-secondary font-italic">
        <ion-spinner *ngIf="searching" class="mt-4"></ion-spinner>
        <p *ngIf="!searching && !facilityResults.length && filter?.length >= 3" class="mt-4">No results found</p>
        <p *ngIf="!facilityResults.length && filter?.length < 3" class="mt-4">Type at least 3 characters to search</p>
      </div>
      <ion-list class="mt-2">
        <ion-card
          *ngFor="let facility of facilityResults"
          detail="false"
          (click)="selectFacility(facility)"
        >
          <ion-grid>
            <ion-row class="align-items-center pl-3 py-3">
              <ion-col>
                <div class="title">{{ facility.Name }}</div>
                <div class="subtitle" *ngIf="facility.Address">{{ facility.Address.Address1 }}</div>
                <div *ngIf="facility.Address && facility.Address.Address2">{{ facility.Address.Address2 }}</div>
                <div class="subtitle" *ngIf="facility.Address">
                  {{ facility.Address.City }}, 
                  <span *ngIf="facility.Address.StateProvinceAbbreviation">{{ facility.Address.StateProvinceAbbreviation }} </span>
                  {{ facility.Address.ZipPostalCode }}
                  <span *ngIf="!facility.Address.StateProvinceAbbreviation">{{ facility.Address.CountryName }} </span>
                </div>
                <ion-text color="primary" class="select-subfacility" *ngIf="facility.FacilityType === 0">Select Subfacility</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-list>
    </section>
    <app-cta-buttons
      [secondaryCtaLabel]="searchAttempted ? 'Can\'t find your facility? Contact Support' : ''"
      secondaryCtaId="secondary-cta"
      (secondaryCtaClick)="openSupportLink()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
