<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button class="mb-5 p-0" icon="chevron-back-outline"
      text=""
      defaultHref="/"></ion-back-button>
    </ion-buttons>
    <div class="d-flex align-items-center justify-content-between">
      <h2>Support</h2>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
</ion-content>