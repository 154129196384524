<ion-header>
  <app-page-header
    [hideBorder]="true"
    (backButtonClick)="exitPeliPalsWorkFlow()"
  >
    <div class="w-100 mt-3 d-flex justify-content-center">
      <div class="w-75">
        <img
          class="light-mode"
          src="../assets/images/pelipals_logo.png"
        />
        <img
          class="dark-mode"
          src="../assets/images/pelipals_logo_white.png"
        />
      </div>
    </div>
  </app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <p>
        Our exclusive prepaid accounts allow your incarcerated loved one
        to prepay for photos from the outside. If your loved one has provided
        you with a PeliPals ID, then you can enter it below:
      </p>
    </section>
    <section id="page-content">
      <form [formGroup]="pelipalsIdForm"
        novalidate>
        <ion-item lines="none">
          <ion-label position="stacked">PELIPALS ID</ion-label>
          <ion-input type="text"
            placeholder="Enter here"
            formControlName="pelipalsNumber"
            required></ion-input>
          <ion-text class="error-text"
            color="danger"
            *ngIf="
            pelipalsIdForm.get('pelipalsNumber').invalid &&
            (pelipalsIdForm.get('pelipalsNumber').dirty ||
            pelipalsIdForm.get('pelipalsNumber').touched)
          ">
              PeliPals ID is required.
          </ion-text>
        </ion-item>
      </form>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      (primaryCtaClick)="continue()"
      [primaryCtaDisabled]="!pelipalsIdForm.valid"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
