import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    MenuController,
    ModalController,
    NavController,
    ViewWillEnter,
    ViewWillLeave
} from "@ionic/angular";
import { openDefaultEditor } from "@pqina/pintura";
import {
    PelipostPhoto,
    PhotoService,
    ProductSpecifications
} from "src/app/core/services/photos.service";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { ModalProps } from "src/app/shared/components/modal/modal.component";
import { BasePhotoEditorPage } from "../base-photo-editor-page";
import { PelipostProductService } from "src/app/core/services/pelipost-service-proxies/pelipost-product.service";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";

@Component({
    selector: "app-preview",
    templateUrl: "./print-preview.page.html",
    styleUrls: ["./print-preview.page.scss"]
})
export class PrintPreviewPage
    extends BasePhotoEditorPage
    implements OnInit, OnDestroy, ViewWillEnter, ViewWillLeave
{
    productSpecifications: ProductSpecifications;
    photos: PelipostPhoto[] = [];
    editorOptions = {};

    constructor(
        private route: ActivatedRoute,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService,
        private modalController: ModalController,
        private navController: NavController,
        private photoService: PhotoService,
        private pelipostProductService: PelipostProductService,
        private menuContoller: MenuController,
        private cleverTapService: CleverTapService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await super.onInit(
            +this.route.snapshot.paramMap.get("productid"),
            this.contactContextService,
            this.productContextService,
            this.modalController,
            this.navController
        );

        this.productSpecifications = this.photoService.getProductSpecifications(
            this.product
        );
        this.photos =
            (await this.selectPhotosWorkflowService.getSelectedPhotos()) ?? [];
    }

    async ionViewWillEnter(): Promise<void> {
        await this.menuContoller.enable(false, "menu-main");
    }

    async ionViewWillLeave(): Promise<void> {
        await this.menuContoller.enable(true, "menu-main");
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    async resetImage(index: number) {
        const props = {
            modalType: "confirmation",
            headerText: "Are you sure?",
            primaryCtaText: "Undo changes",
            primaryAction: async () => {
                try {
                    const photo = this.photos[index];
                    await this.photoService.resetChanges(
                        photo,
                        this.productSpecifications
                    );
                    await this.selectPhotosWorkflowService.storeSelectedPhotos(
                        photo
                    );
                    await this.modalController.dismiss();
                } catch (error) {
                    await this.showErrorModal(this.modalController, error);
                    await FirebaseCrashlytics.log({
                        message: `Failed to reset photo: ${
                            error.message ?? "Unknown error"
                        }`
                    });
                }
            },
            secondaryCtaText: "Cancel",
            secondaryAction: async () => {
                await this.modalController.dismiss();
            }
        } as ModalProps;
        await this.showModal(this.modalController, props);
    }

    async cropImage(index: number): Promise<void> {
        const photo = this.photos[index];
        const originalData = await this.photoService.getOriginalPhotoData(
            photo.id,
            photo.source
        );
        const editor = openDefaultEditor({
            utils: ["crop", "filter"],
            src: originalData ? originalData : photo.data,
            imageCropAspectRatio:
                photo.dimension.width > photo.dimension.height
                    ? photo.ratio
                    : 1 / photo.ratio
        });
        editor.on("load", ({ dest }) => {
            if (photo.history) {
                editor.history.set(photo.history);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        editor.on("process", async ({ dest }) => {
            let originalDataToStore: string;
            if (!originalData) {
                originalDataToStore = photo.data;
            } else if (!originalData.startsWith("data:")) {
                originalDataToStore = originalData;
            }

            if (originalDataToStore) {
                const originalDimensions =
                    await this.photoService.getImageDimensions(
                        originalDataToStore
                    );
                const orgBase64 =
                    await this.photoService.getBase64Image(originalDataToStore);
                const convertedOriginalData =
                    await this.photoService.getJpegData(
                        orgBase64,
                        originalDimensions.width,
                        originalDimensions.height
                    );
                await this.photoService.storeOriginalPhotoData(
                    photo.id,
                    photo.source,
                    convertedOriginalData
                );
            }
            photo.data = URL.createObjectURL(dest);
            photo.history = editor.history.get();
            await this.photoService.updateImage(
                photo,
                this.productSpecifications
            );

            await this.selectPhotosWorkflowService.storeSelectedPhotos(photo);
        });

        editor.on("loaderror", () => {
            editor.destroy();
            void this.handlePhotoEditorLoadError(this.modalController);
        });

        (editor.stores as any).src.subscribe((currentSrc) => {
            if (!currentSrc) {
                editor.destroy();
                void this.handlePhotoEditorLoadError(this.modalController);
            }
        });
    }

    async removeImage(index: number) {
        const props = {
            modalType: "confirmation",
            headerText: "Are you sure?",
            primaryCtaText: "Remove photo",
            primaryAction: async () => {
                const photo = this.photos[index];
                this.photos.splice(index, 1);
                await this.selectPhotosWorkflowService.removeSelectedPhoto(
                    photo.id
                );
                await this.photoService.removeOriginalPhotoData(
                    photo.id,
                    photo.source
                );
                await this.modalController.dismiss();
                if (this.photos.length === 0) {
                    this.navController.pop();
                }
                await this.cleverTapService.recordCartItemEvent(
                    "Item Removed From Cart",
                    this.product.Sku,
                    this.product.Name,
                    photo.source,
                    this.product.ProductPrice.PriceValue,
                    this.product.ProductPrice.PriceValue,
                    1,
                    this.facility,
                    this.contact
                );
            },
            secondaryCtaText: "Cancel",
            secondaryAction: async () => {
                await this.modalController.dismiss();
            }
        } as ModalProps;
        await this.showModal(this.modalController, props);
    }

    async submit() {
        if ((await this.hasNoteProduct()) === false) {
            await this.navController.navigateForward([
                "order/uploading",
                this.product.Id
            ]);
        } else {
            this.navController.navigateForward([
                "order/add-message",
                this.product.Id
            ]);
        }
    }

    private async hasNoteProduct(): Promise<boolean> {
        const relatedProducts = await this.pelipostProductService
            .fetchRelatedProducts(this.product.Id)
            .toPromise();
        const messageProduct = (relatedProducts as any).Data.filter((p) =>
            p.Sku.toLowerCase().startsWith("note_")
        );
        const hasNoteProduct = !!messageProduct.length;
        this.selectPhotosWorkflowService.setSkipAddMessage(!hasNoteProduct);
        return hasNoteProduct;
    }
}
