<ion-header>
    <app-page-header
        [hideBorder]="true"
        pageTitle="Change Password"
    ></app-page-header>
</ion-header>

<ion-content>
    <app-page-template [fullWidthContent]="true">
        <section id="page-content" class="full-width mx-3">
            <div *ngIf="changePasswordForm">
                <form
                    [formGroup]="changePasswordForm"
                    (ngSubmit)="save()"
                    novalidate
                >
                    <ion-item lines="none">
                        <ion-label position="stacked">OLD PASSWORD</ion-label>
                        <ion-input
                            [type]="showOldPassword ? 'text' : 'password'"
                            placeholder="************"
                            formControlName="oldPassword"
                            required
                        ></ion-input>
                        <ion-text
                            class="toggle-password"
                            color="primary"
                            (click)="toggleOldPassword()"
                        >
                            {{ showOldPassword ? "HIDE" : "SHOW" }}
                        </ion-text>
                        <ion-text
                            class="error-text"
                            color="danger"
                            *ngIf="changePasswordForm.get('oldPassword').invalid && (changePasswordForm.get('oldPassword').dirty || changePasswordForm.get('oldPassword').touched)"
                        >
                            <div
                                *ngIf="changePasswordForm.get('oldPassword').hasError('required')"
                            >
                                Old Password is required.
                            </div>
                        </ion-text>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">NEW PASSWORD</ion-label>
                        <ion-input
                            [type]="showPassword ? 'text' : 'password'"
                            placeholder="************"
                            formControlName="password"
                            minlength="7"
                            required
                        ></ion-input>
                        <ion-text
                            class="toggle-password"
                            color="primary"
                            (click)="togglePassword()"
                        >
                            {{ showPassword ? "HIDE" : "SHOW" }}
                        </ion-text>
                        <ion-text
                            class="error-text"
                            [attr.color]="changePasswordForm.get('password').invalid && (changePasswordForm.get('password').dirty || changePasswordForm.get('password').touched) ? 'danger': null"
                        >
                            <div
                                *ngIf="changePasswordForm.get('password').hasError('required') && (changePasswordForm.get('password').dirty || changePasswordForm.get('password').touched)"
                            >
                                Please enter a new password.
                            </div>
                            <div>Must be at least 7 characters long.</div>
                        </ion-text>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">
                            CONFIRM PASSWORD
                        </ion-label>
                        <ion-input
                            [type]="showConfirmPassword ? 'text' : 'password'"
                            placeholder="************"
                            formControlName="confirmPassword"
                            minlength="7"
                            required
                        ></ion-input>
                        <ion-text
                            class="toggle-password"
                            color="primary"
                            (click)="toggleConfirmPassword()"
                        >
                            {{ showConfirmPassword ? "HIDE" : "SHOW" }}
                        </ion-text>
                        <ion-text
                            class="error-text"
                            [attr.color]="changePasswordForm.get('confirmPassword').invalid && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword').touched) ? 'danger': null"
                        >
                            <div
                                *ngIf="changePasswordForm.get('confirmPassword').hasError('required') && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword').touched)"
                            >
                                Please confirm your new password.
                            </div>
                            <div>Both passwords must match.</div>
                        </ion-text>
                    </ion-item>
                </form>
            </div>
        </section>
        <app-cta-buttons
            primaryCtaLabel="Update"
            [primaryCtaDisabled]="!changePasswordForm.valid"
            (primaryCtaClick)="save()"
            secondaryCtaLabel="Cancel"
            (secondaryCtaClick)="cancel()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
