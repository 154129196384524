import { Pipe, PipeTransform } from "@angular/core";
import { StringUtilities } from "src/app/util/string-utilities";

@Pipe({
    name: "stripCurrencyFormatting"
})
export class StripCurrencyFormattingPipe implements PipeTransform {
    transform(value: string): number | null {
        return StringUtilities.stripCurrencyFormatting(value);
    }
}
