/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Subscription, throwError } from "rxjs";
import { catchError, concatMap, finalize } from "rxjs/operators";
import {
    CartService,
    HomeService,
    LoadingService
} from "src/app/core/services";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ProductListService } from "src/app/core/services/service-proxies/product-list.service";
import { Cart } from "src/app/shared/model/cart";
import { Category } from "src/app/shared/model/category";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { BasePage } from "src/app/shared/pages/BasePage";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { SelectPhotosWorkflowService } from "../../../services/select-photos-workflow.service";
import { SliderModel } from "src/app/shared/model/SliderModel";

@Component({
    selector: "app-personalization-photos",
    templateUrl: "./personalization-photos.component.html",
    styleUrls: ["./personalization-photos.component.scss"]
})
export class PersonalizationPhotosComponent
    extends BasePage
    implements OnInit, OnDestroy
{
    promoOptions = {
        slidesPerView: "auto",
        zoom: false
    };

    promos: SliderModel[] = new Array<SliderModel>();
    public photoProducts: ProductDetail[] = [];
    public loadingProducts = true;
    public loadingPromos = true;

    private currentCart: Cart;
    private subscriptions: Subscription[] = [];

    constructor(
        private homeService: HomeService,
        private cartService: CartService,
        private productListService: ProductListService,
        private productContextService: ProductContextService,
        private navController: NavController,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        const cartSub = this.cartService.cart$.subscribe((c) => {
            this.currentCart = c?.Cart;
        });
        this.subscriptions.push(cartSub);

        this.loadProducts();

        this.homeService
            .fetchProductSliders()
            .pipe(
                finalize(() => {
                    this.loadingPromos = false;
                })
            )
            .subscribe({
                next: (res: BaseResponseModel<Array<SliderModel>>) =>
                    (this.promos = res.Data),
                error: (error) =>
                    void this.showErrorModal(this.modalController, error)
            });
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    loadProducts(): void {
        let categoryId: number;
        const products$ = this.productListService.fetchProductCategories().pipe(
            concatMap(
                (val: BaseResponseModel<{ Categories: Array<Category> }>) => {
                    const tags = val.Data.Categories;
                    categoryId = tags.find(
                        (t) => t.Name === "Personalization Photos"
                    ).Id;
                    return this.productListService.fetchProductByCategory(
                        categoryId,
                        1,
                        null,
                        null
                    );
                }
            ),
            catchError((error) => throwError(() => error))
        );

        products$
            .pipe(
                finalize(() => {
                    this.loadingProducts = false;
                })
            )
            .subscribe({
                next: (
                    res: BaseResponseModel<{ Products: Array<ProductDetail> }>
                ) => {
                    this.photoProducts = res.Data.Products;
                    this.photoProducts.forEach((p) => {
                        this.productContextService.setTierPriceDetails(p);
                    });
                },
                error: (error) =>
                    void this.showErrorModal(this.modalController, error)
            });
    }

    async selectProduct(product: ProductDetail): Promise<void> {
        await this.loadingService.show();

        const selectedPhotos =
            await this.selectPhotosWorkflowService.getSelectedPhotos();
        const selectedPhotosProductId = selectedPhotos[0]?.associatedProductId;
        const currentPhotoProductId = this.currentCart?.Items?.find((i) =>
            i.ProductName.toLowerCase().includes("print")
        )?.ProductId;

        if (
            (!!selectedPhotosProductId &&
                product.Id !== selectedPhotosProductId) ||
            (!!currentPhotoProductId && product.Id !== currentPhotoProductId)
        ) {
            const continueCurrentOrder = async () => {
                await this.modalController.dismiss();
                await this.navController.navigateForward([
                    "/order/select-photos",
                    selectedPhotosProductId ?? currentPhotoProductId
                ]);
            };
            const resetShoppingCart = async () => {
                await this.cartService.clearCart().toPromise();
                await this.selectPhotosWorkflowService.resetWorkflow();
                await this.modalController.dismiss();
                await this.continueOrder(product);
            };
            await this.showCartResetWarningModal(
                this.modalController,
                "product",
                continueCurrentOrder,
                resetShoppingCart
            );
        } else {
            await this.continueOrder(product);
        }
        await this.loadingService.dismiss();
    }

    async continueOrder(product: ProductDetail): Promise<void> {
        await this.productContextService.setProduct(product);
        await this.navController.navigateForward([
            "/order/facility-rules",
            product.Id
        ]);
    }

    bannerClicked(banner: SliderModel): void {
        if (banner.ExternalUrl) {
            window.open(banner.ExternalUrl, "_blank");
        }
    }
}
