import { Component, OnInit } from "@angular/core";
import { Browser } from "@capacitor/browser";
import { AlertController, MenuController } from "@ionic/angular";
import { firstValueFrom } from "rxjs";
import { AuthService, HomeService } from "src/app/core/services";
import { AppStartService } from "src/app/core/services/app-start.service";
import { LoginWorkflowService } from "src/app/core/services/login-workflow.service";
import { StartupService } from "src/app/core/services/pelipost-service-proxies/startup-service";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";
import { CustomerInfo } from "src/app/shared/model/account/login";
import { LocalizationConstants } from "src/app/util/LocalizationConstants";
import { StringUtilities } from "src/app/util/string-utilities";
import { APP_VERSION, environment } from "src/environments/environment";

@Component({
    selector: "app-sidemenu",
    templateUrl: "./sidemenu.component.html",
    styleUrls: ["./sidemenu.component.scss"]
})
export class SidemenuComponent implements OnInit {
    customer: CustomerInfo;
    initials = "";
    isLoggedIn: boolean;
    appVersion = "";
    appBuild = "";
    greetingCardsUrl = "";

    constructor(
        private authService: AuthService,
        private loginWorkflowService: LoginWorkflowService,
        private alertCtrl: AlertController,
        private homeService: HomeService,
        private startupService: StartupService,
        private appStartService: AppStartService,
        private menuContoller: MenuController,
        private cleverTapService: CleverTapService
    ) {}

    async ngOnInit(): Promise<void> {
        this.appVersion = StringUtilities.getSemanticVersion(APP_VERSION);
        if (environment.id !== "prod") {
            this.appBuild = StringUtilities.getBuildIdFromVersion(APP_VERSION);
        }
        this.isLoggedIn = this.authService.isLoggedIn;
        this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
        });

        this.customer = this.authService.customer;
        this.authService.customer$.subscribe((customer) => {
            this.customer = customer;
        });

        this.greetingCardsUrl = (
            await firstValueFrom(this.startupService.getSettings())
        ).GreetingCardsUrl;
    }

    getCustomer(): CustomerInfo {
        return this.authService.customer;
    }

    getInitials(): string {
        return StringUtilities.getInitials(
            this.customer.FirstName,
            this.customer.LastName
        );
    }

    public async closeMenu(): Promise<void> {
        await this.menuContoller.close("menu-main");
    }

    public async onLogOut(): Promise<void> {
        await this.alertCtrl
            .create({
                header: this.homeService.fetchString(
                    LocalizationConstants.ACCOUNT_LOGOUT
                ),
                message: this.homeService.fetchString(
                    LocalizationConstants.ACCOUNT_LOGOUT_CONFIRM
                ),
                buttons: [
                    {
                        text: this.homeService.fetchString(
                            LocalizationConstants.COMMON_NO
                        ),
                        role: "cancel"
                    },
                    {
                        text: this.homeService.fetchString(
                            LocalizationConstants.COMMON_YES
                        ),
                        handler: () => this.handleLogOut()
                    }
                ]
            })
            .then((alertEl) => {
                void alertEl.present();
            });
    }

    handleLogOut = async (): Promise<void> => {
        this.appStartService.fromNotification = false;
        await this.menuContoller.close("menu-main");
        await this.loginWorkflowService.logoutRedirect();
    };

    async onGreetingCardsClick(): Promise<void> {
        await this.closeMenu();
        await Browser.open({
            url: this.greetingCardsUrl,
            windowName: "_blank"
        });
    }

    async onSupportClick(): Promise<void> {
        await this.closeMenu();
        await this.cleverTapService.recordEvent("Support Initiated", {
            "Support Type": "External link (side menu)"
        });
        const url = (await firstValueFrom(this.startupService.getSettings()))
            .SupportUrl;
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }

    async onPrivacyPolicyClick(): Promise<void> {
        await this.closeMenu();
        const url = (await firstValueFrom(this.startupService.getSettings()))
            .PrivacyPolicyUrl;
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }
}
