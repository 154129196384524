import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { HomePage } from "./pages/home/home.page";
import { LandingPage } from "./pages/landing/landing.page";

const routes: Routes = [
    {
        path: "home",
        component: HomePage,
        canActivate: [AuthGuard]
    },
    {
        path: "welcome",
        component: LandingPage
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule { }
