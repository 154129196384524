<div *ngIf="hrefValue">
    <ion-text class="custom-button button-block" color="primary">
        <a class="h-100 d-flex align-items-center justify-content-center" [href]="hrefValue" (click)="supportInitiated()">
            <img
                class="h-100"
                src="/assets/icon/sms.png"
            />
            &nbsp;{{label}}
        </a>
    </ion-text>
</div>
