import { Component, Input, OnChanges } from "@angular/core";
import { OrderTotals } from "src/app/shared/model/cart";
import { ShippingOptionModel } from "src/app/shared/model/more/shipping-option-model";
import { LineItem } from "src/app/shared/model/order";
import { StringUtilities } from "src/app/util/string-utilities";

@Component({
    selector: "app-order-details",
    templateUrl: "./order-details.component.html",
    styleUrls: ["./order-details.component.scss"]
})
export class OrderDetailsComponent implements OnChanges {
    @Input() lineItems: Array<LineItem>;
    @Input() orderTotals: OrderTotals;
    @Input() shippingMethod: ShippingOptionModel | undefined;

    pelipalsDiscount: string | undefined;
    orderTotalBeforeCredits: number | undefined;
    subTotalDiscountPercentage: number | undefined;
    orderTotalDiscountPercentage: number | undefined;

    ngOnChanges(): void {
        if (!this.orderTotals) {
            return;
        }

        if (this.orderTotals.SubTotalDiscount) {
            this.subTotalDiscountPercentage = this.calculateDiscountPercentage(
                Math.abs(
                    StringUtilities.stripCurrencyFormatting(
                        this.orderTotals.SubTotalDiscount
                    ) ?? 0
                ),
                StringUtilities.stripCurrencyFormatting(
                    this.orderTotals.SubTotal
                ) ?? 0
            );
        }

        // Completed orders will return AppliedPeliPalsFunds property
        if (this.orderTotals.AppliedPeliPalsFunds) {
            this.pelipalsDiscount = this.orderTotals.AppliedPeliPalsFunds;
        }
        // In-progress orders (carts) will include available PeliPals funds as a gift card
        else {
            const pelipalsGiftCard = this.orderTotals.GiftCards.find((gc) =>
                gc.CouponCode.startsWith("Pelipals")
            );
            if (pelipalsGiftCard) {
                this.pelipalsDiscount = pelipalsGiftCard.Amount;
            } else {
                this.pelipalsDiscount = undefined;
            }
        }

        this.orderTotalBeforeCredits = this.calculateOrderTotalBeforeCredits();

        if (this.orderTotals.OrderTotalDiscount) {
            this.orderTotalDiscountPercentage =
                this.calculateDiscountPercentage(
                    Math.abs(
                        StringUtilities.stripCurrencyFormatting(
                            this.orderTotals.OrderTotalDiscount
                        ) ?? 0
                    ),
                    this.orderTotalBeforeCredits
                );
        }
    }

    private calculateDiscountPercentage(
        discount: number,
        total: number
    ): number {
        return Math.round((discount / total) * 100) / 100;
    }

    private calculateOrderTotalBeforeCredits(): number {
        const orderTotalDiscountFloat = Math.abs(
            StringUtilities.stripCurrencyFormatting(
                this.orderTotals.OrderTotalDiscount
            ) ?? 0
        );

        const pelipalsDiscountFloat = Math.abs(
            StringUtilities.stripCurrencyFormatting(this.pelipalsDiscount) ?? 0
        );

        const pelipointsCreditFloat = Math.abs(
            StringUtilities.stripCurrencyFormatting(
                this.orderTotals.RedeemedRewardPointsAmount
            ) ?? 0
        );

        if (
            orderTotalDiscountFloat === 0 &&
            pelipalsDiscountFloat === 0 &&
            pelipointsCreditFloat === 0
        ) {
            this.orderTotalBeforeCredits = undefined;
            return;
        }

        const orderTotalFloat =
            StringUtilities.stripCurrencyFormatting(
                this.orderTotals.OrderTotal
            ) ?? 0;

        return (
            orderTotalFloat +
            orderTotalDiscountFloat +
            pelipalsDiscountFloat +
            pelipointsCreditFloat
        );
    }
}
