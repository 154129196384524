import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Subscription, firstValueFrom } from "rxjs";
import { CartService, LoadingService } from "src/app/core/services";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { DynamicAttribute } from "src/app/shared/model/attribute";
import { Cart } from "src/app/shared/model/cart";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { SelectPhotosWorkflowService } from "../../../services/select-photos-workflow.service";
import { Facility } from "src/app/shared/model/facility";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";

@Component({
    selector: "app-delivery-assurance",
    templateUrl: "./delivery-assurance.page.html",
    styleUrls: ["./delivery-assurance.page.scss"]
})
// TODO: Shouldn't this be a base order page?
export class DeliveryAssurancePage
    extends BasePage
    implements OnInit, OnDestroy
{
    contact: Contact;
    facility: Facility;
    cart: Cart;
    attribute: DynamicAttribute;
    attributePrice = "";
    loading = false;

    private alreadyInCart = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private navController: NavController,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService,
        private cartService: CartService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private loadingService: LoadingService,
        private cleverTapService: CleverTapService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        const contactSub = this.contactContextService.contact$.subscribe(
            (r) => (this.contact = r)
        );
        const facilitySub = this.contactContextService.facility$.subscribe(
            (f) => (this.facility = f)
        );
        const cartSub = this.cartService.cart$.subscribe((c) => {
            this.cart = c?.Cart;
            this.alreadyInCart =
                c?.SelectedCheckoutAttributes.includes("DeliveryAssurance") ??
                false;
            this.setAttributePrice();
        });
        this.subscriptions.push(contactSub, facilitySub, cartSub);

        try {
            await this.loadingService.show();
            if (!this.cart?.Items?.length) {
                await this.cartService.fetchCartRes().toPromise();
            }
        } finally {
            await this.loadingService.dismiss();
        }
    }

    async ionViewDidEnter(): Promise<void> {
        await firstValueFrom(this.contactContextService.getContactContext());
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    async submit(selected: boolean): Promise<void> {
        this.loading = true;
        try {
            await this.checkoutWorkflowService.setHasViewedDeliveryAssurance();
            let data: DynamicAttribute;
            if (selected) {
                data = this.attribute;
            }
            await this.cartService.updateDeliveryAssurance(data).toPromise();
            await this.navController.navigateForward("order/order-summary");

            if (
                (selected && !this.alreadyInCart) ||
                (!selected && this.alreadyInCart)
            ) {
                const price = parseFloat(this.attributePrice.substring(1));
                await this.cleverTapService.recordCartItemEvent(
                    selected ? "Item Added to Cart" : "Item Removed From Cart",
                    "Delivery Assurance",
                    "Delivery Assurance",
                    undefined,
                    price,
                    price,
                    1,
                    this.facility,
                    this.contact
                );
            }
        } finally {
            this.loading = false;
        }
    }

    async navigateBack(): Promise<void> {
        const productId = this.productContextService.productId;
        const skipAddMessage =
            await this.selectPhotosWorkflowService.getSkipAddMessage();
        if (productId) {
            if (skipAddMessage) {
                await this.navController.navigateBack([
                    "order/print-preview",
                    productId
                ]);
            } else {
                await this.navController.navigateBack([
                    "order/add-message",
                    productId
                ]);
            }
        } else {
            await this.navController.navigateRoot("/");
        }
    }

    private setAttributePrice(): void {
        const attribute = this.cart?.CheckoutAttributes.filter(
            (a) => a.Name === "DeliveryAssurance"
        );
        if (attribute?.length > 0) {
            this.attribute = attribute[0];
            this.attributePrice =
                attribute[0].Values[0].PriceAdjustment.substring(1);
        } else {
            this.attribute = null;
            this.attributePrice = "";
        }
    }
}
