<ion-menu *ngIf="isLoggedIn" side="start" menu-id="menu-main" contentId="main" [swipeGesture]="false">
  <div class="menu--header">
    <ion-menu-toggle>
      <ion-icon name="close-outline" menuClose></ion-icon>
    </ion-menu-toggle>
    <div class="pl-2">
      <ion-avatar
        ><img
          class=""
          src="https://placehold.co/50x50/555B6E/FFF?text={{
            getInitials()
          }}&font=roboto"
      /></ion-avatar>
    </div>
    <ion-text *ngIf="customer">
      <h3>{{ customer.FirstName }} {{ customer.LastName }}</h3>
      <h4>{{ customer.Email }}</h4>
    </ion-text>
  </div>
  <ion-content>
    <ion-list class="pl-2 pt-3">
      <ion-item><a (click)="closeMenu()" [routerLink]="['/']">Home</a></ion-item>
      <ion-item><a (click)="closeMenu()" [routerLink]="['order/my-orders']">My Orders</a></ion-item>
      <ion-item><a (click)="closeMenu()" [routerLink]="['contacts/my-contacts']">My Contacts</a></ion-item>
      <ion-item><a (click)="closeMenu()" [routerLink]="['pelipoints']">PeliPOINTS</a></ion-item>
      <ion-item><a (click)="closeMenu()" [routerLink]="['contacts/new-contact/pelipals-id']">PeliPals</a></ion-item>
      <ion-item><a (click)="onSupportClick()">Support</a></ion-item>
      <ion-item><a (click)="closeMenu()" [routerLink]="['settings']">Settings</a></ion-item>
      <div *ngIf="greetingCardsUrl">
        <ion-item><h5 class="mb-0">Try our other products</h5></ion-item>
        <ion-item>
          <a (click)="onGreetingCardsClick()" class="d-flex">
            <img [src]="'assets/icon/greeting_card_light.png'" class="mr-2 light-mode" />
            <img [src]="'assets/icon/greeting_card_dark.png'" class="mr-2 dark-mode" />
            Greeting Cards
            <ion-icon name="open-outline" size="small" class="align-self-center ml-3"></ion-icon>
          </a>
        </ion-item>
      </div>
    </ion-list>
  </ion-content>
  <div class="pl-2 pb-2 footer">
    <div class="footer-nav">
      <ion-text color="primary" class="d-block mb-3 logout" (click)="onLogOut()" id="link-log-out">Log Out</ion-text>
      <ion-text color="primary" class="d-block mb-2 privacy" (click)="onPrivacyPolicyClick()">Privacy Policy</ion-text>
    </div>

    <div class="footer-app-version">
      <img
        class="toolbar-logo light-mode sidebar-pelipost-logo"
        src="../assets/images/pelipost_logo_horizontal_LightMode.svg"
      />
      <img
        class="toolbar-logo dark-mode sidebar-pelipost-logo"
        src="../assets/images/pelipost_logo_horizontal_DarkMode.svg"
      />
      <div class="text-right">
        <div>Version {{ appVersion }}</div>
        <div *ngIf="appBuild">Build {{ appBuild }}</div>
      </div>
    </div>
  </div>
</ion-menu>
