import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { EarnPage } from "./pages/earn/earn.page";
import { HistoryPage } from "./pages/history/history.page";
import { MyPointsPage } from "./pages/my-points/my-points.page";

const routes: Routes = [
    {
        path: "pelipoints",
        redirectTo: "pelipoints/earn",
        pathMatch: "full"
    },
    {
        path: "pelipoints",
        canActivate: [AuthGuard],
        component: MyPointsPage,
        children: [
            {
                path: "earn",
                component: EarnPage,
                data: {
                    footerTabs: true
                }
            },
            {
                path: "history",
                component: HistoryPage,
                data: {
                    footerTabs: true
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PelipointsRoutingModule {}
