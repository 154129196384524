<div *ngIf="!!this.primaryCtaLabel"
  class="text-center mb-3">
  <ion-button expand="block"
    [color]="primaryCtaColor"
    (click)="primaryCtaClick.emit()"
    [disabled]="primaryCtaDisabled"
    [id]="primaryCtaId">
    {{ primaryCtaLabel }}
  </ion-button>
</div>
<ng-content select="#secondary-cta"></ng-content>
<div *ngIf="!!this.secondaryCtaLabel"
  class="text-center">
  <ion-text class="custom-button button-block mt-3"
    [color]="secondaryCtaColor"
    (click)="secondaryCtaClick.emit()" [id]="secondaryCtaId">{{ secondaryCtaLabel }}</ion-text>
</div>