/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { forkJoin } from "rxjs";
import { CartService, LoadingService } from "src/app/core/services";
import { CartConfigurationService } from "src/app/core/services/pelipost-service-proxies/cart-configuration.service";
import { PelipostCheckoutService } from "src/app/core/services/pelipost-service-proxies/pelipost-checkout.service";
import { PelipostPhoto } from "src/app/core/services/photos.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { OrderService } from "src/app/core/services/service-proxies/order.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";
import { CartData, OrderTotals } from "src/app/shared/model/cart";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { PromoCodeModalComponent } from "../../../components/promo-code/promo-code-modal.component";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { LineItem } from "src/app/shared/model/order";
import { DynamicAttribute } from "src/app/shared/model/attribute";
import { UserSegmentService } from "src/app/core/services/user-segment.service";
import { ShippingOptionModel } from "src/app/shared/model/more/shipping-option-model";
import { Constant } from "src/app/util/constant";

@Component({
    selector: "app-order-summary",
    templateUrl: "./order-summary.page.html",
    styleUrls: ["./order-summary.page.scss"]
})
// TODO: Shouldn't this be a BaseOrderPage?
export class OrderSummaryPage extends BasePage implements OnInit {
    cartData: CartData;
    uploadedPhotos: Array<PelipostPhoto> = [];
    lineItems: Array<LineItem> = [];
    orderTotals: OrderTotals;
    discountCode: string;
    discountApplied = false;
    availablePoints: number;
    availablePointsCashValue: string;
    appliedPoints: number;
    contact: Contact;
    facility: Facility;
    discountsCoverOrderTotal: boolean;
    confirmingOrder = false;
    updatingDiscount = false;
    standardShippingMethod: ShippingOptionModel;
    expeditedShippingMethod: ShippingOptionModel;
    selectedShippingMethod: ShippingOptionModel;

    constructor(
        private navController: NavController,
        private modalCtrl: ModalController,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private checkoutService: CheckoutService,
        private cartService: CartService,
        private cartConfigurationService: CartConfigurationService,
        private orderService: OrderService,
        private pelipostCheckoutService: PelipostCheckoutService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private loadingService: LoadingService,
        private userSegmentService: UserSegmentService
    ) {
        super();
    }

    ngOnInit(): void {
        this.contactContextService.contact$.subscribe(
            (r) => (this.contact = r)
        );
        this.contactContextService.facility$.subscribe(
            (f) => (this.facility = f)
        );
        this.cartService.cart$.subscribe((c) => {
            this.cartData = c;
            void this.renderCart();
        });
    }

    async ionViewDidEnter(): Promise<void> {
        await this.populateShippingMethods();
        await this.getCustomerRewardPoints();

        //TODO: need to fetch imageData from server when we don't have local data
        const photos =
            await this.selectPhotosWorkflowService.getSelectedPhotos();
        if (photos) {
            this.uploadedPhotos = photos;
        }

        //NOTE: if somehow there are no photos in the cart, this will duplicate when navigating back and forth to order summary
        const messagePhoto =
            await this.selectPhotosWorkflowService.getMessagePhoto();
        if (messagePhoto) {
            this.uploadedPhotos.push(messagePhoto);
        }

        await forkJoin([
            this.contactContextService.getContactContext(),
            this.cartService.fetchCartRes()
        ]).toPromise();
        this.lineItems = await this.pelipostCheckoutService
            .getCartLineItems()
            .toPromise();
        this.renderCart();
    }

    private async populateShippingMethods(): Promise<void> {
        const res = await this.pelipostCheckoutService
            .getShippingOptions()
            .toPromise();

        this.standardShippingMethod = res.Data?.ShippingOptions[0];
        this.expeditedShippingMethod = res.Data?.ShippingOptions[1];

        const selectedShippingMethod =
            await this.checkoutWorkflowService.getSelectedShippingMethod();
        if (
            !selectedShippingMethod ||
            !this.expeditedShippingMethod ||
            this.standardShippingMethod.DisplayName === selectedShippingMethod
        ) {
            await this.setShippingMethod(this.standardShippingMethod);
        } else {
            await this.setShippingMethod(this.expeditedShippingMethod);
        }
    }

    async toggleExpeditedShipping(): Promise<void> {
        await this.loadingService.show();
        const newShippingMethod =
            this.selectedShippingMethod === this.standardShippingMethod
                ? this.expeditedShippingMethod
                : this.standardShippingMethod;

        try {
            await this.setShippingMethod(newShippingMethod);
            await this.cartService.fetchCartRes().toPromise();
            this.renderCart();
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private async setShippingMethod(method: ShippingOptionModel) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = [
            {
                Key: "shippingoption",
                Value: method.Name
            }
        ];
        try {
            await this.checkoutService.saveShippingMethod(reqBody).toPromise();
            await this.checkoutWorkflowService.setSelectedShippingMethod(
                method.Name
            );
            this.selectedShippingMethod = method;
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        }
    }

    private renderCart(): void {
        if (!this.cartData) {
            return;
        }

        this.orderTotals = this.cartData.OrderTotals;

        if (
            this.cartData.Cart.DiscountBox.AppliedDiscountsWithCodes.length > 0
        ) {
            this.discountCode =
                this.cartData.Cart.DiscountBox.AppliedDiscountsWithCodes[0].CouponCode;
            this.discountApplied = true;
        } else {
            this.discountApplied = false;
        }

        if (this.orderTotals?.OrderTotalDiscount?.startsWith("(")) {
            this.orderTotals.OrderTotalDiscount =
                this.orderTotals.OrderTotalDiscount.substr(
                    1,
                    this.orderTotals.OrderTotalDiscount.length - 2
                );
        }

        if (this.orderTotals?.RedeemedRewardPointsAmount?.startsWith("(")) {
            this.checkoutWorkflowService.useRewardPoints = true;
            this.orderTotals.RedeemedRewardPointsAmount =
                this.orderTotals.RedeemedRewardPointsAmount.substr(
                    1,
                    this.orderTotals.RedeemedRewardPointsAmount.length - 2
                );
        }

        this.appliedPoints = this.orderTotals?.RedeemedRewardPoints;
        this.discountsCoverOrderTotal =
            (!!this.contact?.PeliPalsNumber ||
                this.discountApplied ||
                this.checkoutWorkflowService.useRewardPoints) &&
            this.orderTotalIsZero();
    }

    private orderTotalIsZero(): boolean {
        if (!this.orderTotals.OrderTotal) {
            return false;
        }

        return (
            this.checkoutWorkflowService.parseOrderTotal(
                this.orderTotals.OrderTotal
            ) === 0
        );
    }

    private async getCustomerRewardPoints(): Promise<void> {
        try {
            const res = await this.orderService
                .fetchCustomerRewardPoints()
                .toPromise();
            this.availablePoints = res.Data.RewardPointsBalance;
            this.availablePointsCashValue = res.Data.RewardPointsAmount;
        } catch (err) {
            await this.showErrorModal(this.modalCtrl, err);
        }
    }

    public async showDiscountModal(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PromoCodeModalComponent
        });

        await modal.present();

        const result = await modal.onDidDismiss();
        if (!result?.data) {
            return;
        }

        this.discountCode = result.data as string;
        await this.applyDiscountCode();

        if (!this.discountApplied) {
            await this.showModal(this.modalCtrl, {
                modalType: "error",
                headerText: "Discount code not found",
                bodyText:
                    "Code " +
                    this.discountCode +
                    " is not valid, please try again",
                primaryCtaText: "Okay",
                actionType: "close"
            });

            this.discountCode = "";
        }
    }

    public async applyDiscountCode(): Promise<void> {
        try {
            this.updatingDiscount = true;
            await this.loadingService.show();
            const deliveryAssurance = this.getDeliveryAssurance();
            if (!this.discountApplied) {
                await this.cartService
                    .applyCoupon(this.discountCode, deliveryAssurance)
                    .toPromise();
            } else {
                this.discountCode = "";
                await this.cartService
                    .removeCoupon(
                        this.cartData.Cart.DiscountBox
                            .AppliedDiscountsWithCodes[0],
                        deliveryAssurance
                    )
                    .toPromise();
            }
            this.lineItems = await this.pelipostCheckoutService
                .getCartLineItems()
                .toPromise();
            this.renderCart();
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        } finally {
            this.updatingDiscount = false;
            await this.loadingService.dismiss();
        }
    }

    public async removeDiscountCode(): Promise<void> {
        this.discountCode = "";
        await this.applyDiscountCode();
    }

    private getDeliveryAssurance(): DynamicAttribute {
        if (
            this.lineItems.find((li) =>
                li.Name.toLowerCase().includes("delivery assurance")
            )
        ) {
            return this.cartData.Cart?.CheckoutAttributes.find(
                (a) => a.Name === "DeliveryAssurance"
            );
        } else {
            return undefined;
        }
    }

    public async applyPelipoints(useRewardPoints: boolean): Promise<void> {
        try {
            this.updatingDiscount = true;
            await this.loadingService.show();
            this.checkoutWorkflowService.useRewardPoints = useRewardPoints;
            await this.checkoutWorkflowService.debugSetFattMerchantStaxCartPaymentMethod();
            await this.cartService.fetchCartRes().toPromise();
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        } finally {
            this.updatingDiscount = false;
            await this.loadingService.dismiss();
        }
    }

    public async proceedToPayment(): Promise<void> {
        if (this.discountsCoverOrderTotal) {
            this.confirmingOrder = true;
            await this.loadingService.show();
            try {
                await this.cartConfigurationService
                    .setDefaultBillingAddress()
                    .toPromise();
                await this.pelipostCheckoutService
                    .setShippingAddressToCurrentFacility()
                    .toPromise();
                const orderCompleteRes =
                    await this.checkoutWorkflowService.confirmOrder();
                await this.userSegmentService.logOrderConfirmedEvent();
                await this.navController.navigateRoot([
                    "/order/confirmation",
                    orderCompleteRes.Data.CompletedModel.CustomOrderNumber
                ]);
            } catch (error) {
                if (error.message === Constant.GENERIC_ERROR_MSG) {
                    error.message =
                        Constant.ORDER_CONFIRMATION_FAILED_ERROR_MSG;
                    await this.showErrorModal(this.modalCtrl, error);
                    await this.navController.navigateRoot("/home");
                } else {
                    await this.checkoutWorkflowService.showPaymentFailedModal(
                        error
                    );
                    await this.cartService.fetchCartRes().toPromise();
                    this.renderCart();
                }
            } finally {
                this.confirmingOrder = false;
                await this.loadingService.dismiss();
            }
        } else {
            await this.navController.navigateForward("order/payment/select");
        }
    }

    public async editCart(): Promise<void> {
        await this.navController.navigateForward([
            "/order/select-product/photos"
        ]);
    }
}
