/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/unbound-method */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { AddressService } from "src/app/core/services/service-proxies/account/address.service";
import { Address } from "../../model/account/address";

@Component({
    selector: "app-address-form",
    templateUrl: "./address-form.component.html",
    styleUrls: ["./address-form.component.scss"]
})
export class AddressFormComponent implements OnInit, OnChanges {
    @Input() existingAddress: Address;
    @Input() addressLabel = "Street Address";
    @Input() countryZipOnly = false;
    @Input() set stateCtrlRequiredBeforeBeingTouched(value: boolean) {
        if (value === true) {
            this.markStateCtrlTouched();
        }
        this._stateCtrlRequiredBeforeBeingTouched = value;
    }
    @Output() formReady = new EventEmitter<UntypedFormGroup>();

    public addressForm: UntypedFormGroup;
    public errorList: string[];
    public countries = new Map<number, string>();
    public states = new Map<number, string>();
    public stateCtrlTouched = false;
    private _stateCtrlRequiredBeforeBeingTouched = true;

    get country(): AbstractControl {
        return this.addressForm.get("country");
    }
    get address1(): AbstractControl {
        return this.addressForm.get("address1");
    }
    get address2(): AbstractControl {
        return this.addressForm.get("address2");
    }
    get city(): AbstractControl {
        return this.addressForm.get("city");
    }
    get state(): AbstractControl {
        return this.addressForm.get("state");
    }
    get zipPostalCode(): AbstractControl {
        return this.addressForm.get("zipPostalCode");
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private addressService: AddressService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadCountriesAndStates();
        this.addressForm = this.formBuilder.group({
            country: [this.existingAddress?.CountryId, Validators.required],
            address1: this.countryZipOnly
                ? undefined
                : [this.existingAddress?.Address1, Validators.required],
            address2: this.countryZipOnly
                ? undefined
                : [this.existingAddress?.Address2],
            city: this.countryZipOnly
                ? undefined
                : [this.existingAddress?.City, Validators.required],
            state: this.countryZipOnly
                ? undefined
                : [
                      this.existingAddress?.StateProvinceId,
                      this._stateCtrlRequiredBeforeBeingTouched
                          ? Validators.required
                          : null
                  ],
            zipPostalCode: [
                this.existingAddress?.ZipPostalCode,
                Validators.required
            ]
        });

        this.addressForm.get("country").valueChanges.subscribe((value) => {
            void this.onCountryChange(value);
        });

        this.setZipValidation();
        this.formReady.emit(this.addressForm);
    }

    private async loadCountriesAndStates(): Promise<void> {
        const res = await this.addressService.fetchAddAddress().toPromise();
        res.Data.Address.AvailableCountries.forEach((d) =>
            this.countries.set(+d.Value, d.Text)
        );

        if (this.countryZipOnly) {
            return;
        }

        if (this.existingAddress?.CountryId) {
            await this.populateStates(this.existingAddress.CountryId);
        } else {
            res.Data.Address.AvailableStates.forEach((d) =>
                this.states.set(+d.Value, d.Text)
            );
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (
            changes.existingAddress &&
            !changes.existingAddress.isFirstChange()
        ) {
            await this.populateFormData();
        }
    }

    async populateFormData(): Promise<void> {
        if (this.existingAddress?.CountryId) {
            await this.onCountryChange(this.existingAddress.CountryId);
        }
        this.addressForm.patchValue(
            {
                country: this.existingAddress.CountryId,
                zipPostalCode: this.existingAddress.ZipPostalCode
            },
            { emitEvent: false }
        );

        if (!this.countryZipOnly) {
            this.addressForm.patchValue(
                {
                    address1: this.existingAddress.Address1,
                    address2: this.existingAddress.Address2,
                    city: this.existingAddress.City,
                    state: this.existingAddress.StateProvinceId
                },
                { emitEvent: false }
            );
        }
    }

    private async onCountryChange(countryId: number): Promise<void> {
        await this.populateStates(countryId);
        this.resetStateField();
        this.setZipValidation();
    }

    private async populateStates(countryId: number): Promise<void> {
        if (this.countryZipOnly) {
            return;
        }

        this.states.clear();
        if (countryId) {
            const statesRes = await this.addressService
                .fetchStates(countryId)
                .toPromise();
            statesRes.Data.forEach((d) => this.states.set(+d.id, d.name));
        }
    }

    private resetStateField(): void {
        if (this.countryZipOnly) {
            return;
        }

        this.state.enable();
        this.state.reset();
        this.stateCtrlTouched = false;
        if (this.states.size <= 1) {
            this.state.disable();
            if (this.states.size === 1) {
                this.state.setValue(Array.from(this.states.keys())[0]);
            }
        }
    }

    private setZipValidation(): void {
        if (this.country.value === 1) {
            this.zipPostalCode.setValidators([
                Validators.minLength(5),
                Validators.required
            ]);
        } else {
            this.zipPostalCode.setValidators(Validators.required);
        }
        this.zipPostalCode.updateValueAndValidity();
    }

    markStateCtrlTouched(): void {
        if (!this.stateCtrlTouched) {
            this.stateCtrlTouched = true;
            this.state.setValidators(Validators.required);
            this.state.markAsTouched();
        }
    }
}
