import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { IonicSelectableComponent } from "ionic-selectable";
import { SharedModule } from "src/app/shared/shared.module";
import { EditContactPage } from "./pages/edit-contact/edit-contact.page";
import { MyContactsPage } from "./pages/my-contacts/my-contacts.page";
import { AdditionalInfoPage } from "./pages/new-contact/additional-info/additional-info.page";
import { ConfirmPage } from "./pages/new-contact/confirm/confirm.page";
import { FacilityPage } from "./pages/new-contact/facility/facility.page";
import { InmateIdPage } from "./pages/new-contact/inmate-id/inmate-id.page";
import { NamePage } from "./pages/new-contact/name/name.page";
import { PelipalsIdPage } from "./pages/new-contact/pelipals-id/pelipals-id.page";
import { PhotoPage } from "./pages/new-contact/photo/photo.page";
import { ContactsRoutingModule } from "./contacts-routing.module";
import { ContactTypePage } from "./pages/new-contact/contact-type/contact-type.page";

@NgModule({
    declarations: [
        EditContactPage,
        MyContactsPage,
        NamePage,
        PelipalsIdPage,
        InmateIdPage,
        FacilityPage,
        PhotoPage,
        ConfirmPage,
        AdditionalInfoPage,
        ContactTypePage
    ],
    imports: [
        IonicModule,
        CommonModule,
        ContactsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        IonicSelectableComponent // This is a component, not a module. The module disappeared with the newest version
    ]
})
export class ContactsModule {}
