<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Facility Rules"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template [stickyActionButtons]="false">
    <section id="page-title">
      <p>The facility you are sending photos to has the following rules regarding photos.</p>
    </section>
    <section id="page-content" *ngIf="facility" class="text-center">
      <h2>{{facility.Name}}</h2>

      <ion-list>
        <ion-item class="item item-text-wrap" lines="none" *ngFor="let rule of facility.Rules">
          <ion-label>
            <h4>{{rule.DisplayName}}</h4>
            <p>{{rule.DisplayText}}</p>
          </ion-label>
          <ion-thumbnail slot="start">
            <img src="{{rule.ImageUrl}}" />
          </ion-thumbnail>
        </ion-item>

        <ion-item class="item item-text-wrap" lines="none">
          <ion-checkbox
            slot="start"
            (ionChange)="termsChanged($event)"
            id="input-checkbox-terms"
          ></ion-checkbox>
          <ion-label class="terms-checkbox">
            I confirm that my photos follow the rules described above. I agree
            that if my photos contain any prohibited content and is returned, I
            will not be given a refund as per the Pelipost Return Policy. Check
            the box on the left to agree and proceed.
          </ion-label>
        </ion-item>
      </ion-list>
    </section>
    <app-cta-buttons
      [primaryCtaLabel]="termsAgreed ? 'Continue' : 'Agree to disclaimer to continue'"
      primaryCtaId="btn-facility-rules-continue"
      (primaryCtaClick)="submit()"
      [primaryCtaDisabled]="!termsAgreed"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
