import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    ExternalAuthenticationParameters,
    LoginGetRes,
    LoginGetResData,
    LoginPostRes,
    PasswordRecoveryResetPostBody
} from "src/app/shared/model/account/login";
import { RegisterGetRes } from "src/app/shared/model/account/register";
import {
    BaseReqBody,
    BaseResponseModel,
    FormValue
} from "src/app/shared/model/BaseResponse";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: "root"
})
export class LoginService {
    constructor(private baseService: BaseService) {}

    fetchLoginRes(): Observable<LoginGetRes> {
        const url: string = this.baseService.BASE_URL + "customer/login";
        return this.baseService.getRequest<LoginGetRes>(url);
    }

    postLoginData(data: LoginGetResData) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = data;

        const url = this.baseService.BASE_URL + "customer/login";
        return this.baseService.postRequest<LoginPostRes>(url, reqBody);
    }

    fetchRegisterData(): Observable<RegisterGetRes> {
        const url: string = this.baseService.BASE_URL + "customer/register";
        return this.baseService.getRequest<RegisterGetRes>(url);
    }

    postRegisterData(data: RegisterGetRes, values: FormValue[]) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = data.Data;
        reqBody.FormValues = values;

        const url = this.baseService.BASE_URL + "customer/register";
        return this.baseService.postRequest(url, reqBody);
    }

    fetchPasswordRecoveryData() {
        const url: string =
            this.baseService.BASE_URL + "customer/passwordrecovery";
        return this.baseService.getRequest(url);
    }

    verifyPasswordResetToken(token: string, guid: string) {
        const url =
            this.baseService.BASE_URL +
            `customer/passwordrecoveryconfirm/${token}/${guid}`;
        return this.baseService.getRequest(url);
    }

    postPasswordResetData(
        token: string,
        guid: string,
        data: PasswordRecoveryResetPostBody
    ) {
        const reqBody = new BaseReqBody();
        reqBody.Data = data;

        const url =
            this.baseService.BASE_URL +
            `customer/passwordrecoveryreset/${token}/${guid}`;
        return this.baseService.postRequest(url, reqBody);
    }

    getExternalProviders(): Observable<ExternalLoginProviderSchemes> {
        const url: string =
            this.baseService.BASE_URL + "customer/externalproviders";
        return this.baseService.getRequest<ExternalLoginProviderSchemes>(url);
    }

    getRegisteredProviders() {
        const url: string =
            this.baseService.BASE_URL + "customer/registeredproviders";
        return this.baseService.getRequest<
            BaseResponseModel<Map<string, string>>
        >(url);
    }

    postExternalAuthentication(parameters: ExternalAuthenticationParameters) {
        const reqBody = new BaseReqBody();
        reqBody.Data = parameters;

        const url: string =
            this.baseService.BASE_URL + "customer/externalauthentication";
        return this.baseService.postRequest<LoginPostRes>(url, reqBody);
    }
}

export interface ExternalLoginProviderSchemes {
    apple: string | undefined;
    google: string | undefined;
}
