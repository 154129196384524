import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PelipostPhoto } from "src/app/core/services/photos.service";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-thumbnails",
    templateUrl: "./thumbnails.component.html",
    styleUrls: ["./thumbnails.component.scss"]
})
export class ThumbnailsComponent extends BasePage implements OnInit {
    @Input() photos: Array<PelipostPhoto> = [];

    constructor(private modalController: ModalController) {
        super();
    }

    ngOnInit() {}

    async viewPhoto(photo: PelipostPhoto) {
        const imageClasses = `${photo.ratio === 1.5 ? "r4x6" : "r5x7"} ${
            photo.dimension.height >= photo.dimension.width
                ? "portrait"
                : "landscape"
        }`;
        const props = {
            customImageUrl: photo.remoteUrl,
            customImageClasses: imageClasses,
            primaryCtaText: "Close",
            showCloseInHeader: true,
            fullscreen: true
        };

        await this.showModal(this.modalController, props);
    }
}
