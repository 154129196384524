import { Address } from "./account/address";
import { CustomProperties } from "./BaseResponse";
import { TaxRate } from "./cart";
import { GiftCard } from "./product-detail/product-detail";
import { PagerModel } from "./review";

// Order history
export interface Order {
    CustomOrderNumber: string;
    OrderTotal: string;
    IsReturnRequestAllowed: false;
    OrderStatusEnum: number;
    OrderStatus: string;
    PaymentStatus: string;
    ShippingStatus: string;
    CreatedOn: string;
    Id: number;
    CustomProperties: CustomProperties;
    ContactName: string;
    PointsEarned: number;
    ContactId: number;
    FacilityId: number;
    CustomerOrderStatus: string;
}

export interface OrderHistoryGetRes {
    Data: {
        Orders: Order[];
        RecurringOrders: any[];
        RecurringPaymentErrors: any[];
        CustomProperties: CustomProperties;
    };
    Message?: string;
    ErrorList?: string[];
}

// Order details
export interface Item {
    OrderItemGuid: string;
    Sku: string;
    ProductId: number;
    ProductName: string;
    ProductSeName: string;
    UnitPrice: string;
    SubTotal: string;
    Quantity: number;
    AttributeInfo: string;
    RentalInfo?: any;
    VendorName: string;
    DownloadId: number;
    LicenseId: number;
    Id: number;
    CustomProperties: CustomProperties;
    PictureFullSizeUrl: string;
    PictureThumbnailUrl: string;
    Width: number;
    Height: number;
    Ratio: number;
}

export interface OrderDetails {
    PrintMode: boolean;
    PdfInvoiceDisabled: boolean;
    CustomOrderNumber: string;
    CreatedOn: string;
    OrderStatus: string;
    IsReOrderAllowed: boolean;
    IsReturnRequestAllowed: boolean;
    IsShippable: boolean;
    PickupInStore: boolean;
    PickupAddress: Address;
    ShippingStatus: string;
    ShippingAddress: Address;
    ShippingMethod: string;
    Shipments: any[];
    BillingAddress: Address;
    VatNumber?: any;
    PaymentMethod: string;
    PaymentMethodStatus: string;
    CanRePostProcessPayment: boolean;
    CustomValues: any;
    OrderSubtotal: string;
    OrderSubTotalDiscount?: any;
    OrderShipping: string;
    PaymentMethodAdditionalFee?: any;
    CheckoutAttributeInfo: string;
    PricesIncludeTax: boolean;
    DisplayTaxShippingInfo: boolean;
    Tax: string;
    TaxRates: TaxRate[];
    DisplayTax: boolean;
    DisplayTaxRates: boolean;
    OrderTotalDiscount?: any;
    RedeemedRewardPoints: number;
    RedeemedRewardPointsAmount?: any;
    OrderTotal: string;
    GiftCards: GiftCard[];
    ShowSku: boolean;
    Items: Item[];
    OrderNotes?: any[];
    ShowVendorName: boolean;
    Id: number;
    CustomProperties: CustomProperties;
    ContactId: number;
    FacilityId: number;
    CustomerOrderStatus: string;
    AppliedPeliPalsFunds: string;
    LineItems: LineItem[];
}

export interface LineItem {
    Name: string;
    ProductId: number;
    Quantity: number;
    PriceBeforeDiscount: number;
    FormattedPriceBeforeDiscount: string;
    PriceAfterDiscount: number;
    FormattedPriceAfterDiscount: string;
    DiscountAmount?: number;
    FormattedDiscountAmount?: number;
    DiscountPercentage?: number;
    FormattedDiscountPercentage?: number;
}

// Return order
export interface ReturnOrderGetRes {
    Data: {
        OrderId: number;
        CustomOrderNumber: string;
        Items: {
            ProductId: number;
            ProductName: string;
            ProductSeName: string;
            AttributeInfo: string;
            UnitPrice: string;
            Quantity: number;
            Id: number;
            CustomProperties: CustomProperties;
        }[];
        ReturnRequestReasonId: number;
        AvailableReturnReasons: {
            Name: string;
            Id: number;
            CustomProperties: CustomProperties;
        }[];
        ReturnRequestActionId: number;
        AvailableReturnActions: {
            Name: string;
            Id: number;
            CustomProperties: CustomProperties;
        }[];
        Comments?: any;
        AllowFiles: boolean;
        UploadedFileGuid: string;
        Result?: any;
        CustomProperties: CustomProperties;
    };
    Message?: string;
    ErrorList?: string[];
}

export interface ReturnOrderPostBody {
    Data: {
        AllowFiles: boolean;
        Comments: string;
        CustomOrderNumber: string;
        CustomProperties?: any;
        Items: ReturnOrderItem[];
        OrderId: number;
        Result?: any;
        ReturnRequestActionId: number;
        ReturnRequestReasonId: number;
        UploadedFileGuid: string;
    };
    ErrorList: string[];
    FormValues: {
        Key: string;
        Value: string;
    }[];
    Message: string;
}

export interface ReturnOrderItem {
    AttributeInfo: string;
    CustomProperties: CustomProperties;
    customerInput: number;
    Id: number;
    ProductId: number;
    ProductName: string;
    ProductSeName: string;
    Quantity: number;
    UnitPrice: string;
}

export interface MyOrdersModel {
    Orders: Array<Order>;
    PagerModel: PagerModel;
}

export enum OrderStatusEnum {
    Pending = 10,
    Processing = 20,
    Rejected = 22,
    Approved = 23,
    Printing = 24,
    Shipped = 25,
    Returned = 26,
    FollowUp = 27,
    Complete = 30,
    Cancelled = 40
}

export enum CustomerOrderStatusEnum {
    Cancelled = 0,
    Returned = 1,
    Processing = 2,
    Printing = 3,
    Shipped = 4,
    Arrived = 5
}
