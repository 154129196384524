import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "",
    loadChildren: () =>
      import("../modules/main/main.module").then((m) => m.MainModule)
  },
  {
    path: "",
    loadChildren: () =>
            import("../modules/account/account.module").then(
                (m) => m.AccountModule
            )
  },
  {
    path: "",
    loadChildren: () =>
      import("../modules/order/order.module").then((m) => m.OrderModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
