import { Location } from "@angular/common";
import {
    AfterViewInit,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { SplashScreen } from "@capacitor/splash-screen";
import { AppLauncher } from "@capacitor/app-launcher";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { SITE_URL } from "src/environments/environment";
import { APP_VERSION } from "../environments/environment";
import { AnalyticsService } from "./core/services/analytics.service";
import { ModalComponent } from "./shared/components/modal/modal.component";
import { BasePage } from "./shared/pages/BasePage";
import { StringUtilities } from "./util/string-utilities";
import { Subject, defer, firstValueFrom, from, noop, of } from "rxjs";
import { StartupService } from "./core/services/pelipost-service-proxies/startup-service";
import { HomeScreenService } from "./core/services/home-screen.service";
import { PostBootstrapService } from "./core/services/post-bootstrap.service";
import { LoadingService } from "./core/services";
import { NetworkConnectivityService } from "./core/services/network-connectivity.service";
import { switchMap, takeUntil } from "rxjs/operators";
import { register as registerSwiper } from "swiper/element/bundle";
import { CleverTapService } from "./core/services/service-proxies/external-providers/clevertap.service";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"]
})
export class AppComponent
    extends BasePage
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(NavController) navChild: NavController;

    public showTabs = false;
    public appLoaded = false;

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private zone: NgZone,
        private modalController: ModalController,
        private analyticsService: AnalyticsService,
        private startupService: StartupService,
        private platform: Platform,
        private location: Location,
        private homeScreenService: HomeScreenService,
        private postBootstrapService: PostBootstrapService,
        private loadingService: LoadingService,
        private networkConnectivityService: NetworkConnectivityService,
        private cleverTapService: CleverTapService
    ) {
        super();
        const intialUrl = this.activatedRoute.snapshot.url.toString();
        void this.setShowTabs(intialUrl);
        // HACK: ionic is not always using the router navigation so we have to listen to the location provider
        this.location.onUrlChange((url) => {
            void this.setShowTabs(url);
        });
    }

    ngAfterViewInit(): void {
        registerSwiper();
    }

    async ngOnInit(): Promise<void> {
        if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
            await SplashScreen.hide();
        }
        this.networkConnectivityService.isConnected$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                switchMap(async (isConnected) => {
                    if (isConnected) {
                        await this.networkConnectivityService.dismissNetworkErrorModal();
                    } else {
                        await this.loadingService.dismiss();
                        await this.networkConnectivityService.showNetworkErrorModal();
                    }
                    return isConnected;
                })
            )
            .subscribe();

        this.postBootstrapService.append(
            defer(() => from(this.preloadModal())),
            defer(() => from(this.analyticsService.init())),
            defer(() => of(this.cleverTapService.initialize())),
            defer(() => from(this.attachDeepLinksListener())),
            defer(() => from(this.platform.ready())),
            defer(() => from(this.checkForUpdate()))
        );
        try {
            if (!this.networkConnectivityService.isModalShown) {
                await this.loadingService.show();
            }
            await this.postBootstrapService.run();

            // HACK: Disable hardware back buttons to force navigation via UI
            this.platform.backButton.subscribeWithPriority(9999, noop);

            this.appLoaded = true;
        } catch (error) {
            void this.showErrorModal(this.modalController, error);
            return;
        } finally {
            await this.loadingService.dismiss();
        }
    }

    async ngOnDestroy() {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    private async setShowTabs(url: string): Promise<void> {
        if (!url) {
            this.showTabs = false;
            return;
        }
        if (url === "/") {
            this.showTabs = true;
            return;
        }
        const regex =
            /\/((home)|(my-orders)|(pelipoints((\/earn)|(\/history))?))\/?$/;
        const matches = regex.exec(url);
        if (
            matches &&
            matches.length > 0 &&
            (!matches.includes("home") ||
                !(await this.homeScreenService.showAlternateHomeScreen()))
        ) {
            this.showTabs = true;
            return;
        }
        this.showTabs = false;
    }

    private async checkForUpdate() {
        if (
            !(this.platform.is("ios") || this.platform.is("android")) ||
            this.platform.is("desktop") ||
            this.platform.is("mobileweb")
        ) {
            return;
        }

        const platform = this.platform.is("ios") ? "ios" : "android";

        const buttonText =
            platform === "android"
                ? "Open in the Play Store"
                : "Open in the App Store";

        //LOW: these could be static string resources in a config file
        const appUrl = this.platform.is("android")
            ? "market://details?id=com.pelipost"
            : "itms-apps://itunes.apple.com/app/1092410781";

        const result = await firstValueFrom(
            this.startupService.checkForUpdate(
                StringUtilities.getSemanticVersion(APP_VERSION),
                platform
            )
        );
        if (result.Enabled && result.UpdateRequired) {
            const upgradeModal = await this.modalController.create({
                component: ModalComponent,
                componentProps: {
                    modalType: "error",
                    headerText: "Please update Pelipost",
                    bodyText:
                        "We've squashed bugs and added new features, please update the Pelipost app to continue.",
                    primaryCtaText: buttonText,
                    primaryAction: async () => {
                        if (this.platform.is("android")) {
                            await Browser.open({
                                url: appUrl
                            });
                        } else {
                            await AppLauncher.openUrl({
                                url: appUrl
                            });
                        }
                    }
                }
            });
            await upgradeModal.present();
        } else if (!result.Enabled) {
            const maintenanceModal = await this.modalController.create({
                component: ModalComponent,
                componentProps: {
                    modalType: "warning",
                    headerText: "We are updating!",
                    bodyText:
                        "We are currently improving your app experience. Please try again later.",
                    dismissable: false
                }
            });
            await maintenanceModal.present();
        }
    }

    private async preloadModal() {
        const tempModal = await this.modalController.create({
            animated: false,
            canDismiss: true,
            backdropDismiss: false,
            showBackdrop: false,
            keyboardClose: false,
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: "",
                bodyText: "preload",
                dismissable: true
            }
        });
        await tempModal.present();
        await tempModal.dismiss();
    }

    private async attachDeepLinksListener() {
        await App.addListener("appUrlOpen", (data: URLOpenListenerEvent) => {
            void this.zone.run(async () => {
                const slug = data.url.split(".com").pop();
                if (slug) {
                    await this.router.navigateByUrl(slug);
                }
            });
        });
    }
}
