import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import {
    PasswordRecoveryConfirmRes,
    PasswordRecoveryResetPostBody
} from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.page.html",
    styleUrls: ["./reset-password.page.scss"]
})
export class ResetPasswordPage extends BasePage implements OnInit {
    public resetPasswordForm: UntypedFormGroup;
    public isTokenValid: boolean;
    public showPassword: boolean = false;
    public showConfirmPassword: boolean = false;
    private resetToken: string;
    private userGuid: string;
    private reqBody: PasswordRecoveryResetPostBody;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private navController: NavController,
        private modalCtrl: ModalController
    ) {
        super();
    }

    ngOnInit() {
        this.pageLoad();
    }

    pageLoad() {
        this.resetPasswordForm = this.formBuilder.group({
            password: ["", [Validators.required, Validators.minLength(7)]],
            confirmPassword: [
                "",
                RxwebValidators.compare({ fieldName: "password" })
            ]
        });

        this.route.queryParams.subscribe((params) => {
            this.resetToken = params["token"];
            this.userGuid = params["guid"];
        });

        this.loginService
            .verifyPasswordResetToken(this.resetToken, this.userGuid)
            .subscribe(
                async (res: PasswordRecoveryConfirmRes) => {
                    this.reqBody = res.Data;
                    this.isTokenValid = true;
                },
                async (error) => {
                    await this.showErrorModal(this.modalCtrl, error);
                    this.isTokenValid = false;
                }
            );
    }

    resetPassword() {
        this.reqBody.NewPassword = this.resetPasswordForm.get("password").value;
        this.reqBody.ConfirmNewPassword = this.resetPasswordForm.get(
            "confirmPassword"
        ).value;

        this.loginService
            .postPasswordResetData(this.resetToken, this.userGuid, this.reqBody)
            .subscribe(
                async () => {
                    this.navController.navigateRoot("/login");
                },
                async (error) => {
                    await this.showErrorModal(this.modalCtrl, error);
                }
            );
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    toggleConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }
}
