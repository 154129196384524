<ion-header>
    <app-header
        [showPelipostLogo]="false"
        pageTitle="Settings"
    ></app-header>
</ion-header>
<ion-content>
    <app-page-template [fullWidthContent]="true">
        <section id="page-content" class="full-width mx-3">
            <div *ngIf="accountForm">
                <form [formGroup]="accountForm" (ngSubmit)="save()" novalidate>
                    <ion-item lines="none">
                        <ion-label position="stacked">FIRST NAME</ion-label>
                        <ion-input
                            type="name"
                            placeholder="John"
                            formControlName="firstName"
                            required
                        ></ion-input>
                        <ion-text
                            class="error-text"
                            color="danger"
                            *ngIf="accountForm.get('firstName').invalid && (accountForm.get('firstName').dirty || accountForm.get('firstName').touched)"
                        >
                            <div
                                *ngIf="accountForm.get('firstName').hasError('required')"
                            >
                                First Name is required.
                            </div>
                        </ion-text>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">LAST NAME</ion-label>
                        <ion-input
                            type="family-name"
                            placeholder="Smith"
                            formControlName="lastName"
                            required
                        ></ion-input>
                        <ion-text
                            class="error-text"
                            color="danger"
                            *ngIf="accountForm.get('lastName').invalid && (accountForm.get('lastName').dirty || accountForm.get('lastName').touched)"
                        >
                            <div
                                *ngIf="accountForm.get('lastName').hasError('required')"
                            >
                                Last Name is required.
                            </div>
                        </ion-text>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">EMAIL ADDRESS</ion-label>
                        <ion-input
                            type="email"
                            placeholder="example@pelipost.com"
                            formControlName="email"
                        ></ion-input>
                        <ion-text
                            class="error-text"
                            color="danger"
                            *ngIf="accountForm.get('email').invalid && (accountForm.get('email').dirty || accountForm.get('email').touched)"
                        >
                            <div
                                *ngIf="accountForm.get('email').hasError('required')"
                            >
                                Email is required.
                            </div>
                            <div
                                *ngIf="accountForm.get('email').hasError('email')"
                            >
                                Invalid Email format.
                            </div>
                        </ion-text>
                    </ion-item>
                    <ion-item
                        *ngIf="accountGetRes?.Data.UsernamesEnabled"
                        lines="none"
                    >
                        <ion-label position="stacked">USERNAME</ion-label>
                        <ion-input
                            type="username"
                            placeholder="username"
                            formControlName="username"
                        ></ion-input>
                        <ion-text
                            class="error-text"
                            color="danger"
                            *ngIf="accountForm.get('username').invalid && (accountForm.get('username').dirty || accountForm.get('username').touched)"
                        >
                            <div
                                *ngIf="accountForm.get('username').hasError('required')"
                            >
                                Username is required.
                            </div>
                        </ion-text>
                    </ion-item>
                </form>

                <ion-item *ngIf="!isExternalAuthUser" lines="none">
                    <ion-text
                        color="primary"
                        [routerLink]="['/settings/change-password']"
                    >
                        Change Password
                    </ion-text>
                </ion-item>
            </div>
            <div>
                <h5 class="text-center ion-text-uppercase pb-2">Return Address</h5>
                <ion-card
                    class="p-3"
                    [routerLink]="returnAddressLoading ? false : ['/settings/edit-return-address']"
                >
                    <div class="row">
                        <div class="col return-address">
                            <div *ngIf="!returnAddressLoading">
                                <p *ngIf="!returnAddress">Not provided</p>
                                <div *ngIf="!!returnAddress">
                                    <p>{{returnAddress.FirstName}} {{returnAddress.LastName}}</p>
                                    <p>{{returnAddress.Address1}}</p>
                                    <p>{{returnAddress.Address2}}</p>
                                    <p>{{returnAddress.City}}, {{returnAddress.StateProvinceName}} {{returnAddress.ZipPostalCode}}</p>
                                </div>
                            </div>
                            <div *ngIf="returnAddressLoading">
                                <ion-skeleton-text animated class="w-50"></ion-skeleton-text>
                                <ion-skeleton-text animated class="w-50"></ion-skeleton-text>
                                <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
                            </div>
                        </div>
                        <div class="col-auto">
                            <ion-text *ngIf="!returnAddressLoading" color="primary">
                                Edit
                            </ion-text>
                        </div>
                    </div>
                </ion-card>
            </div>
            <div>
                <h5 class="text-center ion-text-uppercase pt-4 pb-3">Email Notifications</h5>
                <ion-item lines="none" class="toggle-item mt-2">
                    <ion-label>Exclusive offers and discounts</ion-label>
                    <ion-toggle color="green" slot="end" [(ngModel)]="enableEmailMarketing"></ion-toggle>
                </ion-item>
            </div>
            <div>
                <h5 class="text-center ion-text-uppercase pt-4 pb-3">Push Notifications</h5>
                <ion-item lines="none" class="toggle-item">
                    <ion-label>Order status updates</ion-label>
                    <ion-toggle color="green" slot="end" [(ngModel)]="enablePushOrderStatusUpdates"></ion-toggle>
                </ion-item>
                <ion-item lines="none" class="toggle-item mt-2">
                    <ion-label>Exclusive offers and discounts</ion-label>
                    <ion-toggle color="green" slot="end" [(ngModel)]="enablePushMarketing"></ion-toggle>
                </ion-item>
            </div>
            <div class="m-3">
                <ion-text class="custom-button button-block"
                    color="primary"
                    (click)="deleteMyAccount()">
                    <p>Delete my account</p></ion-text>
            </div>
        </section>
        <app-cta-buttons
            primaryCtaLabel="Update"
            [primaryCtaDisabled]="!accountForm.valid"
            (primaryCtaClick)="save()"
            secondaryCtaLabel="Cancel"
            (secondaryCtaClick)="cancel()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
