<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Credit Card"
  >
  </app-page-header>
</ion-header>
<ion-content>
  <app-page-template [hideCtaSection]="true">
    <section id="page-content">
      <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentInfo()" novalidate *ngIf="paymentForm">
        <ion-item lines="none" #firstNameItem>
          <ion-label position="stacked">FIRST NAME</ion-label>
          <ion-input type="name" placeholder="John" formControlName="firstName" [required]="true" id="input-cardholder-first-name"></ion-input>
          <ion-text class="error-text" color="danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
            <div *ngIf="firstName.hasError('required')">
              First Name is required.
            </div>
          </ion-text>
        </ion-item>
        <ion-item lines="none" #lastNameItem>
          <ion-label position="stacked">LAST NAME</ion-label>
          <ion-input type="name" placeholder="Smith" formControlName="lastName" [required]="true" id="input-cardholder-last-name"></ion-input>
          <ion-text class="error-text" color="danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
            <div *ngIf="lastName.hasError('required')">
              Last Name is required.
            </div>
          </ion-text>
        </ion-item>
        <ion-row class="justify-content-between">
          <ion-col class="px-0" size="9">
            <ion-item lines="none" #cardNumberItem [ngClass]="{'ion-invalid ion-touched': !cardNumberValid && submitAttempted}">
              <ion-label position="stacked">Card Number</ion-label>
              <div id="fattjs-number" class="fattjs-field"></div>
              <ion-text class="error-text" color="danger" *ngIf="!cardNumberValid && submitAttempted">
                Card number is not valid.
              </ion-text>
            </ion-item>
          </ion-col>
          <ion-col class="px-0" size="2">
            <ion-item lines="none" #cvvItem [ngClass]="{'ion-invalid ion-touched': !cardCvvValid && submitAttempted}">
              <ion-label position="stacked">CVV</ion-label>
              <div id="fattjs-cvv" class="fattjs-field"></div>
              <ion-text class="error-text" color="danger" *ngIf="!cardCvvValid && submitAttempted">
                CVV is not valid.
              </ion-text>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-item lines="none" #expDateItem>
          <ion-label position="stacked">EXP DATE</ion-label>
          <ion-input readonly formControlName="expirationDate" [required]="expirationDateEditAttempted" id="expirationDateInput"></ion-input>
          <ion-popover trigger="expirationDateInput" show-backdrop="false" size="cover">
            <ng-template>
              <ion-datetime
                #popoverDatetime
                [min]="minDate"
                [max]="maxDate"
                presentation="month-year"
                [value]="expirationDatePickerValue"
                (ionChange)="expirationDateChanged(popoverDatetime)"
              ></ion-datetime>
            </ng-template>
          </ion-popover>
          <ion-text class="error-text" color="danger" *ngIf="expirationDate.invalid && (expirationDate.dirty || expirationDateEditAttempted)">
              <div *ngIf="expirationDate.hasError('required')">
                  Expiration date is required.
              </div>
          </ion-text>
        </ion-item>
        <app-address-form
          (formReady)="formInitialized('address', $event)"
          addressLabel="Billing Address"
          [countryZipOnly]="true"
          [existingAddress]="initialAddress"
          [stateCtrlRequiredBeforeBeingTouched]="submitAttempted"
          id="cardholder-address"
        ></app-address-form>
        <div class="form-group mt-3 mb-4">
          <ion-row>
            <ion-col size="auto">
              <ion-checkbox formControlName="saveCard"></ion-checkbox>
            </ion-col>
            <ion-col>
              <span>Save my credit card info</span>
            </ion-col>
          </ion-row>
        </div>
        <div>
            <ion-button expand="block" type="submit" [disabled]="submitting" id="btn-card-payment-info-pay">Pay</ion-button>
        </div>
      </form>
    </section>
  </app-page-template>
</ion-content>