<div *ngIf="addressForm">
  <div [formGroup]="addressForm" novalidate>
    <ion-item *ngIf="!countryZipOnly" lines="none">
      <ion-label position="stacked">{{addressLabel}}</ion-label>
      <ion-input name="address1" type="text" formControlName="address1" [required]="true"></ion-input>
      <ion-text class="error-text" color="danger" *ngIf="address1.invalid && (address1.dirty || address1.touched)">
        <div *ngIf="address1.hasError('required')">
          Street Address is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item *ngIf="!countryZipOnly" lines="none">
      <ion-label position="stacked">SUITE, APT, ETC. (OPTIONAL)</ion-label>
      <ion-input name="address2" type="text" formControlName="address2"></ion-input>
    </ion-item>
    <ion-item *ngIf="!countryZipOnly" lines="none">
      <ion-label position="stacked">CITY/TOWN</ion-label>
      <ion-input name="city" type="text" formControlName="city" [required]="true"></ion-input>
      <ion-text class="error-text" color="danger" *ngIf="city.invalid && (city.dirty || city.touched)">
        <div *ngIf="city.hasError('required')">
          City is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked">COUNTRY</ion-label>
      <ion-select name="country" formControlName="country" placeholder="Select country">
        <ion-select-option *ngFor="let country of countries | keyvalue" [value]="country.key">{{country.value}}</ion-select-option>
      </ion-select>
      <ion-text class="error-text" color="danger" *ngIf="country.invalid && (country.dirty || country.touched)">
        <div *ngIf="country.hasError('required')">
          Country is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-row class="justify-content-between">
      <ion-col *ngIf="!countryZipOnly" class="pl-0" size="6">
        <ion-item lines="none">
          <ion-label position="stacked">STATE/PROVINCE</ion-label>
          <ion-select name="state" formControlName="state" placeholder="Select state/province"
                      [required]="stateCtrlTouched || stateCtrlRequiredBeforeBeingTouched"
                      (ionCancel)="markStateCtrlTouched()"
                      (ionChange)="markStateCtrlTouched()">
            <ion-select-option *ngFor="let state of states | keyvalue" [value]="state.key">{{state.value}}</ion-select-option>
          </ion-select>
          <ion-text class="error-text" color="danger" *ngIf="state.invalid && (state.dirty || stateCtrlTouched)">
            <div *ngIf="state.hasError('required')">
              State is required.
            </div>
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col [ngClass]="{'pl-0': countryZipOnly}" class="pr-0" [size]="countryZipOnly ? '12' : '6'">
        <ion-item lines="none">
          <ion-label position="stacked">ZIP/POSTAL CODE</ion-label>
          <ion-input name="zipPostalCode" type="text" formControlName="zipPostalCode" [required]="existingAddress?.ZipPostalCodeRequired"></ion-input>
          <ion-text class="error-text" color="danger" *ngIf="zipPostalCode.invalid && (zipPostalCode.dirty || zipPostalCode.touched)">
            <div *ngIf="zipPostalCode.hasError('required')">
              Zip/postal code is required.
            </div>
            <div *ngIf="zipPostalCode.hasError('minlength')">
              Zip/postal code must be at least 5 digits.
            </div>
          </ion-text>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-text class="error-text" color="danger">
      <ul>
        <li *ngFor="let item of errorList">{{ item }}</li>
      </ul>
    </ion-text>
  </div>
</div>