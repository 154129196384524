import { Component } from "@angular/core";
import { NavController } from "@ionic/angular";

@Component({
    selector: "app-get-started",
    templateUrl: "./get-started.page.html",
    styleUrls: ["./get-started.page.scss"]
})
export class GetStartedPage {
    constructor(private navCtrl: NavController) {}

    async createAccount(): Promise<void> {
        await this.navCtrl.navigateForward("/sign-up/create-account");
    }

    async goToLogin(): Promise<void> {
        await this.navCtrl.navigateForward("/login");
    }
}
