import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SharedModule } from "src/app/shared/shared.module";
import { ContactSliderComponent } from "./components/contact-slider/contact-slider.component";
import { MainRoutingModule } from "./main-routing.module";
import { HomePage } from "./pages/home/home.page";
import { LandingPage } from "./pages/landing/landing.page";

@NgModule({
    declarations: [HomePage, LandingPage, ContactSliderComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MainRoutingModule,
        SharedModule
    ],
    exports: [ContactSliderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // HACK: Required for swiperjs components
})
export class MainModule {}
