<div class="mb-4">
  <h5 class="ion-text-uppercase">Contact Information</h5>
    <app-contact-identity
        [contact]="contact"
        [facility]="facility"
    ></app-contact-identity>
</div>
<div class="mb-3">
  <app-order-details
      [lineItems]="lineItems"
      [orderTotals]="orderTotals"
  ></app-order-details>
</div>
<div
  class="
      d-flex
      justify-content-between
      align-items-center
      border
      pelipoints-earned
  "
  *ngIf="orderTotals?.WillEarnRewardPoints"
>
  <div class="d-flex align-items-center">
      <img src="assets/icon/yellow_star_image.svg" />
      <strong>PELIPOINTS EARNED</strong>
  </div>
  <div>{{orderTotals?.WillEarnRewardPoints}}</div>
</div>
