import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

/**
 * This service is used to maintain for stroing data in device storage
 */

@Injectable({
    providedIn: "root"
})
export class StorageService {
    private _storage: Storage | null = null;

    public static KEY_TOKEN = "token";
    public static KEY_NST = "nst";

    public static FIRST_RUN = "firstRun";
    public static IS_LOGGED_IN = "isLoggedIn";

    public static NEW_URL = "URL_CHANGE_KEY";
    public static SHOULD_USE_NEW_URL = "URL_CHANGE_KEY_BOOLEAN";
    public static APP_LOGO = "APP_LOGO";

    public static SENT_TOKEN_TO_SERVER = "SENT_TOKEN_TO_SERVER_KEY";
    public static FCM_TOKEN = "FCM_TOKEN";

    public static CURRENT_LANGUAGE = "current_language";
    public static CURRENT_CURRENCY = "CURRENT_CURRENCY";
    public static IS_DARK_THEME = "IS_DARK_THEME";
    public static CURRENT_LANGUAGE_ID = "current_language_id";
    public static DeviceID = "DEVICE_UNIQUE_ID";
    public static CUSTOMER_INFO = "CUSTOMER_INFO";
    public static PAGE_SIZE = "PAGE_SIZE";
    public static SMS_NUMBER = "SMS_NUMBER";

    constructor(private storage: Storage) {}

    public async init(): Promise<void> {
        const storage = await this.storage.create();
        this._storage = storage;
    }

    async setString(key: string, value: string): Promise<void> {
        await this._storage?.set(key, value);
    }

    async fetchString(key: string): Promise<string> {
        const val = await this._storage?.get(key);
        return val;
    }

    async setObject(key: string, value: any): Promise<void> {
        await this._storage?.set(key, JSON.stringify(value));
    }

    async getObject<T>(key: string): Promise<T> {
        const obj = await this._storage?.get(key);
        return JSON.parse(obj);
    }

    async containsKey(key: string): Promise<boolean> {
        const keys = await this._storage?.keys();
        return keys.some((k) => k === key);
    }

    async removeItem(key: string): Promise<void> {
        await this._storage?.remove(key);
    }

    async clear(): Promise<void> {
        await this._storage?.clear();
    }
}
