/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { NavigationOptions } from "@ionic/angular/common/providers/nav-controller";
import { IOnApproveCallbackData } from "ngx-paypal";
import { Subscription } from "rxjs";
import { StorageService } from "src/app/core/services";
import { ErrorReason } from "src/app/core/services/analytics.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { PelipostCheckoutService } from "src/app/core/services/pelipost-service-proxies/pelipost-checkout.service";
import {
    PelipostPhoto,
    PhotoService,
    PhotoSource
} from "src/app/core/services/photos.service";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { ProductDetailsService } from "src/app/core/services/service-proxies/product-details.service";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { Address } from "src/app/shared/model/account/address";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";
import { OrderConfirmationResponseModel } from "src/app/shared/model/cart";
import { Facility } from "src/app/shared/model/facility";
import { UploadedPhotoModel } from "src/app/shared/model/photos/uploaded-photo-model";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { AbstractWorkflowService } from "src/app/shared/workflows/abstract-workflow.service";
import { IWorkflowRoute } from "src/app/shared/workflows/workflow-route.interface";
import { FattJsProviderService } from "./fatt-js-provider.service";
import { PaypalService } from "./paypal.service";
import { SelectPhotosWorkflowService } from "./select-photos-workflow.service";

@Injectable({
    providedIn: null // force explicit scope
})
export class CheckoutWorkflowService extends AbstractWorkflowService {
    static WorkflowId = "CheckoutWorkflow";
    static VIEWED_DELIVERY_ASSURANCE = "ViewedDeliveryAssurance";
    static SELECTED_SHIPPING_METHOD = "SelectedShippingMethod";

    protected get exitWorkflowUrl(): string {
        return "/";
    }

    private _useRewardPoints: boolean = false;

    get useRewardPoints(): boolean {
        return this._useRewardPoints;
    }

    set useRewardPoints(val: boolean) {
        this._useRewardPoints = val;
    }

    constructor(
        router: Router,
        private navController: NavController,
        private checkoutService: CheckoutService,
        private pelipostCheckoutService: PelipostCheckoutService,
        private modalCtrl: ModalController,
        private storageService: StorageService,
        private fattJsProviderService: FattJsProviderService,
        private paypalService: PaypalService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private productDetailsService: ProductDetailsService,
        private photoService: PhotoService
    ) {
        super(router);
    }

    protected initializeWorkflowRoutes(): void {
        // TODO: Ensure this config is retreived after the module this corresponds to has been loaded
        this.router.config
            .filter(
                (r) => r.data?.workflowId === CheckoutWorkflowService.WorkflowId
            )
            .sort((r) => r.data?.workflowIndex)
            .forEach((r) => {
                const workflowRoute = {} as IWorkflowRoute;
                Object.assign(workflowRoute, r);
                workflowRoute.relativePath =
                    AbstractWorkflowService.compileRelativeUrlForRoute(
                        r,
                        this.router
                    );
                this.workflowRoutes.push(workflowRoute);
            });

        for (let i = 0; i < this.workflowRoutes.length; i++) {
            const current = this.workflowRoutes[i];
            // TODO: Allow override of default previous route assignment
            if (this.workflowRoutes[i - 1]) {
                current.previousRoute = this.workflowRoutes[i - 1];
            }
            // TODO: Allow override of default next route assignment
            if (this.workflowRoutes[i + 1]) {
                current.nextRoute = this.workflowRoutes[i + 1];
            }
        }
    }

    public async navigateNext(options?: NavigationOptions): Promise<void> {
        if (this.nextRoute) {
            await this.navController.navigateForward(
                this.nextRoute.relativePath,
                options
            );
        } else {
            await this.navController.navigateRoot(
                this.exitWorkflowUrl,
                options
            );
        }
    }

    public async navigatePrevious(options?: NavigationOptions): Promise<void> {
        if (this.previousRoute) {
            await this.navController.navigateBack(
                this.previousRoute.relativePath,
                options
            );
        } else {
            await this.navController.navigateRoot(
                this.exitWorkflowUrl,
                options
            );
        }
    }

    public async continueCheckoutWorkflow(): Promise<void> {
        await this.contactContextService.getContactContext().toPromise();
        await this.syncPhotosWithServer();

        const facility = await this.getCurrentFacilityContext();
        const hasViewedDeliveryAssurance =
            await this.hasViewedDeliveryAssurance();
        if (!facility.DeliveryAssuranceEligible || hasViewedDeliveryAssurance) {
            await this.navController.navigateForward("order/order-summary", {
                replaceUrl: true
            });
        } else {
            await this.navController.navigateForward(
                "order/delivery-assurance",
                {
                    replaceUrl: true
                }
            );
        }
    }

    private async syncPhotosWithServer(): Promise<void> {
        const uploadedImages = (
            await this.pelipostCheckoutService
                .getUploadedPhotosInCart()
                .toPromise()
        ).Data.Photos;

        let photoProduct: ProductDetail;
        let messageProduct: ProductDetail;
        let imageRatio: number;

        const uniqueProductIds = uploadedImages
            .map((p) => p.ProductId)
            .filter((pid, i, self) => self.indexOf(pid) === i);
        for (const productId of uniqueProductIds) {
            const product = await this.productDetailsService
                .fetchProductDetail(productId)
                .toPromise();

            if (product.Data.Sku.toLowerCase().includes("note")) {
                messageProduct = product.Data;
            } else {
                photoProduct = product.Data;
                imageRatio = PhotoService.getImageRatio(product.Data);
            }
        }

        await this.selectPhotosWorkflowService.removeMessagePhoto();
        await this.selectPhotosWorkflowService.clearSelectedPhotos();

        const uploadedPhotos = uploadedImages.filter(
            (p) => p.ProductId === photoProduct.Id
        );

        const pelipostPhotos = [];
        for (const photo of uploadedPhotos) {
            const pelipostPhoto = this.getPelipostPhoto(photo, imageRatio);

            if (photo.SourceImageUrl?.length) {
                await this.photoService.storeOriginalPhotoData(
                    pelipostPhoto.id,
                    pelipostPhoto.source,
                    photo.SourceImageUrl
                );
            }

            pelipostPhotos.push(pelipostPhoto);
        }

        await this.selectPhotosWorkflowService.storeSelectedPhotos(
            ...pelipostPhotos
        );

        if (!messageProduct) return;

        const messagePhoto = uploadedImages
            .filter((p) => p.ProductId === messageProduct.Id)
            .map((p) => this.getPelipostPhoto(p, imageRatio))[0];

        await this.selectPhotosWorkflowService.storeMessagePhoto(messagePhoto);
        await this.selectPhotosWorkflowService.storeMessageProduct(
            messageProduct
        );
    }

    private getPelipostPhoto(
        uploadedPhoto: UploadedPhotoModel,
        imageRatio: number
    ): PelipostPhoto {
        return {
            id: uploadedPhoto.DownloadGuid,
            filepath: uploadedPhoto.ImageUrl,
            data: uploadedPhoto.ImageUrl,
            thumbnailUrl: uploadedPhoto.ThumbnailUrl,
            thumbnailData: uploadedPhoto.ThumbnailUrl,
            dimension: {
                width: uploadedPhoto.Width,
                height: uploadedPhoto.Height
            },
            source: PhotoSource.Gallery, //LOW: Track actual photo sources on server side
            downloadGuid: uploadedPhoto.DownloadGuid,
            remoteUrl: uploadedPhoto.ImageUrl,
            associatedProductId: uploadedPhoto.ProductId,
            ratio: imageRatio
        } as PelipostPhoto;
    }

    private async getCurrentFacilityContext(): Promise<Facility> {
        let facilityContextSubscription: Subscription;
        try {
            const facility = await new Promise<Facility>((resolve, reject) => {
                facilityContextSubscription =
                    this.contactContextService.facility$.subscribe(
                        (f) => resolve(f),
                        (err) => reject(err)
                    );
            });
            return facility;
        } finally {
            facilityContextSubscription.unsubscribe();
        }
    }

    async setHasViewedDeliveryAssurance(): Promise<void> {
        await this.storageService.setObject(
            CheckoutWorkflowService.VIEWED_DELIVERY_ASSURANCE,
            true
        );
    }

    async hasViewedDeliveryAssurance(): Promise<boolean> {
        return await this.storageService.getObject<boolean>(
            CheckoutWorkflowService.VIEWED_DELIVERY_ASSURANCE
        );
    }

    async resetHasViewedDeliveryAssurance(): Promise<void> {
        await this.storageService.removeItem(
            CheckoutWorkflowService.VIEWED_DELIVERY_ASSURANCE
        );
    }

    async setSelectedShippingMethod(
        selectedShippingMethod: string
    ): Promise<void> {
        await this.storageService.setString(
            CheckoutWorkflowService.SELECTED_SHIPPING_METHOD,
            selectedShippingMethod
        );
    }

    async getSelectedShippingMethod(): Promise<string> {
        return await this.storageService.fetchString(
            CheckoutWorkflowService.SELECTED_SHIPPING_METHOD
        );
    }

    async resetSelectedShippingMethod(): Promise<void> {
        await this.storageService.removeItem(
            CheckoutWorkflowService.SELECTED_SHIPPING_METHOD
        );
    }

    async resetCheckoutAttributes(): Promise<void> {
        this.useRewardPoints = false;
        await this.resetHasViewedDeliveryAssurance();
        await this.resetSelectedShippingMethod();
    }

    public async useExistingPaymentMethod(
        method: FattMerchant.PaymentMethod,
        orderTotal: string
    ): Promise<void> {
        if (!method) {
            throw Error(
                "Invalid payment method. Please contact an administrator."
            );
        }

        const billingAddress = await this.fattJsProviderService.getAddress(
            method
        );
        await this.debugSaveBillingAddress(billingAddress);
        await this.pelipostCheckoutService
            .setShippingAddressToCurrentFacility()
            .toPromise();
        await this.debugSetPelipostShippingMethod();
        await this.setPaymentMethod("Payments.FattMerchantStax");
        await this.fattJsProviderService.savePaymentInfo(
            method.id,
            method.card_last_four,
            method.card_type,
            false,
            orderTotal
        );
    }

    public async usePaypal(
        data: IOnApproveCallbackData,
        orderTotal: string
    ): Promise<void> {
        if (!data) {
            throw Error(
                "Invalid payment method. Please contact an administrator."
            );
        }

        await this.debugSetPelipostShippingMethod();
        await this.setPaymentMethod("Payments.PayPalSmartPaymentButtons");
        await this.paypalService.savePaymentInfo(data.orderID, orderTotal);
    }

    public parseOrderTotal(orderTotal: string): number {
        if (!orderTotal) {
            throw Error("Cannot parse null or undefined order total string");
        }

        return parseFloat(orderTotal.substring(1));
    }

    public async debugSaveBillingAddress(address: Address) {
        const reqBody: BaseReqBody = new BaseReqBody();

        reqBody.Data = {
            PickupInStore: false,
            ShipToSameAddress: false,
            BillingNewAddress: address
        };

        return await this.checkoutService
            .saveBillingAddress(reqBody)
            .toPromise();
    }

    public async debugSetPelipostShippingMethod() {
        const selectedShippingMethod = await this.getSelectedShippingMethod();
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: "shippingoption",
            Value: selectedShippingMethod
        });
        return await this.checkoutService
            .saveShippingMethod(reqBody)
            .toPromise();
    }

    public async debugSetFattMerchantStaxCartPaymentMethod() {
        return await this.setPaymentMethod("Payments.FattMerchantStax");
    }

    public async setPaymentMethod(paymentMethod) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: `paymentmethod`,
            Value: paymentMethod
        });
        reqBody.Data = {
            UseRewardPoints: this.useRewardPoints
        };
        return await this.checkoutService
            .savePaymentMethod(reqBody)
            .toPromise();
    }

    public async showPaymentFailedModal(error: any): Promise<void> {
        const messages = (error?.error?.ErrorList ||
            error?.ErrorList || [error.message]) as Array<string>;
        const bodyText = messages?.join(" ");
        const modal = await this.modalCtrl.create({
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: "Payment failed",
                bodyText,
                primaryCtaText: "Okay",
                primaryAction: async () => {
                    await this.modalCtrl.dismiss();
                },
                errorReason: ErrorReason.PaymentFailed
            }
        });

        await modal.present();
    }

    public async confirmOrder(): Promise<OrderConfirmationResponseModel> {
        const confirmOrderResponse = await this.checkoutService
            .confirmOrder()
            .toPromise();
        if (confirmOrderResponse && confirmOrderResponse.ErrorList?.length) {
            throw new Error(confirmOrderResponse.ErrorList.toString());
        }
        return confirmOrderResponse;
    }
}
