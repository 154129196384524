import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NavController } from "@ionic/angular";
import { CustomAttributeService } from "src/app/core/services/custom-attribute.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { FormValue } from "src/app/shared/model/BaseResponse";
import { Facility } from "src/app/shared/model/facility";
import { FacilityAttributeModel } from "src/app/shared/model/facilityAttributeModel";
import { Contact } from "src/app/shared/model/contact";

@Component({
    selector: "app-additional-info",
    templateUrl: "./additional-info.page.html",
    styleUrls: ["./additional-info.page.scss"]
})
export class AdditionalInfoPage implements OnInit {
    contact: Contact;
    facility: Facility;
    values: FormValue[];
    public dataForm: UntypedFormGroup;
    private attributeIdMap = new Map();
    public attributes: FacilityAttributeModel[] = [];

    constructor(
        private contactService: ContactService,
        private contactWorkFlowService: ContactWorkflowService,
        private formBuilder: UntypedFormBuilder,
        private attributeService: CustomAttributeService,
        private navCtrl: NavController
    ) {}

    async ngOnInit(): Promise<void> {
        this.contact = await this.contactWorkFlowService.GetContactModel();
        this.facility = await this.contactWorkFlowService.GetFacility();
        this.values = await this.contactWorkFlowService.GetAttributes();
        this.prepareAttributeFormControls();
    }

    private prepareAttributeFormControls() {
        this.contactService
            .getCustomAttributes(this.facility.Id)
            .subscribe((res) => {
                if (res.Data && res.Data.length > 0) {
                    this.attributes = res.Data;

                    let group: any = {};
                    group = this.attributeService.prepFormControls(
                        group,
                        "facility_attribute_",
                        this.attributes,
                        this.attributeIdMap
                    );
                    this.dataForm = this.formBuilder.group(group);
                    this.loadSavedValues();
                } else {
                    void this.navCtrl.navigateForward(
                        "/contacts/new-contact/photo"
                    );
                }
            });
    }

    private loadSavedValues(): void {
        //TODO: debug issue with setting dropdown value
        if (this.values != null) {
            this.values.forEach((value) => {
                this.dataForm
                    .get(value.Key)
                    .setValue(value.Value, { onlySelf: true });
            });
        }
    }

    async continue(): Promise<void> {
        const formValues: FormValue[] = [];

        for (const attributeId in this.dataForm.controls) {
            if (this.attributeIdMap.has(attributeId)) {
                formValues.push({
                    Key: `${attributeId}`,
                    Value: this.dataForm
                        .get(attributeId)
                        .value.toString()
                        .trim()
                });
            }
        }

        this.contact.Attributes = this.attributes;
        await this.contactWorkFlowService.StoreContactModel(this.contact);

        const values = this.attributeService.getSelectedValues(
            this.dataForm,
            this.attributeIdMap
        );
        await this.contactWorkFlowService.StoreAttributes(formValues, values);

        if (this.contact.Id) {
            await this.navCtrl.navigateForward(
                `/contacts/edit-contact/${this.contact.Id}`
            );
        } else {
            await this.navCtrl.navigateForward("/contacts/new-contact/photo");
        }
    }
}
