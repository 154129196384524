/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AuthService, LoadingService } from "src/app/core/services";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { AddressService } from "src/app/core/services/service-proxies/account/address.service";
import {
    AddEditAddressGetRes,
    Address
} from "src/app/shared/model/account/address";
import { CustomerInfo } from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";
import { StringUtilities } from "src/app/util/string-utilities";

@Component({
    templateUrl: "./return-address.page.html",
    styleUrls: ["./return-address.page.scss"]
})
export class ReturnAddressPage extends BasePage implements OnInit, OnDestroy {
    customer: CustomerInfo;
    addEditAddressGetRes: AddEditAddressGetRes;
    address: Address;
    hasReturnAddress = false;
    contactId: number;
    initials = "";
    isOrderWorkflow = false;
    pageTitle = "";
    private subscriptions: Subscription[] = [];

    public addressForm = this.formBuilder.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required]
    });

    get firstName(): AbstractControl {
        return this.addressForm.get("firstName");
    }
    get lastName(): AbstractControl {
        return this.addressForm.get("lastName");
    }

    constructor(
        private route: ActivatedRoute,
        private accountService: AccountService,
        private addressService: AddressService,
        private authService: AuthService,
        private loadingService: LoadingService,
        private modalCtrl: ModalController,
        private navCtrl: NavController,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.loadingService.show();
        this.contactId = +this.route.snapshot.paramMap.get("contactid");
        this.subscriptions.push(
            this.route.data.subscribe((d) => {
                this.isOrderWorkflow = d.isOrderWorkflow;
                this.setPageTitle();
            })
        );

        this.customer = this.authService.customer;
        this.authService.customer$.subscribe((customer) => {
            this.customer = customer;
        });

        try {
            await this.pageload();
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => {
            if (!s.closed) {
                s.unsubscribe();
            }
        });
    }

    public formInitialized(name: string, form: UntypedFormGroup): void {
        this.addressForm.setControl(name, form);
    }

    async pageload(): Promise<void> {
        const returnAddressRes = await this.accountService
            .fetchReturnAddress()
            .toPromise();

        this.hasReturnAddress = !!returnAddressRes.Data;
        this.setPageTitle();
        if (this.hasReturnAddress) {
            this.addEditAddressGetRes = await this.addressService
                .fetchEditAddress(returnAddressRes.Data.Id)
                .toPromise();
        } else {
            this.addEditAddressGetRes = await this.addressService
                .fetchAddAddress()
                .toPromise();
        }

        this.address = this.addEditAddressGetRes.Data?.Address;
        this.addressForm.patchValue({
            firstName: this.address?.FirstName,
            lastName: this.address?.LastName
        });

        await this.loadingService.dismiss();
    }

    getInitials(): string {
        return this.customer
            ? StringUtilities.getInitials(
                  this.customer.FirstName,
                  this.customer.LastName
              )
            : "";
    }

    setPageTitle(): void {
        let prefix: string;

        if (this.isOrderWorkflow) {
            if (this.hasReturnAddress) {
                prefix = "Confirm your";
            } else {
                prefix = "Add your";
            }
        } else {
            prefix = "Edit";
        }

        this.pageTitle = `${prefix} return address`;
    }

    async save(): Promise<void> {
        this.addEditAddressGetRes.Data.Address = {
            ...this.addEditAddressGetRes.Data.Address,
            FirstName: this.firstName?.value?.trim(),
            LastName: this.lastName?.value?.trim(),
            CountryId: this.addressForm.get("address.country").value,
            Address1: this.addressForm.get("address.address1").value,
            Address2: this.addressForm.get("address.address2").value,
            City: this.addressForm.get("address.city").value,
            StateProvinceId: this.addressForm.get("address.state").value,
            ZipPostalCode: this.addressForm.get("address.zipPostalCode").value,
            Email: this.customer.Email,
            Id: this.hasReturnAddress ? this.address.Id : 0
        };

        try {
            await this.accountService
                .saveReturnAddress(this.addEditAddressGetRes, [])
                .toPromise();

            if (this.isOrderWorkflow) {
                await this.navCtrl.navigateForward(
                    `order/select-product/photos`
                );
            } else {
                this.navCtrl.back();
            }
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        }
    }

    cancel(): void {
        this.navCtrl.back();
    }
}
