<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Confirmation"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template [centerPageContent]="true">
    <section id="page-title">
      <p class="mb-4">Your mailing address will look like this:</p>
    </section>
    <section id="page-content" class="h-100 d-flex flex-column align-items-center justify-content-between">
      <div class="contact-info" *ngIf="contact">
        <div
          *ngIf="!photo && !contact?.PeliPalsNumber"
          class="my-auto"
        >
          <ion-button class="border rounded-circle" (click)="addPhoto()">
            Add Photo
            <ion-icon name="add-outline"></ion-icon>
          </ion-button>
        </div>
        <app-contact-identity
          [contact]="contact"
          [facility]="facility"
          [showAvatar]="photo || contact?.PeliPalsNumber"
          class="pl-3"
        ></app-contact-identity>
      </div>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      primaryCtaId="btn-contact-confirm-continue"
      (primaryCtaClick)="addContact()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
