import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
    @Input() showHamburgerMenu: boolean = true;
    @Input() showPelipostLogo: boolean = true;
    @Input() showCloseButton: boolean = false;
    @Input() pageTitle: string;
    @Output() closeButtonClick = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
