import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";

@Component({
    selector: "app-contact-type",
    templateUrl: "./contact-type.page.html",
    styleUrls: ["./contact-type.page.scss"]
})
export class ContactTypePage implements OnInit {
    constructor(
        private contactService: ContactService,
        private contactWorkFlowService: ContactWorkflowService,
        private navCtrl: NavController
    ) {}

    async ngOnInit(): Promise<void> {
        await this.contactService.startContact().toPromise();
    }

    async exitWorkFlow(): Promise<void> {
        await this.contactWorkFlowService.ClearAll();
    }

    async traditionalContact(): Promise<void> {
        await this.navCtrl.navigateForward("/contacts/new-contact/name");
    }

    async pelipalsContact(): Promise<void> {
        await this.navCtrl.navigateForward("/contacts/new-contact/pelipals-id");
    }
}
