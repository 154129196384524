<ion-toolbar>
  <ion-buttons *ngIf="showHamburgerMenu" slot="start">
    <ion-menu-button auto-hide="false" menu="menu-main"></ion-menu-button>
  </ion-buttons>
  <ion-title *ngIf="showPelipostLogo" class="ion-text-center">
    <img
      class="light-mode header-pelipost-logo"
      src="../assets/images/pelipost_logo_no_slogan_LightMode.svg"
    />
    <img
      class="dark-mode header-pelipost-logo"
      src="../assets/images/pelipost_logo_no_slogan_DarkMode.svg"
    />
  </ion-title>
  <ion-title *ngIf="!showPelipostLogo" class="ion-text-center">
    <h3 class="my-0">{{pageTitle}}</h3>
  </ion-title>
  <ion-buttons slot="end">
    <ion-icon
      *ngIf="showCloseButton"
      name="close-circle-outline"
      class="close-button mr-2"
      (click)="closeButtonClick.emit()"
    ></ion-icon>
    <ng-content select='[slot="end"]'></ng-content>
  </ion-buttons>
</ion-toolbar>
<ng-content select="section#below-header-content"></ng-content>
