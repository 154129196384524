/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ModalController,
    NavController,
    Platform,
    ViewWillLeave
} from "@ionic/angular";
import {
    CartService,
    LoadingService,
    StorageService
} from "src/app/core/services";
import {
    PelipostPhoto,
    PhotoService,
    PhotoSource
} from "src/app/core/services/photos.service";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { GalleryPhotoModalComponent } from "../../../components/gallery-photo-modal/gallery-photo-modal.component";
import { BaseOrderPage } from "../../base-order-page";
import { SHOW_GOOGLE_FEATURES } from "src/environments/environment";
import { AndroidMultiselectModalComponent } from "../../../components/android-multiselect-modal/android-multiselect-modal.component";
import { firstValueFrom, tap } from "rxjs";
import {
    AnalyticsService,
    EventTag
} from "src/app/core/services/analytics.service";
import { PermissionsService } from "src/app/core/services/permissions.service";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";

@Component({
    templateUrl: "./select-photos.page.html",
    styleUrls: ["./select-photos.page.scss"]
})
export class SelectPhotosPage
    extends BaseOrderPage
    implements OnInit, OnDestroy, ViewWillLeave
{
    public static ANDROID_MULTISELECT_DO_NOT_SHOW_AGAIN =
        "ANDROID_MULTISELECT_DO_NOT_SHOW_AGAIN";

    photoSource = PhotoSource;
    photos: PelipostPhoto[] = [];
    ios: boolean;
    android: boolean;
    mobileNative: boolean;
    photoOptions = {
        centerInsufficientSlides: true,
        slidesPerView: "auto",
        zoom: false
    };
    editPhotosMode: boolean;
    subtotal = 0;
    maxPhotos: number;
    showGooglePhotos = SHOW_GOOGLE_FEATURES;
    selectingPhotos = false;
    isLetter = false;

    constructor(
        private route: ActivatedRoute,
        private navController: NavController,
        private modalCtrl: ModalController,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService,
        private photoService: PhotoService,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private storageService: StorageService,
        private cartService: CartService,
        private analyticsService: AnalyticsService,
        private permissionsService: PermissionsService,
        private cleverTapService: CleverTapService,
        platform: Platform
    ) {
        super();

        this.ios = platform.is("ios");
        this.android = platform.is("android");
        this.mobileNative =
            !platform.is("desktop") && !platform.is("mobileweb");
    }

    async ngOnInit(): Promise<void> {
        await super.onInit(
            +this.route.snapshot.paramMap.get("productid"),
            this.contactContextService,
            this.productContextService,
            this.modalController,
            this.navController
        );

        this.subscriptions.push(
            this.productContextService.product$
                .pipe(
                    tap(
                        (product) =>
                            (this.isLetter = product?.Sku?.startsWith("letter"))
                    )
                )
                .subscribe()
        );
    }

    async ionViewWillEnter(): Promise<void> {
        await this.loadingService.show();
        try {
            if (!this.product) {
                this.resolveProduct(
                    this.productContextService,
                    this.modalController,
                    this.navController
                );
            }
            this.maxPhotos = this.getMaxPhotos();
            await this.loadPhotos();
        } finally {
            await this.loadingService.dismiss();
        }
    }

    ionViewWillLeave(): void {
        document.getElementById("_capacitor-camera-input-multiple")?.remove();
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    getMaxPhotos(): number {
        return this.facility.MaxNumberOfPhotos;
    }

    async addPhotoFromCamera(): Promise<void> {
        this.selectingPhotos = true;

        const hasPermissions =
            await this.permissionsService.checkPermissions("camera");
        if (!hasPermissions) {
            this.selectingPhotos = false;
            return;
        }

        await this.loadingService.show();

        try {
            const cameraPhoto = await this.photoService.getPhotoFromCamera();
            const photo = await this.photoService.processImage(
                cameraPhoto,
                PhotoSource.Camera,
                this.product
            );
            await this.addPhotos(photo);
        } finally {
            this.selectingPhotos = false;
            await this.loadingService.dismiss();
        }
    }

    async addPhotoFromGallery(): Promise<void> {
        this.selectingPhotos = true;

        const hasPermissions =
            await this.permissionsService.checkPermissions("photos");
        if (!hasPermissions) {
            this.selectingPhotos = false;
            return;
        }

        if (this.android) {
            await this.showAndroidMultiselectModal();
        }

        //HACK: The openFileSelector call will not return if the user selects "Cancel"
        // outside of mobile native, so we automatically release the photo source lock
        // after giving the file picker time to be displayed
        if (!this.mobileNative) {
            setTimeout(() => (this.selectingPhotos = false), 1000);
        } else {
            await this.loadingService.show();
        }

        try {
            const remainingImages = this.maxPhotos - this.photos.length;
            const selectedPhotos =
                await this.photoService.openFileSelector(remainingImages);

            if (!this.mobileNative) {
                await this.loadingService.show();
                this.selectingPhotos = true;
            }

            let capacityExceeded = false;
            const photosToAdd = new Array<PelipostPhoto>();
            for (const selectedPhoto of selectedPhotos.photos) {
                if (this.photos.length + photosToAdd.length >= this.maxPhotos) {
                    capacityExceeded = true;
                    break;
                }
                const photo = await this.photoService.processImage(
                    selectedPhoto,
                    PhotoSource.Gallery,
                    this.product
                );
                photosToAdd.push(photo);
            }
            await this.addPhotos(...photosToAdd);

            if (capacityExceeded) {
                await this.photoLimitReached();
            }
        } catch (err) {
            //HACK: Capacitor Camera plugin only throws cancelation errors on mobile native,
            // which we want to ignore compared to other errors such as "Error loading image"
            if (!err.message?.includes("cancelled")) {
                await this.showModal(this.modalCtrl, {
                    modalType: "error",
                    headerText: "Something went wrong",
                    bodyText:
                        "We were unable to load one or more of your selected photos. Please try again.",
                    primaryCtaText: "Okay",
                    actionType: "close"
                });
                throw err;
            }
        } finally {
            await this.loadingService.dismiss();
            this.selectingPhotos = false;
        }
    }

    private async showAndroidMultiselectModal(): Promise<void> {
        const doNotShowAgain = await this.storageService.getObject<boolean>(
            SelectPhotosPage.ANDROID_MULTISELECT_DO_NOT_SHOW_AGAIN
        );

        if (doNotShowAgain === true) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: AndroidMultiselectModalComponent
        });
        await modal.present();
        const result = await modal.onDidDismiss<boolean>();
        if (result?.data === true) {
            await this.storageService.setObject(
                SelectPhotosPage.ANDROID_MULTISELECT_DO_NOT_SHOW_AGAIN,
                result.data
            );
        }
    }

    async removePhoto(photo: PelipostPhoto): Promise<void> {
        const index = this.photos.indexOf(photo);
        if (index < 0) {
            return;
        }
        this.photos.splice(index, 1);
        this.photos = [...this.photos];
        if (this.photos.length === 0) {
            this.editPhotosMode = false;
        }
        await this.photoService.removeOriginalPhotoData(photo.id, photo.source);
        await this.selectPhotosWorkflowService.removeSelectedPhoto(photo.id);

        this.calculateSubtotal();
        await this.cleverTapService.recordCartItemEvent(
            "Item Removed From Cart",
            this.product.Sku,
            this.product.Name,
            photo.source,
            this.product.ProductPrice.PriceValue,
            this.product.ProductPrice.PriceValue,
            1,
            this.facility,
            this.contact
        );
    }

    async photoLimitReached(): Promise<void> {
        await this.loadingService.dismiss();

        const props = {
            headerText: "Too many photos selected",
            bodyText: `This facility or product type only allows ${this.maxPhotos}. Some photos were not added to cart.`,
            primaryCtaText: "OK"
        };

        const modal = await this.showModal(this.modalController, props);
        await modal.onDidDismiss();
    }

    async showGoogleGalleryModal(): Promise<void> {
        this.selectingPhotos = true;
        try {
            await this.showGalleryPhotoModal(PhotoSource.Google);
        } finally {
            this.selectingPhotos = false;
        }
    }

    async submit(): Promise<void> {
        await this.navController.navigateForward([
            "/order/print-preview",
            this.product.Id
        ]);
    }

    async resetCart(): Promise<void> {
        const continueCurrentOrder = async () => {
            await this.modalController.dismiss();
        };
        const resetShoppingCart = async () => {
            const cart = await firstValueFrom(this.cartService.cart$);
            await this.analyticsService.logEvent(EventTag.CartReset, { cart });
            await firstValueFrom(this.cartService.clearCart());
            await this.selectPhotosWorkflowService.resetWorkflow();
            await this.modalController.dismiss();
            await this.navController.navigateBack([
                "/order/select-product/photos"
            ]);
        };
        await this.showCartResetWarningModal(
            this.modalController,
            "option",
            continueCurrentOrder,
            resetShoppingCart
        );
    }

    private async addPhotos(...photosToAdd: PelipostPhoto[]): Promise<void> {
        if (this.photos.length >= this.maxPhotos) {
            return;
        }

        const overage =
            this.photos.length + photosToAdd.length - this.maxPhotos;
        if (overage > 0) {
            photosToAdd.length = photosToAdd.length - overage;
        }
        if (!photosToAdd?.length) {
            return;
        }

        for (const photo of photosToAdd) {
            photo.associatedProductId = this.product.Id;
            photo.ratio = PhotoService.getImageRatio(this.product);
        }
        this.photos = [...this.photos, ...photosToAdd];
        await this.selectPhotosWorkflowService.storeSelectedPhotos(
            ...photosToAdd
        );
        this.calculateSubtotal();
        await this.cleverTapService.recordCartItemEvent(
            "Item Added to Cart",
            this.product.Sku,
            this.product.Name,
            photosToAdd[0].source,
            this.product.ProductPrice.PriceValue,
            this.subtotal,
            photosToAdd.length,
            this.facility,
            this.contact
        );
    }

    private async loadPhotos(): Promise<void> {
        const selectedPhotos =
            await this.selectPhotosWorkflowService.getSelectedPhotos();
        this.photos = selectedPhotos || [];
        this.calculateSubtotal();
    }

    //TODO: Trigger this via a debounced observable to prevent frequent and/or multiple subsequent calls
    private calculateSubtotal(): void {
        this.subtotal = this.selectPhotosWorkflowService.getSubtotal(
            this.product,
            this.photos.length
        );
    }

    private async showGalleryPhotoModal(
        photoSource: PhotoSource
    ): Promise<void> {
        const photos = this.photos;
        const sourceCapacity =
            this.maxPhotos -
            this.photos.filter((p) => p.source !== photoSource).length;
        const modal = await this.modalController.create({
            component: GalleryPhotoModalComponent,
            componentProps: { photos, photoSource, sourceCapacity }
        });

        await modal.present();
        const result = await modal.onDidDismiss<Array<PelipostPhoto>>();
        if (result && result.data) {
            await this.loadingService.show();
            try {
                this.photos = this.photos.filter(
                    (p) =>
                        p.source !== photoSource ||
                        result.data.some((r) => r.id === p.id)
                );
                const photosToProcess = result.data.filter((r) =>
                    this.photos.every((p) => p.id !== r.id)
                );

                // Although we can work with URLs when resizing and scaling, we run
                // into CORS issues with images from Google, so we need to manually
                // convert them to base64 strings using this function
                const productSpecifications =
                    this.photoService.getProductSpecifications(this.product);
                await this.photoService.convertUrlPhotosToData(photosToProcess);
                for (const photo of photosToProcess) {
                    await this.photoService.resizeAndStoreOriginalData(
                        photo.id,
                        photo.source,
                        photo.data,
                        productSpecifications.dimension
                    );
                    await this.photoService.scaleAndCorrectAspectRatio(
                        photo,
                        productSpecifications
                    );
                }
                await this.addPhotos(...photosToProcess);
            } finally {
                await this.loadingService.dismiss();
            }
        }
        this.calculateSubtotal();
    }
}
