import { Component, ElementRef, ViewChild } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { StartupService } from "src/app/core/services/pelipost-service-proxies/startup-service";
import { SwiperContainer } from "swiper/element";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-landing",
    templateUrl: "./landing.page.html",
    styleUrls: ["./landing.page.scss"]
})
export class LandingPage implements IonViewWillEnter {
    @ViewChild("welcomeSlider") slider: ElementRef<SwiperContainer>;

    sliderOptions = {
        initialSlide: 0,
        slidesPerView: 1,
        autoHeight: true,
        autoplay: false,
        preloadImages: false,
        pagination: {
            el: ".bullet-container",
            bulletClass: "bullet",
            bulletActiveClass: "bullet-active"
        }
    };

    constructor(
        private navCtrl: NavController,
        private startupService: StartupService
    ) {}

    async ionViewWillEnter(): Promise<void> {
        this.slider.nativeElement.swiper.slideTo(0, 1000);
    }

    async createAccount(): Promise<void> {
        await this.navCtrl.navigateForward("/sign-up/get-started");
    }

    async goToLogin(): Promise<void> {
        await this.navCtrl.navigateForward("/login");
    }

    async onPrivacyPolicyClick(): Promise<void> {
        let url: string;
        try {
            url = (await firstValueFrom(this.startupService.getSettings()))
                .PrivacyPolicyUrl;
        } catch (error) {
            url = "https://www.pelipost.com/our-company/legal";
        }
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }
}
