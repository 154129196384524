import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { ReturnAddressPage } from "src/app/shared/pages/return-address/return-address.page";
import { CardPaymentInfoPage } from "./pages/checkout/card-payment-info/card-payment-info.page";
import { ConfirmationPage } from "./pages/checkout/confirmation/confirmation.page";
import { DeliveryAssurancePage } from "./pages/checkout/delivery-assurance/delivery-assurance.page";
import { EditCreditCardPage } from "./pages/checkout/edit-credit-card/edit-credit-card.page";
import { FacilityRulesPage } from "./pages/checkout/facility-rules/facility-rules.page";
import { OrderSummaryPage } from "./pages/checkout/order-summary/order-summary.page";
import { ReviewOrderPage } from "./pages/checkout/review-order/review-order.page";
import { SelectPaymentMethodPage } from "./pages/checkout/select-payment-method/select-payment-method.page";
import { MyOrdersPage } from "./pages/my-orders/my-orders.page";
import { OrderStatusPage } from "./pages/order-status/order-status.page";
import { AddMessagePage } from "./pages/photos/add-message/add-message.page";
import { PrintPreviewPage } from "./pages/photos/print-preview/print-preview.page";
import { SelectPhotosPage } from "./pages/photos/select-photos/select-photos.page";
import { UploadingPage } from "./pages/photos/uploading/uploading.page";
import { PersonalizationBooksComponent } from "./pages/select-product/personalization-books/personalization-books.component";
import { PersonalizationGreetingsComponent } from "./pages/select-product/personalization-greetings/personalization-greetings.component";
import { PersonalizationPhotosComponent } from "./pages/select-product/personalization-photos/personalization-photos.component";
import { PersonalizationPostcardsComponent } from "./pages/select-product/personalization-postcards/personalization-postcards.component";
import { SelectProductPage } from "./pages/select-product/select-product.page";
import { CheckoutWorkflowService } from "./services/checkout-workflow.service";
import { SelectPhotosWorkflowService } from "./services/select-photos-workflow.service";

const routes: Routes = [
    // HACK: Overloading the "order" path to accomodate footer tab functionality
    {
        path: "order",
        redirectTo: "order/my-orders",
        pathMatch: "full"
    },
    {
        path: "order",
        canActivate: [AuthGuard],
        children: [
            {
                path: "return-address",
                component: ReturnAddressPage,
                data: {
                    isOrderWorkflow: true
                }
            },
            {
                path: "select-product",
                component: SelectProductPage,
                children: [
                    {
                        path: "photos",
                        component: PersonalizationPhotosComponent,
                        data: {
                            workflowId: SelectPhotosWorkflowService.WorkflowId,
                            workflowIndex: 0
                        }
                    },
                    {
                        path: "greetings",
                        component: PersonalizationGreetingsComponent
                    },
                    {
                        path: "postcards",
                        component: PersonalizationPostcardsComponent
                    },
                    {
                        path: "books",
                        component: PersonalizationBooksComponent
                    }
                ]
            },
            {
                path: "facility-rules/:productid",
                component: FacilityRulesPage,
                data: {
                    workflowId: SelectPhotosWorkflowService.WorkflowId,
                    workflowIndex: 1
                }
            },
            {
                path: "select-photos/:productid",
                component: SelectPhotosPage,
                data: {
                    workflowId: SelectPhotosWorkflowService.WorkflowId,
                    workflowIndex: 2
                }
            },
            {
                path: "print-preview/:productid",
                component: PrintPreviewPage,
                data: {
                    workflowId: SelectPhotosWorkflowService.WorkflowId,
                    workflowIndex: 3
                }
            },
            {
                path: "add-message/:productid",
                component: AddMessagePage,
                data: {
                    workflowId: SelectPhotosWorkflowService.WorkflowId,
                    workflowIndex: 4
                }
            },
            {
                path: "uploading/:productid",
                component: UploadingPage,
                data: {
                    workflowId: SelectPhotosWorkflowService.WorkflowId,
                    workflowIndex: 5
                }
            },
            {
                path: "delivery-assurance",
                component: DeliveryAssurancePage,
                data: {
                    workflowId: CheckoutWorkflowService.WorkflowId,
                    workflowIndex: 1
                }
            },
            {
                path: "order-summary",
                component: OrderSummaryPage,
                data: {
                    workflowId: CheckoutWorkflowService.WorkflowId,
                    workflowIndex: 2
                }
            },
            {
                path: "payment",
                children: [
                    {
                        path: "select",
                        component: SelectPaymentMethodPage,
                        data: {
                            workflowId: CheckoutWorkflowService.WorkflowId,
                            workflowIndex: 3
                        }
                    },
                    {
                        path: "credit-card",
                        component: CardPaymentInfoPage
                    },
                    {
                        path: "edit-credit-card/:id",
                        component: EditCreditCardPage
                    }
                ]
            },
            {
                path: "review-order",
                component: ReviewOrderPage,
                data: {
                    workflowId: CheckoutWorkflowService.WorkflowId,
                    workflowIndex: 4
                }
            },
            {
                path: "confirmation/:orderid",
                component: ConfirmationPage,
                data: {
                    workflowId: CheckoutWorkflowService.WorkflowId,
                    workflowIndex: 5
                }
            },
            {
                path: "my-orders",
                component: MyOrdersPage,
                data: {
                    footerTabs: true
                }
            },
            {
                path: "my-orders/:orderid",
                component: OrderStatusPage
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrderRoutingModule {}
