import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";

@Component({
    selector: "app-credit-card-icon",
    templateUrl: "./credit-card-icon.component.html",
    styleUrls: ["./credit-card-icon.component.scss"]
})
export class CreditCardIconComponent implements OnInit, OnChanges {
    @Input() cardType: string;

    cardIconSource: string;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cardType.currentValue !== changes.cardType.previousValue) {
            this.setCardIcon();
        }
    }

    ngOnInit() { }

    private setCardIcon(): void {
        switch (this.cardType) {
            case CreditCardType.AmericanExpress:
                this.cardIconSource = "../assets/icon/amex.svg";
                break;
            case CreditCardType.Discover:
                this.cardIconSource = "../assets/icon/discover.svg";
                break;
            case CreditCardType.MasterCard:
                this.cardIconSource = "../assets/icon/mastercard.svg";
                break;
            case CreditCardType.Visa:
                this.cardIconSource = "../assets/icon/visa.svg";
                break;
            case CreditCardType.Paypal:
                this.cardIconSource = "../assets/icon/paypal_icon.png";
                break;
            default:
                this.cardIconSource = null;
                break;
        }
    }
}

export enum CreditCardType {
    AmericanExpress = "amex",
    Discover = "discover",
    MasterCard = "mastercard",
    Visa = "visa",
    Paypal = "paypal"
}
