export abstract class LocalizationConstants {
    public static TITLE_FORGOT_PASS = "account.passwordrecovery";
    public static TITLE_CHANGE_PASS = "account.changepassword";
    public static TITLE_REGISTER = "pagetitle.register";
    public static TITLE_REVIEW = "reviews";
    public static TITLE_ORDER_DETAILS = "pagetitle.orderdetails";
    public static TITLE_ORDER_SHIPMENT_DETAILS = "pagetitle.shipmentdetails";
    public static TITLE_SEARCH = "pagetitle.search";
    public static TITLE_ACCOUNT = "pagetitle.account";

    public static HOME_NAV_HOME = "common.home";
    public static HOME_NAV_CATEGORY = "nopstation.webapi.common.category";
    public static HOME_NAV_SEARCH = "common.search";
    public static HOME_NAV_ACCOUNT = "nopstation.webapi.common.account";
    public static HOME_NAV_MORE = "nopstation.webapi.common.more";

    public static HOME_MANUFACTURER = "manufacturers";
    public static HOME_FEATURED_PRODUCT = "homepage.products";
    public static HOME_BESTSELLER = "bestsellers";

    public static CHANGE_PASS_BTN = "account.changepassword.button";
    public static CHANGE_PASS_OLD = "account.changepassword.fields.oldpassword";
    public static CHANGE_PASS_OLD_REQ =
        "account.changepassword.fields.oldpassword.required";
    public static CHANGE_PASS_NEW = "account.changepassword.fields.newpassword";
    public static CHANGE_PASS_NEW_REQ =
        "account.changepassword.fields.confirmnewpassword.required";
    public static CHANGE_PASS_CONFIRM =
        "account.changepassword.fields.confirmnewpassword";
    public static CHANGE_PASS_CONFIRM_REQ =
        "account.changepassword.fields.confirmnewpassword.required";
    public static CHANGE_PASS_MISMATCH =
        "account.changepassword.fields.newpassword.enteredpasswordsdonotmatch";

    public static FORGOT_PASS_BTN =
        "account.passwordrecovery.changepasswordbutton";
    public static FORGOT_PASS_EMAIL = "account.passwordrecovery.email";
    public static FORGOT_PASS_EMAIL_REQ =
        "account.passwordrecovery.email.required";

    public static REVIEW_WRITE = "reviews.write";
    public static REVIEW_HELPFUL = "reviews.helpfulness.washelpful?";
    public static REVIEW_TITLE = "reviews.fields.title";
    public static REVIEW_TITLE_REQ = "reviews.fields.title.required";
    public static REVIEW_TEXT = "reviews.fields.reviewtext";
    public static REVIEW_TEXT_REQ = "reviews.fields.reviewtext.required";
    public static REVIEW_SUBMIT_BTN = "reviews.submitbutton";
    public static REVIEW_RATING = "reviews.fields.rating";

    public static SETTINGS_URL = "nopstation.webapi.settings.nopcommerceurl";
    public static SETTINGS_LANGUAGE = "nopstation.webapi.settings.language";
    public static SETTINGS_CURRENCY = "nopstation.webapi.settings.currency";
    public static SETTINGS_THEME = "nopstation.webapi.settings.darktheme";
    public static SETTINGS_INVALID_URL =
        "nopstation.webapi.settings.invalidurl";
    public static SETTINGS_BTN_TEST = "nopstation.webapi.settings.test";
    public static SETTINGS_BTN_SET_DEFAULT =
        "nopstation.webapi.settings.setdefault";
    public static SETTINGS_BASE_URL_CHANGE_FAIL =
        "nopstation.webapi.settings.baseUrl_fail";

    public static ENTER_VALID_EMAIL =
        "nopstation.webapi.common.entervalidemail";
    public static ENTER_PRICE =
        "nopstation.webapi.shoppingcart.donation.enterprice";
    public static ENTER_PRICE_REQ =
        "nopstation.webapi.shoppingcart.donation.enterprice.required";

    public static LOGIN_EMAIL = "account.login.fields.email";
    public static LOGIN_EMAIL_REQ = "account.login.fields.email.required";
    public static LOGIN_PASS = "account.login.fields.password";
    public static LOGIN_PASS_REQ = "nopstation.webapi.login.password.required";
    public static LOGIN_NEW_CUSTOMER = "account.login.newcustomer";
    public static LOGIN_FORGOT_PASS = "account.login.forgotpassword";
    public static LOGIN_LOGIN_BTN = "account.login.loginbutton";
    public static LOGIN_OR = "nopstation.webapi.login.or";
    public static LOGIN_LOGIN_WITH_FB = "nopstation.webapi.loginwithfb";

    public static REWARD_NO_HISTORY = "rewardpoints.nohistory";
    public static REWARD_POINT_DATE = "rewardpoints.fields.createddate";
    public static REWARD_POINT_END_DATE = "rewardpoints.fields.enddate";
    public static REWARD_POINT_MSG = "rewardpoints.fields.message";
    public static REWARD_POINT_ = "rewardpoints.fields.points";
    public static REWARD_POINT_BALANCE = "rewardpoints.fields.pointsbalance";
    public static REWARD_POINT_BALANCE_MIN = "rewardpoints.minimumbalance";
    public static REWARD_POINT_BALANCE_CURRENT = "rewardpoints.currentbalance";

    public static ORDER_NUMBER = "account.customerorders.ordernumber";
    public static ORDER_STATUS = "account.customerorders.orderstatus";
    public static ORDER_TOTAL = "account.customerorders.ordertotal";
    public static ORDER_DATE = "account.customerorders.orderdate";
    public static ORDER_PRICE = "order.product(s).price";
    public static ORDER_QUANTITY = "order.product(s).quantity";
    public static ORDER_TOTAL_ = "order.product(s).sku";
    public static ORDER_REORDER = "order.reorder";
    public static ORDER_PDF_INVOICE = "order.getpdfinvoice";
    public static ORDER_NOTES = "order.notes";
    public static ORDER_RETURN_ITEMS = "order.returnitems";

    public static ORDER_SHIPMENT = "order.shipments";
    public static ORDER_SHIPMENT_ID = "order.shipments.id";
    public static ORDER_DATE_SHIPPED = "order.shipments.shippeddate";
    public static ORDER_DATE_DELIVERED = "order.shipments.deliverydate";
    public static SHIPMENT_TRACKING_NUMBER = "order.shipments.trackingnumber";

    public static RETURN_ID = "account.customerreturnrequests.title";
    public static RETURNED_ITEM = "account.customerreturnrequests.item";
    public static RETURN_REASON = "account.customerreturnrequests.reason";
    public static RETURN_ACTION = "returnrequests.returnaction";
    public static RETURN_DATE_REQUESTED = "account.customerreturnrequests.date";
    public static RETURN_UPLOADED_FILE =
        "account.customerreturnrequests.uploadedfile";

    public static RETURN_REQ_UPLOAD = "returnrequests.uploadedfile";
    public static RETURN_REQ_UPLOAD_FILE = "common.fileuploader.upload";
    public static RETURN_REQ_SUBMIT = "returnrequests.submit";
    public static RETURN_REQ_COMMENTS = "returnrequests.comments";
    public static RETURN_REQ_REASON = "returnrequests.returnreason";
    public static RETURN_REQ_ACTION = "returnrequests.returnaction";
    public static RETURN_REQ_TITLE_WHY = "returnrequests.whyreturning";
    public static RETURN_REQ_TITLE_WHICH_ITEM =
        "returnrequests.selectproduct(s)";
    public static RETURN_REQ_RETURN_QTY = "returnrequests.products.quantity";
    //        public static RETURN_REQ_ =;

    public static SKU = "products.sku";
    public static ORDER_QUANTITY_SHIPPED =
        "order.shipments.product(s).quantity";

    public static CATALOG_ITEMS_PER_PAGE = "catalog.pagesize.label";
    public static CATALOG_ORDER_BY = "catalog.orderby";

    public static MORE_SCAN_BARCODE = "nopstation.webapi.scanbarcode";
    public static MORE_SETTINGS = "nopstation.webapi.settings";
    public static MORE_PRIVACY_POLICY = "nopstation.webapi.privacypolicy";
    public static MORE_ABOUT_US = "nopstation.webapi.aboutus";
    public static MORE_CONTACT_US = "contactus";

    public static ACCOUNT_MY_REVIEW = "account.customerproductreviews";
    public static ACCOUNT_INFO = "nopstation.webapi.account.info";
    public static ACCOUNT_CUSTOMER_ADDRESS = "account.customeraddresses";
    public static ACCOUNT_SHOPPING_CART = "account.shoppingcart";
    public static ACCOUNT_REWARD_POINT = "account.rewardpoints";
    public static ACCOUNT_LOGIN = "account.login";
    public static ACCOUNT_LOGOUT = "account.logout";
    public static ACCOUNT_LOGOUT_CONFIRM =
        "nopstation.webapi.account.logoutconfirmation";
    public static ACCOUNT_ORDERS = "account.customerorders";
    public static ACCOUNT_RETURN_REQUESTS = "account.customerreturnrequests";
    public static ACCOUNT_DOWNLOADABLE_PRODUCTS =
        "account.downloadableproducts";
    public static ACCOUNT_WISHLIST = "wishlist";

    public static CONTACT_US_FULLNAME = "contactus.fullname.hint";
    public static CONTACT_US_EMAIL = "contactus.email.hint";
    public static CONTACT_US_ENQUIRY = "contactus.enquiry.hint";
    public static CONTACT_US_SUBJECT = "contactus.subject.hint";
    public static CONTACT_US_BUTTON = "contactus.button";
    public static CONTACT_US_REQUIRED_FULLNAME = "contactus.fullname.required";
    public static CONTACT_US_REQUIRED_EMAIL = "contactvendor.email.required";
    public static CONTACT_US_REQUIRED_ENQUIRY = "contactus.enquiry.required";

    public static VENDOR_CONTACT_VENDOR = "contactvendor";
    public static VENDOR_FULLNAME = "contactvendor.fullname.hint";
    public static VENDOR_EMAIL = "contactvendor.email.hint";
    public static VENDOR_ENQUIRY = "contactvendor.enquiry.hint";
    public static VENDOR_SUBJECT = "contactvendor.subject.hint";
    public static VENDOR_BUTTON = "contactvendor.button";
    public static VENDOR_REQUIRED_FULLNAME = "contactvendor.fullname.required";
    public static VENDOR_REQUIRED_EMAIL = "contactvendor.email.required";
    public static VENDOR_REQUIRED_ENQUIRY = "contactvendor.enquiry.required";
    public static VENDOR_REQUIRED_SUBJECT = "contactvendor.subject.required";

    public static ADDRESS_COMPANY_NAME = "account.fields.company";
    public static ADDRESS_FAX = "account.fields.fax";
    public static ADDRESS_PHONE = "account.fields.phone";
    public static ADDRESS_EMAIL = "account.fields.email";

    public static FILTER = "nopstation.webapi.filtering.filter";
    public static FILTER_REMOVE = "filtering.pricerangefilter.remove";
    public static FILTER_PRICE_RANGE = "filtering.pricerangefilter";
    public static FILTER_SPECIFICATION = "filtering.specificationfilter";
    public static FILTER_FILTER_BY =
        "filtering.specificationfilter.currentlyfilteredby";

    public static SEARCH_QUERY_LENGTH =
        "search.searchtermminimumlengthisncharacters";
    public static SEARCH_NO_RESULT = "search.noresultstext";
    public static PRODUCT_GROUPED_PRODUCT =
        "enums.nop.core.domain.catalog.producttype.groupedproduct";
    public static PRODUCT_MANUFACTURER = "products.manufacturer";

    public static PRODUCT_TAG = "products.tags";
    public static PRODUCT_VENDOR = "products.vendor";
    public static PRODUCT_AVAILABILITY = "products.availability";
    public static PRODUCT_QUANTITY = "products.tierprices.quantity";
    public static PRODUCT_FREE_SHIPPING = "products.freeshipping";
    public static PRODUCT_INVALID_PRODUCT =
        "nopstation.webapi.sliders.fields.entityid.invalidproduct";
    public static PRODUCT_BTN_BUY_NOW = "nopstation.webapi.shoppingcart.buynow";
    public static PRODUCT_BTN_RENT_NOW =
        "nopstation.webapi.shoppingcart.rentnow";
    public static PRODUCT_WISHLIST_DISABLED = "shoppingcart.wishlistdisabled";
    public static PRODUCT_BUY_DISABLED = "shoppingcart.buyingdisabled";
    public static PRODUCT_BTN_ADDTOCART = "shoppingcart.addtocart";
    public static PRODUCT_BTN_ADDTO_WISHLIST = "shoppingcart.addtowishlist";
    public static PRODUCT_ADDED_TO_WISHLIST =
        "products.producthasbeenaddedtothewishlist";
    public static PRODUCT_ALSO_PURCHASED = "products.alsopurchased";
    public static PRODUCT_DESCRIPTION = "account.vendorinfo.description";
    public static PRODUCT_RELATED_PRODUCT = "products.relatedproducts";
    public static PRODUCT_RENTAL_START = "shoppingcart.rental.enterstartdate";
    public static PRODUCT_RENTAL_END = "shoppingcart.rental.enterenddate";
    public static PRODUCT_RENT = "shoppingcart.rent";
    public static PRODUCT_GIFT_CARD_SENDER = "products.giftcard.sendername";
    public static PRODUCT_GIFT_CARD_RECIEPIENT =
        "products.giftcard.contactname";
    public static PRODUCT_GIFT_CARD_MESSAGE = "products.giftcard.message";
    public static PRODUCT_QUANTITY_POSITIVE =
        "shoppingcart.quantityshouldpositive";

    public static WISHLIST_NO_ITEM = "wishlist.cartisempty";
    public static WISHLIST_ADD_ALL_TO_CART =
        "nopstation.webapi.wishlist.addall";

    public static APP_UPDATE_MSG = "nopstation.webapi.update.msg";
    public static APP_UPDATE_BTN = "nopstation.webapi.update.label";

    public static COMMON_SOMETHING_WENT_WRONG =
        "nopstation.webapi.common.somethingwrong";
    public static COMMON_NO_DATA = "nopstation.webapi.common.nodata";
    public static COMMON_SEE_ALL = "nopstation.webapi.home.seeall";
    public static COMMON_DONE = "nopstation.webapi.common.done";
    public static COMMON_PLEASE_WAIT = "nopstation.webapi.common.pleasewait";
    public static COMMON_SELECT = "nopstation.webapi.common.select";
    public static COMMON_AGAIN_PRESS_TO_EXIT =
        "nopstation.webapi.home.pressagaintoexit";
    public static COMMON_YES = "common.yes";
    public static COMMON_NO = "common.no";
    public static FILE_DOWNLOADED = "File downloaded to Download directory";
    public static COMMON_MAX_FILE_SIZE = "shoppingcart.maximumuploadedfilesize";

    public static DOWNLOADABLE_I_AGREE = "downloadableproducts.iagree";
    public static DOWNLOADABLE_USER_AGREEMENT =
        "downloadableproducts.useragreement";
    public static DOWNLOADABLE_USER_DOWNLOAD =
        "downloadableproducts.fields.download";

    // SHOPPING CART;
    public static SHOPPING_CART_TITLE = "pagetitle.shoppingcart";

    public static PRODUCTS = "shoppingcart.product(s)";
    public static ITEMS = "shoppingcart.mini.items";
    public static ENTER_YOUR_COUPON = "shoppingcart.discountcouponcode.tooltip";
    public static ENTER_GIFT_CARD = "shoppingcart.giftcardcouponcode.tooltip";

    public static APPLY_COUPON = "shoppingcart.discountcouponcode.button";
    public static ADD_GIFT_CARD = "shoppingcart.giftcardcouponcode.button";

    public static ENTERED_COUPON_CODE =
        "shoppingcart.discountcouponcode.currentcode";

    public static SUB_TOTAL = "shoppingcart.totals.subtotal";
    public static SHIPPING = "shoppingcart.totals.shipping";
    public static DISCOUNT = "shoppingcart.totals.subtotaldiscount";
    public static GIFT_CARD = "shoppingcart.totals.giftcardinfo";
    public static GIFT_CARD_REMAINING =
        "shoppingcart.totals.giftcardinfo.remaining";
    public static TAX = "shoppingcart.totals.tax";
    public static TOTAL = "shoppingcart.totals.ordertotal";

    public static CART_EMPTY = "shoppingcart.cartisempty";
    public static CHECKOUT = "checkout.button";
    public static CART_ESTIMATE_SHIPPING_TITLE =
        "shoppingcart.estimateshipping";
    public static CART_ESTIMATE_SHIPPING_SUBTITLE =
        "shoppingcart.estimateshipping.tooltip";
    public static CART_ESTIMATE_SHIPPING_BTN =
        "shoppingcart.estimateshipping.button";
    public static CART_ESTIMATE_SHIPPING_ZIP =
        "shoppingcart.estimateshipping.zippostalcode";

    public static CALCULATED_DURING_CHECKOUT =
        "shoppingcart.totals.calculatedduringcheckout";

    // GUEST CHECOUT DIALOG;

    public static CHECKOUT_AS_GUEST_TITLE =
        "account.login.checkoutasguestorregister";
    public static CHECKOUT_AS_GUEST = "account.login.checkoutasguest";
    public static REGISTER_AND_SAVE_TIME = "nopstation.webapi.registersavetime";
    public static CREATE_ACCOUNT_LONG_TEXT =
        "nopstation.webapi.creatingaccountlongtext";
    public static RETURNING_CUSTOMER = "nopstation.webapi.returningcustomer";

    // CHECKOUT;
    public static PLEASE_COMPLETE_PREVIOUS_STEP =
        "nopstation.webapi.checkout.completepreviousstep";

    public static ADDRESS_TAB = "checkout.progress.address";
    public static SHIPPING_TAB = "checkout.progress.shipping";
    public static PAYMENT_TAB = "checkout.progress.payment";
    public static CONFIRM_TAB = "checkout.progress.confirm";

    public static BILLING_ADDRESS_TAB = "checkout.billingaddress";
    public static SHIPPING_ADDRESS_TAB = "checkout.shippingaddress";

    public static SHIP_TO_SAME_ADDRESS = "checkout.shiptosameaddress";

    public static NEW_ADDRESS = "checkout.newaddress";
    public static CHECKOUT_PICKUP_POINT_CHECKMARK_TITLE =
        "checkout.pickuppoints.description";

    // Payment method;
    public static USE_REWARD_POINTS = "checkout.userewardpoints";

    public static FIRST_NAME = "address.fields.firstname";
    public static LAST_NAME = "address.fields.lastname";
    public static COMPANY = "address.fields.company";
    public static CITY = "address.fields.city";
    public static PHONE = "address.fields.phonenumber";
    public static FAX = "address.fields.fax";
    public static ADDRESS_1 = "address.fields.address1";
    public static ADDRESS_2 = "address.fields.address2";
    public static EMAIL = "address.fields.email";
    public static CONFIRM_EMAIL = "account.fields.confirmemail";

    public static REGISTRATION_PERSONAL_DETAILS = "account.yourpersonaldetails";
    public static REGISTRATION_PASSWORD = "account.yourpassword";
    public static GENDER = "account.fields.gender";
    public static GENDER_MALE = "account.fields.gender.male";
    public static GENDER_FEMALE = "account.fields.gender.female";

    public static ENTER_PASSWORD =
        "nopstation.webapi.account.fields.enterpassword";
    public static CONFIRM_PASSWORD = "account.fields.confirmpassword";
    public static OPTIONS = "account.options";
    public static CHANGE_PASSWORD = "account.changepassword";
    public static NEWSLETTER = "account.fields.newsletter";
    public static ACCEPT_PRIVACY_POLICY = "account.fields.acceptprivacypolicy";

    public static DATE_OF_BIRTH = "account.fields.dateofbirth";

    public static USERNAME = "account.fields.username";

    public static SELECT_COUNTRY = "address.selectcountry";

    public static SELECT_STATE = "address.selectstate";

    public static STATE_PROVINCE = "address.fields.stateprovince";
    public static ZIP_CODE = "address.fields.zippostalcode";

    public static ADD_NEW_ADDRESS = "account.customeraddresses.addnew";

    public static UPDATED_SUCCESSFULLY = "nopstation.webapi.updated";

    public static ADDRESS_UPDATED_SUCCESSFULLY =
        "nopstation.webapi.address.fields.addressupdated";
    public static ADDRESS_SAVED_SUCCESSFULLY =
        "nopstation.webapi.address.fields.addresssaved";

    public static CONFIRM_DELETE_ADDRESS =
        "nopstation.webapi.address.fields.confirmdeleteaddress";
    public static DELETE_ADDRESS =
        "nopstation.webapi.address.fields.deleteaddress";

    public static STREET_ADDRESS = "account.fields.streetaddress";
    public static STREET_ADDRESS_2 = "account.fields.streetaddress2";
    public static COUNTRY = "account.fields.country";
    public static COUNTRY_REQUIRED = "account.fields.country.required";

    public static IS_REQUIRED = "nopstation.webapi.common.isrequired";

    // Confirm Order;
    public static PICK_UP_POINT_ADDRESS = "order.pickupaddress";
    public static SHIPPING_METHOD = "checkout.shippingmethod";
    public static PAYMENT_METHOD = "checkout.paymentmethod";
    public static SELECTED_ATTRIBUTES =
        "nopstation.webapi.checkout.selectedattributes";
    public static ORDER_CALCULATION =
        "nopstation.webapi.checkout.ordercalculation";
    public static WILL_EARN = "shoppingcart.totals.rewardpoints.willearn";
    public static POINTS = "shoppingcart.totals.rewardpoints.willearn.point";
    public static QUANTITY = "order.product(s).quantity";
    public static PRICE = "order.product(s).price";
    public static CHECKOUT_ORDER_NUMBER = "checkout.ordernumber";
    public static CHECKOUT_ORDER_PLACED = "nopstation.webapi.order.confirmed"; // Your Order is Confirmed;

    public static CONTINUE = "common.continue";
    public static REGISTER_BUTTON = "account.register.button";
    public static SAVE_BUTTON = "common.save";
    public static CONFIRM_BUTTON = "checkout.confirmbutton";

    public static ONLINE_PAYMENT = "nopstation.webapi.checkout.onlinepayment";

    // Barcode;
    public static PUT_BARCODE_HERE = "nopstation.webapi.barcode.putbarcodehere";
    public static INVALID_PRODUCT = "nopstation.webapi.barcode.invalidproduct";

    // Order Complete Dialog;

    public static ORDER_PROCESSED =
        "checkout.yourorderhasbeensuccessfullyprocessed";
    public static THANK_YOU = "checkout.thankyou";

    public static NO_INTERNET = "nopstation.webapi.nointernet";
    public static SETTINGS = "nopstation.webapi.settings";
    public static TRY_AGAIN = "nopstation.webapi.tryagain";

    public static READ_BEFORE_CONTINUE = "nopstation.webapi.readbeforecontinue";
    public static I_READ_I_ACCEPT = "nopstation.webapi.accept";

    // search;
    public static ADVANCED_SEARCH = "search.advancedsearch";
    public static COMMON_ALL = "common.all";
    public static SEARCH_MANUFACTURER = "search.manufacturer";
    public static SEARCH_VENDOR = "search.vendor";
    public static SEARCH_CATEGORY = "search.category";
    public static SEARCH_BUTTON = "search.button";
    public static AUTOMATICALLY_SEARCH_SUBCATEGORIES =
        "search.includesubcategories";
    public static SEARCH_PRICE_RANGE = "search.pricerange";
    public static SEARCH_TO = "search.pricerange.to";
    public static SEARCH_IN_PRODUCT_DISCRIPTIONS =
        "search.searchindescriptions";
}
