import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { NopCountry } from "src/app/shared/model/country";
import { NopStateProvince } from "src/app/shared/model/state";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class LocationLookupService {
    private url = `${this.baseService.BASE_PEL_URL}locationlookup`;

    constructor(private baseService: BasePelipostService) {}

    public getCountries() {
        return this.baseService.getRequest<BaseResponseModel<NopCountry[]>>(
            `${this.url}/getcountries`
        );
    }

    public findCountryByThreeLetterCode(threeLetterCode: string) {
        return this.baseService.getRequestWithParam<
            BaseResponseModel<NopCountry>
        >(
            `${this.url}/getcountrybythreelettercode`,
            new HttpParams({ fromObject: { threeLetterCode } })
        );
    }

    public findStateByTwoLetterCode(twoLetterCode: string, countryId: number) {
        return this.baseService.getRequestWithParam<
            BaseResponseModel<NopStateProvince>
        >(
            `${this.url}/getstatebytwolettercode/${countryId}`,
            new HttpParams({ fromObject: { twoLetterCode } })
        );
    }

    public findCountryById(id: number) {
        const countryId = id.toString();
        return this.baseService.getRequestWithParam<
            BaseResponseModel<NopCountry>
        >(
            `${this.url}/getcountrybyid`,
            new HttpParams({ fromObject: { countryId } })
        );
    }

    public findStateById(id: number) {
        const stateProvinceId = id.toString();
        return this.baseService.getRequestWithParam<
            BaseResponseModel<NopStateProvince>
        >(
            `${this.url}/getstatebyid`,
            new HttpParams({ fromObject: { stateProvinceId } })
        );
    }
}
