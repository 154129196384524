import packageJson from "../../package.json";

export const environment = {
    production: true,
    id: "dev"
};

export const SITE_URL = "https://devpro.pelipost.com";

export const BASE_URL = `${SITE_URL}/api/`;
export const BASE_PEL_URL = `${SITE_URL}/pelapi/`;
export const APP_VERSION = packageJson.version;
export const SHOW_GOOGLE_FEATURES = true;

export const applicationInsightsConfig = {
    instrumentationKey: "04ec3afa-cd68-4e49-9db1-f9bbc437848e"
};

export const firebaseConfig = {
    apiKey: "AIzaSyDLAYtDtNK50vz6LJb_jduu34WsIuYkxb4",
    authDomain: "pelipost---ga4.firebaseapp.com",
    projectId: "pelipost---ga4",
    storageBucket: "pelipost---ga4.appspot.com",
    messagingSenderId: "277141142693",
    appId: "1:277141142693:web:83d72774651ae54a47614c",
    measurementId: "G-QWL8RTXDD6"
};

export const PAYPAL_CLIENT_ID =
    "AXltIwf9EPVK3qi85OsDfkRWghnKiTayLmX4rXLppCIffJs_JwuELqhj0fIAnsdHTED1qKEZ3cHHj5cA";
