import { Injectable } from "@angular/core";
import { JwtUtils } from "../util/JwtUtils";
import { AnalyticsService, EventTag } from "./analytics.service";
import { AuthService } from "./auth.service";

enum Segment {
    A,
    B,
    None
}

class Context {
    static AddAMessageScreen: string = "add_a_message_screen";
    static HomeScreen: string = "home_screen";
}

interface UserSegment {
    segment: Segment;
    context: string;
}

@Injectable({
    providedIn: "root"
})
export class UserSegmentService {
    private userSegments: UserSegment[] = [];

    constructor(
        private analyticsService: AnalyticsService,
        private authService: AuthService
    ) {}

    async populateUserSegments(): Promise<void> {
        this.userSegments.length = 0;

        // There are not currently any active user segments
    }

    private getUserSegment(context: string): UserSegment {
        return this.userSegments.find((s) => s.context === context);
    }

    private getSegmentByUserId(): Segment {
        if (!this.authService.token) {
            return Segment.None;
        }

        const userId = JwtUtils.getUserId(this.authService.token);
        if (!userId || userId < 1) {
            return Segment.None;
        }

        return userId % 2 === 0 ? Segment.A : Segment.B;
    }

    async logOrderConfirmedEvent(): Promise<void> {
        for (const userSegment of this.userSegments) {
            let eventName: string;
            if (userSegment.segment === Segment.A) {
                eventName = EventTag.OrderConfirmedGroupA(userSegment.context);
            } else if (userSegment.segment === Segment.B) {
                eventName = EventTag.OrderConfirmedGroupB(userSegment.context);
            } else {
                eventName = EventTag.OrderConfirmed;
            }

            await this.analyticsService.logEvent(eventName, {});
        }
    }

    async logOrderStartedEvent(): Promise<void> {
        for (const userSegment of this.userSegments) {
            let eventName: string;
            if (userSegment.segment === Segment.A) {
                eventName = EventTag.OrderStartedGroupA(userSegment.context);
            } else if (userSegment.segment === Segment.B) {
                eventName = EventTag.OrderStartedGroupB(userSegment.context);
            } else {
                eventName = EventTag.OrderStarted;
            }

            await this.analyticsService.logEvent(eventName, {});
        }
    }
}
