import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AccountModule } from "../modules/account/account.module";
import { MainModule } from "../modules/main/main.module";
import { OrderModule } from "../modules/order/order.module";
import { PelipointsModule } from "../modules/pelipoints/pelipoints.module";
import { ContactsModule } from "../modules/contacts/contacts.module";
import { CoreRoutingModule } from "./core-routing.module";

@NgModule({
    imports: [
        CommonModule,
        CoreRoutingModule,
        MainModule,
        AccountModule,
        ContactsModule,
        OrderModule,
        PelipointsModule
    ],
    exports: [RouterModule]
})
export class CoreModule {}
