<ion-header>
    <app-page-header
        [hideBorder]="true"
        pageTitle="Create account"
    ></app-page-header>
</ion-header>
<ion-content>
    <section>
        <div class="text-center">
            <div class="mt-2 mb-4">
                <img
                  class="light-mode w-75"
                  src="../assets/images/pelipost_logo_horizontal_LightMode.svg"
                />
                <img
                  class="dark-mode w-75"
                  src="../assets/images/pelipost_logo_horizontal_DarkMode.svg"
                />
            </div>
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col size="12" sizeMd="6" sizeXl="3">
                        <ion-button
                            expand="block"
                            class="mb-3"
                            (click)="createAccount()"
                            id="btn-create-account"
                        >
                            Sign up with email
                        </ion-button>
                        <app-social-logins
                            type="registration"
                        ></app-social-logins>

                        <div class="section-header mt-5 mb-3">
                            <span>Already have an account?</span>
                        </div>
                        <div class="custom-button button-block button-outline" (click)="goToLogin()">
                            <ion-text color="primary">
                                Log in
                            </ion-text>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </section>
</ion-content>
