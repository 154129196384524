export class JwtUtils {
    static isJwtExpired(token: string | null): boolean {
        if (!token) {
            return false;
        }
        const jwt = JwtUtils.parseJwt(token);
        if (!jwt) {
            return false;
        }
        const expirationAsJsDate = new Date(0);
        expirationAsJsDate.setUTCSeconds(jwt.exp as number);
        const isExpired = new Date() >= expirationAsJsDate;
        return isExpired;
    }

    static getUserId(token: string): number {
        const jwt = JwtUtils.parseJwt(token);
        return jwt?.CustomerId;
    }

    private static parseJwt(token: string): any {
        if (!token) {
            return null;
        }
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map((c) => {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
}
