<ion-grid>
  <ion-row *ngIf="photos?.length">
    <ion-col *ngFor="let photo of photos" size-xs="2.4" size-sm="2" size-md="1" (click)="viewPhoto(photo)">
      
      <div class="image-wrap">
        <div class="image-box">
          <img [src]="photo.thumbnailData || photo.thumbnailUrl" />
        </div>
      </div>
    </ion-col>
  </ion-row>
  
  <!-- Skeleton text -->
  <ion-row *ngIf="!photos?.length">
    <ion-col *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" size-xs="2.4" size-sm="2" size-md="1">
      <div class="image-wrap r4x6 landscape skeleton">
        <ion-skeleton-text animated class="w-100 h-100"></ion-skeleton-text>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
