import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ModalController, NavController } from "@ionic/angular";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    templateUrl: "./pelipals-id.page.html",
    styleUrls: ["./pelipals-id.page.scss"]
})
export class PelipalsIdPage extends BasePage {
    public pelipalsIdForm = this.formBuilder.group({
        pelipalsNumber: ["", [Validators.required]]
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private contactWorkFlowService: ContactWorkflowService,
        private contactService: ContactService,
        private modalCtrl: ModalController,
        private navCtrl: NavController
    ) {
        super();
    }

    async exitPeliPalsWorkFlow() {
        await this.contactWorkFlowService.ClearAll();
    }

    continue() {
        this.getContactByPeliPalsNumber();
    }

    private async getContactByPeliPalsNumber(): Promise<void> {
        var number = (
            this.pelipalsIdForm.get("pelipalsNumber").value as string
        ).trim();
        this.contactService.getContactByPeliPalsNumber(number).subscribe(
            async (res) => {
                await this.contactWorkFlowService.StoreContactModel(res.Data);
                this.navCtrl.navigateForward("/contacts/new-contact/confirm");
            },
            async () => {
                await this.showNotFoundModal();
            }
        );
    }

    private async showNotFoundModal(): Promise<void> {
        await this.showModal(this.modalCtrl, {
            modalType: "error",
            headerText: "Invalid PeliPals ID",
            bodyText: "Please check your ID and try again.",
            primaryCtaText: "Okay",
            actionType: "close"
        });
    }
}
