import {
    LoadingController,
    ModalController,
    ToastController
} from "@ionic/angular";
import { LoadingOptions } from "src/app/core/services";
import { Constant } from "src/app/util/constant";
import {
    ModalComponent,
    ModalProps
} from "../components/modal/modal.component";

export class BasePage {
    ErrorList: string[];

    async showToast(
        toastController: ToastController,
        msg: string
    ): Promise<void> {
        const toast = await toastController.create({
            message: msg,
            animated: true,
            duration: 2000
        });
        await toast.present();
    }

    async showError(
        toastController: ToastController,
        msg: string
    ): Promise<void> {
        const toast = await toastController.create({
            message: msg,
            animated: true,
            duration: 2000,
            color: "danger"
        });
        await toast.present();
    }

    async showModal(
        modalCtrl: ModalController,
        props: ModalProps
    ): Promise<HTMLIonModalElement> {
        const modal = await modalCtrl.create({
            component: ModalComponent,
            componentProps: props
        });

        await modal.present();
        return modal;
    }

    async showErrorModal(
        modalCtrl: ModalController,
        errorResult: any,
        actionType?: string,
        errorReason?: string
    ): Promise<HTMLIonModalElement> {
        const error = this.getUserFriendlyErrorText(errorResult);
        const modal = await modalCtrl.create({
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: error.name,
                bodyText: error.message,
                primaryCtaText: "Okay",
                actionType: actionType ? actionType : "close",
                errorReason
            }
        });

        await modal.present();
        return modal;
    }

    async showCartResetWarningModal(
        modalCtrl: ModalController,
        item: string,
        continueAction: () => Promise<void>,
        resetAction: () => Promise<void>
    ): Promise<HTMLIonModalElement> {
        const props = {
            headerText: Constant.GENERIC_WARNING_TITLE,
            bodyText: `Selecting this ${item} will reset your current shopping cart.`,
            primaryCtaText: "Continue Current Order",
            primaryAction: continueAction,
            secondaryCtaText: "Reset Shopping Cart",
            secondaryAction: resetAction
        } as ModalProps;
        const modal = await modalCtrl.create({
            component: ModalComponent,
            componentProps: props
        });

        await modal.present();
        return modal;
    }

    private getUserFriendlyErrorText(error: any): {
        name: string;
        message: string;
    } {
        const errors = error?.error?.ErrorList?.join("\n") ?? error?.message;
        if (errors?.length) {
            return {
                name: "An error occurred",
                message: errors
            };
        }
        // TODO: Replace these with localized strings
        switch (error.status) {
            case 502:
                return {
                    name: "Failed to Load",
                    message:
                        "Please check your connectivity and try again later."
                };
            case 500:
                return {
                    name: "Sorry, we have a problem",
                    message:
                        "Something is wrong on our end. Our teams have been alerted. Please try again later."
                };
            default:
                return {
                    name: "Something went wrong",
                    message: "Please try again later."
                };
        }
    }
}
