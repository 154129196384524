import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { Facility } from "src/app/shared/model/facility";
import { FacilitySearchModel } from "src/app/shared/model/facilitySearchModel";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class FacilityService {
    constructor(private baseService: BasePelipostService) {}

    getFacilities(
        model: FacilitySearchModel
    ): Observable<BaseResponseModel<Array<Facility>>> {
        let params = new HttpParams();
        params = params.set("Filter", model.filter);
        // Always ignoring parent facilities
        params = params.set("IgnoreParentFacilities", "true");

        const url = this.baseService.BASE_PEL_URL + "facility";
        return this.baseService.getRequestWithParam<
            BaseResponseModel<Array<Facility>>
        >(url, params);
    }

    getFacilityById(id: number): Observable<BaseResponseModel<Facility>> {
        const url = `${this.baseService.BASE_PEL_URL}facility/${id}`;
        return this.baseService.getRequest<BaseResponseModel<Facility>>(url);
    }

    // TODO: This, along with other methods, should be heavily cached as the data should not frequently change
    getParentFacilities(): Observable<BaseResponseModel<Array<Facility>>> {
        const url = `${this.baseService.BASE_PEL_URL}facility/parents`;
        return this.baseService.getRequest<BaseResponseModel<Array<Facility>>>(
            url
        );
    }

    getFacilityLocations() {
        const url =
            this.baseService.BASE_PEL_URL + "facility/facilitylocations";
        return this.baseService.getRequest(url);
    }
}
