import { ModalController } from "@ionic/angular";
import { ErrorReason } from "src/app/core/services/analytics.service";
import { ModalProps } from "src/app/shared/components/modal/modal.component";
import { BaseOrderPage } from "../base-order-page";

export class BasePhotoEditorPage extends BaseOrderPage {
    constructor() {
        super();
    }

    async handlePhotoEditorLoadError(modalController: ModalController) {
        await this.showErrorModal(
            modalController,
            {
                name: "Sorry, we hit a snag",
                message:
                    "Image and note editing is not working right now. Please try again later"
            },
            undefined,
            ErrorReason.FailedLoadingPintura
        );
    }
}
