import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { SharedModule } from "src/app/shared/shared.module";
import { EarnPage } from "./pages/earn/earn.page";
import { HistoryPage } from "./pages/history/history.page";
import { MyPointsPage } from "./pages/my-points/my-points.page";
import { PelipointsRoutingModule } from "./pelipoints-routing.module";

@NgModule({
    declarations: [MyPointsPage, EarnPage, HistoryPage],
    imports: [IonicModule, CommonModule, PelipointsRoutingModule, SharedModule]
})
export class PelipointsModule {}
