/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from "@angular/core";
import { NavController, ViewWillEnter } from "@ionic/angular";
import { IPayPalConfig } from "ngx-paypal";
import { CartService, LoadingService } from "src/app/core/services";
import { FattMerchantService } from "src/app/core/services/pelipost-service-proxies/fatt-merchant.service";
import { PelipostCheckoutService } from "src/app/core/services/pelipost-service-proxies/pelipost-checkout.service";
import { BasePage } from "src/app/shared/pages/BasePage";
import { PAYPAL_CLIENT_ID } from "src/environments/environment";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-select-payment-method",
    templateUrl: "./select-payment-method.page.html",
    styleUrls: ["./select-payment-method.page.scss"]
})
export class SelectPaymentMethodPage extends BasePage implements ViewWillEnter {
    orderTotal: string;
    paymentMethods: Array<FattMerchant.PaymentMethod> =
        new Array<FattMerchant.PaymentMethod>();
    public payPalConfig?: IPayPalConfig;
    loading = false;

    constructor(
        private cartService: CartService,
        private fattMerchantService: FattMerchantService,
        private pelipostCheckoutService: PelipostCheckoutService,
        private loadingService: LoadingService,
        private workflowService: CheckoutWorkflowService,
        private navController: NavController
    ) {
        super();
    }

    async ionViewWillEnter(): Promise<void> {
        this.paymentMethods.length = 0;
        await this.loadingService.show();
        this.paymentMethods.push(
            ...(await this.fattMerchantService.getPaymentMethods().toPromise())
        );
        await this.getOrderTotal();
        this.initPaypalWeb();
    }

    private async getOrderTotal(): Promise<void> {
        this.orderTotal = (
            await firstValueFrom(this.cartService.fetchCartRes())
        ).Data.OrderTotals.OrderTotal;
    }

    private initPaypalWeb() {
        this.payPalConfig = {
            currency: "USD",
            clientId: PAYPAL_CLIENT_ID,
            onClick: (data, actions) => {
                this.loading = true;
                void this.loadingService.show();
            },
            onInit: () => {
                void this.loadingService.dismiss();
            },
            createOrderOnServer: async (data) => {
                const order = await firstValueFrom(
                    this.pelipostCheckoutService.createPaypalOrderRequest()
                );
                return order.id;
            },
            advanced: {
                extraQueryParams: [
                    {
                        name: "components",
                        value: "buttons"
                    },
                    {
                        name: "disable-funding",
                        value: "card,credit"
                    }
                ]
            },
            authorizeOnServer: async (data) => {
                await this.workflowService.usePaypal(data, this.orderTotal);
                this.loading = false;
                void this.loadingService.dismiss();
                void this.navController.navigateForward([
                    "/order/review-order"
                ]);
            },
            onCancel: (data, actions) => {
                this.loading = false;
                void this.loadingService.dismiss();
            },
            onError: (err) => {
                this.loading = false;
                void this.loadingService.dismiss();
            }
        };
    }

    public async useExisting(
        method: FattMerchant.PaymentMethod
    ): Promise<void> {
        this.loading = true;
        await this.loadingService.show();
        try {
            await this.workflowService.useExistingPaymentMethod(
                method,
                this.orderTotal
            );
            await this.navController.navigateForward(["/order/review-order"]);
        } catch (error) {
            await this.workflowService.showPaymentFailedModal(error);
            await this.getOrderTotal();
        } finally {
            this.loading = false;
            await this.loadingService.dismiss();
        }
    }
}
