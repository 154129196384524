<ion-header>
  <app-page-header
    [hideBorder]="true"
    [pageTitle]="pageTitle"
  ></app-page-header>
</ion-header>

<ion-content>
  <app-page-template [stickyActionButtons]="false">
    <section *ngIf="isOrderWorkflow" id="page-title">
      <p class="mb-5">The facility you are sending to requires us to print your return address on the shipping envelope. Please {{ hasReturnAddress ? "confirm" : "add" }} your return address below:</p>
      <div>
        <ion-avatar class="d-inline-block"><img src="https://placehold.co/50x50/555B6E/FFF?text={{
              getInitials()
            }}&font=roboto" /></ion-avatar>
      </div>
    </section>
    <section id="page-content">
      <form [formGroup]="addressForm" novalidate (ngSubmit)="save()">
        <ion-item lines="none">
          <ion-label position="stacked">FIRST NAME</ion-label>
          <ion-input type="name" placeholder="John" formControlName="firstName" [required]="true"></ion-input>
          <ion-text class="error-text" color="danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
            <div *ngIf="firstName.hasError('required')">
              First Name is required.
            </div>
          </ion-text>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">LAST NAME</ion-label>
          <ion-input type="name" placeholder="Smith" formControlName="lastName" [required]="true"></ion-input>
          <ion-text class="error-text" color="danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
            <div *ngIf="lastName.hasError('required')">
              Last Name is required.
            </div>
          </ion-text>
        </ion-item>
        <app-address-form (formReady)="formInitialized('address', $event)" [existingAddress]="address"></app-address-form>
        <p *ngIf="isOrderWorkflow">
          <small>
            NOTE: Your order will be sent back to the address above if the facility returns it.
          </small>
        </p>
      </form>
    </section>
    <app-cta-buttons
      [primaryCtaLabel]="isOrderWorkflow ? 'Proceed' : 'Save'"
      [primaryCtaDisabled]="!addressForm.valid"
      (primaryCtaClick)="save()"
      [secondaryCtaLabel]="isOrderWorkflow ? null : 'Cancel'"
      (secondaryCtaClick)="cancel()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
