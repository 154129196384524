<ion-header>
  <app-page-header
    [hideBorder]="true"
    (closeButtonClick)="navigateBack()"
    pageTitle="Add a Message"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template [centerPageContent]="message">
    <section id="page-title">
      <p>Send a personalized message to your loved one for only {{messageProduct?.ProductPrice?.Price}}.</p>
    </section>
    <section id="page-content" class="w-100" [ngClass]="{'p-0': !message}">
      <div *ngIf="!message" class="mt-5">
        <img
          class="light-mode"
          src="../../../../../../assets/images/add_message_LightMode.png"
        />
        <img
          class="dark-mode"
          src="../../../../../../assets/images/add_message_DarkMode.png"
        />
      </div>
      <div *ngIf="message" class="message-preview" (click)="editMessage()">
        <ion-img [src]="message.data"></ion-img>
      </div>
    </section>
    <app-cta-buttons
      [primaryCtaLabel]="message ? 'Continue' : 'Add a message'"
      (primaryCtaClick)="message ? continue(true) : editMessage()"
      secondaryCtaLabel="{{message ? 'Delete Message' : 'No thanks'}}"
      (secondaryCtaClick)="continue(false)"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
