<ion-header>
    <app-page-header [hideBorder]="true" pageTitle="Order Summary"></app-page-header>
</ion-header>
<ion-content>
    <app-page-template [stickyActionButtons]="true" [fullWidthContent]="true">
        <section id="page-content" class="pt-2 px-0">
            <section class="px-5">
                <div class="mb-2">
                    <h5 class="ion-text-uppercase">Contact Information</h5>
                    <div class="pb-3 pt-2">
                        <app-contact-identity [contact]="contact" [facility]="facility"></app-contact-identity>
                    </div>
                </div>
                <div class="mb-3">
                    <app-order-details [lineItems]="lineItems" [orderTotals]="orderTotals" [shippingMethod]="selectedShippingMethod"></app-order-details>
                </div>
            </section>
            <section class="mb-2 px-5 text-center discount-text">
                <ion-text class="custom-button button-block" color="primary" *ngIf="!discountApplied" (click)="showDiscountModal()">
                    Have a promo code? Apply it here
                </ion-text>
                <ion-text class="custom-button button-block" color="primary" *ngIf="discountApplied" (click)="updatingDiscount ? null : removeDiscountCode()">
                    Promo code applied (click to remove)
                </ion-text>
            </section>
            <section *ngIf="expeditedShippingMethod" class="mb-2 px-5 py-3 bg-gray">
                <h5 class="my-0 ion-text-uppercase">
                    <img src="assets/icon/shipped_active.svg">
                    {{expeditedShippingMethod.DisplayName}} shipping + tracking
                </h5>
                <small>
                    {{expeditedShippingMethod | arrivalText}}
                </small>
                <div class="d-flex align-items-center justify-content-end">
                    <ion-label class="add-on-price">Add {{(expeditedShippingMethod.Rate - standardShippingMethod.Rate) | currency}}</ion-label>
                    <ion-toggle class="py-1 pr-0" color="primary" [checked]="selectedShippingMethod === expeditedShippingMethod" (click)="toggleExpeditedShipping()"></ion-toggle>
                </div>
            </section>
            <section class="mb-2 px-5 py-3 bg-gray">
                <h5 class="my-0 ion-text-uppercase">
                    <img src="assets/icon/yellow_star_image.png" />
                    Pay With Points
                </h5>
                <p class="my-2 available-points">
                    {{ availablePoints }} PeliPOINTS available (equals {{ availablePointsCashValue }})
                </p>
                <div class="d-flex align-items-center justify-content-center">
                    <ion-input class="discount-input py-0 my-0 ml-2 pl-2 d-none" type="text" [(ngModel)]="availablePoints" readonly></ion-input>
                    <div *ngIf="!appliedPoints" class="custom-button button-block button-outline ml-2 my-0" (click)="updatingDiscount ? null : applyPelipoints(true)">
                        <ion-text color="primary">Apply</ion-text>
                    </div>
                    <div *ngIf="appliedPoints" class="custom-button button-block button-outline ml-2 my-0" (click)="updatingDiscount ? null : applyPelipoints(false)">
                        <ion-text color="primary">Remove</ion-text>
                    </div>
                </div>
            </section>
            <section class="mb-3 px-5">
                <h5 class="mb-1 ion-text-uppercase">Your Photos</h5>
                <app-thumbnails [photos]="uploadedPhotos"></app-thumbnails>
            </section>
            <section class="mb-1">
                <app-sms-link
                    label="Having trouble? Text us for help"
                    defaultMessage="I'm having trouble finishing my order."
                ></app-sms-link>
            </section>
        </section>
        <app-cta-buttons
            [primaryCtaLabel]="discountsCoverOrderTotal ? 'Complete Order' : 'Proceed to Payment'"
            [primaryCtaDisabled]="updatingDiscount || confirmingOrder"
            primaryCtaId="btn-order-summary-proceed"
            (primaryCtaClick)="proceedToPayment()"
            secondaryCtaLabel="Edit cart"
            [secondaryCtaDisabled]="confirmingOrder"
            (secondaryCtaClick)="editCart()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
