import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { BaseOrderPage } from "../../base-order-page";

@Component({
    selector: "app-facility-rules",
    templateUrl: "./facility-rules.page.html",
    styleUrls: ["./facility-rules.page.scss"]
})
export class FacilityRulesPage
    extends BaseOrderPage
    implements OnInit, OnDestroy
{
    termsAgreed: boolean;

    constructor(
        private route: ActivatedRoute,
        private toastController: ToastController,
        private modalController: ModalController,
        private navController: NavController,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService
    ) {
        super();
    }

    async ngOnInit() {
        await super.onInit(
            +this.route.snapshot.paramMap.get("productid"),
            this.contactContextService,
            this.productContextService,
            this.modalController,
            this.navController
        );
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    termsChanged(event) {
        if (event.detail.checked) {
            this.termsAgreed = true;
        } else {
            this.termsAgreed = false;
        }
    }

    submit() {
        if (!this.termsAgreed) {
            return;
        }
        this.navController.navigateForward([
            "/order/select-photos",
            this.product.Id
        ]);
    }
}
