import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IonDatetime } from "@ionic/angular";
import { DateTime } from "luxon";

@Component({
    selector: "app-custom-attributes",
    templateUrl: "./custom-attributes.component.html",
    styleUrls: ["./custom-attributes.component.scss"]
})
export class CustomAttributesComponent {
    @Input() attribute: any;
    @Input() form: UntypedFormGroup;
    @Input() prefix: string;
    item: any;

    // Attribute Types
    public TYPE_DROPDOWN = 1;
    public TYPE_CHECKBOX = 3;
    public TYPE_TEXTBOX = 4;
    public TYPE_MULTILINE_TEXTBOX = 10;
    public TYPE_DATEPICKER = 20;

    get isValid(): boolean {
        const formControl = this.form.controls[this.getControlId()];
        return formControl.valid || !formControl.dirty;
    }

    getControlId(): string {
        return `${this.prefix}${this.attribute.Id}`;
    }

    public dateChanged(ionDateTime: IonDatetime): void {
        if (typeof ionDateTime.value !== "string") {
            return;
        }
        this.form
            .get(this.getControlId())
            .patchValue(
                DateTime.fromISO(ionDateTime.value).toFormat("dd MMM yyyy")
            );
    }
}
