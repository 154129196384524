<ion-row class="footer py-2">
    <ion-col size-xs="2.4" routerLink="/home" routerLinkActive="active">
        <ion-icon name="home-outline"></ion-icon>
        <ion-label>Home</ion-label>
    </ion-col>

    <ion-col size-xs="2.4" routerLink="/pelipoints" routerLinkActive="active">
        <ion-icon name="star-outline"></ion-icon>
        <ion-label>Points</ion-label>
    </ion-col>

    <ion-col size-xs="2.4" routerLink="/order" routerLinkActive="active">
        <ion-icon name="refresh-outline"></ion-icon>
        <ion-label>Orders</ion-label>
    </ion-col>

    <ion-col size-xs="2.4" (click)="onSupportClick()">
        <ion-icon name="information-circle-outline"></ion-icon>
        <ion-label>Support</ion-label>
    </ion-col>

    <ion-col size-xs="2.4" (click)="onCartClick()">
        <div class="position-relative">
            <ion-icon name="cart-outline"></ion-icon>
            <span *ngIf="currentCart?.Items?.length" class="badge badge-pill">
                {{ currentCart.Items.length }}
            </span>
        </div>
        <ion-label>Cart</ion-label>
    </ion-col>
</ion-row>
