import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as Bowser from "bowser";
import { LoadingService } from "src/app/core/services";
import { ErrorReason } from "src/app/core/services/analytics.service";
import { LoginWorkflowService } from "src/app/core/services/login-workflow.service";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import { GoogleAuthService } from "src/app/core/services/service-proxies/external-providers/google-auth.service";
import { ModalProps } from "src/app/shared/components/modal/modal.component";
import { ExternalAuthenticationParameters } from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-google-login",
    templateUrl: "./google-login.component.html",
    styleUrls: ["./google-login.component.scss"]
})
export class GoogleLoginComponent extends BasePage implements OnInit {
    @Input() providerSchemeName: string;
    @Input() type = "login";

    constructor(
        private accountService: AccountService,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private loginWorkflowService: LoginWorkflowService,
        private googleAuthService: GoogleAuthService
    ) {
        super();
    }

    async ngOnInit() {
        await this.googleAuthService.initialize();
    }

    async login() {
        await this.loadingService.show();
        let googleUser;
        try {
            googleUser = await this.googleAuthService.signIn();
        } catch (error) {
            this.onLoginFailed(error);
            return;
        }

        //TODO: Get email address
        const parameters = new ExternalAuthenticationParameters();
        parameters.providerSystemName = this.providerSchemeName;
        parameters.externalIdentifier = googleUser.id;
        parameters.accessToken = googleUser.authentication.idToken;

        try {
            await this.accountService
                .checkAndMigrateOldUser(parameters)
                .toPromise();
            const res = await this.loginService
                .postExternalAuthentication(parameters)
                .toPromise();
            await this.postLoginRedirect(res);
        } catch (error) {
            this.loginWorkflowService.loginFailed("google");
            await this.showErrorModal(
                this.modalController,
                error,
                undefined,
                ErrorReason.AuthenticationError
            );
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private postLoginRedirect = async (loginResponse) => {
        const regex = /creation/gi;
        if (loginResponse.Message && regex.test(loginResponse.Message)) {
            await this.loginWorkflowService.signupRedirect(
                loginResponse,
                "google"
            );
        } else {
            await this.loginWorkflowService.loginRedirect(
                loginResponse,
                "google"
            );
        }
    };

    private onLoginFailed(error: Error) {
        const browserName = Bowser.getParser(
            window.navigator.userAgent
        ).getBrowserName();
        const props = {
            modalType: "error",
            headerText: "Hmm, that didn't work",
            bodyText: `Check your ${browserName} settings to ensure pop ups are allowed, or try again later.`,
            primaryCtaText: "Okay",
            primaryAction: async () => {
                await this.modalController.dismiss();
            },
            errorReason: error?.message
        } as ModalProps;
        console.error(error);
        void this.showModal(this.modalController, props);
    }
}
