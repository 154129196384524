<div *ngIf="loginForm">
  <form class="mt-3"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
    novalidate>
    <ion-item
      *ngIf="!loginGetRes?.Data?.UsernamesEnabled"
      lines="none">
      <ion-label position="stacked">EMAIL ADDRESS</ion-label>
      <ion-input appAutofill
        type="email"
        autocomplete="email"
        placeholder="sample@pelipost.com"
        formControlName="email"></ion-input>
      <ion-text class="error-text"
        color="danger"
        *ngIf="
          loginForm.get('email').invalid &&
          (loginForm.get('email').dirty || loginForm.get('email').touched)
        ">
        <div *ngIf="loginForm.get('email').hasError('required')">
          Email is required.
        </div>
        <div *ngIf="loginForm.get('email').hasError('email')">
          Invalid Email format.
        </div>
      </ion-text>
    </ion-item>
    <ion-item
      *ngIf="loginGetRes?.Data?.UsernamesEnabled"
      lines="none">
      <ion-label position="stacked">USERNAME</ion-label>
      <ion-input type="text"
        placeholder="username"
        formControlName="username"></ion-input>
      <ion-text class="error-text"
        color="danger"
        *ngIf="
          loginForm.get('username').invalid &&
          (loginForm.get('username').dirty || loginForm.get('username').touched)
        ">
        <div *ngIf="loginForm.get('username').hasError('required')">
          Username is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked">PASSWORD</ion-label>
      <ion-input appAutofill
        [type]="showPassword ? 'text' : 'password'"
        autocomplete="current-password"
        placeholder="************"
        formControlName="password"
        required>
      </ion-input>
      <ion-text
        class="toggle-password"
        color="primary"
        (click)="showPassword = !showPassword"
      >
        {{ showPassword ? "HIDE" : "SHOW" }}
      </ion-text>
      <ion-text class="error-text"
        color="danger"
        *ngIf="
          loginForm.get('password').invalid &&
          (loginForm.get('password').dirty || loginForm.get('password').touched)
        ">
        <div *ngIf="loginForm.get('password').hasError('required')">
          Password is required.
        </div>
      </ion-text>
    </ion-item>

    <div class="text-center">
      <ion-button expand="block"
        [disabled]="!loginForm.valid || isLoginLoading"
        (click)="login()">
        Log in
      </ion-button>
      <ion-button fill="clear"
        size="small"
        color="primary"
        expand="block"
        (click)="forgotPassword()">
        Forgot your Password?
      </ion-button>
    </div>
  </form>
</div>