import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    Observable,
    firstValueFrom,
    of,
    throwError
} from "rxjs";
import { catchError, concatMap, tap } from "rxjs/operators";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";
import { AuthService } from "./auth.service";
import { CartConfigurationService } from "./pelipost-service-proxies/cart-configuration.service";
import { FacilityService } from "./pelipost-service-proxies/facility.service";
import { ContactService } from "./pelipost-service-proxies/contact.service";

@Injectable({
    providedIn: "root"
})
export class ContactContextService {
    private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
    public readonly contact$: Observable<Contact> =
        this._contact.asObservable();
    private _facility: BehaviorSubject<Facility> = new BehaviorSubject(null);
    public readonly facility$: Observable<Facility> =
        this._facility.asObservable();

    constructor(
        private contactService: ContactService,
        private authService: AuthService,
        private facilityService: FacilityService,
        private cartConfigurationService: CartConfigurationService
    ) {}

    public getContactContext(): Observable<Contact> {
        return this.cartConfigurationService.getCartConfiguration().pipe(
            catchError((error) => {
                this._contact.error(error);
                return throwError(() => error);
            }),
            concatMap((response) => {
                const cartConfig = response.Data;
                if (!cartConfig) {
                    return of(null);
                }

                if (cartConfig.Contact) {
                    this._contact.next(cartConfig.Contact);
                }
                if (cartConfig.Facility) {
                    this._facility.next(cartConfig.Facility);
                    //store facility Id in header for fetching price info from nopCommerce
                    this.authService.currentFacilityId = cartConfig.Facility.Id;
                }

                return of(cartConfig.Contact);
            })
        );
    }

    public async setContactContext(contactId: number): Promise<void> {
        const contactResponse = await firstValueFrom(
            this.contactService.getContactById(contactId).pipe(
                catchError((error) => {
                    this._contact.error(error);
                    return throwError(() => error);
                })
            )
        );
        this._contact.next(contactResponse.Data);
        //store facility Id in header for fetching price info from nopCommerce
        this.authService.currentFacilityId = contactResponse.Data.FacilityId;

        await firstValueFrom(this.fetchCorrespondingFacility(contactResponse));

        await firstValueFrom(
            this.cartConfigurationService.setSelectedContact(contactId)
        );
    }

    private fetchCorrespondingFacility(
        contactResponse: BaseResponseModel<Contact>
    ) {
        return this.facilityService
            .getFacilityById(contactResponse.Data.FacilityId)
            .pipe(
                catchError((error) => {
                    this._facility.error(error);
                    return throwError(() => error);
                }),
                tap((facilityResponse) => {
                    this._facility.next(facilityResponse.Data);
                })
            );
    }

    public async clearContactContext(): Promise<void> {
        await firstValueFrom(
            this.cartConfigurationService.setSelectedContact(null).pipe(
                tap(() => {
                    this._contact.next(null);
                    this._facility.next(null);
                })
            )
        );
    }
}
