import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Address } from "src/app/shared/model/account/address";
import { FattMerchantWebPaymentsTokenResponseModel } from "src/app/shared/model/fattMerchantModel";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class FattMerchantService {
    private url = `${this.baseService.BASE_PEL_URL}fattmerchant`;

    constructor(private baseService: BasePelipostService) {}

    public getWebPaymentsToken(): Observable<FattMerchantWebPaymentsTokenResponseModel> {
        return this.baseService.getRequest(this.url + "/webpaymentstoken");
    }

    public getPaymentMethods(): Observable<Array<FattMerchant.PaymentMethod>> {
        return this.baseService.getRequest(`${this.url}/paymentmethods`);
    }

    public getPaymentMethodById(id): Observable<FattMerchant.PaymentMethod> {
        return this.baseService.getRequest(
            `${this.url}/paymentmethodbyid/${id}`
        );
    }

    public updatePaymentMethod(
        data: FattMerchant.PaymentMethod
    ): Observable<void> {
        return this.baseService.putRequest(
            `${this.url}/updatepaymentmethod`,
            data
        );
    }

    public deletePaymentMethod(id): Observable<void> {
        return this.baseService.deleteRequest(
            `${this.url}/paymentmethod?paymentMethodId=${id}`
        );
    }

    public getCustomer(): Observable<FattMerchant.Customer> {
        return this.baseService.getRequest(`${this.url}/customer`);
    }
}
