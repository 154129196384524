import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "discountPrice"
})
export class DiscountPricePipe implements PipeTransform {
    transform(value: string): string {
        const exp = /\((.*?)\)/g;
        const match = exp.exec(value);

        if (match) {
            return "-" + match[1].replace(exp, "");
        } else if (!value.startsWith("-")) {
            return "-" + value;
        } else {
            return value;
        }
    }
}
