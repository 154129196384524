import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerRewardPoints } from "src/app/shared/model/account/reward-points";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import {
    OrderDetails,
    OrderHistoryGetRes,
    ReturnOrderPostBody
} from "../../../shared/model/order";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root"
})
export class OrderService {
    constructor(private baseService: BaseService) {}

    fetchOrderHistory(): Observable<OrderHistoryGetRes> {
        const url = this.baseService.BASE_URL + `order/history`;
        return this.baseService.getRequest<OrderHistoryGetRes>(url);
    }

    fetchOrderDetails(id: number): Observable<BaseResponseModel<OrderDetails>> {
        const url = this.baseService.BASE_URL + `order/orderdetails/${id}`;
        return this.baseService.getRequest<BaseResponseModel<OrderDetails>>(
            url
        );
    }

    fetchDownloadInvoicePdf(id: number) {
        const url = this.baseService.BASE_URL + `order/orderdetails/pdf/${id}`;
        return this.baseService.getRequestFiles(url);
    }

    fetchCustomerRewardPoints(
        page: number = 1
    ): Observable<CustomerRewardPoints> {
        const url =
            this.baseService.BASE_URL + `order/customerrewardpoints/${page}`;
        return this.baseService.getRequest<CustomerRewardPoints>(url);
    }

    reorder(orderId: number) {
        const url = this.baseService.BASE_URL + `order/reorder/${orderId}`;
        return this.baseService.getRequest(url);
    }

    fetchReturnOrder(orderId: number) {
        const url =
            this.baseService.BASE_URL +
            `returnrequest/returnrequest/${orderId}`;
        return this.baseService.getRequest(url);
    }

    postReturnRequest(orderId: number, reqBody: ReturnOrderPostBody) {
        const url =
            this.baseService.BASE_URL +
            `returnrequest/returnrequest/${orderId}`;
        return this.baseService.postRequest(url, reqBody);
    }
}
