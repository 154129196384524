import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-page-template",
    templateUrl: "./page-template.component.html",
    styleUrls: ["./page-template.component.scss"]
})
export class PageTemplateComponent implements OnInit {
    @Input() hideCtaSection = false;
    @Input() centerPageContent = false;
    @Input() stickyActionButtons = true;
    @Input() fullWidthContent = false;

    constructor() {}

    ngOnInit() {}
}
