export interface AzurePhotoUploadResponse {
    DownloadGUID: string;
    BlobUri: string;
    SasQueryClause: string;
}

export enum AzureUploadType {
    Image,
    Thumbnail,
    Original
}
