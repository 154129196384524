import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import {
    PelipostPhoto,
    PhotoService
} from "src/app/core/services/photos.service";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { PermissionsService } from "src/app/core/services/permissions.service";

@Component({
    selector: "app-photo",
    templateUrl: "./photo.page.html",
    styleUrls: ["./photo.page.scss"]
})
export class PhotoPage extends BasePage implements OnInit {
    contact: Contact;
    photo: PelipostPhoto;
    hasPhoto: boolean;
    photoPath: string;

    constructor(
        private contactWorkFlowService: ContactWorkflowService,
        private permissionsService: PermissionsService,
        private photoService: PhotoService,
        private navCtrl: NavController
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.contact = await this.contactWorkFlowService.GetContactModel();
        const existingPhoto = await this.contactWorkFlowService.GetPhoto();
        this.setSelectedPhoto(existingPhoto);
    }

    public async continue(uploadPhoto: boolean = false): Promise<void> {
        if (uploadPhoto && !this.hasPhoto) {
            const hasPermissions =
                await this.permissionsService.checkPermissions("photos");
            if (!hasPermissions) {
                return;
            }

            const galleryPhoto =
                await this.photoService.getThumbnailFromGallery();
            this.setSelectedPhoto(galleryPhoto);
            return;
        }

        if (!uploadPhoto && this.hasPhoto) {
            await this.contactWorkFlowService.ClearPhoto();
        }

        if (uploadPhoto && this.hasPhoto) {
            await this.contactWorkFlowService.StorePhoto(this.photo);
        }

        await this.contactWorkFlowService.StoreContactModel(this.contact);
        await this.navCtrl.navigateForward("/contacts/new-contact/confirm");
    }

    private setSelectedPhoto(selectedPhoto: PelipostPhoto) {
        this.photo = selectedPhoto;
        this.photoPath =
            selectedPhoto?.data ?? "assets/images/contact_placeholder.png";
        this.hasPhoto = !!selectedPhoto;
    }
}
