<ion-skeleton-text
  *ngIf="!imageSrc"
  animated
  [class]="'m-0 contact ' + avatarSize"
></ion-skeleton-text>
<ion-avatar
  *ngIf="imageSrc"
  [class]="'contact ' + avatarSize"
>
  <ion-img
    class="image"
    [src]="imageSrc"
    alt="Contact Photo"
  ></ion-img>
</ion-avatar>
<div
  *ngIf="contact?.PeliPalsNumber"
  [class]="avatarSize"
>
  <img
    class="light-mode"
    src="../assets/images/pelipals_logo.png"
  />
  <img
    class="dark-mode"
    src="../assets/images/pelipals_logo_white.png"
  />
</div>
