import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import {
    CartService,
    HomeService,
    LoadingService
} from "src/app/core/services";
import {
    ModalComponent,
    ModalProps
} from "src/app/shared/components/modal/modal.component";
import { BasePage } from "src/app/shared/pages/BasePage";
import { firstValueFrom, forkJoin, Subject, Subscription } from "rxjs";
import { SliderModel } from "src/app/shared/model/SliderModel";
import { Cart } from "src/app/shared/model/cart";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { Contact } from "src/app/shared/model/contact";
import { CheckoutWorkflowService } from "src/app/modules/order/services/checkout-workflow.service";
import { HomeScreenService } from "src/app/core/services/home-screen.service";
import { UserSegmentService } from "src/app/core/services/user-segment.service";

@Component({
    selector: "app-home",
    templateUrl: "home.page.html",
    styleUrls: ["home.page.scss"]
})
export class HomePage
    extends BasePage
    implements OnInit, OnDestroy, IonViewWillEnter
{
    readonly ionViewWillEnterSubject = new Subject<void>();

    bannerOptions = {
        initialSlide: 0,
        slidesPerView: 1,
        autoplay: false,
        pagination: {
            el: ".bullet-container",
            bulletClass: "bullet",
            bulletActiveClass: "bullet-active"
        }
    };

    banners = new Array<SliderModel>();
    currentCart: Cart;
    currentContact: Contact;
    displayAlternateHomeScreen = false;
    subscriptions: Subscription[] = [];

    constructor(
        private cartService: CartService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private contactContextService: ContactContextService,
        private homeService: HomeService,
        private loadingService: LoadingService,
        private modalCtrl: ModalController,
        private homeScreenService: HomeScreenService,
        private userSegmentService: UserSegmentService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.displayAlternateHomeScreen =
            await this.homeScreenService.showAlternateHomeScreen();
        await this.populateSliders();

        const contactSub = this.contactContextService.contact$.subscribe(
            (r) => (this.currentContact = r)
        );
        const cartSub = this.cartService.cart$.subscribe(
            (c) => (this.currentCart = c?.Cart)
        );
        this.subscriptions.push(contactSub, cartSub);

        await firstValueFrom(
            forkJoin([
                this.contactContextService.getContactContext(),
                this.cartService.fetchCartRes()
            ])
        );
    }

    async ionViewWillEnter(): Promise<void> {
        await this.userSegmentService.populateUserSegments();
        const showHomeScreen =
            await this.homeScreenService.showAlternateHomeScreen();
        if (showHomeScreen !== this.displayAlternateHomeScreen) {
            this.displayAlternateHomeScreen = showHomeScreen;
            await this.populateSliders();
        }
        this.ionViewWillEnterSubject.next();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => {
            if (!s.closed) {
                s.unsubscribe();
            }
        });
    }

    async showBanner(banner: SliderModel): Promise<void> {
        if (banner.ExternalUrl) {
            window.open(banner.ExternalUrl, "_blank");
        } else if (banner.ModalTitle) {
            const props = {
                headerText: banner.ModalTitle,
                bodyText: banner.ModalBody,
                textAlign: "left",
                customImageUrl: banner.PictureUrl,
                customImageClasses: "extended",
                primaryCtaText: "Start Order",
                secondaryCtaText: "Close",
                actionType: "close"
            } as ModalProps;

            await this.showModal(this.modalCtrl, props);
        }
    }

    async onCartClick(): Promise<void> {
        if (this.currentCart?.Items?.length) {
            await this.loadingService.show();
            await this.contactContextService.setContactContext(
                this.currentContact.Id
            );
            await this.checkoutWorkflowService.continueCheckoutWorkflow();
            await this.loadingService.dismiss();
        } else {
            const props = {
                modalType: "error",
                headerText: "Nothing to see here yet",
                bodyText: `Start an order to continue.`,
                primaryCtaText: "Okay",
                primaryAction: async () => {
                    await this.modalCtrl.dismiss();
                }
            } as ModalProps;
            const modal = await this.modalCtrl.create({
                component: ModalComponent,
                componentProps: props
            });

            await modal.present();
        }
    }

    private async populateSliders(): Promise<void> {
        const res = await firstValueFrom(
            this.homeService.fetchHomePageSliders(
                this.displayAlternateHomeScreen
            )
        );
        this.banners = res.Data;
    }
}
