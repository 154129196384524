<div
    class="h-100 d-flex flex-column align-items-center justify-content-between"
    [ngClass]="{ 'mx-3': !fullWidthContent }"
>
    <div
        class="w-100"
        [ngClass]="{
            'content-overflow': stickyActionButtons && !hideCtaSection,
            'center-page-content': centerPageContent,
            'pb-3': hideCtaSection
        }"
    >
        <div class="text-center">
            <ng-content select="section#page-title"></ng-content>
        </div>
        <div
            class="w-100"
            [ngClass]="{ 'center-page-content': centerPageContent }"
        >
            <ng-content select="section#page-content"></ng-content>
        </div>
    </div>
    <section *ngIf="!hideCtaSection" class="w-100 mt-3 pb-3">
        <ng-content select="app-cta-buttons"></ng-content>
        <ng-content select="section#cta-buttons"></ng-content>
    </section>
</div>
