import { Injectable } from "@angular/core";
import {
    Camera,
    CameraResultType,
    CameraSource,
    GalleryPhoto,
    GalleryPhotos,
    Photo
} from "@capacitor/camera";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { GooglePhotoService } from "./pelipost-service-proxies/google-photo.service";
import { v4 as uuidv4 } from "uuid";
import { StorageService } from "./storage.service";
import { iif, of } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PhotoService {
    public static DEFAULT_MIME_TYPE = "image/jpeg";
    public static MAX_PRODUCT_SIZE_MULTIPLIER = 1.5;
    static MAX_THUMBNAIL_SIZE = 400;
    photos: PelipostPhoto[] = [];

    constructor(
        private googlePhotoService: GooglePhotoService,
        private storageService: StorageService
    ) {}

    getProductSpecifications(product: ProductDetail): ProductSpecifications {
        if (!product) {
            return undefined;
        }

        let productRatio = +product.ProductSpecifications.find((s) =>
            s.SpecificationAttributeName.toLowerCase().includes("ratio")
        ).ValueRaw;
        const productDpi = +product.ProductSpecifications.find((s) =>
            s.SpecificationAttributeName.toLowerCase().includes("dpi")
        ).ValueRaw;

        let canvasWidth =
            +product.ProductSpecifications.find((s) =>
                s.SpecificationAttributeName.toLowerCase().includes("length")
            ).ValueRaw * productDpi;
        let canvasHeight = canvasWidth / productRatio;

        const isGrayscaleValue = product.ProductSpecifications.find((s) =>
            s.SpecificationAttributeName.toLowerCase().includes("grayscale")
        )?.ValueRaw;
        const isGrayscale = isGrayscaleValue && isGrayscaleValue == "true";

        return {
            dimension: { width: canvasWidth, height: canvasHeight },
            isGrayscale
        };
    }

    private getCanvasDimension = (
        productDimension: PhotoDimension,
        imageRatio: number
    ): PhotoDimension =>
        imageRatio <= 1
            ? { width: productDimension.height, height: productDimension.width }
            : productDimension;

    private getThumbnailDimension = (
        dimension: PhotoDimension,
        imageRatio: number = dimension.width / dimension.height
    ): PhotoDimension =>
        imageRatio <= 1
            ? {
                  width: PhotoService.MAX_THUMBNAIL_SIZE * imageRatio,
                  height: PhotoService.MAX_THUMBNAIL_SIZE
              }
            : {
                  width: PhotoService.MAX_THUMBNAIL_SIZE,
                  height: PhotoService.MAX_THUMBNAIL_SIZE / imageRatio
              };

    public async resizeAndStoreOriginalData(
        id: string,
        source: string,
        data: string,
        productDimension: PhotoDimension
    ) {
        const newDimension = {
            width:
                productDimension.width *
                PhotoService.MAX_PRODUCT_SIZE_MULTIPLIER,
            height:
                productDimension.height *
                PhotoService.MAX_PRODUCT_SIZE_MULTIPLIER
        };
        const originalData = await this.restrictImageSize(data, newDimension);
        await this.storeOriginalPhotoData(id, source, originalData);
    }

    public storeOriginalPhotoData = async (
        id: string,
        source: string,
        data: string
    ): Promise<void> =>
        await this.storageService.setObject(
            this.getPhotoOriginalDataKey(source, id),
            data
        );

    public getOriginalPhotoData = async (
        id: string,
        source: string
    ): Promise<string> =>
        await this.storageService.getObject<string>(
            this.getPhotoOriginalDataKey(source, id)
        );

    public removeOriginalPhotoData = async (
        id: string,
        source: string
    ): Promise<void> =>
        await this.storageService.removeItem(
            this.getPhotoOriginalDataKey(source, id)
        );

    private getPhotoOriginalDataKey = (id: string, source: string): string =>
        `Photo-${source}-${id}-Original`;

    async getPhotoFromCamera(): Promise<Photo> {
        // Take a photo
        return await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100
        });
    }

    async getThumbnailFromGallery(
        product: ProductDetail = undefined
    ): Promise<PelipostPhoto> {
        // Take a photo
        const selectedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            quality: 100
        });

        const dimension = await this.getImageDimensions(selectedPhoto.webPath);
        const photo = {
            id: uuidv4(),
            filepath: "",
            data: selectedPhoto.webPath,
            dimension: dimension,
            productSpecifications: this.getProductSpecifications(product),
            source: PhotoSource.Gallery
        };

        const thumbDimension = this.getThumbnailDimension(dimension);
        photo.data = await this.getJpegData(
            selectedPhoto.webPath,
            thumbDimension.width,
            thumbDimension.height
        );

        return photo;
    }

    async convertUrlPhotosToData(photos: Array<PelipostPhoto>): Promise<void> {
        const photosToConvert = photos.filter((p) => p.data.startsWith("http"));
        const googlePhotos = photosToConvert.filter(
            (p) => p.source === PhotoSource.Google
        );
        const allOtherPhotos = photosToConvert.filter(
            (p) => p.source !== PhotoSource.Google
        );
        for (let photo of allOtherPhotos) {
            const data = await this.getBase64Image(photo.data);
            photo.data = data;
        }
        if (googlePhotos.length) {
            await this.googlePhotoService
                .convertUrlsToData(googlePhotos)
                .toPromise()
                .then((res) => {
                    res.Data.Photos.forEach((p1) => {
                        const photo = googlePhotos.find(
                            (p2) => p1.PhotoId === p2.id
                        );
                        photo.data = p1.DataUrl;
                    });
                });
        }
    }

    public async getBase64Image(url: string): Promise<string> {
        // Fetch the photo, read as a blob, then convert to base64 format
        const response = await fetch(url);
        const blob = await response.blob();
        return await this.convertBlobToBase64(blob);
    }

    private convertBlobToBase64 = (blob: Blob): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onloadend = () => {
                const data = reader.result.toString();
                resolve(data);
            };
            reader.readAsDataURL(blob);
        });

    getImageDimensions = (file): Promise<PhotoDimension> =>
        new Promise(function (resolved, rejected) {
            const i = new Image();
            i.onload = function () {
                resolved({ width: i.width, height: i.height });
            };
            i.src = file;
        });

    async openFileSelector(maxPhotos: number = 0): Promise<GalleryPhotos> {
        return await Camera.pickImages({
            quality: 100,
            correctOrientation: false,
            limit: maxPhotos
        });
    }

    async processImage(
        image: Photo | GalleryPhoto,
        source: PhotoSource,
        product: ProductDetail
    ): Promise<PelipostPhoto> {
        const dimension = await this.getImageDimensions(image.webPath);
        const productSpecifications = this.getProductSpecifications(product);
        const photo: PelipostPhoto = {
            id: uuidv4(),
            filepath: "",
            data: image.webPath,
            dimension,
            source
        };
        await this.scaleAndCorrectAspectRatio(photo, productSpecifications);
        await this.resizeAndStoreOriginalData(
            photo.id,
            photo.source,
            image.webPath,
            productSpecifications.dimension
        );

        return photo;
    }

    private resetUpload(photo: PelipostPhoto): void {
        photo.remoteUrl = undefined;
        photo.downloadGuid = undefined;
    }

    async resetChanges(
        photo: PelipostPhoto,
        productSpecifications: ProductSpecifications
    ): Promise<void> {
        photo.data = await this.getOriginalPhotoData(photo.id, photo.source);
        photo.dimension = await this.getImageDimensions(photo.data);
        await this.scaleAndCorrectAspectRatio(photo, productSpecifications);
        delete photo.history;
        this.resetUpload(photo);
    }

    async updateImage(
        photo: PelipostPhoto,
        productSpecifications: ProductSpecifications
    ): Promise<void> {
        photo.dimension = await this.getImageDimensions(photo.data);
        await this.scaleAndCorrectAspectRatio(photo, productSpecifications);
        this.resetUpload(photo);
    }

    public async assignPhotoThumbnails(
        ...photos: Array<PelipostPhoto>
    ): Promise<void> {
        for (let photo of photos) {
            const dimension = this.getThumbnailDimension(photo.dimension);
            photo.thumbnailData = await this.getJpegData(
                photo.data,
                dimension.width,
                dimension.height
            );
        }
    }

    public async scaleAndCorrectAspectRatio(
        photo: PelipostPhoto,
        productSpecifications: ProductSpecifications
    ): Promise<void> {
        const mimeType = this.getMimeType(photo.data);
        const isDefaultMimeType = mimeType === PhotoService.DEFAULT_MIME_TYPE;

        const imageRatio = photo.dimension.width / photo.dimension.height;
        const canvasDimension = this.getCanvasDimension(
            productSpecifications.dimension,
            imageRatio
        );
        const productRatio = canvasDimension.width / canvasDimension.height;

        //png, same aspect ratio and dimension
        if (
            isDefaultMimeType &&
            imageRatio === productRatio &&
            canvasDimension.width == photo.dimension.width &&
            canvasDimension.height == photo.dimension.height
        ) {
            await this.assignPhotoThumbnails(photo);
            return;
        }

        await this.resizephoto(photo, productSpecifications, canvasDimension);
    }

    private async resizephoto(
        photo: PelipostPhoto,
        productSpecifications: ProductSpecifications,
        canvasDimension: PhotoDimension
    ): Promise<void> {
        const imageDimension = this.getScaledImageDimensions(
            canvasDimension,
            photo.dimension
        );

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const imageX = (canvasDimension.width - imageDimension.width) / 2;
        const imageY = (canvasDimension.height - imageDimension.height) / 2;

        const dataUrl = await new Promise<void>((resolve) => {
            const img = new Image(imageDimension.width, imageDimension.height);
            img.onload = () => {
                canvas.width = canvasDimension.width;
                canvas.height = canvasDimension.height;
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.drawImage(
                    img,
                    imageX,
                    imageY,
                    imageDimension.width,
                    imageDimension.height
                );
                if (productSpecifications.isGrayscale) {
                    this.convertToGrayscale(ctx);
                }
                photo.data = canvas.toDataURL(PhotoService.DEFAULT_MIME_TYPE);

                const thumbDimension =
                    this.getThumbnailDimension(imageDimension);
                canvas.width = thumbDimension.width;
                canvas.height = thumbDimension.height;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(
                    img,
                    0,
                    0,
                    thumbDimension.width,
                    thumbDimension.height
                );
                if (productSpecifications.isGrayscale) {
                    this.convertToGrayscale(ctx);
                }
                photo.thumbnailData = canvas.toDataURL(
                    PhotoService.DEFAULT_MIME_TYPE
                );
                ctx.clearRect(
                    0,
                    0,
                    thumbDimension.width,
                    thumbDimension.height
                );
                resolve();
            };
            img.crossOrigin = "";
            img.src = photo.data;
        });
        canvas.remove();
        return dataUrl;
    }

    private getScaledImageDimensions(
        canvasDimension: PhotoDimension,
        imageDimension: PhotoDimension
    ): PhotoDimension {
        const productRatio = canvasDimension.width / canvasDimension.height;
        const imageRatio = imageDimension.width / imageDimension.height;
        let width = imageDimension.width,
            height = imageDimension.height;

        //2100x1400 - 2000x1500 = 1866x1400
        //1400x2100 - 1200/2200
        if (imageRatio < productRatio) {
            height = canvasDimension.height;
            width = canvasDimension.height * imageRatio;
        }
        //2100x1400 - 2200x1200 = 2100x1145
        //1400x2100 - 1500/2000
        else if (imageRatio >= productRatio) {
            width = canvasDimension.width;
            height = canvasDimension.width / imageRatio;
        }

        //round up any decimals to prevent low res warning after resize
        return { width: Math.ceil(width), height: Math.ceil(height) };
    }

    private convertToGrayscale(ctx: CanvasRenderingContext2D): void {
        const imgData = ctx.getImageData(
            0,
            0,
            ctx.canvas.width,
            ctx.canvas.height
        );
        const pixels = imgData.data;
        for (var i = 0; i < pixels.length; i += 4) {
            let lightness = (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3;

            pixels[i] = lightness;
            pixels[i + 1] = lightness;
            pixels[i + 2] = lightness;
        }
        ctx.putImageData(imgData, 0, 0);
    }

    public async restrictImageSize(
        data: string,
        productDimension: PhotoDimension
    ): Promise<string> {
        const imageDimension = await this.getImageDimensions(data);
        const imageRatio = imageDimension.width / imageDimension.height;
        const canvasDimension = this.getCanvasDimension(
            productDimension,
            imageRatio
        );
        const productRatio = canvasDimension.width / canvasDimension.height;

        let width = imageDimension.width,
            height = imageDimension.height,
            needResize = false;

        if (
            imageRatio < productRatio &&
            imageDimension.height > canvasDimension.height
        ) {
            height = canvasDimension.height;
            width = canvasDimension.height * imageRatio;
            needResize = true;
        } else if (
            imageRatio >= productRatio &&
            imageDimension.width > canvasDimension.width
        ) {
            width = canvasDimension.width;
            height = canvasDimension.width / imageRatio;
            needResize = true;
        }

        return iif(
            () => needResize,
            this.getJpegData(data, width, height),
            of(data)
        ).toPromise();
    }

    public getMimeType(data: string): string {
        if (!data) {
            return null;
        }
        const matches = data.match(/^data:([A-Za-z-+\/]+);/);
        if (matches === null || matches.length < 2) {
            return;
        }
        return matches[1];
    }

    public async getJpegData(
        source: string,
        width: number,
        height: number
    ): Promise<string> {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const dataUrl = await new Promise<string>((resolve, reject) => {
            const img = new Image(width, height);
            img.onload = () => {
                canvas.width = width;
                canvas.height = height;
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, width, height);
                const dataUrlRes = canvas.toDataURL(
                    PhotoService.DEFAULT_MIME_TYPE
                );
                ctx.clearRect(0, 0, width, height);
                resolve(dataUrlRes);
            };
            img.onerror = (e: string | Event) => {
                reject(e);
            };
            img.crossOrigin = "";
            img.src = source;
        });

        canvas.remove();
        return dataUrl;
    }

    public static getImageRatio(productDetail: ProductDetail): number {
        return +productDetail.ProductSpecifications.find((sa) =>
            sa.SpecificationAttributeName.toLowerCase().includes("ratio")
        )?.ValueRaw;
    }
}

export enum PhotoSource {
    Camera = "Camera",
    Gallery = "Gallery",
    Google = "Google",
    Facebook = "Facebook", // Obsolete but kept around for device-cached carts
    Instagram = "Instagram",
    TextEditor = "Text Editor"
}

export interface PelipostPhoto {
    id: string;
    filepath: string;
    data: string;
    thumbnailData?: string;
    thumbnailUrl?: string;
    dimension: PhotoDimension;
    source: string;
    downloadGuid?: string;
    remoteUrl?: string;
    associatedProductId?: number;
    ratio?: number;
    history?: any[];
}

export interface PhotoDimension {
    width: number;
    height: number;
}

export interface ProductSpecifications {
    dimension: PhotoDimension;
    isGrayscale: boolean;
}
