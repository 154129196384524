import { Component, OnInit } from "@angular/core";
import { BlockBlobClient } from "@azure/storage-blob";
import { ModalController, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AzurePhotoService } from "src/app/core/services/pelipost-service-proxies/azure-photo.service";
import { PelipostPhoto } from "src/app/core/services/photos.service";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { Facility } from "src/app/shared/model/facility";
import { FacilityAttributeModel } from "src/app/shared/model/facilityAttributeModel";
import { AzureUploadType } from "src/app/shared/model/photos/azure-photo-models";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { LoadingService } from "src/app/core/services";
import {
    AnalyticsService,
    EventTag
} from "src/app/core/services/analytics.service";

@Component({
    selector: "app-confirm",
    templateUrl: "./confirm.page.html",
    styleUrls: ["./confirm.page.scss"]
})
export class ConfirmPage extends BasePage implements OnInit {
    contact: Contact;
    facility: Facility;
    photo: PelipostPhoto;
    blockBlobClient: BlockBlobClient;
    private subscriptions: Array<Subscription> = new Array<Subscription>();

    constructor(
        private contactWorkFlowService: ContactWorkflowService,
        private azurePhotoService: AzurePhotoService,
        private navController: NavController,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private analytics: AnalyticsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.contact = await this.getContact();
        this.photo = await this.contactWorkFlowService.GetPhoto();
        this.facility = await this.contactWorkFlowService.GetFacility();
    }

    private async getContact(): Promise<Contact> {
        const contact = await this.contactWorkFlowService.GetContactModel();

        if (contact) {
            contact.Attributes = [];
            const attributes =
                await this.contactWorkFlowService.GetAttributeValues();
            if (attributes) {
                attributes.forEach((a) => {
                    const contactAttribute = {
                        Id: a.Id,
                        Name: a.Name,
                        DefaultValue: a.Value
                    } as FacilityAttributeModel;
                    contact.Attributes.push(contactAttribute);
                });
            }
            const photo = await this.contactWorkFlowService.GetPhoto();
            contact.ImageUrl = photo?.data;
        }

        return contact;
    }

    async addPhoto() {
        await this.navController.navigateBack("contacts/new-contact/photo");
    }

    async addContact(): Promise<void> {
        await this.loadingService.show();
        try {
            if (this.contact.PeliPalsNumber) {
                await this.contactWorkFlowService.MapPeliPalsContact();
                this.analytics.logEvent(EventTag.ContactCreated, {
                    type: "pelipals"
                });
            } else {
                await this.createNewContact();
                this.analytics.logEvent(EventTag.ContactCreated, {
                    type: "regular"
                });
            }
            await this.contactWorkFlowService.ClearAll();
            await this.navController.navigateRoot("/");
        } catch (error) {
            console.error(`addContact: ${error}`);
            await this.showErrorModal(this.modalController, error);
        } finally {
            await this.loadingService.dismiss();
        }
    }

    private async createNewContact(): Promise<void> {
        if (this.photo) {
            const azurePhotoUploadResponse = await this.azurePhotoService
                .uploadImageToAzure(this.photo.data, AzureUploadType.Image)
                .toPromise();
            this.contact.ImageUrl =
                azurePhotoUploadResponse.sasResponse.Data.BlobUri;
            await this.contactWorkFlowService.StoreContactModel(this.contact);
        }
        await this.contactWorkFlowService.CreateContact();
    }

    onDestroy() {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }
}
