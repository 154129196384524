import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription, firstValueFrom, forkJoin } from "rxjs";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { CartService } from "src/app/core/services";

@Component({
    selector: "app-select-product",
    templateUrl: "./select-product.page.html",
    styleUrls: ["./select-product.page.scss"]
})
export class SelectProductPage extends BasePage implements OnInit, OnDestroy {
    contact: Contact;
    facility: Facility;
    private subscriptions: Subscription[] = [];

    constructor(
        private cartService: CartService,
        private contactContextService: ContactContextService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        const contactSub = this.contactContextService.contact$.subscribe(
            (r) => (this.contact = r)
        );
        const facilitySub = this.contactContextService.facility$.subscribe(
            (f) => (this.facility = f)
        );
        this.subscriptions.push(contactSub, facilitySub);

        await firstValueFrom(
            forkJoin([
                this.contactContextService.getContactContext(),
                this.cartService.fetchCartRes()
            ])
        );
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }
}
