<ion-refresher slot="fixed" (ionRefresh)="refreshHistory($event)">
  <ion-refresher-content pullingIcon="lines"></ion-refresher-content>
</ion-refresher>
<div *ngFor="let rewardPointsYear of rewardPointsByYear">
  <div class="row mx-0 px-2 mt-3">
    <div class="col-12">
      <h5>
        {{ rewardPointsYear.Year }}
      </h5>
    </div>
  </div>
  <div
    *ngFor="let rewardPoint of rewardPointsYear.RewardPoints"
    class="row reward-points mx-0 px-2 py-3"
  >
    <div class="col-5 d-flex align-items-center">
      <p *ngIf="rewardPoint.Points < 0" class="mb-0">Redeemed Points:</p>
      <p *ngIf="rewardPoint.Points >= 0" class="mb-0">Earned Points:</p>
    </div>
    <div class="col-auto">
      <span class="balance" [ngClass]="{'redeemed': rewardPoint.Points < 0}">
        <img src="assets/icon/yellow_star_image.svg"> {{ rewardPoint.Points }}
      </span>
    </div>
    <div class="col-auto ml-auto d-flex align-items-center">
      <small>{{ rewardPoint.Date | date: "MM.dd.yy" }}</small>
    </div>
  </div>
</div>
<ion-infinite-scroll (ionInfinite)="onInfiniteScroll($event)">
  <ion-infinite-scroll-content
    loadingSpinner="bubbles"
    loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>
