import { OrderTotals } from "src/app/shared/model/cart";
import { OrderDetails } from "src/app/shared/model/order";

export interface Item {
    ProductName: string;
    SubTotal: string;
}

export class OrderCalculations {
    public static getOrderTotals(order: OrderDetails): OrderTotals {
        return {
            IsEditable: false,
            SubTotal: order.OrderSubtotal,
            OrderTotal: order.OrderTotal,
            OrderTotalDiscount: order.OrderTotalDiscount,
            RequiresShipping: true,
            HideShippingTotal: false,
            Shipping: order.OrderShipping,
            Tax: order.Tax,
            TaxRates: order.TaxRates,
            DisplayTax: order.DisplayTax,
            DisplayTaxRates: order.DisplayTaxRates,
            GiftCards: [],
            RedeemedRewardPoints: order.RedeemedRewardPoints,
            RedeemedRewardPointsAmount: order.RedeemedRewardPointsAmount,
            WillEarnRewardPoints: 0,
            CustomProperties: {},
            AppliedPeliPalsFunds: order.AppliedPeliPalsFunds,
            SubTotalDiscount: order.OrderSubTotalDiscount,
            SelectedShippingMethod: order.ShippingMethod
        };
    }
}
