import { Route, Router } from "@angular/router";
import { IWorkflowRoute } from "./workflow-route.interface";

/// IMPORTANT! A workflow service MUST be defined and consumed only within the module its routes are defined; otherwise, lazy-loaded routes in the workflow will NOT be available
/// To ensure predicatable behavior, when decorating your workflow service implementation with Injectable,
/// use 'providedIn: null' and explicitly provide it to the module in which it should belong with useClass to delay instantiation
export abstract class AbstractWorkflowService {
    protected workflowRoutes: Array<IWorkflowRoute> = [];

    public get canNavigateForwards(): boolean {
        return this.nextRoute !== null;
    }
    public get canNavigateBackwards(): boolean {
        return this.previousRoute !== null;
    }
    protected get currentRoute() {
        return this.getRouteFromUrl(this.router.url);
    }
    protected get nextRoute() {
        return this.currentRoute?.nextRoute;
    }
    protected get previousRoute() {
        return this.currentRoute?.previousRoute;
    }
    protected abstract get exitWorkflowUrl(): string;

    constructor(protected router: Router) {
        //TODO: Implement then uncomment
        //this.initializeWorkflowRoutes();
    }

    public abstract navigateNext(): void;
    public abstract navigatePrevious(): void;
    protected abstract initializeWorkflowRoutes(): void;

    private getRouteFromUrl(url: string): IWorkflowRoute | null {
        throw new Error("Method not implemented.");
        return this.workflowRoutes.length ? this.workflowRoutes[0] : null;
    }

    protected static compileRelativeUrlForRoute(
        route: Route,
        router: Router
    ): string {
        throw new Error("Method not implemented.");
    }
}
