import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { Address } from "../../model/account/address";
import { Facility } from "../../model/facility";
import { Contact } from "../../model/contact";
import { BasePage } from "../../pages/BasePage";

@Component({
    selector: "app-contact-identity",
    templateUrl: "./contact-identity.component.html",
    styleUrls: ["./contact-identity.component.scss"]
})
export class ContactIdentityComponent extends BasePage implements OnChanges {
    @Input() contact: Contact;
    @Input() facility: Facility;
    @Input() showAvatar = true;
    @Input() isHeader = false;
    contactModel: ContactIdentityModel;

    constructor(private facilityService: FacilityService) {
        super();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.contact?.currentValue) {
            await this.populateContactModel();
        }
    }

    private async populateContactModel(): Promise<void> {
        if (this.contact) {
            this.contactModel = new ContactIdentityModel(this.contact);
            if (!this.facility) {
                try {
                    const facilityResponse = await this.facilityService
                        .getFacilityById(this.contact.FacilityId)
                        .toPromise();
                    this.facility = facilityResponse.Data;
                } catch {
                    //TODO: Confirm this is how we want to handle this (PEL-559)
                    this.facility.Name = "Unknown Facility";
                }
            }
            this.contactModel.FacilityName = this.facility.Name;
            this.contactModel.FacilityAddress = this.facility.Address;
        }
    }
}

class ContactIdentityModel {
    Id: number;
    InmateId: string;
    FirstName: string;
    LastName: string;
    ImageUrl: string;
    FacilityId: number;
    FacilityName: string;
    FacilityAddress: Address;
    AdditionalInfoLine: string;

    constructor(contact: Contact) {
        this.Id = contact.Id;
        this.InmateId = contact.InmateId;
        this.FirstName = contact.FirstName;
        this.LastName = contact.LastName;
        this.ImageUrl = contact.ImageUrl;
        this.FacilityId = contact.FacilityId;
        this.AdditionalInfoLine = this.getAdditionalInfoLine(contact);
    }

    private getAdditionalInfoLine(contact: Contact): string {
        let additionalInfoLine = "";
        contact.Attributes?.forEach((a) => {
            if (a.DefaultValue) {
                if (additionalInfoLine.length > 0) {
                    additionalInfoLine += ", ";
                }
                additionalInfoLine += `${a.Name} ${a.DefaultValue}`;
            }
        });
        return additionalInfoLine;
    }
}
