<ion-header>
    <app-page-header
        [hideBorder]="true"
        pageTitle="Edit Credit Card"
    ></app-page-header>
</ion-header>

<ion-content>
    <app-page-template [stickyActionButtons]="false">
        <section id="page-content">
            <form
                [formGroup]="editCardForm"
                (ngSubmit)="updateCard()"
                novalidate
            >
                <ion-item lines="none">
                    <ion-label position="stacked">NAME ON CARD</ion-label>
                    <ion-input type="name" placeholder="John Smith" formControlName="nameOnCard" [required]="true"></ion-input>
                    <ion-text class="error-text" color="danger" *ngIf="nameOnCard.invalid && (nameOnCard.dirty || nameOnCard.touched)">
                        <div *ngIf="nameOnCard.hasError('required')">
                            Name on Card is required.
                        </div>
                    </ion-text>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">
                        Card Number
                    </ion-label>
                    <div class="d-flex">
                        <app-credit-card-icon
                            class="pr-2"
                            [cardType]="paymentMethod?.card_type"
                        ></app-credit-card-icon>
                        <ion-text>
                            xxxx{{paymentMethod?.card_last_four}}
                        </ion-text>
                    </div>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">EXP DATE</ion-label>
                    <ion-input readonly formControlName="expirationDate" [required]="true" id="expirationDateInput"></ion-input>
                    <ion-popover trigger="expirationDateInput" show-backdrop="false" size="cover">
                      <ng-template>
                        <ion-datetime
                          #popoverDatetime
                          [min]="minDate"
                          [max]="maxDate"
                          presentation="month-year"
                          [value]="expirationDatePickerValue"
                          (ionChange)="expirationDateChanged(popoverDatetime)"
                        ></ion-datetime>
                      </ng-template>
                    </ion-popover>
                    <ion-text
                        class="error-text"
                        color="danger"
                        *ngIf="expirationDate.invalid && (expirationDate.dirty || expirationDate.touched)"
                    >
                        <div
                            *ngIf="expirationDate.hasError('required')"
                        >
                            Expiration date is required.
                        </div>
                    </ion-text>
                </ion-item>
                <app-address-form (formReady)="formInitialized('address', $event)" [countryZipOnly]="true" [existingAddress]="address" addressLabel="Billing Address"></app-address-form>
            </form>
        </section>
        <app-cta-buttons
            primaryCtaLabel="Update"
            [primaryCtaDisabled]="!editCardForm.valid"
            (primaryCtaClick)="updateCard()"
            secondaryCtaLabel="Delete Payment Method"
            (secondaryCtaClick)="deleteCard()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
