/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from "@angular/core";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { Contact } from "src/app/shared/model/contact";
import { Facility } from "src/app/shared/model/facility";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { BasePage } from "src/app/shared/pages/BasePage";
import { ModalController, NavController } from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { StartupService } from "src/app/core/services/pelipost-service-proxies/startup-service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-facility",
    templateUrl: "./facility.page.html",
    styleUrls: ["./facility.page.scss"]
})
export class FacilityPage extends BasePage implements OnInit {
    contact: Contact;
    facilityResults: Facility[] = [];
    filter: string;
    searching = false;
    searchAttempted = false;

    constructor(
        private contactWorkflowService: ContactWorkflowService,
        private facilityService: FacilityService,
        private modalController: ModalController,
        private navController: NavController,
        private startupService: StartupService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.contact = await this.contactWorkflowService.GetContactModel();
    }

    async populateFacilities($event): Promise<void> {
        this.filter = $event.detail?.value as string;
        if (!this.filter || this.filter.length < 3) {
            this.clearResults();
            return;
        }

        try {
            this.searching = true;
            this.facilityResults.length = 0;
            this.facilityResults = (
                await firstValueFrom(
                    this.facilityService.getFacilities({ filter: this.filter })
                )
            ).Data;
        } catch (err) {
            await this.showErrorModal(this.modalController, err);
        } finally {
            this.searching = false;
            this.searchAttempted = true;
        }
    }

    clearSearch(): void {
        this.filter = undefined;
        this.clearResults();
    }

    private clearResults(): void {
        this.facilityResults.length = 0;
    }

    async selectFacility(facility: Facility): Promise<void> {
        this.contact.FacilityId = facility.Id;
        await this.contactWorkflowService.StoreContactModel(this.contact);
        await this.contactWorkflowService.StoreFacility(facility);

        if (facility.RequireInmateId) {
            await this.navController.navigateForward("contacts/new-contact/id");
        } else {
            await this.navController.navigateForward(
                "/contacts/new-contact/additional-info"
            );
        }
    }

    async openSupportLink(): Promise<void> {
        const url = (await firstValueFrom(this.startupService.getSettings()))
            .SupportUrl;
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }
}
