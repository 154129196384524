import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { forkJoin } from "rxjs";
import { LoadingService } from "src/app/core/services";
import { LoginWorkflowService } from "src/app/core/services/login-workflow.service";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import { LoginGetRes } from "src/app/shared/model/account/login";
import { RegisterGetRes } from "src/app/shared/model/account/register";
import { FormValue } from "src/app/shared/model/BaseResponse";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-create-account-form",
    templateUrl: "./create-account-form.component.html",
    styleUrls: ["./create-account-form.component.scss"]
})
export class CreateAccountFormComponent extends BasePage implements OnInit {
    public accountForm: UntypedFormGroup;
    registerGetRes: RegisterGetRes;
    loginGetRes: LoginGetRes;
    errorList: string[];
    public showPassword = false;
    public isLoading = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private modalCtrl: ModalController,
        private loginWorkflowService: LoginWorkflowService,
        private loadingService: LoadingService
    ) {
        super();
    }

    ngOnInit() {
        this.pageLoad();
    }

    pageLoad() {
        this.accountForm = this.formBuilder.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            email: [null],
            username: [null],
            password: [null, [Validators.required, Validators.minLength(7)]]
        });

        forkJoin([
            this.loginService.fetchRegisterData(),
            this.loginService.fetchLoginRes()
        ]).subscribe(
            (results) => {
                this.registerGetRes = results[0];
                this.loginGetRes = results[1];

                if (this.registerGetRes.Data.UsernamesEnabled) {
                    this.accountForm
                        .get("username")
                        .setValidators(Validators.required);
                } else {
                    this.accountForm
                        .get("email")
                        .setValidators([Validators.required, Validators.email]);
                }
            },
            async (error) => {
                await this.showErrorModal(this.modalCtrl, error);
            }
        );
    }

    async createAccount(): Promise<void> {
        this.isLoading = true;
        await this.loadingService.show();
        this.registerGetRes.Data.FirstName = this.accountForm
            .get("firstName")
            .value.trim();
        this.registerGetRes.Data.LastName = this.accountForm
            .get("lastName")
            .value.trim();

        this.registerGetRes.Data.Email = this.loginGetRes.Data.Email = this
            .registerGetRes.Data.UsernamesEnabled
            ? null
            : this.accountForm.get("email").value.trim();
        this.registerGetRes.Data.Username = this.loginGetRes.Data.Username =
            !this.registerGetRes.Data.UsernamesEnabled
                ? null
                : this.accountForm.get("username").value.trim();

        this.registerGetRes.Data.Password = this.loginGetRes.Data.Password =
            this.accountForm.get("password").value.trim();
        this.registerGetRes.Data.ConfirmPassword = this.accountForm
            .get("password")
            .value.trim();

        const formValues: FormValue[] = [];

        try {
            await this.loginService
                .postRegisterData(this.registerGetRes, formValues)
                .toPromise();

            const res = await this.loginService
                .postLoginData(this.loginGetRes.Data)
                .toPromise();

            await this.loginWorkflowService.signupRedirect(res, "email");
        } catch (error) {
            this.loginWorkflowService.signUpFailed("email");
            await this.showErrorModal(this.modalCtrl, error);
        } finally {
            this.isLoading = false;
            await this.loadingService.dismiss();
        }
    }
}
