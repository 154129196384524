import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { firstValueFrom, forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingService } from "src/app/core/services";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { PelipostOrderService } from "src/app/core/services/pelipost-service-proxies/pelipost-order.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { PelipostPhoto } from "src/app/core/services/photos.service";
import { OrderService } from "src/app/core/services/service-proxies/order.service";
import {
    ModalComponent,
    ModalProps
} from "src/app/shared/components/modal/modal.component";
import { OrderTotals } from "src/app/shared/model/cart";
import { Facility } from "src/app/shared/model/facility";
import {
    CustomerOrderStatusEnum,
    OrderDetails,
    OrderStatusEnum
} from "src/app/shared/model/order";
import { Contact } from "src/app/shared/model/contact";
import { OrderCalculations } from "src/app/core/util/orderCalculations";
import { Browser } from "@capacitor/browser";
import { ShippingOptionModel } from "src/app/shared/model/more/shipping-option-model";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";

@Component({
    templateUrl: "./order-status.page.html",
    styleUrls: ["./order-status.page.scss"]
})
export class OrderStatusPage implements OnInit {
    order: OrderDetails;
    orderStatusEnum: OrderStatusEnum;
    customerOrderStatusEnum: CustomerOrderStatusEnum;
    orderTotals: OrderTotals;
    contact: Contact;
    facility: Facility;
    uploadedPhotos: Array<PelipostPhoto> = [];
    trackingNumber: string;
    shippingMethod: ShippingOptionModel;

    constructor(
        private navController: NavController,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private pelipostOrderService: PelipostOrderService,
        private contactService: ContactService,
        private facilityService: FacilityService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private toastController: ToastController,
        private cleverTapService: CleverTapService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const orderId = params["orderid"];
            this.populateOrder(orderId);
        });
    }

    private async populateOrder(orderId: number) {
        const orderData = await forkJoin({
            orderDetails: this.orderService.fetchOrderDetails(orderId),
            shippingMethod: this.pelipostOrderService.getShippingMethod(orderId)
        }).toPromise();

        this.order = orderData.orderDetails.Data;
        this.shippingMethod = orderData.shippingMethod.Data;

        await this.cleverTapService.recordEvent("Order Status Checked", {
            "Order ID": this.order.Id,
            "Order Status": this.order.OrderStatus
        });

        this.orderStatusEnum = OrderStatusEnum[this.order.OrderStatus];
        this.customerOrderStatusEnum =
            CustomerOrderStatusEnum[this.order.CustomerOrderStatus];
        this.orderTotals = OrderCalculations.getOrderTotals(this.order);
        this.populateTrackingNumber();
        this.populateImages();
        forkJoin({
            facility: this.facilityService.getFacilityById(
                this.order.FacilityId
            ),
            contact: this.contactService.getContactById(this.order.ContactId)
        }).subscribe((res) => {
            this.facility = res.facility.Data;
            this.contact = res.contact.Data;
        });
    }

    private populateImages(): void {
        this.uploadedPhotos.length = 0;

        if (!this.order?.Items?.length) {
            return;
        }

        this.order.Items.forEach((oi) => {
            this.uploadedPhotos.push({
                id: "",
                filepath: "",
                data: "",
                thumbnailUrl: oi.PictureThumbnailUrl,
                dimension: { width: oi.Width, height: oi.Height },
                source: "",
                remoteUrl: oi.PictureFullSizeUrl,
                associatedProductId: oi.ProductId,
                ratio: oi.Ratio
            });
        });
    }

    private populateTrackingNumber(): void {
        if (
            this.order.ShippingMethod === "Premium" ||
            this.order.ShippingMethod === "Expedited"
        ) {
            this.trackingNumber = this.order?.Shipments[0]?.TrackingNumber;
        } else {
            this.trackingNumber = undefined;
        }
    }

    async cancelOrder(): Promise<void> {
        const props = {
            modalType: "confirmation",
            headerText: "Are you sure?",
            bodyText: "Your charge will be refunded.",
            primaryCtaText: "Cancel order",
            primaryAction: async () => {
                await this.loadingService.show();
                try {
                    await firstValueFrom(
                        this.pelipostOrderService.cancelOrder(this.order.Id)
                    );
                    await this.cleverTapService.recordEvent("Order Cancelled", {
                        "Order ID": this.order.Id
                    });
                    this.order.OrderStatus =
                        OrderStatusEnum[OrderStatusEnum.Cancelled];
                    this.orderStatusEnum = OrderStatusEnum.Cancelled;
                    this.order.CustomerOrderStatus =
                        CustomerOrderStatusEnum[
                            CustomerOrderStatusEnum.Cancelled
                        ];
                    this.customerOrderStatusEnum =
                        CustomerOrderStatusEnum.Cancelled;
                } catch (err) {
                    const toast = await this.toastController.create({
                        message:
                            "Oops! Something went wrong. Please try again later.",
                        animated: true,
                        duration: 2000,
                        color: "danger"
                    });
                    toast.present();
                } finally {
                    await this.loadingService.dismiss();
                    await this.modalController.dismiss();
                }
            },
            secondaryCtaText: "Never mind",
            secondaryAction: async () => {
                await this.modalController.dismiss();
            }
        } as ModalProps;
        const modal = await this.modalController.create({
            component: ModalComponent,
            componentProps: props
        });

        await modal.present();
    }

    close(): void {
        this.navController.back();
    }

    async returnHome(): Promise<void> {
        await this.navController.navigateRoot("/");
    }

    async onTrackMyPackageClick(): Promise<void> {
        const url = `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${this.trackingNumber}`;
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }
}
