import { Injectable } from "@angular/core";
import { LoadingController, AnimationController } from "@ionic/angular";

export interface LoadingOptions {
  loadingDuration?: number;
  loadingMessage?: string;
  loadingCssClass?: string;
}

@Injectable({
  providedIn: "root"
})
export class LoadingService {
  isLoading = false;
  loaderCounter = 0;
  loading: HTMLIonLoadingElement;

  constructor(
    public loadingController: LoadingController,
    public animationCtrl: AnimationController
  ) {}

  async show(options: LoadingOptions = {}) {
    const leaveAnimation = (baseEl: any) => {
      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(baseEl.querySelector("ion-backdrop"))
        .fromTo("opacity", "0.01", "var(--backdrop-opacity)");
      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing("ease-out")
        .duration(400)
        .addAnimation([backdropAnimation])
        .direction("reverse");
    };
    this.loaderCounter = this.loaderCounter + 1;
    if (this.loaderCounter === 1) {
      this.isLoading = true;
      const { loadingDuration, loadingCssClass } = options;
      this.loading = await this.loadingController.create({
        duration: loadingDuration,
        cssClass: loadingCssClass ? loadingCssClass : "loading-class",
        mode: "ios",
        leaveAnimation
      });
      await this.loading.present();
      return this.loading;
    }
  }

  async dismiss() {
    if (this.loaderCounter > 0) {
      this.loaderCounter = this.loaderCounter - 1;
    }
    if (this.loaderCounter === 0) {
      this.isLoading = false;
      await this.loading.dismiss();
    }
  }
}
