import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { ProductDetailsService } from "./service-proxies/product-details.service";

@Injectable({
    providedIn: "root"
})
export class ProductContextService {
    public productId: number;

    private _product: BehaviorSubject<ProductDetail> = new BehaviorSubject(
        null
    );
    public readonly product$: Observable<ProductDetail> = this._product.asObservable();

    constructor(private productDetailsService: ProductDetailsService) { }

    async setProduct(product: ProductDetail) {
        if (this.productId === product.Id) {
            return;
        }
        this.productId = product.Id;
        this._product.next(product);
    }

    async setProductId(productId: number) {
        if (this.productId === productId) {
            return;
        }
        this.productId = productId;
        try {
            const productResponse = await this.productDetailsService
                .fetchProductDetail(productId)
                .toPromise();
            this.setTierPriceDetails(productResponse.Data);
            this._product.next(productResponse.Data);
        } catch {
            this._product.error("Failed to fetch product details.");
        }
    }

    setTierPriceDetails(product: ProductDetail) {
        product.TierPrices.forEach((tp) => {
            tp.TotalPrice = +tp.Price.substring(1);
            const prevItem =
                product.TierPrices[product.TierPrices.indexOf(tp) - 1];
            tp.StartQuantity = prevItem ? prevItem.Quantity + 1 : 1;
        });
    }
}
