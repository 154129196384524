<ion-header>
    <app-page-header [hideBorder]="true" pageTitle="Log in"></app-page-header>
</ion-header>
<ion-content>
    <section>
        <div class="logo-top">
            <img
              class="light-mode w-75"
              src="../assets/images/pelipost_logo_horizontal_LightMode.svg"
            />
            <img
              class="dark-mode w-75"
              src="../assets/images/pelipost_logo_horizontal_DarkMode.svg"
            />
        </div>
        <ion-grid>
            <ion-row class="ion-justify-content-center">
                <ion-col size="12" sizeMd="6" sizeXl="3">
                    <login-form></login-form>

                    <div class="text-center">
                        <app-social-logins></app-social-logins>
                        <div class="section-header mb-3 mt-4">
                            <span>Need an account?</span>
                        </div>
                        <ion-button
                            fill="clear"
                            size="small"
                            expand="block"
                            color="primary"
                            class="mb-3"
                            (click)="signup()"
                            id="btn-login-sign-up"
                        >
                            Create account
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </section>
</ion-content>
