import { Injectable } from "@angular/core";
import { CleverTap } from "@awesome-cordova-plugins/clevertap";
import clevertap from "clevertap-web-sdk";
import { LoginEvent, LoginWorkflowService } from "../../login-workflow.service";
import { Platform } from "@ionic/angular";
import { CustomerInfo } from "src/app/shared/model/account/login";
import { AnalyticsService } from "../../analytics.service";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";

@Injectable({
    providedIn: "root"
})
export class CleverTapService {
    constructor(
        private analyticsService: AnalyticsService,
        private loginWorkflowService: LoginWorkflowService,
        private platform: Platform
    ) {
        this.loginWorkflowService.loginEvent.subscribe((event: LoginEvent) => {
            this.handleUserLogin(event.res.Data.CustomerInfo).then(() => {
                this.recordEvent("User Logged In", {
                    "Method Used": event.method
                });
            });
        });

        this.loginWorkflowService.loginFailedEvent.subscribe((method) => {
            this.recordEvent("User Login Failed", {
                "Method Used": method
            });
        });

        this.loginWorkflowService.signUpEvent.subscribe((event: LoginEvent) => {
            this.handleUserLogin(event.res.Data.CustomerInfo).then(() => {
                this.recordEvent("User Signed Up", {
                    "Method Used": event.method
                });
            });
        });

        this.loginWorkflowService.signUpFailedEvent.subscribe((method) => {
            this.recordEvent("User Sign Up Failed", {
                "Method Used": method
            });
        });
    }

    initialize(): void {
        if (this.isMobileNative()) {
            return;
        }

        clevertap.privacy.push({ optOut: false });
        clevertap.privacy.push({ useIP: false });
        clevertap.init("46W-46W-9W7Z", "us1");
        clevertap.spa = true;
    }

    async handleUserLogin(customer: CustomerInfo): Promise<void> {
        const payload = {
            Identity: customer.Email,
            Name: `${customer.FirstName} ${customer.LastName}`,
            Email: customer.Email
        };

        if (this.isMobileNative()) {
            await CleverTap.onUserLogin(payload);
        } else {
            clevertap.onUserLogin.push({
                Site: payload
            });
        }
    }

    async recordCartItemEvent(
        type: "Item Added to Cart" | "Item Removed From Cart",
        productSku: string,
        productName: string,
        source: string | undefined,
        unitPrice: number,
        subtotal: number,
        quantity: number,
        facility: Facility,
        contact: Contact
    ): Promise<void> {
        const payload = {
            "Product SKU": productSku,
            "Product Name": productName,
            Source: source ?? "N/A",
            "Unit Price": unitPrice,
            Subtotal: subtotal,
            Quantity: quantity,
            "Facility Name": facility?.Name,
            "Inmate First Name": contact?.FirstName,
            "Inmate Last Name": contact?.LastName,
            PRO: facility?.IsProFacility,
            "Is PeliPAL": !!contact?.PeliPalsNumber
        };
        await this.recordEvent(type, payload);
    }

    async recordEvent(
        eventName: string,
        payload: any | undefined = undefined
    ): Promise<void> {
        try {
            if (!this.isMobileNative()) {
                clevertap.event.push(eventName, payload);
            } else if (payload) {
                void CleverTap.recordEventWithNameAndProps(eventName, payload);
            } else {
                void CleverTap.recordEventWithName(eventName);
            }
        } catch (error) {
            console.error(`Failed to record CleverTap event: ${error}`);
            await this.analyticsService.logError(error);
        }
    }

    private isMobileNative(): boolean {
        return (
            (this.platform.is("ios") || this.platform.is("android")) &&
            !this.platform.is("desktop") &&
            !this.platform.is("mobileweb")
        );
    }
}
