import { Component, OnInit } from "@angular/core";
import {
    IonRouterOutlet,
    NavController,
    Platform,
    ViewDidEnter,
    ViewWillLeave
} from "@ionic/angular";
import { forkJoin, Observer } from "rxjs";
import { CartService, LoadingService } from "src/app/core/services";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import { OrderTotals } from "src/app/shared/model/cart";
import { Facility } from "src/app/shared/model/facility";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { FacilityService } from "src/app/core/services/pelipost-service-proxies/facility.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { OrderService } from "src/app/core/services/service-proxies/order.service";
import { ActivatedRoute } from "@angular/router";
import { OrderDetails } from "src/app/shared/model/order";
import { OrderCalculations } from "src/app/core/util/orderCalculations";
import { AnalyticsService } from "src/app/core/services/analytics.service";

@Component({
    selector: "app-confirmation",
    templateUrl: "./confirmation.page.html",
    styleUrls: ["./confirmation.page.scss"]
})
//TODO: Shouldn't this be a BaseOrderPage?
export class ConfirmationPage
    extends BasePage
    implements OnInit, ViewDidEnter, ViewWillLeave {
    order: OrderDetails;
    orderTotals: OrderTotals;
    contact: Contact;
    facility: Facility;
    private backButtonObserver: Observer<any>;

    constructor(
        private routerOutlet: IonRouterOutlet,
        private navController: NavController,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private platform: Platform,
        private cartService: CartService,
        private facilityService: FacilityService,
        private contactService: ContactService,
        private orderService: OrderService,
        private analyticsService: AnalyticsService,
        private loadingService: LoadingService,
        private route: ActivatedRoute
    ) {
        super();
    }

    async ngOnInit() {
        await this.loadingService.show();

        this.route.params.subscribe((params) => {
            const orderId = params["orderid"];
            this.populateOrder(orderId);
        });

        await this.resetOrderWorkflow();
    }

    ionViewDidEnter() {
        this.routerOutlet.swipeGesture = false;
        this.backButtonObserver = this.platform.backButton.observers.pop();
    }

    ionViewWillLeave() {
        this.platform.backButton.observers.push(this.backButtonObserver);
    }

    private async resetOrderWorkflow(): Promise<void> {
        await this.cartService.clearCart().toPromise();
        await this.checkoutWorkflowService.resetCheckoutAttributes();
        await this.contactContextService.clearContactContext();
        await this.selectPhotosWorkflowService.resetWorkflow();
    }

    private async populateOrder(orderId: number) {
        this.order = (
            await this.orderService.fetchOrderDetails(orderId).toPromise()
        ).Data;
        this.orderTotals = OrderCalculations.getOrderTotals(this.order);

        forkJoin({
            facility: this.facilityService.getFacilityById(
                this.order.FacilityId
            ),
            contact: this.contactService.getContactById(this.order.ContactId)
        }).subscribe((res) => {
            this.facility = res.facility.Data;
            this.contact = res.contact.Data;
            this.loadingService.dismiss();
        });

        const ecommerceData = {
            transaction_id: this.order.Id, // Required
            currency: 'USD',
            value: parseFloat(this.order.OrderTotal.substring(1)),
            items: this.order.LineItems.map((li) => {
                return {
                    item_id: li.ProductId,
                    item_name: li.Name,
                    price: li.PriceAfterDiscount,
                    quantity: li.Quantity
                }
            })
        };

        await this.analyticsService.logEvent("purchase", ecommerceData);
    }

    async returnToMainScreen() {
        await this.navController.navigateRoot("/");
    }
}
