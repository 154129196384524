import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PelipostPhoto } from "src/app/core/services/photos.service";

@Component({
    selector: "app-selected-photo",
    templateUrl: "./selected-photo.component.html",
    styleUrls: ["./selected-photo.component.scss"]
})
export class SelectedPhotoComponent implements OnInit {
    @Input() photo: PelipostPhoto;
    @Input() editEnabled = false;
    @Output() deleteClick = new EventEmitter();
    public imageLoading = true;

    constructor() {}

    ngOnInit() {}
}
