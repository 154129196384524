<ion-header>
  <app-page-header
    [hideBorder]="true"
    (backButtonClick)="navigateBack()"
    pageTitle="Delivery Assurance"
  ></app-page-header>
</ion-header>

<ion-content>
  <app-page-template [stickyActionButtons]="false">
    <section id="page-title">
      <div class="mx-auto">
        <img class="light-mode" src="assets/icon/delivery_assurance_black_envelope@2x.png"/>
        <img class="dark-mode" src="assets/icon/delivery_assurance_white_envelope@2x.png"/>
      </div>
      <ion-text color="green">
        <h4>Send with confidence for only {{attributePrice}}</h4>
      </ion-text>
    </section>
    <section id="page-content">
      <p>If your photos are returned by the correctional facility directly to Pelipost for the following reasons, we will RESEND your order at <strong><i>no additional charge:</i></strong></p>
      <ul>
        <li>Name and/or ID are incorrect.</li>
        <li>Your incarcerated loved one has been moved.</li>
        <li>Your incarcerated loved one is no longer in custody.</li>
        <li>The address was entered incorrectly.</li>
        <li>14 days have gone by without your loved one receiving photos.</li>
      </ul>
      <p>Not covered by Delivery Assurance:</p>
      <ul>
        <li>Photos that are returned due to facility rules violations (ie photos containing nudity, gang content, cash pictures, etc.).</li>
        <li>Photos returned to your personal mailing address by the facility.</li>
      </ul>
    </section>
    <app-cta-buttons
      [primaryCtaLabel]="'Protect my order for ' + attributePrice"
      [primaryCtaDisabled]="loading"
      primaryCtaColor="green"
      primaryCtaId="btn-delivery-assurance-protect"
      (primaryCtaClick)="submit(true)"
      secondaryCtaLabel="No Thanks"
      [secondaryCtaDisabled]="loading"
      secondaryCtaId="btn-delivery-assurance-decline"
      (secondaryCtaClick)="submit(false)"
      secondaryCtaColor="blackandwhite"
    ></app-cta-buttons>
  </app-page-template>

</ion-content>
