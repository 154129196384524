import { Component, Input, OnInit } from "@angular/core";
import { Device } from "@capacitor/device";
import { firstValueFrom } from "rxjs";
import {
    ExternalLoginProviderSchemes,
    LoginService
} from "src/app/core/services/service-proxies/account/login.service";
import { SHOW_GOOGLE_FEATURES } from "src/environments/environment";

@Component({
    selector: "app-social-logins",
    templateUrl: "./social-logins.component.html",
    styleUrls: ["./social-logins.component.scss"]
})
export class SocialLoginsComponent implements OnInit {
    @Input() type: "login" | "registration" = "login";

    availableProviderSchemes: ExternalLoginProviderSchemes;
    googleLoginAvailable: boolean;
    appleLoginAvailable: boolean;

    constructor(private loginService: LoginService) {}

    async ngOnInit(): Promise<void> {
        this.availableProviderSchemes = await firstValueFrom(
            this.loginService.getExternalProviders()
        );

        this.googleLoginAvailable =
            !!this.availableProviderSchemes?.google && SHOW_GOOGLE_FEATURES;

        this.appleLoginAvailable =
            !!this.availableProviderSchemes?.apple &&
            (await Device.getInfo()).platform === "ios";
    }
}
