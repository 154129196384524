<ion-header>
    <app-page-header [hideBorder]="true" pageTitle="Payment"></app-page-header>
</ion-header>
<ion-content>
    <section class="mx-3">
        <div class="text-center">
            <p class="mb-5">How would you like to pay for your order?</p>
        </div>
        <ion-text class="custom-button button-block border rounded flex-row justify-content-between mb-3" *ngFor="let method of paymentMethods">
            <div class="d-flex" (click)="loading ? null : useExisting(method)">
                <app-credit-card-icon class="pr-2" [cardType]="method.card_type"></app-credit-card-icon>
                <ion-text class="m-0">
                    {{ method.card_type | titlecase }} ending in {{ method.card_last_four}}
                </ion-text>
            </div>
            <ion-text color="primary" class="m-0" [routerLink]="['/order/payment/edit-credit-card', method.id]">
                <small>Edit</small>
            </ion-text>
        </ion-text>
        <ion-button expand="block" fill="clear" class="mb-3 border rounded" [routerLink]="['/order/payment/credit-card']" id="btn-select-payment-add-card">
            <ion-icon name="card-outline" class="pr-1"></ion-icon>
            <span>Credit Card</span>
        </ion-button>
        <ngx-paypal [config]="payPalConfig" [ngClass]="{'no-click': loading}" class="mb-3"></ngx-paypal>
        <div class="text-center order-total mt-3 mb-3" *ngIf="orderTotal">
            ORDER TOTAL: {{ orderTotal }}
        </div>
    </section>
</ion-content>
