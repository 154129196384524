<ion-header>
  <app-header [showPelipostLogo]="false" [pageTitle]="'My Orders'"></app-header>
</ion-header>
<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="refreshOrderList($event)">
    <ion-refresher-content pullingIcon="lines"></ion-refresher-content>
  </ion-refresher>
  <app-page-template
    [hideCtaSection]="true"
    [fullWidthContent]="true"
  >
    <section id="page-content" class="full-width mx-3">
      <div *ngFor="let orderYear of ordersByYear">
        <h5 class="mt-4">
          {{ orderYear.Year }}
        </h5>
        <ion-card
          *ngFor="let order of orderYear.Orders"
          class="mb-3 mx-0"
          [routerLink]="[order.Id]"
        >
          <div class="px-3">
            <div class="row pt-2">
              <div class="col-auto">
                <small>Order date: {{ order.CreatedOn | date: "MM/dd/yy" }}</small>
              </div>
              <div class="col-auto ml-auto">
                <small>STATUS: {{ order.CustomerOrderStatus }}</small>
              </div>
            </div>
            <div class="row py-2 mb-1">
              <div class="col-auto">
                <h4 class="contact-name m-0 pb-1">{{ order.ContactName }}</h4>
                <small>Order {{ order.CustomOrderNumber }}</small>
              </div>
              <div class="col-auto order-total-wrapper text-right ml-auto">
                <h4 class="m-0">Order Total:</h4>
                <span>{{ order.OrderTotal }}</span>
              </div>
            </div>
            <span
              class="points ion-text-uppercase pt-1 pb-2"
              [ngClass]="{ 'points-earned': order.PointsEarned > 0 }"
            >
              <img src="assets/icon/yellow_star_image.svg">{{
                order.PointsEarned > 0
                ? '+' + order.PointsEarned
                : '0'
              }} points earned
            </span>
          </div>
        </ion-card>
      </div>
      <ion-infinite-scroll (ionInfinite)="onInfiniteScroll($event)">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </section>
  </app-page-template>
</ion-content>
