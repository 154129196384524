import { Injectable } from "@angular/core";
import { ConnectionStatus, Network } from "@capacitor/network";
import { ModalController } from "@ionic/angular";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { ErrorReason } from "./analytics.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NetworkConnectivityService {
    private readonly isConnected = new Subject<boolean>();
    public readonly isConnected$ = this.isConnected.asObservable();

    private networkErrorModal: HTMLIonModalElement;

    public get isModalShown(): boolean {
        return !!this.networkErrorModal;
    }

    constructor(private modalController: ModalController) {
        Network.getStatus().then((status) => {
            this.isConnected.next(status.connected);
            Network.addListener(
                "networkStatusChange",
                (status: ConnectionStatus) => {
                    this.isConnected.next(status.connected);
                }
            );
        });
    }

    public async showNetworkErrorModal() {
        if (this.isModalShown) {
            return;
        }

        this.networkErrorModal = await this.modalController.create({
            animated: true,
            canDismiss: false,
            backdropDismiss: false,
            showBackdrop: true,
            keyboardClose: true,
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: "Service Unavailable",
                bodyText:
                    "Check your network connection. This modal will disappear when a valid connection has been established.",
                dismissable: false,
                errorReason: ErrorReason.ServiceUnavailable
            }
        });
        await this.networkErrorModal.present();
    }

    public async dismissNetworkErrorModal() {
        await this.networkErrorModal?.dismiss();
        this.networkErrorModal = null;
    }
}
