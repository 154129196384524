<section class="full-width d-flex flex-column justify-content-start mt-3">
    <swiper-container class="m-0"
                slidesPerView="[promoOptions.slidesPerView]"
                zoom="[promoOptions.zoom]">
        <swiper-slide class="promo-slide" *ngFor="let promo of promos">
            <ion-img 
                [src]="promo.PictureUrl"
                (click)="bannerClicked(promo)">
            </ion-img>
        </swiper-slide>
    </swiper-container>
    <div *ngIf="loadingPromos">
        <ion-grid>
            <ion-row size="12" class="align-items-center m-0">
                <ion-skeleton-text animated style="height:250px; width:100%">
                </ion-skeleton-text>
            </ion-row>
        </ion-grid>
    </div>

    <ion-card
        class="product-card"
        *ngFor="let product of photoProducts"
        detail="false"
        (click)="selectProduct(product)"
    >
        <ion-grid class="px-4 py-3">
            <ion-row class="align-items-center">
                <ion-col size="9">
                    <h3>{{ product.Name }}</h3>
                </ion-col>
                <ion-col size="3">
                    <ion-text class="font-weight-bold">{{ product.ProductPrice.PriceValue | currency }} ea</ion-text>
                </ion-col>
            </ion-row>
            <ion-row class="pt-1">
                <ion-text>{{ product.ShortDescription }}</ion-text>
            </ion-row>
        </ion-grid>
    </ion-card>
    <div *ngIf="loadingProducts">
        <ion-card *ngFor="let fakeProduct of [1, 2]">
            <ion-grid class="px-4 py-3">
                <ion-row class="align-items-center">
                    <ion-col size="9">
                        <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
                    </ion-col>
                    <ion-col size="3">
                        <ion-skeleton-text animated></ion-skeleton-text>
                    </ion-col>
                </ion-row>
                <ion-row class="pt-2">
                    <ion-skeleton-text animated class="w-100"></ion-skeleton-text>
                    <ion-skeleton-text animated></ion-skeleton-text>
                </ion-row>
            </ion-grid>
        </ion-card>
    </div>
</section>
