import { Injectable } from "@angular/core";
import { Camera } from "@capacitor/camera";
import { ModalController, Platform } from "@ionic/angular";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import {
    NativeSettings,
    AndroidSettings,
    IOSSettings
} from "capacitor-native-settings";
import { Browser } from "@capacitor/browser";

@Injectable({
    providedIn: "root"
})
export class PermissionsService {
    constructor(
        private modalController: ModalController,
        private platform: Platform
    ) {}

    async checkPermissions(type: "camera" | "photos"): Promise<boolean> {
        if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
            return true;
        }

        let permissions = await Camera.checkPermissions();
        if (permissions[type] === "denied") {
            await this.showPhotoPermissionsRequiredModal(type);
            return false;
        }

        if (
            permissions[type] !== "granted" &&
            permissions[type] !== "limited"
        ) {
            const modal = await this.showPhotoPermissionsExplainerModal();
            await modal.onDidDismiss();
            permissions = await Camera.requestPermissions();
        }

        return (
            permissions[type] === "granted" || permissions[type] === "limited"
        );
    }

    private async showPhotoPermissionsRequiredModal(
        type: "camera" | "photos"
    ): Promise<HTMLIonModalElement> {
        const allowSettings =
            this.platform.is("android") || this.platform.is("ios");
        const modal = await this.modalController.create({
            component: ModalComponent,
            componentProps: {
                customImageUrl: `assets/images/${
                    this.platform.is("ios") ? "ios" : "android"
                }_permissions_header.png`,
                customImageClasses: "mw-50",
                headerText: `${
                    type === "camera" ? "Camera" : "Photo"
                } Access Needed`,
                bodyText: `Uh-oh. Pelipost does not have access to your ${type}. Please go to your device settings and grant Pelipost ${
                    this.platform.is("ios") ? "FULL ACCESS" : "access"
                } to your ${type}. \n\n We respect your privacy! We never access or use your ${type} without your permission. For more information, please tap \"Learn more\" below.`,
                primaryCtaText: allowSettings ? "Go to Settings" : "Okay",
                primaryAction: async () => {
                    if (allowSettings) {
                        NativeSettings.open({
                            optionAndroid: AndroidSettings.ApplicationDetails,
                            optionIOS: IOSSettings.App
                        });
                    }
                    await this.modalController.dismiss();
                },
                secondaryCtaText: "Learn more",
                secondaryAction: async () => {
                    await Browser.open({
                        url: "https://pelipost.zohodesk.com/portal/en/kb/articles/photo-access",
                        windowName: "_blank"
                    });
                    await this.modalController.dismiss();
                },
                errorReason: `${type}_permissions_required`
            }
        });

        await modal.present();
        return modal;
    }

    private async showPhotoPermissionsExplainerModal(): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: ModalComponent,
            componentProps: {
                customImageUrl: "assets/images/photo_access_header.png",
                headerText: "Photo Access Needed",
                bodyText:
                    "In order to add photos from your device, you'll need to give Pelipost access to your photo gallery. \n\n Tap GRANT ACCESS below and be sure to give Pelipost full access to your photos on the next screen.",
                primaryCtaText: "Grant Access",
                actionType: "close",
                errorReason: "photo_permissions_explainer"
            }
        });

        await modal.present();
        return modal;
    }
}
