import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SharedModule } from "src/app/shared/shared.module";
import { AccountRoutingModule } from "./account-routing.module";
import { AppleLoginComponent } from "./components/apple-login/apple-login.component";
import { CreateAccountFormComponent } from "./components/create-account-form/create-account-form.component";
import { GoogleLoginComponent } from "./components/google-login/google-login.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { SocialLoginsComponent } from "./components/social-logins/social-logins.component";
import { ForgotPasswordPage } from "./pages/forgot-password/forgot-password.page";
import { LoginPage } from "./pages/login/login.page";
import { ChangePasswordPage } from "./pages/settings/change-password/change-password.page";
import { IncompleteOrdersPage } from "./pages/settings/incomplete-orders/incomplete-orders.page";
import { SettingsPage } from "./pages/settings/settings.page";
import { PelipalsPage } from "./pages/settings/pelipals/pelipals.page";
import { TicketsPage } from "./pages/settings/tickets/tickets.page";
import { ResetPasswordPage } from "./pages/reset-password/reset-password.page";
import { AddContactPromptPage } from "./pages/sign-up/add-contact-prompt/add-contact-prompt.page";
import { CreateAccountPage } from "./pages/sign-up/create-account/create-account.page";
import { GetStartedPage } from "./pages/sign-up/get-started/get-started.page";
import { SignInWithApple } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";

@NgModule({
    declarations: [
        LoginFormComponent,
        GoogleLoginComponent,
        AppleLoginComponent,
        SocialLoginsComponent,
        LoginPage,
        GetStartedPage,
        CreateAccountFormComponent,
        CreateAccountPage,
        AddContactPromptPage,
        SettingsPage,
        TicketsPage,
        ForgotPasswordPage,
        ResetPasswordPage,
        IncompleteOrdersPage,
        PelipalsPage,
        ChangePasswordPage
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        IonicModule,
        AccountRoutingModule,
        SharedModule
    ],
    providers: [SignInWithApple]
})
export class AccountModule {}
