import { Injectable } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { LocationLookupService } from "src/app/core/services/pelipost-service-proxies/location-lookup.service";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { Address } from "src/app/shared/model/account/address";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";

@Injectable({
    providedIn: "root"
})
export class FattJsProviderService {

    constructor(
        private authService: AuthService,
        private checkoutService: CheckoutService,
        private locationLookupService: LocationLookupService,

    ) { }

    public initializeFattJsApiInstance(
        token: string,
        config: FattMerchant.Config
    ): FattMerchant.Api {
        return new window.FattJs(token, config);
    }

    public async getAddress(
        method: FattMerchant.PaymentMethod
    ): Promise<Address> {
        if (!method.address_country) {
            throw Error(
                "Country was not saved for payment method. Please contact an administrator."
            );
        }
        const countryResponse = await this.locationLookupService
            .findCountryByThreeLetterCode(method.address_country)
            .toPromise();
        if (!countryResponse.Data?.Id) {
            throw Error(
                `Country code '${method.address_country}' not found for payment`
            );
        }
        let stateResponse = null;
        if (method.address_state || method.customer?.address_state) {
            stateResponse = await this.locationLookupService
                .findStateByTwoLetterCode(
                    method.address_state ?? method.customer?.address_state,
                    countryResponse.Data?.Id
                )
                .toPromise();
        }

        const cardholderName = this.splitCardholderName(method.person_name);
        return {
            Email: this.authService.customer.Email,
            FirstName: cardholderName.firstName,
            LastName: cardholderName.lastName,
            PhoneNumber: this.authService.customer.Phone,
            Address1: method.address_1,
            Address2: method.address_2,
            City: method.address_city,
            ZipPostalCode: method.address_zip,
            CountryId: countryResponse?.Data?.Id,
            StateProvinceId: stateResponse?.Data?.Id
        } as Address;
    }

    public splitCardholderName(
        cardholderName: string
    ): { firstName: string; lastName: string } {
        const splitName = cardholderName.split(" ");
        if (!splitName?.length || splitName.length < 2) {
            throw Error("Invalid cardholder name");
        }

        const firstName = splitName.splice(0, 1)[0];
        const lastName = splitName.reduce(
            (name, namePart) => (name += ` ${namePart}`)
        );
        return {
            firstName,
            lastName
        };
    }

    public async savePaymentInfo(
        cardToken: string,
        cardLastFour: string,
        cardType: string,
        saveCard: boolean,
        orderTotal: string
    ): Promise<void> {
        const reqBody = new BaseReqBody();
        const formValues = [];
        formValues.push({
            Key: "cardToken",
            Value: cardToken
        });
        formValues.push({
            Key: "cardLastFour",
            Value: `**** **** **** ${cardLastFour}`
        });
        formValues.push({
            Key: "cardType",
            Value: cardType
        });
        formValues.push({
            Key: "saveCard",
            Value: saveCard
        });
        formValues.push({
            Key: "orderTotal",
            Value: this.parseOrderTotal(orderTotal)
        });
        reqBody.FormValues = formValues;
        await this.checkoutService.savePaymentInfoWithApi(reqBody).toPromise();
    }

    public parseOrderTotal(orderTotal: string): number {
        if (!orderTotal) {
            throw Error("Cannot parse null or undefined order total string");
        }

        return parseFloat(orderTotal.substring(1));
    }
}