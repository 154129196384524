<div class="d-flex align-items-center">
  <div
    *ngIf="showAvatar"
    class="pr-3"
  >
    <app-contact-avatar
      [contact]="contact"
      [avatarSize]="isHeader ? 'small' : 'medium'"
    ></app-contact-avatar>
  </div>
  <div
    *ngIf="contactModel"
    [ngClass]="{'header-text': isHeader}"
  >
    <h4 class="my-0 contact-name on-text-capitalize">
      {{contactModel.FirstName}} {{contactModel.LastName}} 
      <span *ngIf="contactModel.InmateId">#{{contactModel.InmateId}}</span>
    </h4>
    <ion-label class="contact-facility ion-text-capitalize">{{contactModel.FacilityName}}</ion-label>
    <div *ngIf="!isHeader">
      <div *ngIf="contactModel.FacilityAddress">{{ contactModel.FacilityAddress.Address1 }}</div>
      <div *ngIf="contactModel.FacilityAddress && contactModel.FacilityAddress.Address2">{{ contactModel.FacilityAddress.Address2 }}</div>
      <div *ngIf="contactModel.AdditionalInfoLine">{{ contactModel.AdditionalInfoLine }}</div>
      <div *ngIf="contactModel.FacilityAddress">
        {{ contactModel.FacilityAddress.City }}, 
        <span *ngIf="contactModel.FacilityAddress.StateProvinceAbbreviation">{{ contactModel.FacilityAddress.StateProvinceAbbreviation }} </span>
        {{ contactModel.FacilityAddress.ZipPostalCode }}
        <span *ngIf="!contactModel.FacilityAddress.StateProvinceAbbreviation">{{ contactModel.FacilityAddress.CountryName }} </span>
      </div>
    </div>
  </div>

  <!-- Skeleton text -->
  <div *ngIf="!contactModel" class="w-100">
    <ion-skeleton-text animated class="contact-name w-75 pb-1"></ion-skeleton-text>
    <ion-skeleton-text animated class="pb-1"></ion-skeleton-text>
    <ion-skeleton-text animated class="w-75 pb-1"></ion-skeleton-text>
    <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
  </div>
</div>
