<ion-refresher slot="fixed" (ionRefresh)="refreshEarnPage($event)">
  <ion-refresher-content pullingIcon="lines"></ion-refresher-content>
</ion-refresher>
<ion-row class="mt-5 mx-3">
  <ion-col size="12" class="text-center">
    <h3>Your PeliPOINTS balance:</h3>
    <span class="balance"><img src="assets/icon/yellow_star_image.svg"> {{ currentPointsBalance }}</span>
    <p class="mt-4">Get rewarded for every dollar you spend with points towards free photos.</p>
  </ion-col>
</ion-row>
