import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { CartConfigurationModel } from "src/app/shared/model/cartConfigurationModel";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class CartConfigurationService {
    private url = `${this.baseService.BASE_PEL_URL}cartconfiguration`;

    constructor(private baseService: BasePelipostService) {}

    public getCartConfiguration(): Observable<
        BaseResponseModel<CartConfigurationModel>
    > {
        return this.baseService.getRequest<
            BaseResponseModel<CartConfigurationModel>
        >(this.url);
    }

    public setSelectedContact(contactId: number): Observable<void> {
        return this.baseService.putRequest<void>(
            this.url + "/setcontactid",
            contactId
        );
    }

    public setDefaultBillingAddress(): Observable<void> {
        return this.baseService.postRequest<void>(
            this.url + "/savedefaultbillingaddress",
            {}
        );
    }
}
