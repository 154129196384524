import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-cta-buttons",
    templateUrl: "./cta-buttons.component.html",
    styleUrls: ["./cta-buttons.component.scss"]
})
export class CtaButtonsComponent {
    @Input() primaryCtaLabel: string;
    @Input() primaryCtaDisabled = false;
    @Input() primaryCtaId = "btn-primary-cta";
    @Input() secondaryCtaId = "link-secondary-cta";
    @Input() primaryCtaColor = "primary";
    @Input() secondaryCtaColor = "primary";
    @Input() secondaryCtaLabel: string;
    @Input() secondaryCtaDisabled = false;
    @Output() primaryCtaClick = new EventEmitter();
    @Output() secondaryCtaClick = new EventEmitter();
    showPrimaryCta: boolean;
    showSecondaryCta: boolean;
}
