import { Component, OnInit } from "@angular/core";
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { LoadingService } from "src/app/core/services";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";
import { PasswordRecoveryPostRes } from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.page.html",
    styleUrls: ["./forgot-password.page.scss"]
})
export class ForgotPasswordPage extends BasePage implements OnInit {
    public forgotPasswordForm: UntypedFormGroup;
    private passwordRecoveryPostRes: PasswordRecoveryPostRes;
    public displayConfirmation = false;
    public isLoading = false;

    constructor(
        private loginService: LoginService,
        private accountService: AccountService,
        private modalCtrl: ModalController,
        private loadingService: LoadingService,
        private cleverTapService: CleverTapService
    ) {
        super();
        this.displayConfirmation = false;
    }

    ngOnInit() {
        this.displayConfirmation = false;
        this.pageLoad();
    }

    pageLoad() {
        this.loginService
            .fetchPasswordRecoveryData()
            .subscribe((res: PasswordRecoveryPostRes) => {
                this.passwordRecoveryPostRes = res;
                this.forgotPasswordForm = new UntypedFormGroup({
                    email: new UntypedFormControl(null, {
                        updateOn: "change",
                        validators: [Validators.required, Validators.email]
                    })
                });
            });
    }

    async submit(): Promise<void> {
        this.isLoading = true;
        await this.loadingService.show();
        this.passwordRecoveryPostRes.Data.Email = this.forgotPasswordForm
            .get("email")
            .value.trim();

        try {
            await this.accountService
                .postPasswordRecoveryData(this.passwordRecoveryPostRes.Data)
                .toPromise();
            await this.cleverTapService.recordEvent("Forgot Password", {
                "Email Address": this.passwordRecoveryPostRes.Data.Email
            });
            const props = {
                modalType: "confirmation",
                headerText: "Recovery Link Sent",
                bodyText:
                    "If account exists, an email will be sent with a password recovery link.",
                primaryCtaText: "Return to Login",
                actionUrl: "/login"
            };
            await this.showModal(this.modalCtrl, props);
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        } finally {
            this.isLoading = false;
            await this.loadingService.dismiss();
        }
    }
}
