<ion-header>
    <app-header>
        <div
            *ngIf="displayAlternateHomeScreen"
            slot="end"
            class="position-relative"
            (click)="onCartClick()"
        >
            <ion-icon name="cart-outline" size="large"></ion-icon>
            <span *ngIf="currentCart?.Items?.length" class="badge badge-pill">
                {{ currentCart.Items.length }}
            </span>
        </div>
    </app-header>
</ion-header>
<ion-content id="main">
    <swiper-container [initialSlide]="bannerOptions.initialSlide"
                [slidesPerView]="bannerOptions.slidesPerView"
                [autoplay]="bannerOptions.autoplay"
                [pagination]="bannerOptions.pagination">
        <swiper-slide *ngFor="let banner of banners">
            <ion-card class="banner ion-no-margin">
                <ion-img
                    [src]="banner.PictureUrl"
                    (click)="showBanner(banner)"
                ></ion-img>
                <ion-card-title class="title">{{banner.Title}}</ion-card-title>
                <ion-card-subtitle class="subtitle">
                    {{banner.Subtitle}}
                </ion-card-subtitle>
            </ion-card>
        </swiper-slide>
    </swiper-container>
    <div class="bullet-container"></div>
    <app-contact-slider
        [displayAlternateHomeScreen]="displayAlternateHomeScreen"
        [refreshContacts]="ionViewWillEnterSubject.asObservable()"
    ></app-contact-slider>
</ion-content>
