<ion-header>
    <app-page-header
        [hideBorder]="true"
        (backButtonClick)="exitWorkFlow()"
        pageTitle="Contact Type"
    ></app-page-header>
</ion-header>
<ion-content>
    <app-page-template>
        <section id="page-title">
            <p>What type of contact are you adding?</p>
        </section>
        <section id="page-content">
            <div class="row pt-3">
                <div class="col-6 p-0 pr-1">
                    <ion-card
                        class="ion-no-margin py-3 text-center h-100"
                        (click)="traditionalContact()"
                    >
                        <img src="/assets/images/add_contact.png" />
                        <hr />
                        <ion-card-title class="title">
                            Traditional
                        </ion-card-title>
                        <ion-card-subtitle class="subtitle">
                            <em>(Most Commonly Used)</em>
                        </ion-card-subtitle>
                        <ion-card-content class="p-0 mx-2 mt-3">
                            Select this option using loved one's name and inmate number.
                        </ion-card-content>
                    </ion-card>
                </div>
                <div class="col-6 p-0 pl-1">
                    <ion-card
                        class="ion-no-margin py-3 text-center h-100"
                        (click)="pelipalsContact()"
                    >
                        <img src="/assets/images/add_contact.png" />
                        <div class="pelipals-logo-wrapper mb-2 w-100">
                            <div class="d-flex justify-content-center">
                                <div class="pelipals-image w-75 pt-1">
                                    <img
                                        class="light-mode"
                                        src="../assets/images/pelipals_logo.png"
                                    />
                                    <img
                                        class="dark-mode"
                                        src="../assets/images/pelipals_logo_white.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <ion-card-title class="title">
                            Inmate Prepaid Account
                        </ion-card-title>
                        <ion-card-content class="p-0 mx-2 mt-3">
                            Send photos using the inmate's prepaid funds. <b>A PeliPals ID is required to select this option.</b>
                        </ion-card-content>
                    </ion-card>
                </div>
            </div>
        </section>
    </app-page-template>
</ion-content>
  