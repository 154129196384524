import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { firstValueFrom, forkJoin, Subscription } from "rxjs";
import { CartService } from "src/app/core/services";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { CheckoutWorkflowService } from "src/app/modules/order/services/checkout-workflow.service";
import { Browser } from "@capacitor/browser";
import { Cart } from "../../model/cart";
import { Contact } from "../../model/contact";
import { ModalComponent, ModalProps } from "../modal/modal.component";
import { StartupService } from "src/app/core/services/pelipost-service-proxies/startup-service";

@Component({
    selector: "app-footer-tabs",
    templateUrl: "./footer-tabs.component.html",
    styleUrls: ["./footer-tabs.component.scss"]
})
export class FooterTabsComponent implements OnInit, OnDestroy {
    public currentCart: Cart;
    public currentContact: Contact;
    subscriptions: Subscription[] = [];

    constructor(
        private contactContextService: ContactContextService,
        private cartService: CartService,
        private startupService: StartupService,
        private modalCtrl: ModalController,
        private checkoutWorkflowService: CheckoutWorkflowService
    ) {}

    async ngOnInit(): Promise<void> {
        const contactSub = this.contactContextService.contact$.subscribe(
            (r) => (this.currentContact = r)
        );
        const cartSub = this.cartService.cart$.subscribe(
            (c) => (this.currentCart = c?.Cart)
        );
        this.subscriptions.push(contactSub, cartSub);
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    async onCartClick(): Promise<void> {
        if (this.currentCart?.Items?.length) {
            await this.contactContextService.setContactContext(
                this.currentContact.Id
            );
            await this.checkoutWorkflowService.continueCheckoutWorkflow();
        } else {
            const props = {
                modalType: "error",
                headerText: "Your cart is empty.",
                bodyText: "Click on a contact to start your order.",
                primaryCtaText: "Okay",
                primaryAction: async () => {
                    await this.modalCtrl.dismiss();
                }
            } as ModalProps;
            const modal = await this.modalCtrl.create({
                component: ModalComponent,
                componentProps: props
            });

            await modal.present();
        }
    }

    async onSupportClick(): Promise<void> {
        const url = (await firstValueFrom(this.startupService.getSettings()))
            .SupportUrl;
        await Browser.open({
            url,
            windowName: "_blank"
        });
    }
}
