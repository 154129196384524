import { Component } from "@angular/core";
import { NavController } from "@ionic/angular";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-login",
    templateUrl: "./login.page.html",
    styleUrls: ["./login.page.scss"]
})
export class LoginPage extends BasePage {
    constructor(private navController: NavController) {
        super();
    }

    signup() {
        this.navController.navigateForward("/sign-up/get-started");
    }
}
