import { Injectable } from "@angular/core";
import {
    BaseResponseModel,
    FormValue
} from "src/app/shared/model/BaseResponse";
import { Facility } from "src/app/shared/model/facility";
import { SelectedAttributeValueModel } from "src/app/shared/model/facilityAttributeModel";
import { Contact } from "src/app/shared/model/contact";
import { ContactService } from "./pelipost-service-proxies/contact.service";
import { PelipostPhoto } from "./photos.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class ContactWorkflowService {
    static NEW_CONTACT_MODEL_KEY = "newContactModel";
    static NEW_CONTACT_FACILITY_KEY = "newContactFacility";
    static NEW_CONTACT_PHOTO_KEY = "newContactPhoto";
    static NEW_CONTACT_ATTRIBUTES_KEY = "newContactAttributes";
    static NEW_CONTACT_ATTRIBUTE_VALUES_KEY = "newContactAttributeValues";

    constructor(
        private storageService: StorageService,
        private contactService: ContactService
    ) {}

    async StoreContactModel(contact: Contact) {
        await this.storageService.setObject(
            ContactWorkflowService.NEW_CONTACT_MODEL_KEY,
            contact
        );
    }

    async GetContactModel(): Promise<Contact> {
        return this.storageService.getObject<Contact>(
            ContactWorkflowService.NEW_CONTACT_MODEL_KEY
        );
    }

    async CreateContact(): Promise<BaseResponseModel<void>> {
        const newContact = await this.GetContactModel();
        const attributes = await this.GetAttributes();
        return this.contactService
            .createNewContact(newContact, attributes)
            .toPromise();
    }

    async MapPeliPalsContact(): Promise<BaseResponseModel<void>> {
        const newContact = await this.GetContactModel();
        return this.contactService
            .addPeliPalsContactMapping(newContact)
            .toPromise();
    }

    async ClearContact(): Promise<void> {
        await this.storageService.removeItem(
            ContactWorkflowService.NEW_CONTACT_MODEL_KEY
        );
    }

    async StoreFacility(facility: Facility) {
        await this.storageService.setObject(
            ContactWorkflowService.NEW_CONTACT_FACILITY_KEY,
            facility
        );
    }

    async GetFacility(): Promise<Facility> {
        return this.storageService.getObject<Facility>(
            ContactWorkflowService.NEW_CONTACT_FACILITY_KEY
        );
    }

    async ClearFacility(): Promise<void> {
        await this.storageService.removeItem(
            ContactWorkflowService.NEW_CONTACT_FACILITY_KEY
        );
    }

    async StorePhoto(photo: PelipostPhoto) {
        await this.storageService.setObject(
            ContactWorkflowService.NEW_CONTACT_PHOTO_KEY,
            photo
        );
    }

    async GetPhoto(): Promise<PelipostPhoto> {
        return this.storageService.getObject<PelipostPhoto>(
            ContactWorkflowService.NEW_CONTACT_PHOTO_KEY
        );
    }

    async ClearPhoto(): Promise<void> {
        await this.storageService.removeItem(
            ContactWorkflowService.NEW_CONTACT_PHOTO_KEY
        );
    }

    async StoreAttributes(
        values: FormValue[],
        attributes: SelectedAttributeValueModel[]
    ) {
        await this.storageService.setObject(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTES_KEY,
            values
        );
        await this.storageService.setObject(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTE_VALUES_KEY,
            attributes
        );
    }

    async GetAttributes(): Promise<FormValue[]> {
        return this.storageService.getObject<FormValue[]>(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTES_KEY
        );
    }

    async ClearAttributes(): Promise<void> {
        await this.storageService.removeItem(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTES_KEY
        );
    }

    async GetAttributeValues(): Promise<SelectedAttributeValueModel[]> {
        return this.storageService.getObject<SelectedAttributeValueModel[]>(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTE_VALUES_KEY
        );
    }

    async ClearAttributeValues(): Promise<void> {
        await this.storageService.removeItem(
            ContactWorkflowService.NEW_CONTACT_ATTRIBUTE_VALUES_KEY
        );
    }

    async ClearAll(): Promise<void> {
        await this.ClearContact();
        await this.ClearFacility();
        await this.ClearPhoto();
        await this.ClearAttributes();
        await this.ClearAttributeValues();
    }
}
