/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { AuthService, LoadingService } from "src/app/core/services";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { AccountInfoService } from "src/app/core/services/service-proxies/account/account-info.service";
import { ModalProps } from "src/app/shared/components/modal/modal.component";
import { AccountInfoGetRes } from "src/app/shared/model/account/account-info";
import { Address } from "src/app/shared/model/account/address";
import { BasePage } from "src/app/shared/pages/BasePage";
import { Constant } from "src/app/util/constant";

@Component({
    templateUrl: "./settings.page.html",
    styleUrls: ["./settings.page.scss"]
})
export class SettingsPage extends BasePage implements OnInit {
    accountGetRes: AccountInfoGetRes;
    accountForm: UntypedFormGroup;
    returnAddress: Address;
    isExternalAuthUser: boolean;
    returnAddressLoading = true;
    enableEmailMarketing = true;
    enablePushOrderStatusUpdates = true;
    enablePushMarketing = true;

    get firstName(): AbstractControl {
        return this.accountForm.get("firstName");
    }
    get lastName(): AbstractControl {
        return this.accountForm.get("lastName");
    }
    get email(): AbstractControl {
        return this.accountForm.get("email");
    }
    get username(): AbstractControl {
        return this.accountForm.get("username");
    }

    constructor(
        private accountInfoService: AccountInfoService,
        private accountService: AccountService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private loadingService: LoadingService,
        private modalCtrl: ModalController,
        private navController: NavController,
        private toastController: ToastController
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.accountForm = this.formBuilder.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            username: [null]
        });

        await this.pageload();
    }

    async ionViewWillEnter(): Promise<void> {
        await this.loadReturnAddress();
    }

    async pageload(): Promise<void> {
        await this.loadingService.show();
        try {
            this.accountGetRes = await this.accountInfoService
                .fetchAccountInfoRes()
                .toPromise();

            this.isExternalAuthUser =
                this.accountGetRes.Data.AssociatedExternalAuthRecords.length >
                0;

            if (this.accountGetRes.Data.UsernamesEnabled) {
                this.accountForm
                    .get("username")
                    .setValidators(Validators.required);
            }

            this.accountForm.patchValue({
                firstName: this.accountGetRes.Data.FirstName,
                lastName: this.accountGetRes.Data.LastName,
                email: this.accountGetRes.Data.Email,
                username: this.accountGetRes.Data.Username
            });

            this.accountGetRes.Data.CustomerAttributes.forEach((ca) => {
                if (
                    ca.Name ===
                    Constant.MARKETING_EMAIL_NOTIFICATIONS_ATTRIBUTE_NAME
                ) {
                    this.enableEmailMarketing = ca.Values.find(
                        (v) => v.Name === Constant.ENABLED_ATTRIBUTE_VALUE_NAME
                    ).IsPreSelected;
                }
                if (
                    ca.Name ===
                    Constant.ORDER_STATUS_UPDATE_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME
                ) {
                    this.enablePushOrderStatusUpdates = ca.Values.find(
                        (v) => v.Name === Constant.ENABLED_ATTRIBUTE_VALUE_NAME
                    ).IsPreSelected;
                }
                if (
                    ca.Name ===
                    Constant.MARKETING_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME
                ) {
                    this.enablePushMarketing = ca.Values.find(
                        (v) => v.Name === Constant.ENABLED_ATTRIBUTE_VALUE_NAME
                    ).IsPreSelected;
                }
            });
        } finally {
            await this.loadingService.dismiss();
        }
    }

    async save(): Promise<void> {
        await this.loadingService.show();
        try {
            this.accountGetRes.Data.FirstName =
                this.firstName?.value?.trim() as string;
            this.accountGetRes.Data.LastName =
                this.lastName?.value?.trim() as string;
            this.accountGetRes.Data.Email = this.email?.value?.trim() as string;
            this.accountGetRes.Data.Username =
                this.username?.value?.trim() as string;
            this.accountGetRes.FormValues =
                this.accountGetRes.Data.CustomerAttributes.map((ca) => {
                    let selectedValue = "";
                    const enabledValueId = ca.Values.find(
                        (v) => v.Name === Constant.ENABLED_ATTRIBUTE_VALUE_NAME
                    )?.Id;
                    const disabledValueId = ca.Values.find(
                        (v) => v.Name === Constant.DISABLED_ATTRIBUTE_VALUE_NAME
                    )?.Id;

                    if (
                        ca.Name ===
                        Constant.MARKETING_EMAIL_NOTIFICATIONS_ATTRIBUTE_NAME
                    ) {
                        selectedValue += this.enableEmailMarketing
                            ? enabledValueId
                            : disabledValueId;
                    } else if (
                        ca.Name ===
                        Constant.ORDER_STATUS_UPDATE_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME
                    ) {
                        selectedValue += this.enablePushOrderStatusUpdates
                            ? enabledValueId
                            : disabledValueId;
                    } else if (
                        ca.Name ===
                        Constant.MARKETING_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME
                    ) {
                        selectedValue += this.enablePushMarketing
                            ? enabledValueId
                            : disabledValueId;
                    } else {
                        ca.Values.forEach((value) => {
                            if (value.IsPreSelected) {
                                if (selectedValue) {
                                    selectedValue += ",";
                                }
                                selectedValue += value.Id;
                            }
                        });
                    }

                    if (!selectedValue) {
                        selectedValue = ca.DefaultValue;
                    }

                    if (selectedValue) {
                        return {
                            Key: "customer_attribute_" + ca.Id,
                            Value: selectedValue
                        };
                    }
                }).filter((fv) => !!fv);

            const accountResponse = await this.accountInfoService
                .postAccountInfo(this.accountGetRes)
                .toPromise();

            if (accountResponse && accountResponse.ErrorList?.length) {
                await this.showErrorModal(
                    this.modalCtrl,
                    accountResponse.ErrorList
                );
            } else {
                this.authService.customer = accountResponse.Data;
                await this.showToast(
                    this.toastController,
                    "Account saved successfully!"
                );
            }
        } finally {
            await this.loadingService.dismiss();
        }
    }

    cancel(): void {
        this.navController.back();
    }

    async deleteMyAccount(): Promise<void> {
        const props = {
            modalType: "confirmation",
            headerText: "Are You Sure?",
            bodyText: `Are you sure you wish to delete your account?`,
            primaryCtaText: "Yes",
            primaryAction: async () => {
                try {
                    await this.modalCtrl.dismiss();
                    await this.accountService.deleteMyAccount().toPromise();
                    await this.showToast(
                        this.toastController,
                        "Deletion request submitted!"
                    );
                } catch {
                    await this.showError(
                        this.toastController,
                        "Something went wrong. Please try again later."
                    );
                }
            },
            secondaryCtaText: "No",
            secondaryAction: async () => {
                await this.modalCtrl.dismiss();
            }
        } as ModalProps;
        await this.showModal(this.modalCtrl, props);
    }

    private async loadReturnAddress(): Promise<void> {
        this.returnAddressLoading = true;
        const returnAddressRes = await this.accountService
            .fetchReturnAddress()
            .toPromise();
        this.returnAddress = returnAddressRes.Data;
        this.returnAddressLoading = false;
    }
}
