<div *ngIf="accountForm">
  <form [formGroup]="accountForm" (ngSubmit)="createAccount()" novalidate>
    <ion-item lines="none">
      <ion-label position="stacked">FIRST NAME</ion-label>
      <ion-input
        type="name"
        placeholder="John"
        formControlName="firstName"
        required
        id="input-first-name"
      ></ion-input>
      <ion-text
        class="error-text"
        color="danger"
        *ngIf="
          accountForm.get('firstName').invalid &&
          (accountForm.get('firstName').dirty ||
            accountForm.get('firstName').touched)
        "
      >
        <div *ngIf="accountForm.get('firstName').hasError('required')">
          First Name is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked">LAST NAME</ion-label>
      <ion-input
        type="family-name"
        placeholder="Smith"
        formControlName="lastName"
        required
        id="input-last-name"
      ></ion-input>
      <ion-text
        class="error-text"
        color="danger"
        *ngIf="
          accountForm.get('lastName').invalid &&
          (accountForm.get('lastName').dirty ||
            accountForm.get('lastName').touched)
        "
      >
        <div *ngIf="accountForm.get('lastName').hasError('required')">
          Last Name is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item *ngIf="!registerGetRes?.Data?.UsernamesEnabled" lines="none">
      <ion-label position="stacked">EMAIL ADDRESS</ion-label>
      <ion-input
        type="email"
        placeholder="example@pelipost.com"
        formControlName="email"
        id="input-email"
      ></ion-input>
      <ion-text
        class="error-text"
        color="danger"
        *ngIf="
          accountForm.get('email').invalid &&
          (accountForm.get('email').dirty || accountForm.get('email').touched)
        "
      >
        <div *ngIf="accountForm.get('email').hasError('required')">
          Email is required.
        </div>
        <div *ngIf="accountForm.get('email').hasError('email')">
          Invalid Email format.
        </div>
      </ion-text>
    </ion-item>
    <ion-item *ngIf="registerGetRes?.Data?.UsernamesEnabled" lines="none">
      <ion-label position="stacked">USERNAME</ion-label>
      <ion-input
        type="username"
        placeholder="username"
        formControlName="username"
        id="input-username"
      ></ion-input>
      <ion-text
        class="error-text"
        color="danger"
        *ngIf="
          accountForm.get('username').invalid &&
          (accountForm.get('username').dirty ||
            accountForm.get('username').touched)
        "
      >
        <div *ngIf="accountForm.get('username').hasError('required')">
          Username is required.
        </div>
      </ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked">PASSWORD</ion-label>
      <ion-input
        [type]="showPassword ? 'text' : 'password'"
        placeholder="************"
        formControlName="password"
        minlength="7"
        required
        id="input-password"
      ></ion-input>
      <ion-text
        class="toggle-password"
        color="primary"
        (click)="showPassword = !showPassword"
      >
        {{ showPassword ? "HIDE" : "SHOW" }}
      </ion-text>
      <ion-text
        class="error-text"
        [attr.color]="
          accountForm.get('password').invalid &&
          (accountForm.get('password').dirty ||
            accountForm.get('password').touched)
            ? 'danger'
            : null
        "
      >
        <div
          *ngIf="
            accountForm.get('password').hasError('required') &&
            (accountForm.get('password').dirty ||
              accountForm.get('password').touched)
          "
        >
          Password is required.
        </div>
        <div>Must be at least 7 characters long.</div>
      </ion-text>
    </ion-item>

    <ion-text class="error-text" color="danger">
      <ul>
        <li *ngFor="let item of errorList">{{ item }}</li>
      </ul>
    </ion-text>
    <div class="text-center">
      <ion-button
        expand="block"
        class="mb-3"
        (click)="createAccount()"
        [disabled]="!accountForm.valid || isLoading"
        id="btn-submit-sign-up"
      >
        Sign Up
      </ion-button>
    </div>
  </form>
</div>
