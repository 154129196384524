import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { CustomerRewardPoints } from "src/app/shared/model/account/reward-points";
import { OrderService } from "./service-proxies/order.service";

@Injectable({
    providedIn: "root"
})
export class PelipointsContextService {
    private _pelipoints: BehaviorSubject<CustomerRewardPoints> = new BehaviorSubject(
        null
    );
    public readonly pelipoints$: Observable<CustomerRewardPoints> = this._pelipoints.asObservable();

    constructor(private orderService: OrderService) {}

    public getPelipointsContext() {
        return this.orderService.fetchCustomerRewardPoints().pipe(
            catchError((error) => {
                this._pelipoints.error(error);
                return throwError(error);
            }),
            concatMap((response) => {
                this._pelipoints.next(response);
                return of(response);
            })
        );
    }
}
