<ion-header>
  <app-page-header [hideBorder]="true" pageTitle="Review Order"></app-page-header>
</ion-header>
<ion-content>
  <app-page-template [stickyActionButtons]="true" [fullWidthContent]="true">
      <section id="page-content" class="pt-2 px-0">
        <section class="px-5 py-5 mt-3">
          <div class="mb-5 text-center" *ngIf="isPaypal">
            <app-credit-card-icon class="paypal" cardType="paypal"></app-credit-card-icon>
          </div>
          <div class="mb-5 text-center" *ngIf="cardInfo">
            <app-credit-card-icon [cardType]="cardInfo.CreditCardType"></app-credit-card-icon>
            <div class="card-digits">***{{cardInfo.CreditCardLastFour}}</div>
            <ion-text class="font-weight-bold" color="primary" (click)="editPaymentMethod()">Edit payment method</ion-text>
          </div>
          <app-order-details [lineItems]="lineItems" [orderTotals]="orderTotals"></app-order-details>
        </section>
      </section>
      <app-cta-buttons primaryCtaLabel="Complete Order" [primaryCtaDisabled]="disableButton" (primaryCtaClick)="completeOrder()"></app-cta-buttons>
  </app-page-template>
</ion-content>
