<ion-header>
    <app-header
        [showPelipostLogo]="false"
        [pageTitle]="selectedTab.Name"
    >
        <section id="below-header-content" class="full-width">
            <div class="px-0 text-center">
                <p>Your PeliPOINTS balance: {{ currentPointsBalance }}</p>
                <ion-row class="ion-justify-content-around tabs-row px-4">
                    <ion-col
                        *ngFor="let tab of myPointsTabs"
                        size="auto"
                        [routerLink]="tab.Path"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        (click)="changeTab(tab)"
                    >
                        <h5>{{ tab.Name }}</h5>
                    </ion-col>
                </ion-row>
            </div>
        </section>
    </app-header>
</ion-header>
<ion-content>
    <app-page-template [hideCtaSection]="true" [fullWidthContent]="true">
        <section id="page-content" class="full-width">
            <router-outlet></router-outlet>
        </section>
    </app-page-template>
</ion-content>
