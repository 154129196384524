<div *ngIf="googleLoginAvailable || appleLoginAvailable">
    <div *ngIf="type === 'login'" class="section-header mb-3">
        <span>Sign in with social media</span>
    </div>
    <app-google-login
        *ngIf="googleLoginAvailable"
        [providerSchemeName]="availableProviderSchemes['google']"
    ></app-google-login>
    <app-apple-login
        *ngIf="appleLoginAvailable"
        [providerSchemeName]="availableProviderSchemes['apple']"
    ></app-apple-login>
</div>
