import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseReqBody } from "../../../shared/model/BaseResponse";
import {
  ProductDetail,
  ProductDetailData
} from "../../../shared/model/product-detail/product-detail";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root"
})
export class ProductDetailsService {
  private associatedProduct?: ProductDetail = null;

  getAssociatedProduct(): ProductDetail {
    return this.associatedProduct;
  }

  setAssociatedProduct(prod?: ProductDetail) {
    this.associatedProduct = prod;
  }

  constructor(private baseService: BaseService) {}

  fetchProductDetail(productId: number): Observable<ProductDetailData> {
    const url =
      this.baseService.BASE_URL + `product/productdetails/${productId}/0`;
    return this.baseService.getRequest(url);
  }

  fetchRelatedProducts(productId: number) {
    const url =
      this.baseService.BASE_URL + `product/relatedproducts/${productId}`;
    return this.baseService.getRequest(url);
  }

  fetchAlsoPurchasedProducts(productId: number) {
    const url =
      this.baseService.BASE_URL + `product/productsalsopurchased/${productId}`;
    return this.baseService.getRequest(url);
  }

  fetchDownloadJson(productId: number) {
    const url = this.baseService.BASE_URL + `download/sample/${productId}`;
    return this.baseService.getRequest(url);
  }

  fetchDownloadFiles(productId: number) {
    const url = this.baseService.BASE_URL + `download/sample/${productId}`;
    return this.baseService.getRequestFiles(url);
  }

  postSelectedAttributes(productId: number, body: BaseReqBody) {
    const url =
      this.baseService.BASE_URL +
      `shoppingcart/productattributechange/${productId}`;
    return this.baseService.postRequest(url, body);
  }
}
