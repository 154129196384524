import { ErrorHandler, Injectable } from "@angular/core";
import { AnalyticsService } from "./analytics.service";

@Injectable({
    providedIn: "root"
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private analyticsService: AnalyticsService
    ) { }

    handleError(error: Error): void {
        console.error(error);
        this.analyticsService.logError(error);
    }
}
