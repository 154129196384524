/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Browser } from "@capacitor/browser";
import { NavController } from "@ionic/angular";
import { firstValueFrom } from "rxjs";
import { ContactWorkflowService } from "src/app/core/services/contact-workflow.service";
import { ContactService } from "src/app/core/services/pelipost-service-proxies/contact.service";
import { StartupService } from "src/app/core/services/pelipost-service-proxies/startup-service";
import { Contact } from "src/app/shared/model/contact";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-inmate-id",
    templateUrl: "./inmate-id.page.html",
    styleUrls: ["./inmate-id.page.scss"]
})
export class InmateIdPage extends BasePage implements OnInit {
    contact: Contact;
    contactInmateIdForm = this.formBuilder.group({
        inmateId: [null, Validators.required]
    });
    findInmateIdUrl: string;

    constructor(
        private contactService: ContactService,
        private formBuilder: UntypedFormBuilder,
        private startupService: StartupService,
        private contactWorkFlowService: ContactWorkflowService,
        private navCtrl: NavController
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.contact = await this.contactWorkFlowService.GetContactModel();
        this.findInmateIdUrl = (
            await firstValueFrom(this.startupService.getSettings())
        ).InmateIdSupportUrl;
    }

    async findInmateId(): Promise<void> {
        if (!this.findInmateIdUrl) {
            return;
        }

        await Browser.open({
            url: this.findInmateIdUrl,
            windowName: "_blank"
        });
    }

    async continue(): Promise<void> {
        this.contact.InmateId = this.contactInmateIdForm
            .get("inmateId")
            .value.trim();
        await this.contactWorkFlowService.StoreContactModel(this.contact);

        const additionalInfoRes = await this.contactService
            .getCustomAttributes(this.contact.FacilityId)
            .toPromise();

        if (additionalInfoRes?.Data?.length) {
            await this.navCtrl.navigateForward(
                "/contacts/new-contact/additional-info"
            );
        } else if (this.contact.Id) {
            await this.navCtrl.navigateForward(
                `/contacts/edit-contact/${this.contact.Id}`
            );
        } else {
            await this.navCtrl.navigateForward("/contacts/new-contact/photo");
        }
    }
}
