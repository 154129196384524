import { Injectable } from "@angular/core";
import { NavigationEnd, Router, Event, RouterEvent } from "@angular/router";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Device } from "@capacitor/device";
import { filter, tap } from "rxjs/operators";
import * as StackTrace from "stacktrace-js";
import {
    applicationInsightsConfig,
    environment,
    firebaseConfig
} from "../../../environments/environment";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export class EventTag {
    static CartReset: string = "cart_reset";
    static ContactCreated: string = "contact_created";
    static ErrorModalShown: string = "error_modal_shown";
    static Login: string = "login";
    static MessageAdded = "message_added";
    static MessageAddedGroupA = "message_added_group_a";
    static MessageAddedGroupB = "message_added_group_b";
    static OrderStarted: string = "order_started";
    static OrderStartedGroupA = (context: string): string =>
        `order_started_${context}_group_a`;
    static OrderStartedGroupB = (context: string): string =>
        `order_started_${context}_group_b`;
    static OrderConfirmed: string = "order_confirmed";
    static OrderConfirmedGroupA = (context: string): string =>
        `order_confirmed_${context}_group_a`;
    static OrderConfirmedGroupB = (context: string): string =>
        `order_confirmed_${context}_group_b`;
    static SignUp: string = "sign_up";
}

export class ErrorReason {
    static PaymentFailed: string = "payment_failed";
    static ServiceUnavailable: string = "service_unavailable";
    static AuthenticationError: string = "authentication_error";
    static FailedLoadingPintura: string = "failed_loading_pintura";
    static ExternalProviderPhotos: string = "external_provider_photos";
}

@Injectable({
    providedIn: "root"
})
export class AnalyticsService {
    private applicationInsights: ApplicationInsights;
    private firebaseWeb: FirebaseApp;

    constructor(private router: Router) {}

    public async init(): Promise<void> {
        await this.initFirebase();
        this.initApplicationInsights();
    }

    private async initFirebase(): Promise<void> {
        const deviceInfo = await Device.getInfo();
        if (deviceInfo.platform === "web") {
            this.firebaseWeb = initializeApp(firebaseConfig);
        }

        await this.setProperty("environment", environment.id);

        if (
            deviceInfo.platform === "ios" ||
            deviceInfo.platform === "android"
        ) {
            this.router.events
                .pipe(
                    filter(
                        (e: Event | RouterEvent) => e instanceof NavigationEnd
                    ),
                    tap(async (e: NavigationEnd) => {
                        await this.setScreenName(e.url);
                        await this.logEvent("page_view", {
                            page_location: e.url
                        });
                    })
                )
                .subscribe();

            await FirebaseCrashlytics.setEnabled({ enabled: true });
        }
    }

    private initApplicationInsights(): void {
        if (!applicationInsightsConfig.instrumentationKey) {
            console.warn(
                "Skipping Application Insights initialization; no instrumentation key provided"
            );
            return;
        }
        this.applicationInsights = new ApplicationInsights({
            config: {
                instrumentationKey:
                    applicationInsightsConfig.instrumentationKey,
                enableAutoRouteTracking: true
            }
        });
        this.applicationInsights.loadAppInsights();
        this.applicationInsights.trackPageView(); // current user/session/pageview
    }

    async setUser(userId: string): Promise<void> {
        // Use Firebase Auth uid
        await FirebaseAnalytics.setUserId({
            userId: userId
        });
    }

    async setProperty(name: string, value: string): Promise<void> {
        await FirebaseAnalytics.setUserProperty({
            key: name,
            value: value
        });
    }

    async logError(error: Error, severityLevel?: number): Promise<void> {
        try {
            const deviceInfo = await Device.getInfo();
            const stacktrace = await StackTrace.fromError(error);
            if (
                deviceInfo.platform === "ios" ||
                deviceInfo.platform === "android"
            ) {
                await FirebaseCrashlytics.recordException({
                    message: error.message,
                    stacktrace: stacktrace
                });
            }
            this.applicationInsights?.trackException({
                exception: error,
                severityLevel: severityLevel
            });
        } catch (loggingError) {
            console.error(loggingError);
        }
    }

    async logEvent(name: string, parameters: object): Promise<void> {
        await FirebaseAnalytics.logEvent({
            name: name,
            params: parameters
        });

        this.applicationInsights?.trackEvent({ name }, parameters);
    }

    async setScreenName(screenName: string): Promise<void> {
        await FirebaseAnalytics.setCurrentScreen({
            screenName
        });
    }
}
