import { Injectable, OnDestroy } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, Subscription } from "rxjs";
import { publishReplay, refCount, tap } from "rxjs/operators";
import {
    BaseReqBody,
    BaseResponseModel,
    FormValue
} from "src/app/shared/model/BaseResponse";
import { FacilityAttributeModel } from "src/app/shared/model/facilityAttributeModel";
import { Contact } from "src/app/shared/model/contact";
import { BasePelipostService } from "../base.pelipost.service";
import { LoginWorkflowService } from "../login-workflow.service";

@Injectable({
    providedIn: "root"
})
export class ContactService implements OnDestroy {
    private contactsCacheObservable$: Observable<
        BaseResponseModel<Array<Contact>>
    >;
    private contactsCacheExpiration: DateTime;
    private subscriptions = new Array<Subscription>();

    constructor(
        private baseService: BasePelipostService,
        private loginWorkflowService: LoginWorkflowService
    ) {
        let sub = this.loginWorkflowService.logoutEvent.subscribe(() => {
            this.clearContactCache();
        });
        this.subscriptions.push(sub);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getAllContacts(): Observable<BaseResponseModel<Array<Contact>>> {
        const url = `${this.baseService.BASE_PEL_URL}contact`;
        if (
            this.contactsCacheExpiration &&
            this.contactsCacheExpiration < DateTime.local()
        ) {
            this.clearContactCache();
        }

        if (!this.contactsCacheObservable$) {
            // LOW: Converting this into a replayable multicast could potentially be a memory leak
            this.contactsCacheObservable$ = this.baseService
                .getRequest<BaseResponseModel<Array<Contact>>>(url)
                .pipe(publishReplay(1), refCount());
            this.contactsCacheExpiration = DateTime.local().plus({ day: 1 });
        }

        return this.contactsCacheObservable$;
    }

    getContactById(id: number): Observable<BaseResponseModel<Contact>> {
        const url = `${this.baseService.BASE_PEL_URL}contact/${id}`;
        return this.baseService.getRequest<BaseResponseModel<Contact>>(url);
    }

    getContactByPeliPalsNumber(
        pelipalsNumber: string
    ): Observable<BaseResponseModel<Contact>> {
        const url = `${this.baseService.BASE_PEL_URL}contact/pelipals/${pelipalsNumber}`;
        return this.baseService.getRequest<BaseResponseModel<Contact>>(url);
    }

    createNewContact(newContact: Contact, form: FormValue[]) {
        const url = `${this.baseService.BASE_PEL_URL}contact`;
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = newContact;
        reqBody.FormValues = form;

        return this.baseService
            .postRequest<BaseResponseModel<void>>(url, reqBody)
            .pipe(tap(() => this.clearContactCache()));
    }

    addPeliPalsContactMapping(contact: Contact) {
        const url = `${this.baseService.BASE_PEL_URL}contact/pelipals/add-contact`;
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = contact;

        return this.baseService
            .putRequest<BaseResponseModel<void>>(url, reqBody)
            .pipe(tap(() => this.clearContactCache()));
    }

    removePeliPalsContactMapping(contact: Contact) {
        const url = `${this.baseService.BASE_PEL_URL}contact/pelipals/remove-contact`;
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = contact;

        return this.baseService
            .putRequest<BaseResponseModel<void>>(url, reqBody)
            .pipe(tap(() => this.clearContactCache()));
    }

    updateContact(contact: Contact, form: FormValue[]) {
        const url = `${this.baseService.BASE_PEL_URL}contact/${contact.Id}`;
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.Data = contact;
        reqBody.FormValues = form;

        return this.baseService
            .putRequest<BaseResponseModel<void>>(url, reqBody)
            .pipe(tap(() => this.clearContactCache()));
    }

    deleteContact(contactId: number) {
        const url = `${this.baseService.BASE_PEL_URL}contact/${contactId}`;
        return this.baseService
            .deleteRequest<BaseResponseModel<void>>(url)
            .pipe(tap(() => this.clearContactCache()));
    }

    clearContactCache() {
        this.contactsCacheObservable$ = null;
        this.contactsCacheExpiration = null;
    }

    getCustomAttributes(
        facilityId: number
    ): Observable<BaseResponseModel<Array<FacilityAttributeModel>>> {
        const url = `${this.baseService.BASE_PEL_URL}contact/attributes/${facilityId}`;
        return this.baseService.getRequest(url);
    }

    startContact(): Observable<void> {
        const url = `${this.baseService.BASE_PEL_URL}contact/startcontact`;
        return this.baseService.putRequest(url, null);
    }
}
