<div class="no-header-page"></div>
<ion-content>
  <div class="d-flex justify-content-start position-absolute mt-4">
    <ion-back-button
      class="back-button"
      color="medium"
      text=""
      defaultHref="/welcome"
    ></ion-back-button>
  </div>
  <section class="mt-5">
    <div class="text-center">
      <h3>Reset Password</h3>
      <p class="mb-5">
        In order to protect your account, ensure your password is at least 7
        characters long.
      </p>
      <form [formGroup]="resetPasswordForm" novalidate>
        <ion-item lines="none">
          <ion-label position="stacked">NEW PASSWORD</ion-label>
          <ion-input
            [type]="showPassword ? 'text' : 'password'"
            placeholder="************"
            formControlName="password"
            minlength="7"
            required
          ></ion-input>
          <ion-button
            class="toggle-password"
            fill="clear"
            expand="block"
            size="small"
            color="primary"
            (click)="togglePassword()"
          >
            Show
          </ion-button>
          <ion-text
            class="error-text"
            [attr.color]="resetPasswordForm
        .get('password').invalid && (resetPasswordForm
        .get('password').dirty || resetPasswordForm
        .get('password').touched) ? 'danger': null"
          >
            <div
              *ngIf="resetPasswordForm
          .get('password').hasError('required') && (resetPasswordForm
          .get('password').dirty || resetPasswordForm
          .get('password').touched)"
            >
              Please enter a new password.
            </div>
            <div>Must be at least 7 characters long.</div>
          </ion-text>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">CONFIRM PASSWORD</ion-label>
          <ion-input
            [type]="showConfirmPassword ? 'text' : 'password'"
            placeholder="************"
            formControlName="confirmPassword"
            minlength="7"
            required
          ></ion-input>
          <ion-button
            class="toggle-password"
            fill="clear"
            expand="block"
            size="small"
            color="primary"
            (click)="toggleConfirmPassword()"
          >
            Show
          </ion-button>
          <ion-text
            class="error-text"
            [attr.color]="resetPasswordForm
        .get('confirmPassword').invalid && (resetPasswordForm
        .get('confirmPassword').dirty || resetPasswordForm
        .get('confirmPassword').touched) ? 'danger': null"
          >
            <div
              *ngIf="resetPasswordForm
          .get('confirmPassword').hasError('required') && (resetPasswordForm
          .get('confirmPassword').dirty || resetPasswordForm
          .get('confirmPassword').touched)"
            >
              Please confirm your new password.
            </div>
            <div>Both passwords must match.</div>
          </ion-text>
        </ion-item>
        <div class="text-center">
          <ion-button
            expand="block"
            class="mb-3"
            (click)="resetPassword()"
            [disabled]="!resetPasswordForm
        .valid || !isTokenValid"
          >
            Change Password
          </ion-button>
        </div>
      </form>
    </div>
  </section>
</ion-content>
