<ion-toolbar class="page-header" [ngClass]="{ 'hide-border': hideBorder }">
    <ion-buttons
        slot="start"
        [ngClass]="{
            'no-content':
                !showBackButton && !showHamburgerMenu && showCloseButton
        }"
    >
        <ion-menu-button
            *ngIf="showHamburgerMenu"
            auto-hide="false"
            menu="menu-main"
        ></ion-menu-button>
        <ion-back-button
            *ngIf="showBackButton"
            class="py-0 pl-1 pr-2"
            icon="chevron-back-outline"
            defaultHref="/"
            (click)="backButtonClick.emit()"
        ></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="pageTitle" class="ion-text-center">
        <h3 class="ion-text-wrap my-0">{{ pageTitle }}</h3>
    </ion-title>
    <ion-buttons
        slot="end"
        [ngClass]="{
            'no-content':
                (showBackButton || showHamburgerMenu) && !showCloseButton
        }"
    >
        <ion-icon
            *ngIf="showCloseButton"
            name="close-circle-outline"
            class="close-button mr-2"
            (click)="closeButtonClick.emit()"
        ></ion-icon>
        <ng-content select='[slot="end"]'></ng-content>
    </ion-buttons>
    <div class="header-content d-flex align-items-center justify-content-between">
        <ng-content></ng-content>
    </div>
</ion-toolbar>
<ng-content select="section#below-header-content"></ng-content>
