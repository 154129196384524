<div [ngSwitch]="attribute?.AttributeControlTypeId" [formGroup]="form">
  <!-- Dropdown : 1 -->
  
  <div class="form-group text-left" *ngSwitchCase="TYPE_DROPDOWN">
    <ion-label position="stacked">
      {{ attribute.Name }}
    </ion-label>
    <ion-select [formControlName]="getControlId()" class="ion-text-warp">
      <ion-select-option *ngFor="let opt of attribute.Values" [value]="opt.Id">
        {{ opt.Name }}
      </ion-select-option>
    </ion-select>
    <ion-text class="error-text" color="danger" *ngIf="!isValid">
      {{ attribute.Name }} is required.
    </ion-text>
  </div>

  <!-- Checkboxes : 1 -->
  <div class="form-group text-left" *ngSwitchCase="TYPE_CHECKBOX">
    <ion-label position="stacked">{{ attribute.Name }}</ion-label>
    <ion-select
      multiple="true"
      [formControlName]="getControlId()"
      class="ion-text-warp"
    >
      <ion-select-option *ngFor="let opt of attribute.Values" [value]="opt.Id">
        {{ opt.Name }}
      </ion-select-option>
    </ion-select>
  </div>

  <!-- Text Box : 4 & Multiline Text Box : 10 -->
  <div class="form-group text-left"  *ngSwitchCase="TYPE_TEXTBOX || TYPE_MULTILINE_TEXTBOX"  >
    <ion-label position="stacked">
      {{ attribute.Name }}
    </ion-label>
    <ion-input
      type="text"
      [formControlName]="getControlId()"
      [placeholder]="attribute.Name"
    ></ion-input>
    <ion-text class="error-text" color="danger" *ngIf="!isValid">
      {{ attribute.Name }} is required
    </ion-text>
  </div>

  <!-- Date picker : 20 -->
  <div class="form-group text-left" *ngSwitchCase="TYPE_DATEPICKER">
    <ion-label position="stacked">
      {{ item.TextPrompt }}
    </ion-label>
    <ion-input readonly [formControlName]="getControlId()" id="dateInput"></ion-input>
    <ion-popover trigger="dateInput" show-backdrop="false">
      <ng-template>
        <ion-datetime
          #popoverDatetime
          presentation="date"
          (ionChange)="dateChanged(popoverDatetime)"
        ></ion-datetime>
      </ng-template>
    </ion-popover>
    <ion-text class="error-text" color="danger" *ngIf="!isValid">
      {{ attribute.Name }} is required
    </ion-text>
  </div>
</div>
