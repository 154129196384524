export abstract class Constant {
    static readonly NST_KEY = "";
    static readonly NST_SECRET = "";

    static readonly GENERIC_ERROR_MSG = "Something went wrong";
    static readonly GENERIC_WARNING_TITLE = "Are you sure?";
    static readonly IMAGE_ATTRIBUTE = "Image";
    static readonly ORDER_CONFIRMATION_FAILED_ERROR_MSG =
        "We were not able to confirm your order, possibly due to poor internet connectivity. " +
        "Please check your Order History and try again.";
    static readonly ORDER_STATUS_UPDATE_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME =
        "Order Status Update Push Notifications";
    static readonly MARKETING_EMAIL_NOTIFICATIONS_ATTRIBUTE_NAME =
        "Marketing Email Notifications";
    static readonly MARKETING_PUSH_NOTIFICATIONS_ATTRIBUTE_NAME =
        "Marketing Push Notifications";
    static readonly ENABLED_ATTRIBUTE_VALUE_NAME = "Enabled";
    static readonly DISABLED_ATTRIBUTE_VALUE_NAME = "Disabled";
}
