import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { ReturnAddressPage } from "src/app/shared/pages/return-address/return-address.page";
import { ForgotPasswordPage } from "./pages/forgot-password/forgot-password.page";
import { LoginPage } from "./pages/login/login.page";
import { ChangePasswordPage } from "./pages/settings/change-password/change-password.page";
import { IncompleteOrdersPage } from "./pages/settings/incomplete-orders/incomplete-orders.page";
import { SettingsPage } from "./pages/settings/settings.page";
import { PelipalsPage } from "./pages/settings/pelipals/pelipals.page";
import { TicketsPage } from "./pages/settings/tickets/tickets.page";
import { ResetPasswordPage } from "./pages/reset-password/reset-password.page";
import { AddContactPromptPage } from "./pages/sign-up/add-contact-prompt/add-contact-prompt.page";
import { CreateAccountPage } from "./pages/sign-up/create-account/create-account.page";
import { GetStartedPage } from "./pages/sign-up/get-started/get-started.page";

const routes: Routes = [
    {
        path: "login",
        component: LoginPage
    },
    {
        path: "forgot-password",
        component: ForgotPasswordPage
    },
    {
        path: "reset-password",
        component: ResetPasswordPage
    },
    {
        path: "settings",
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                component: SettingsPage
            },
            {
                path: "pelipals",
                component: PelipalsPage
            },
            {
                path: "incomplete-orders",
                component: IncompleteOrdersPage
            },
            {
                path: "support",
                component: TicketsPage
            },
            {
                path: "change-password",
                component: ChangePasswordPage
            },
            {
                path: "edit-return-address",
                component: ReturnAddressPage,
                data: {
                    isOrderWorkflow: false
                }
            }
        ]
    },
    {
        path: "sign-up",
        children: [
            {
                path: "get-started",
                component: GetStartedPage
            },
            {
                path: "create-account",
                component: CreateAccountPage
            },
            {
                path: "add-contact",
                component: AddContactPromptPage
            },
            {
                path: "",
                redirectTo: "get-started",
                pathMatch: "full"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule {}
