import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GoogleAuthService } from "./google-auth.service";

@Injectable({
    providedIn: "root"
})
export class GooglePhotosApiService {
    private static GOOGLE_API_BASE_URL = "https://photoslibrary.googleapis.com";
    private headers = {
        Authorization: ""
    };

    constructor(
        private httpClient: HttpClient,
        private googleAuthService: GoogleAuthService
    ) {}

    async getPhotos(): Promise<GoogleMediaItems> {
        await this.setBearerToken();
        const url = `${GooglePhotosApiService.GOOGLE_API_BASE_URL}/v1/mediaItems:search`;
        return this.httpClient
            .post<GoogleMediaItems>(
                url,
                {
                    pageSize: "100",
                    filters: {
                        mediaTypeFilter: {
                            mediaTypes: ["PHOTO"]
                        }
                    }
                },
                {
                    headers: this.headers
                }
            )
            .toPromise();
    }

    private async setBearerToken() {
        const token = await this.googleAuthService.getOAuthToken();
        this.headers.Authorization = `Bearer ${token}`;
    }
}

export interface GoogleMediaItems {
    mediaItems: GoogleMediaItem[];
    nextPageToken: string;
}

export interface GoogleMediaItem {
    id: string;
    baseUrl: string;
    mediaMetadata: GoogleMediaMetadata;
}

export interface GoogleMediaMetadata {
    width: string;
    height: string;
}
