import { Component } from "@angular/core";
import { NavController } from "@ionic/angular";

@Component({
    templateUrl: "./add-contact-prompt.page.html",
    styleUrls: ["./add-contact-prompt.page.scss"]
})
export class AddContactPromptPage {
    constructor(private navCtrl: NavController) {}

    async addContact(): Promise<void> {
        await this.navCtrl.navigateRoot("/contacts/new-contact");
    }

    async skip(): Promise<void> {
        await this.navCtrl.navigateRoot("/home");
    }
}
