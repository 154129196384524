import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import {
    createDefaultFontFamilyOptions,
    createMarkupEditorShapeStyleControls,
    createMarkupEditorToolbar,
    getEditorDefaults,
    openDefaultEditor,
    PinturaEditorModal,
    Shape
} from "@pqina/pintura";
import { throwError } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import {
    PelipostPhoto,
    PhotoService,
    PhotoSource
} from "src/app/core/services/photos.service";
import { ProductContextService } from "src/app/core/services/product-context.service";
import { ContactContextService } from "src/app/core/services/contact-context.service";
import { ProductDetailsService } from "src/app/core/services/service-proxies/product-details.service";
import { SelectPhotosWorkflowService } from "src/app/modules/order/services/select-photos-workflow.service";
import {
    ProductDetail,
    ProductDetailData
} from "src/app/shared/model/product-detail/product-detail";
import { Constant } from "src/app/util/constant";
import { BasePhotoEditorPage } from "../base-photo-editor-page";
import { PelipostProductService } from "src/app/core/services/pelipost-service-proxies/pelipost-product.service";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";

@Component({
    selector: "app-add-message",
    templateUrl: "./add-message.page.html",
    styleUrls: ["./add-message.page.scss"]
})
export class AddMessagePage
    extends BasePhotoEditorPage
    implements OnInit, OnDestroy
{
    message: PelipostPhoto;
    messageProduct: ProductDetail;
    canvas: HTMLCanvasElement;
    imageLengthInInches: number;
    imageHeightInInches: number;
    imageRatio: number;
    imageDpi: number;
    editorOptions = {};

    constructor(
        private route: ActivatedRoute,
        private navController: NavController,
        private modalController: ModalController,
        private selectPhotosWorkflowService: SelectPhotosWorkflowService,
        private contactContextService: ContactContextService,
        private productContextService: ProductContextService,
        private photoService: PhotoService,
        private productDetailsService: ProductDetailsService,
        private pelipostProductService: PelipostProductService,
        private cleverTapService: CleverTapService
    ) {
        super();
        this.editorOptions = {
            // Pass the editor default configuration options
            ...getEditorDefaults()
        };
    }

    async ngOnInit(): Promise<void> {
        await super.onInit(
            +this.route.snapshot.paramMap.get("productid"),
            this.contactContextService,
            this.productContextService,
            this.modalController,
            this.navController
        );

        this.message = await this.selectPhotosWorkflowService.getMessagePhoto();
        const messageProduct$ = this.pelipostProductService
            .fetchRelatedProducts(this.product.Id)
            .pipe(
                concatMap((val: any) =>
                    val.Data.filter((p) =>
                        p.Sku.toLowerCase().startsWith("note_")
                    )
                ),
                concatMap((val: any) => {
                    const productDetail =
                        this.productDetailsService.fetchProductDetail(val.Id);

                    return productDetail;
                }),
                catchError((error) => throwError(error))
            );

        messageProduct$.subscribe((res: ProductDetailData) => {
            this.messageProduct = res.Data;
            const specs = res.Data.ProductSpecifications.map((s) => {
                return {
                    Name: s.SpecificationAttributeName.toLowerCase(),
                    Value: +s.ValueRaw
                };
            });

            this.imageLengthInInches = specs.find((s) =>
                s.Name.includes("length")
            ).Value;
            this.imageRatio = specs.find((s) => s.Name.includes("ratio")).Value;
            this.imageDpi = specs.find((s) => s.Name.includes("dpi")).Value;
            this.imageHeightInInches =
                this.imageLengthInInches / this.imageRatio;
        });
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    async continue(addMessage: boolean): Promise<void> {
        if (this.message && !addMessage) {
            await this.showModal(this.modalController, {
                headerText: Constant.GENERIC_WARNING_TITLE,
                bodyText: "Your draft will not be saved.",
                primaryCtaText: "Delete Message",
                primaryAction: async () => {
                    await this.clearMessage();
                    await this.modalController.dismiss();
                    await this.navController.navigateForward([
                        "order/uploading",
                        this.product.Id
                    ]);
                },
                secondaryCtaText: "Cancel"
            });
        } else {
            await this.navController.navigateForward([
                "order/uploading",
                this.product.Id
            ]);
        }
    }

    editMessage(): void {
        const canvasWidth = this.imageLengthInInches * this.imageDpi;
        const canvasHeight = this.imageHeightInInches * this.imageDpi;
        const defaultFontFamilies = createDefaultFontFamilyOptions();
        const editor = openDefaultEditor({
            src: this.createBlankCanvas(canvasWidth, canvasHeight),
            utils: ["annotate"],
            enableButtonRevert: false,
            markupEditorTextInputMode: "modal",
            markupEditorToolbar: createMarkupEditorToolbar([]),
            markupEditorShapeStyleControls:
                createMarkupEditorShapeStyleControls({
                    fontFamilyOptions: [
                        ['"Lato"', "Lato"],
                        ...defaultFontFamilies
                    ],
                    strokeWidthOptions: null,
                    colorOptions: null
                }),
            imageAnnotation: this.createDefaultImageAnnotation()
        });

        //HACK: storing the initial height of the Pintura overlay and re-setting on edit prevents iOS screen bug (PEL-775)
        const overlay = document.querySelector(
            ".PinturaModal"
        ) as HTMLInputElement;
        let initialOverlayHeight: string;
        editor.on("load", () => {
            initialOverlayHeight = overlay.style.height;
            if (this.message?.history) {
                this.setEditorContentFromHistory(editor, this.message?.history);
            }
        });
        editor.on("update", () => {
            overlay.style.height = initialOverlayHeight;
        });
        editor.on("process", async ({ dest }) => {
            await this.processEditor(editor, dest);
        });
        editor.on("loaderror", () => {
            editor.destroy();
            void this.handlePhotoEditorLoadError(this.modalController);
        });
        (editor.stores as any).src.subscribe((currentSrc) => {
            if (!currentSrc) {
                editor.destroy();
                void this.handlePhotoEditorLoadError(this.modalController);
            }
        });
    }

    async navigateBack(): Promise<void> {
        const productId = this.productContextService.productId;
        if (productId) {
            await this.navController.navigateBack([
                "/order/print-preview",
                productId
            ]);
        } else {
            await this.navController.navigateRoot("/");
        }
    }

    async clearMessage(): Promise<void> {
        this.message = null;
        await this.selectPhotosWorkflowService.removeMessagePhoto();
        await this.cleverTapService.recordCartItemEvent(
            "Item Removed From Cart",
            this.messageProduct.Sku,
            this.messageProduct.Name,
            undefined,
            this.messageProduct?.ProductPrice?.PriceValue,
            this.messageProduct?.ProductPrice?.PriceValue,
            1,
            this.facility,
            this.contact
        );
    }

    private createBlankCanvas(
        width: number,
        height: number
    ): HTMLCanvasElement {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const imageContext = canvas.getContext("2d");
        imageContext.fillStyle = "white";
        imageContext.fillRect(0, 0, canvas.width, canvas.height);
        return canvas;
    }

    private createDefaultImageAnnotation(): Shape[] {
        return [
            {
                x: 0.5 * this.imageDpi,
                y: 0.5 * this.imageDpi,
                width: (this.imageLengthInInches - 1) * this.imageDpi,
                height: (this.imageHeightInInches - 1) * this.imageDpi,
                backgroundColor: [255, 255, 255],
                text: "",
                fontSize: 96,
                textAlign: "center",
                disableDuplicate: true,
                disableErase: true,
                disableFlip: true,
                disableManipulate: true,
                disableMove: true,
                disableRemove: true,
                disableReorder: true,
                disableResize: true,
                disableRotate: true,
                disableStyle: ["strokeWidth", "strokeColor"],
                disableTextLayout: true,
                isEditing: true,
                isSelected: true
            }
        ];
    }

    private setEditorContentFromHistory(
        editor: PinturaEditorModal,
        history: any[]
    ) {
        setTimeout(() => {
            editor.history.set(history);
            editor.imageAnnotation[0].isSelected = true;
            editor.imageAnnotation[0].isEditing = true;
        }, 1000);
    }

    private async processEditor(
        editor: PinturaEditorModal,
        dest: any
    ): Promise<void> {
        const text = editor.imageAnnotation[0].text;

        if (!text || !text.trim()) {
            await this.clearMessage();
            return;
        }

        const webPath = URL.createObjectURL(dest);
        const base64Data = await this.photoService.getBase64Image(webPath);
        const dimensions =
            await this.photoService.getImageDimensions(base64Data);

        this.message = {
            id: "",
            filepath: "",
            data: base64Data,
            dimension: dimensions,
            source: PhotoSource.TextEditor,
            ratio: this.imageRatio,
            history: editor.history.get()
        };

        await this.selectPhotosWorkflowService.storeMessagePhoto(this.message);
        await this.cleverTapService.recordCartItemEvent(
            "Item Added to Cart",
            this.messageProduct.Sku,
            this.messageProduct.Name,
            undefined,
            this.messageProduct?.ProductPrice?.PriceValue,
            this.messageProduct?.ProductPrice?.PriceValue,
            1,
            this.facility,
            this.contact
        );
    }
}
