import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-personalization-greetings",
  templateUrl: "./personalization-greetings.component.html",
  styleUrls: ["./personalization-greetings.component.scss"]
})
export class PersonalizationGreetingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
