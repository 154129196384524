import { Injectable } from "@angular/core";
import { ICreateOrderRequest } from "ngx-paypal";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { BaseReqBody } from "src/app/shared/model/BaseResponse";
import { PAYPAL_CLIENT_ID } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class PaypalService {

    constructor(
        private checkoutService: CheckoutService
    ) { }

    public initializeButtons(
        total: string
    ) {
        return {
            currency: 'USD',
            clientId: PAYPAL_CLIENT_ID,
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'AUTHORIZE',
                purchase_units: [{
                    amount: {
                        value: total
                    }
                }]
            },
            onClientAuthorization: (data) => {
                //data.payer.address
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);

            },
            onError: err => {
                console.log('OnError', err);
            },
        }
    }

    public async savePaymentInfo(
        orderId: string,
        orderTotal: string
    ): Promise<void> {
        const reqBody = new BaseReqBody();
        const formValues = [];
        formValues.push({
            Key: "PayPal order ID",
            Value: orderId
        });
        formValues.push({
            Key: "orderTotal",
            Value: this.parseOrderTotal(orderTotal)
        });
        reqBody.FormValues = formValues;
        await this.checkoutService.savePaymentInfoWithApi(reqBody).toPromise();
    }

    public parseOrderTotal(orderTotal: string): number {
        if (!orderTotal) {
            throw Error("Cannot parse null or undefined order total string");
        }

        return parseFloat(orderTotal.substring(1));
    }
}
