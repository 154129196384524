import { Injectable } from "@angular/core";
import { Device } from "@capacitor/device";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { User } from "@codetrix-studio/capacitor-google-auth";

@Injectable({
    providedIn: "root"
})
export class GoogleAuthService {
    public async initialize() {
        const deviceInfo = await Device.getInfo();
        if (deviceInfo.platform == "web") {
            GoogleAuth.initialize({
                scopes: [
                    "https://www.googleapis.com/auth/photoslibrary.readonly"
                ]
            });
        }
    }

    public async signIn(): Promise<User> {
        return await GoogleAuth.signIn();
    }

    public async getOAuthToken(): Promise<string> {
        let token: string;

        // Attempt to refresh token if user is already logged in with Google
        try {
            const response = await GoogleAuth.refresh();
            token = response?.accessToken;
        } catch {
            token = null;
        }

        // If no token found, sign in
        if (!token) {
            const googleUser = await GoogleAuth.signIn();
            token = googleUser?.authentication?.accessToken;
        }

        if (!token) {
            throw new Error("Could not log in to Google");
        }

        return token;
    }
}
