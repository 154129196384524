import { Injectable } from "@angular/core";
import { IOrderDetails } from "ngx-paypal";
import { Observable } from "rxjs";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import { CurrentPaymentInfoModel } from "src/app/shared/model/currentPaymentInfoModel";
import { ShippingOptionListModel } from "src/app/shared/model/more/shipping-option-model";
import { LineItem } from "src/app/shared/model/order";
import { UploadedPhotoListModel } from "src/app/shared/model/photos/uploaded-photo-model";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class PelipostCheckoutService {
    constructor(private baseService: BasePelipostService) {}

    setShippingAddressToCurrentFacility(): Observable<object> {
        const url = `${this.baseService.BASE_PEL_URL}checkout/savefacilityshippingaddress`;
        return this.baseService.postRequest(url, null);
    }

    getCurrentPaymentInfo(): Observable<CurrentPaymentInfoModel> {
        const url = `${this.baseService.BASE_PEL_URL}checkout/getcurrentpaymentinfo`;
        return this.baseService.getRequest(url);
    }

    getCartLineItems(): Observable<LineItem[]> {
        const url = `${this.baseService.BASE_PEL_URL}checkout/getcartlineitems`;
        return this.baseService.getRequest(url);
    }

    createPaypalOrderRequest(): Observable<IOrderDetails> {
        const url = `${this.baseService.BASE_PEL_URL}checkout/createpaypalorderrequest`;
        return this.baseService.postRequest(url, null);
    }

    getUploadedPhotosInCart(): Observable<
        BaseResponseModel<UploadedPhotoListModel>
    > {
        const url = `${this.baseService.BASE_PEL_URL}checkout/getuploadedphotosincart`;
        return this.baseService.getRequest(url);
    }

    getShippingOptions(): Observable<
        BaseResponseModel<ShippingOptionListModel>
    > {
        const url = `${this.baseService.BASE_PEL_URL}checkout/getshippingoptions`;
        return this.baseService.getRequest(url);
    }
}
