import { Injectable } from "@angular/core";
import { ContactService } from "./pelipost-service-proxies/contact.service";

@Injectable({
    providedIn: "root"
})
export class HomeScreenService {
    constructor(private contactService: ContactService) {}

    async showAlternateHomeScreen(): Promise<boolean> {
        const contactsRes = await this.contactService
            .getAllContacts()
            .toPromise();

        return !contactsRes?.Data || contactsRes.Data.length < 2;
    }
}
