<section
  [@visibilityChanged]
  class="d-flex justify-content-center align-items-center h-100 text-center modal"
>
  <div class="modal-body w-100">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <h5 class="ion-no-margin text-left text-uppercase">{{ photoSource }} Photos</h5>
        </ion-col>
        <ion-col size="auto">
          <ion-icon class="dismiss" name="close-circle-outline" size="large" (click)="dismiss()"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-content scroll-y="true" style="height:300px">
      <ion-text *ngIf="loaded && (!selectablePhotos || !selectablePhotos.length)" class="text-center">
        No photos
      </ion-text>
      <ion-grid>
        <ion-row>
          <ion-col *ngFor="let photo of selectablePhotos" size="4">
            <ion-img [ngClass]="{'selected':photo.selected, 'disabled':!photo.selected && photoLimitReached}" [src]="photo.data" (click)="toggleSelectPhoto(photo)"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <div class="buttons">
      <ion-button expand="block" class="mb-3" (click)="dismiss()">
        Save
      </ion-button>
    </div>
  </div>
</section>
