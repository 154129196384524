import { CustomProperties } from "../BaseResponse";

// Login get response data && login post request body
export interface LoginGetResData {
    CheckoutAsGuest: boolean;
    Email?: string;
    UsernamesEnabled: boolean;
    RegistrationType: number;
    Username?: string;
    Password?: string;
    RememberMe: boolean;
    DisplayCaptcha: boolean;
    CustomProperties: CustomProperties;
}

// Login get response
export interface LoginGetRes {
    Data: LoginGetResData;
    Message?: string;
    ErrorList: string[];
}

// Login post response data
export interface LoginPostRes {
    Data: {
        CustomerInfo: CustomerInfo;
        Token: string;
    };
    Message?: string;
    ErrorList: string[];
}

// Password recovery post body
export interface PasswordRecoveryPostBody {
    Email: string;
    Result: string;
    DisplayCaptcha: boolean;
    CustomProperties: CustomProperties;
}

// Password recovery post body response
export interface PasswordRecoveryPostRes {
    Data: PasswordRecoveryPostBody;
    Message?: string;
    ErrorList: string[];
}

export interface PasswordRecoveryConfirmRes {
    Data: PasswordRecoveryResetPostBody;
    Message?: string;
    ErrorList: string[];
}

export interface PasswordRecoveryResetPostBody {
    NewPassword: string;
    ConfirmNewPassword: string;
    DisablePasswordChanging: boolean;
    Result: string;
}

// Interface for available coutries, available states, available time zones
export interface DropDown {
    Disabled: boolean;
    Group?: any;
    Selected: boolean;
    Text: string;
    Value: string;
}

export interface CustomerAttributeValue {
    Id: number;
    Name: string;
    IsPreSelected: boolean;
}

export interface CustomerAttribute {
    Name: string;
    IsRequired: boolean;
    DefaultValue?: any;
    AttributeControlType: number;
    Values: CustomerAttributeValue[];
    Id: number;
    CustomProperties: CustomProperties;
}

export interface CustomerInfo {
    Email: string;
    EmailToRevalidate?: any;
    CheckUsernameAvailabilityEnabled: boolean;
    AllowUsersToChangeUsernames: boolean;
    UsernamesEnabled: boolean;
    Username: string;
    GenderEnabled: boolean;
    Gender: string;
    FirstName: string;
    LastName: string;
    DateOfBirthEnabled: boolean;
    DateOfBirthDay?: number;
    DateOfBirthMonth?: number;
    DateOfBirthYear?: number;
    DateOfBirthRequired: boolean;
    CompanyEnabled: boolean;
    CompanyRequired: boolean;
    Company?: any;
    StreetAddressEnabled: boolean;
    StreetAddressRequired: boolean;
    StreetAddress?: any;
    StreetAddress2Enabled: boolean;
    StreetAddress2Required: boolean;
    StreetAddress2?: any;
    ZipPostalCodeEnabled: boolean;
    ZipPostalCodeRequired: boolean;
    ZipPostalCode?: any;
    CityEnabled: boolean;
    CityRequired: boolean;
    City?: any;
    CountyEnabled: boolean;
    CountyRequired: boolean;
    County?: any;
    CountryEnabled: boolean;
    CountryRequired: boolean;
    CountryId: number;
    AvailableCountries: DropDown[];
    StateProvinceEnabled: boolean;
    StateProvinceRequired: boolean;
    StateProvinceId: number;
    AvailableStates: DropDown[];
    PhoneEnabled: boolean;
    PhoneRequired: boolean;
    Phone: string;
    FaxEnabled: boolean;
    FaxRequired: boolean;
    Fax?: any;
    NewsletterEnabled: boolean;
    Newsletter: boolean;
    SignatureEnabled: boolean;
    Signature?: any;
    TimeZoneId?: any;
    AllowCustomersToSetTimeZone: boolean;
    AvailableTimeZones: DropDown[];
    VatNumber?: any;
    VatNumberStatusNote: string;
    DisplayVatNumber: boolean;
    AssociatedExternalAuthRecords: any[];
    NumberOfExternalAuthenticationProviders: number;
    AllowCustomersToRemoveAssociations: boolean;
    CustomerAttributes: CustomerAttribute[];
    GdprConsents: any[];
    CustomProperties: CustomProperties;
}

export class ExternalAuthenticationParameters {
    providerSystemName: string;
    externalIdentifier: string;
    externalDisplayIdentifier?: string;
    accessToken: string;
    email?: string;
}


export class ImportOldUserModel extends ExternalAuthenticationParameters {
    password?: string;
}
