import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BlobUploadCommonResponse, BlockBlobClient } from "@azure/storage-blob";
import { image } from "@rxweb/reactive-form-validators";
import { forkJoin, from, iif, Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { BaseResponseModel } from "src/app/shared/model/BaseResponse";
import {
    AzurePhotoUploadResponse,
    AzureUploadType
} from "src/app/shared/model/photos/azure-photo-models";
import { ProductDetail } from "src/app/shared/model/product-detail/product-detail";
import { BasePelipostService } from "../base.pelipost.service";
import { PelipostPhoto, PhotoService, PhotoSource } from "../photos.service";

@Injectable({
    providedIn: "root"
})
export class AzurePhotoService {
    constructor(
        private baseService: BasePelipostService,
        private photoService: PhotoService
    ) {}

    public async uploadProductPhoto(photo: PelipostPhoto): Promise<
        Observable<
            [
                {
                    sasResponse: BaseResponseModel<AzurePhotoUploadResponse>;
                    uploadResponse: BlobUploadCommonResponse;
                    mimeType: string;
                },
                {
                    sasResponse: BaseResponseModel<AzurePhotoUploadResponse>;
                    uploadResponse: BlobUploadCommonResponse;
                    mimeType: string;
                },
                {
                    sasResponse: BaseResponseModel<AzurePhotoUploadResponse>;
                    uploadResponse: BlobUploadCommonResponse;
                    mimeType: string;
                }
            ]
        >
    > {
        const uploadImage$ = this.uploadImageToAzure(
            photo.data,
            AzureUploadType.Image
        );

        const uploadThumbnail$ = this.uploadImageToAzure(
            photo.thumbnailData,
            AzureUploadType.Thumbnail
        );

        let originalData = null;
        if (photo.source !== PhotoSource.TextEditor && photo.history?.length) {
            originalData = await this.photoService.getOriginalPhotoData(
                photo.id,
                photo.source
            );

            //HACK: ensuring we only upload base64 data
            if (!originalData?.startsWith("data:")) {
                const originalDimensions =
                    await this.photoService.getImageDimensions(originalData);
                const orgBase64 = await this.photoService.getBase64Image(
                    originalData
                );
                originalData = await this.photoService.getJpegData(
                    orgBase64,
                    originalDimensions.width,
                    originalDimensions.height
                );
            }
        }

        const uploadSource$ = iif(
            // If original data starts with http, it was already uploaded.
            // This can happen when a user switches devices in the middle of
            // an order or goes back and re-edits an already edited photo.
            () => !!originalData && !originalData.startsWith("http"),
            this.uploadImageToAzure(originalData, AzureUploadType.Original),
            of(null)
        );

        const createDownload$ = forkJoin([
            uploadImage$,
            uploadThumbnail$,
            uploadSource$
        ]).pipe(
            concatMap((results) =>
                this.createDownloadEntity(
                    results[0].sasResponse.Data.DownloadGUID,
                    results[0].sasResponse.Data.BlobUri,
                    photo.filepath,
                    results[0].mimeType,
                    results[1]?.sasResponse.Data.BlobUri,
                    results[2]?.sasResponse.Data.BlobUri
                ).pipe(map(() => results))
            )
        );

        return createDownload$;
    }

    public uploadImageToAzure(
        photoData: string,
        uploadType: AzureUploadType
    ): Observable<{
        sasResponse: BaseResponseModel<AzurePhotoUploadResponse>;
        uploadResponse: BlobUploadCommonResponse;
        mimeType: string;
    }> {
        const mimeType = this.photoService.getMimeType(photoData);
        return this.getBlobSasUrl(mimeType, uploadType).pipe(
            concatMap((sasResponse) =>
                this.uploadToBlockBlobClient(
                    sasResponse.Data.BlobUri,
                    sasResponse.Data.SasQueryClause,
                    photoData,
                    mimeType
                ).pipe(
                    map((uploadResponse) => ({
                        sasResponse,
                        uploadResponse,
                        mimeType
                    }))
                )
            )
        );
    }

    private getBlobSasUrl(
        mimeType: string,
        uploadType: AzureUploadType
    ): Observable<BaseResponseModel<AzurePhotoUploadResponse>> {
        const uploadTypeAsString = uploadType.toString();
        const params = new HttpParams({
            fromObject: {
                mimeType,
                uploadType: uploadTypeAsString
            }
        });
        return this.baseService.getRequestWithParam(
            `${this.baseService.BASE_PEL_URL}azurephoto`,
            params
        );
    }

    private uploadToBlockBlobClient(
        blobUri: string,
        sasQueryClause: string,
        data: string,
        mimeType: string
    ): Observable<BlobUploadCommonResponse> {
        return from(fetch(data)).pipe(
            concatMap((fetch64) => from(fetch64.blob())),
            concatMap((blob) => {
                const blockBlobClient = new BlockBlobClient(
                    blobUri + sasQueryClause
                );
                return from(
                    blockBlobClient.uploadData(blob, {
                        blobHTTPHeaders: { blobContentType: mimeType }
                    })
                );
            })
        );
    }

    private createDownloadEntity(
        downloadGuid: string,
        blobUrl: string,
        filename: string,
        mimeType: string,
        thumbnailBlobUrl: string,
        sourceBlobUrl: string
    ): Observable<BaseResponseModel<void>> {
        return this.baseService.postRequest(
            `${this.baseService.BASE_PEL_URL}azurephoto/${downloadGuid}`,
            {
                blobUrl,
                filename,
                mimeType,
                thumbnailBlobUrl,
                sourceBlobUrl
            }
        );
    }
}
