<ion-header>
    <app-page-header>
        <app-contact-identity
            [contact]="contact"
            [facility]="facility"
            [isHeader]="true"
        ></app-contact-identity>
    </app-page-header>
</ion-header>

<ion-content>
    <app-personalization-photos></app-personalization-photos>
</ion-content>
