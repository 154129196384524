<h5 class="ion-text-uppercase">Order Details</h5>
<ion-grid class="ion-no-padding">
    <ion-row class="mb-1" *ngFor="let item of lineItems">
        <ion-col size="1" class="ion-no-padding">
            {{ item.Quantity }}
        </ion-col>
        <ion-col class="ion-no-padding">
            <div class="ion-text-uppercase">{{ item.Name }}</div>
            <ion-text *ngIf="item.DiscountAmount > 0" color="success">{{ item.FormattedDiscountPercentage }} discount</ion-text>
        </ion-col>
        <ion-col size="3" class="ion-no-padding text-right">
            <div>{{ item.FormattedPriceBeforeDiscount || (item.PriceBeforeDiscount | currency) }}</div>
            <ion-text *ngIf="item.DiscountAmount > 0" color="success">-{{ item.FormattedDiscountAmount }}</ion-text>
        </ion-col>
    </ion-row>
    <div *ngIf="!lineItems?.length">
        <ion-row class="mb-1" *ngFor="let i of [1, 2]">
            <ion-col size="1" class="ion-no-padding">
                <ion-skeleton-text animated class="large w-75"></ion-skeleton-text>
            </ion-col>
            <ion-col class="ion-no-padding">
                <ion-skeleton-text animated class="large w-75"></ion-skeleton-text>
            </ion-col>
            <ion-col size="3" class="ion-no-padding">
                <ion-skeleton-text animated class="large w-75 ml-auto"></ion-skeleton-text>
            </ion-col>
        </ion-row>
    </div>
    <div class="py-2 my-2 border-top border-bottom order-totals">
        <ion-row class="ion-text-uppercase font-weight-bolder text-small">
            <ion-col class="ion-no-padding">Subtotal</ion-col>
            <ion-col class="ion-no-padding text-right">
                <ion-text *ngIf="orderTotals?.SubTotal">
                    {{ orderTotals.SubTotal }}
                </ion-text>
                <ion-skeleton-text animated *ngIf="!orderTotals?.SubTotal" class="w-25 ml-auto"></ion-skeleton-text>
            </ion-col>
        </ion-row>
        <ion-row class="mt-1" *ngIf="orderTotals?.SubTotalDiscount">
            <ion-col class="ml-2">
                <ion-text color="success">Discount Applied - {{ subTotalDiscountPercentage | percent }}</ion-text>
            </ion-col>
            <ion-col class="ion-no-padding text-right">
                <ion-text color="success">
                    {{ orderTotals.SubTotalDiscount | discountPrice }}
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="mt-1">
            <ion-col class="ml-2">
                <ion-text>{{ shippingMethod ? shippingMethod.DisplayName : orderTotals?.SelectedShippingMethod }} Shipping</ion-text>
            </ion-col>
            <ion-col class="text-right">
                <ion-text *ngIf="orderTotals?.Shipping">
                    {{
                        orderTotals.Shipping === "$0.00"
                            ? "FREE"
                            : orderTotals.Shipping
                    }}
                </ion-text>
                <ion-skeleton-text animated *ngIf="!orderTotals?.Shipping" class="w-25 ml-auto medium"></ion-skeleton-text>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="shippingMethod">
            <ion-col class="ml-3">
                <small>{{shippingMethod | arrivalText}}</small>
            </ion-col>
        </ion-row>
        <ion-row class="mt-1">
            <ion-col class="ml-2">
                <ion-text>Sales Tax</ion-text>
            </ion-col>
            <ion-col class="text-right">
                <ion-text *ngIf="orderTotals?.Tax">{{ orderTotals.Tax }}</ion-text>
                <ion-skeleton-text animated *ngIf="!orderTotals?.Tax" class="w-25 ml-auto medium"></ion-skeleton-text>
            </ion-col>
        </ion-row>
    </div>
    <ion-row class="order-totals font-weight-bold" *ngIf="orderTotalBeforeCredits">
        <ion-col>
            <ion-text>Order total</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding text-right">
            <ion-text>
                {{ orderTotalBeforeCredits | currency }}
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row class="mt-1 order-totals" *ngIf="orderTotals?.OrderTotalDiscount">
        <ion-col>
            <ion-text color="success">Discount Applied - {{ orderTotalDiscountPercentage | percent }}</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding text-right">
            <ion-text color="success">
                {{ orderTotals.OrderTotalDiscount | discountPrice }}
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row class="mt-1 order-totals" *ngIf="orderTotals?.RedeemedRewardPoints && orderTotals?.RedeemedRewardPointsAmount">
        <ion-col>
            <ion-text color="success">PeliPoints Applied - {{ orderTotals.RedeemedRewardPoints }}</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding text-right">
            <ion-text color="success">
                {{ orderTotals.RedeemedRewardPointsAmount | discountPrice }}
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row class="mt-1 order-totals" *ngIf="pelipalsDiscount">
        <ion-col>
            <ion-text color="success">Pay with PeliPals</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding text-right">
            <ion-text color="success">
                {{ pelipalsDiscount | discountPrice }}
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row class="mt-1 font-weight-bold order-totals">
        <ion-col class="ion-no-padding">
            <ion-text [ngClass]="{'ion-text-uppercase': !orderTotalBeforeCredits }">{{ !!orderTotalBeforeCredits ? 'Total due at checkout' : 'Total' }}</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding text-right">
            <ion-text *ngIf="orderTotals?.OrderTotal">{{ orderTotals.OrderTotal }}</ion-text>
            <ion-skeleton-text animated *ngIf="!orderTotals?.OrderTotal" class="w-25 ml-auto medium"></ion-skeleton-text>
        </ion-col>
    </ion-row>
</ion-grid>
