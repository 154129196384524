import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { EditContactPage } from "./pages/edit-contact/edit-contact.page";
import { MyContactsPage } from "./pages/my-contacts/my-contacts.page";
import { AdditionalInfoPage } from "./pages/new-contact/additional-info/additional-info.page";
import { ConfirmPage } from "./pages/new-contact/confirm/confirm.page";
import { ContactTypePage } from "./pages/new-contact/contact-type/contact-type.page";
import { FacilityPage } from "./pages/new-contact/facility/facility.page";
import { InmateIdPage } from "./pages/new-contact/inmate-id/inmate-id.page";
import { NamePage } from "./pages/new-contact/name/name.page";
import { PelipalsIdPage } from "./pages/new-contact/pelipals-id/pelipals-id.page";
import { PhotoPage } from "./pages/new-contact/photo/photo.page";
const routes: Routes = [
    {
        path: "contacts",
        canActivate: [AuthGuard],
        children: [
            {
                path: "my-contacts",
                component: MyContactsPage
            },
            {
                path: "edit-contact",
                children: [
                    {
                        path: ":contactid",
                        component: EditContactPage
                    },
                    {
                        path: ":contactid/facility",
                        component: FacilityPage
                    }
                ]
            },
            {
                path: "new-contact",
                children: [
                    {
                        path: "contact-type",
                        component: ContactTypePage
                    },
                    {
                        path: "name",
                        component: NamePage
                    },
                    {
                        path: "pelipals-id",
                        component: PelipalsIdPage
                    },
                    {
                        path: "id",
                        component: InmateIdPage
                    },
                    {
                        path: "facility",
                        component: FacilityPage
                    },
                    {
                        path: "photo",
                        component: PhotoPage
                    },
                    {
                        path: "confirm",
                        component: ConfirmPage
                    },
                    {
                        path: "additional-info",
                        component: AdditionalInfoPage
                    },
                    {
                        path: "",
                        redirectTo: "contact-type",
                        pathMatch: "full"
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContactsRoutingModule {}
