import { StorageService } from "../services";
import { AppStartService } from "../services/app-start.service";

export const configurationFactory = (
    appStartService: AppStartService,
    storageService: StorageService
) => {
    return async (): Promise<void> => {
        await storageService.init();
        await appStartService.load();
    };
};
