import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { AngularPinturaModule } from "@pqina/angular-pintura";
import { LuxonModule } from "luxon-angular";
import { StripCurrencyFormattingPipe } from "src/app/shared/pipes/strip-currency-formatting.pipe";
import { SharedModule } from "src/app/shared/shared.module";
import { GalleryPhotoModalComponent } from "./components/gallery-photo-modal/gallery-photo-modal.component";
import { OrderDetailsComponent } from "./components/order-details/order-details.component";
import { OrderOverviewComponent } from "./components/order-overview/order-overview.component";
import { PhotosGalleryComponent } from "./components/photos-gallery/photos-gallery.component";
import { PromoCodeModalComponent } from "./components/promo-code/promo-code-modal.component";
import { SelectedPhotoComponent } from "./components/selected-photo/selected-photo.component";
import { ThumbnailsComponent } from "./components/thumbnails/thumbnails.component";
import { OrderRoutingModule } from "./order-routing.module";
import { CardPaymentInfoPage } from "./pages/checkout/card-payment-info/card-payment-info.page";
import { ConfirmationPage } from "./pages/checkout/confirmation/confirmation.page";
import { DeliveryAssurancePage } from "./pages/checkout/delivery-assurance/delivery-assurance.page";
import { EditCreditCardPage } from "./pages/checkout/edit-credit-card/edit-credit-card.page";
import { FacilityRulesPage } from "./pages/checkout/facility-rules/facility-rules.page";
import { OrderSummaryPage } from "./pages/checkout/order-summary/order-summary.page";
import { ReviewOrderPage } from "./pages/checkout/review-order/review-order.page";
import { SelectPaymentMethodPage } from "./pages/checkout/select-payment-method/select-payment-method.page";
import { MyOrdersPage } from "./pages/my-orders/my-orders.page";
import { OrderStatusPage } from "./pages/order-status/order-status.page";
import { AddMessagePage } from "./pages/photos/add-message/add-message.page";
import { PrintPreviewPage } from "./pages/photos/print-preview/print-preview.page";
import { SelectPhotosPage } from "./pages/photos/select-photos/select-photos.page";
import { UploadingPage } from "./pages/photos/uploading/uploading.page";
import { PersonalizationBooksComponent } from "./pages/select-product/personalization-books/personalization-books.component";
import { PersonalizationGreetingsComponent } from "./pages/select-product/personalization-greetings/personalization-greetings.component";
import { PersonalizationPhotosComponent } from "./pages/select-product/personalization-photos/personalization-photos.component";
import { PersonalizationPostcardsComponent } from "./pages/select-product/personalization-postcards/personalization-postcards.component";
import { SelectProductPage } from "./pages/select-product/select-product.page";
import { CheckoutWorkflowService } from "./services/checkout-workflow.service";
import { SelectPhotosWorkflowService } from "./services/select-photos-workflow.service";
import { NgxPayPalModule } from "ngx-paypal";
import { AndroidMultiselectModalComponent } from "./components/android-multiselect-modal/android-multiselect-modal.component";

@NgModule({
    declarations: [
        GalleryPhotoModalComponent,
        PhotosGalleryComponent,
        SelectedPhotoComponent,
        ThumbnailsComponent,
        SelectProductPage,
        PersonalizationBooksComponent,
        PersonalizationGreetingsComponent,
        PersonalizationPhotosComponent,
        PersonalizationPostcardsComponent,
        PromoCodeModalComponent,
        OrderOverviewComponent,
        FacilityRulesPage,
        SelectPhotosPage,
        PrintPreviewPage,
        UploadingPage,
        DeliveryAssurancePage,
        OrderSummaryPage,
        AddMessagePage,
        SelectPaymentMethodPage,
        CardPaymentInfoPage,
        ConfirmationPage,
        OrderDetailsComponent,
        MyOrdersPage,
        OrderStatusPage,
        ReviewOrderPage,
        EditCreditCardPage,
        AndroidMultiselectModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        OrderRoutingModule,
        SharedModule,
        AngularPinturaModule,
        LuxonModule,
        NgxPayPalModule
    ],
    providers: [
        {
            provide: SelectPhotosWorkflowService,
            useClass: SelectPhotosWorkflowService
        },
        { provide: CheckoutWorkflowService, useClass: CheckoutWorkflowService },
        StripCurrencyFormattingPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // HACK: Required for swiperjs components
})
export class OrderModule {}
