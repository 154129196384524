import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { AnalyticsService } from "./core/services/analytics.service";
import { StorageService } from "./core/services";
import { AppStartService } from "./core/services/app-start.service";
import { GlobalErrorHandler } from "./core/services/global-error-handler.service";
import { configurationFactory } from "./core/util/configurationFactory";
import { SharedModule } from "./shared/shared.module";
import { GlobalHttpInterceptorService } from "./core/services/service-proxies/GlobalHttpInterceptorService";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        IonicStorageModule.forRoot(),
        CoreModule,
        SharedModule
    ],
    providers: [
        StorageService,
        {
            provide: APP_INITIALIZER,
            useFactory: configurationFactory,
            deps: [AppStartService, StorageService],
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: GlobalHttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
            deps: [AnalyticsService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
