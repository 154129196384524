import { Injectable } from "@angular/core";
import { BasePelipostService } from "../base.pelipost.service";

@Injectable({
    providedIn: "root"
})
export class PelipostProductService {
    constructor(private baseService: BasePelipostService) {}

    fetchRelatedProducts(productId: number) {
        const url =
            this.baseService.BASE_PEL_URL +
            `product/relatedproducts/${productId}`;
        return this.baseService.getRequest(url);
    }
}
