import { EventEmitter, Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { LoginPostRes } from "src/app/shared/model/account/login";
import { AnalyticsService, EventTag } from "./analytics.service";
import { AuthService } from "./auth.service";
import { GlobalHttpInterceptorService } from "./service-proxies/GlobalHttpInterceptorService";
import { StorageService } from "./storage.service";

export interface LoginEvent {
    res: LoginPostRes;
    method: string;
}

@Injectable({
    providedIn: "root"
})
export class LoginWorkflowService {
    loginEvent = new EventEmitter<LoginEvent>();
    loginFailedEvent = new EventEmitter<string>();
    logoutEvent = new EventEmitter<void>();
    signUpEvent = new EventEmitter<LoginEvent>();
    signUpFailedEvent = new EventEmitter<string>();

    constructor(
        private analyticsService: AnalyticsService,
        private storageService: StorageService,
        private authService: AuthService,
        private navCtrl: NavController,
        httpinterceptor: GlobalHttpInterceptorService
    ) {
        httpinterceptor.onUnauthorizedError.subscribe(() => {
            this.logoutRedirect();
        });
        httpinterceptor.onTokenRefreshed.subscribe((token) => {
            this.updateToken(token);
        });
    }

    loginFailed(method: string): void {
        this.loginFailedEvent.emit(method);
    }

    async loginRedirect(res: LoginPostRes, method: string) {
        await this.storeToken(res);
        await this.analyticsService.logEvent(EventTag.Login, {
            method: method
        });
        await this.navCtrl.navigateRoot("/home");
        this.loginEvent.emit({ res, method });
    }

    async signupRedirect(res: LoginPostRes, method: string) {
        await this.storeToken(res);
        const customerInfo = res.Data.CustomerInfo;
        await this.analyticsService.setUser(
            customerInfo.UsernamesEnabled && customerInfo.Username
                ? customerInfo.Username
                : customerInfo.Email
        );
        if (customerInfo.TimeZoneId) {
            await this.analyticsService.setProperty(
                "timezone_id",
                customerInfo.TimeZoneId
            );
        }
        await this.analyticsService.logEvent(EventTag.SignUp, {
            method: method
        });
        await this.navCtrl.navigateRoot("/sign-up/add-contact");
        this.signUpEvent.emit({ res, method });
    }

    signUpFailed(method: string): void {
        this.signUpFailedEvent.emit(method);
    }

    async logoutRedirect() {
        await this.clearToken();
        await this.navCtrl.navigateRoot("/welcome");
        this.logoutEvent.emit();
    }

    private async storeToken(res: LoginPostRes) {
        await this.storageService.setObject(StorageService.KEY_TOKEN, {
            val: res.Data.Token
        });
        await this.storageService.setObject(StorageService.IS_LOGGED_IN, {
            val: true
        });
        await this.storageService.setObject(
            StorageService.CUSTOMER_INFO,
            res.Data.CustomerInfo
        );
        this.authService.isLoggedIn = true;
        this.authService.token = res.Data.Token;
        this.authService.customer = res.Data.CustomerInfo;
    }

    private async updateToken(token: string) {
        await this.storageService.setObject(StorageService.KEY_TOKEN, {
            val: token
        });
        this.authService.token = token;
    }

    private async clearToken() {
        await this.storageService.removeItem(StorageService.KEY_TOKEN);
        await this.storageService.setObject(StorageService.IS_LOGGED_IN, false);
        await this.storageService.removeItem(StorageService.CUSTOMER_INFO);
        this.authService.isLoggedIn = false;
        this.authService.token = "";
        this.authService.customer = undefined;
    }
}
