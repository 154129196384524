import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {
    ModalController,
    NavController,
    ToastController
} from "@ionic/angular";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { LoadingService } from "src/app/core/services";
import { AccountInfoService } from "src/app/core/services/service-proxies/account/account-info.service";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.page.html",
    styleUrls: ["./change-password.page.scss"]
})
export class ChangePasswordPage extends BasePage implements OnInit {
    public changePasswordForm: UntypedFormGroup;
    public showOldPassword: boolean = false;
    public showPassword: boolean = false;
    public showConfirmPassword: boolean = false;
    get oldPassword() {
        return this.changePasswordForm.get("oldPassword");
    }
    get newPassword() {
        return this.changePasswordForm.get("password");
    }
    get confirmPassword() {
        return this.changePasswordForm.get("confirmPassword");
    }

    constructor(
        private accountInfoService: AccountInfoService,
        private formBuilder: UntypedFormBuilder,
        private loadingService: LoadingService,
        private modalCtrl: ModalController,
        private navController: NavController,
        private toastController: ToastController
    ) {
        super();
    }

    ngOnInit(): void {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: [null, Validators.required],
            password: [null, Validators.required],
            confirmPassword: [
                "",
                RxwebValidators.compare({ fieldName: "password" })
            ]
        });
    }

    async save(): Promise<void> {
        await this.loadingService.show();
        try {
            await this.accountInfoService
                .postChangePassword({
                    OldPassword: this.oldPassword?.value,
                    NewPassword: this.newPassword?.value,
                    ConfirmNewPassword: this.confirmPassword?.value
                })
                .toPromise();

            await this.showToast(
                this.toastController,
                "Account saved successfully!"
            );
            this.navController.back();
        } catch (error) {
            await this.showErrorModal(this.modalCtrl, error);
        } finally {
            await this.loadingService.dismiss();
        }
    }

    cancel() {
        this.navController.back();
    }

    toggleOldPassword() {
        this.showOldPassword = !this.showOldPassword;
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    toggleConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }
}
