/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { CartService, LoadingService } from "src/app/core/services";
import { UserSegmentService } from "src/app/core/services/user-segment.service";
import { PelipostCheckoutService } from "src/app/core/services/pelipost-service-proxies/pelipost-checkout.service";
import { CheckoutService } from "src/app/core/services/service-proxies/checkout.service";
import { CartData, OrderTotals } from "src/app/shared/model/cart";
import { CurrentPaymentInfoModel } from "src/app/shared/model/currentPaymentInfoModel";
import { LineItem } from "src/app/shared/model/order";
import { BasePage } from "src/app/shared/pages/BasePage";
import { Constant } from "src/app/util/constant";
import { CheckoutWorkflowService } from "../../../services/checkout-workflow.service";
import { Subject, forkJoin } from "rxjs";
import { delay, filter, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
    selector: "app-review-order",
    templateUrl: "./review-order.page.html",
    styleUrls: ["./review-order.page.scss"]
})
export class ReviewOrderPage extends BasePage implements OnInit, OnDestroy {
    cardInfo: CurrentPaymentInfoModel;
    cardIconSource: string;
    cartData: CartData;
    lineItems: Array<LineItem>;
    orderTotals: OrderTotals;
    isPaypal: boolean;
    disableButton = false;

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        private loadingService: LoadingService,
        private pelipostCheckoutService: PelipostCheckoutService,
        private checkoutWorkflowService: CheckoutWorkflowService,
        private cartService: CartService,
        private navController: NavController,
        private checkoutService: CheckoutService,
        private userSegmentService: UserSegmentService,
        private modalController: ModalController
    ) {
        super();
    }

    ngOnInit(): void {
        this.cartService.cart$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                tap((c) => {
                    this.cartData = c;
                    this.populateOrderData();
                })
            )
            .subscribe();

        forkJoin([
            this.cartService.fetchCartRes(),
            this.pelipostCheckoutService.getCartLineItems(),
            this.checkoutService.getConfirmOrderSummary()
        ])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                tap(([_, lineItems, summary]) => {
                    this.lineItems = lineItems;
                    this.isPaypal =
                        summary.Data.Cart.OrderReviewData.PaymentMethod.indexOf(
                            "PayPal"
                        ) >= 0;
                }),
                filter(() => !this.isPaypal),
                switchMap(() =>
                    this.pelipostCheckoutService.getCurrentPaymentInfo()
                ),
                tap((cardInfo) => (this.cardInfo = cardInfo))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    private populateOrderData(): void {
        if (!this.cartData?.OrderTotals?.OrderTotal) {
            return;
        }

        this.orderTotals = this.cartData.OrderTotals;
    }

    public async editPaymentMethod(): Promise<void> {
        await this.navController.navigateForward([
            "/order/payment/edit-credit-card",
            this.cardInfo.CreditCardToken
        ]);
    }

    public async completeOrder(): Promise<void> {
        this.disableButton = true;
        await this.loadingService.show();
        try {
            const orderCompleteRes =
                await this.checkoutWorkflowService.confirmOrder();
            await this.userSegmentService.logOrderConfirmedEvent();
            await this.navController.navigateRoot([
                "/order/confirmation",
                orderCompleteRes.Data.CompletedModel.CustomOrderNumber
            ]);
        } catch (error) {
            if (error.message === Constant.GENERIC_ERROR_MSG) {
                error.message = Constant.ORDER_CONFIRMATION_FAILED_ERROR_MSG;
                await this.showErrorModal(this.modalController, error);
                await this.navController.navigateRoot("/home");
            } else {
                await this.checkoutWorkflowService.showPaymentFailedModal(
                    error
                );
                await this.navController.navigateBack(["order/order-summary"]);
            }
        } finally {
            this.disableButton = false;
            await this.loadingService.dismiss();
        }
    }
}
