<ion-header>
  <app-page-header
    [showBackButton]="false"
    [hideBorder]="true"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template>
    <section id="page-title">
      <h3>Add Contact</h3>
      <p class="mb-5">Would you like to add your loved one's mailing information?</p>
    </section>
    <section id="page-content">
      <ion-img
      class="image"
      src="/assets/icon/add_contact.svg"
    ></ion-img>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Yes"
      primaryCtaId="btn-add-contact-yes"
      (primaryCtaClick)="addContact()"
      secondaryCtaLabel="I'll add it later"
      secondaryCtaId="link-add-contact-skip"
      (secondaryCtaClick)="skip()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
