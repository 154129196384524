import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import {
    AnalyticsService,
    EventTag
} from "src/app/core/services/analytics.service";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    animations: [
        trigger("visibilityChanged", [
            transition(":enter", [
                style({ transform: "translateY(100%)" }),
                animate("300ms ease-out", style({ transform: "translateY(0)" }))
            ]),
            transition(":leave", [
                style({ transform: "translateY(0)" }),
                animate(
                    "300ms ease-in",
                    style({ transform: "translateY(-100%)" })
                )
            ])
        ])
    ]
})
export class ModalComponent implements OnInit {
    @Input() modalType: string;
    @Input() customImageUrl: string;
    @Input() customImageClasses: string;
    @Input() headerText: string;
    @Input() bodyText: string;
    @Input() primaryCtaText: string;
    @Input() actionUrl: string;
    @Input() dismissable = true;
    @Input() actionType: string;
    @Input() showCloseInHeader = false;
    @Input() fullscreen = false;
    @Input() textAlign: "center" | "left" | "right" = "center";
    @Input() errorReason: string | null = null;
    @Input() primaryAction: () => Promise<any>;
    @Input() secondaryCtaText: string;
    @Input() secondaryAction: () => Promise<any> = async () =>
        await this.modalCtrl.dismiss();
    @Input() secondaryLink: string;

    constructor(
        private modalCtrl: ModalController,
        private navCtrl: NavController,
        private analyticsService: AnalyticsService
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.modalType === "error" && this.bodyText !== "preload") {
            await this.analyticsService.logEvent(EventTag.ErrorModalShown, {
                errorReason: this.errorReason ?? "undefined_reason",
                modalTitle: this.headerText,
                modalDescription: this.bodyText
            });
        }
        if (!this.primaryAction) {
            this.primaryAction = async () => await this.resolvePrimaryAction();
        }
    }

    // TODO: primaryAction should just be default to dismiss like secondary action
    async resolvePrimaryAction(): Promise<void> {
        if (this.actionType === "close") {
            // Do nothing
        } else if (this.actionType === "forward" && this.actionUrl) {
            await this.navCtrl.navigateForward(this.actionUrl);
        } else if (this.actionType === "back" && this.actionUrl) {
            await this.navCtrl.navigateBack(this.actionUrl);
        } else if (this.actionType === "back") {
            this.navCtrl.back();
        } else if (this.actionUrl) {
            await this.navCtrl.navigateRoot(this.actionUrl);
        }

        await this.closeModal();
    }

    async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }
}

export interface ModalProps {
    modalType?: string;
    customImageUrl?: string;
    customImageClasses?: string;
    headerText?: string;
    bodyText?: string;
    primaryCtaText?: string;
    primaryAction?: () => any;
    secondaryCtaText?: string;
    secondaryAction?: () => any;
    actionUrl?: string;
    dismissable?: boolean;
    actionType?: string;
    showCloseInHeader?: boolean;
    fullscreen?: boolean;
    textAlign?: "center" | "left" | "right";
}
