<div *ngIf="photo" class="image-wrap">
  <div class="image-box">
    <ion-skeleton-text animated *ngIf="imageLoading"></ion-skeleton-text>
    <ion-img
      [src]="photo.thumbnailData || photo.data"
      (ionImgDidLoad)="imageLoading = false"
      [ngClass]="{'image-loading': imageLoading}"
    ></ion-img>
  </div>
  <ion-icon
    name="close-circle-outline"
    class="delete-icon"
    (click)="deleteClick.emit(photo)"
    *ngIf="editEnabled"
  >
  </ion-icon>  
</div>