import { Injectable } from "@angular/core";
import {
    Observable,
    TimeoutError,
    concat,
    lastValueFrom,
    of,
    throwError
} from "rxjs";
import { catchError, delay, finalize, retry, timeout } from "rxjs/operators";
import { LoadingService } from "./loading.service";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { ModalController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class PostBootstrapService {
    static readonly BASE_RETRY_DELAY = 1000;
    static readonly MAX_RETRY_DELAY = 120000;
    private getJitterFn = () => Math.random() * 10000 - 5000;
    private backoffIntervalFn = (retryCount: number) =>
        Math.min(
            Math.max(
                PostBootstrapService.BASE_RETRY_DELAY,
                Math.floor(
                    PostBootstrapService.BASE_RETRY_DELAY * 2 ** retryCount +
                        this.getJitterFn()
                )
            ),
            PostBootstrapService.MAX_RETRY_DELAY
        );
    private bootstrapObservables: Array<Observable<any>> = [];

    constructor(
        private loadingService: LoadingService,
        private modalCtrl: ModalController
    ) {}

    public async run(): Promise<void> {
        await this.loadingService.show();

        return lastValueFrom(
            concat(...this.bootstrapObservables).pipe(
                // The timeout resets with each completion of a call in the sequence
                timeout(10000),
                retry({
                    count: 10,
                    delay: (error, retryCount) => {
                        if (error instanceof TimeoutError) {
                            console.error(
                                "Post-bootstrap timed out, cancelling..."
                            );
                            return throwError(() => error);
                        } else {
                            const retryDelay =
                                this.backoffIntervalFn(retryCount);
                            console.warn(
                                `Failed a postbootstrap task. Restarting in ${Math.round(
                                    retryDelay / 1000
                                )} seconds; Attempt ${retryCount}`
                            );
                            return of(true).pipe(delay(retryDelay));
                        }
                    }
                }),
                catchError((err, _) => {
                    this.showPostBootstrapErrorModal(err);
                    return of();
                }),
                finalize(() => {
                    () => (this.bootstrapObservables.length = 0);
                    void this.loadingService.dismiss();
                })
            )
        );
    }

    public append(...steps: Array<Observable<any>>): void {
        this.bootstrapObservables.push(...steps);
    }

    private async showPostBootstrapErrorModal(
        err: Error
    ): Promise<HTMLIonModalElement> {
        const modal = await this.modalCtrl.create({
            component: ModalComponent,
            componentProps: {
                modalType: "error",
                headerText: "Failed to start",
                bodyText:
                    "The application could not start. Please try again later.",
                primaryCtaText: "Okay",
                actionType: "close",
                errorReason: err.message
            }
        });

        await modal.present();
        return modal;
    }
}
