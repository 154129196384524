import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-promo-code-modal",
    templateUrl: "./promo-code-modal.component.html",
    styleUrls: ["./promo-code-modal.component.scss"],
    animations: [
        trigger("visibilityChanged", [
            transition(":enter", [
                style({ transform: "translateY(100%)" }),
                animate("300ms ease-out", style({ transform: "translateY(0)" }))
            ]),
            transition(":leave", [
                style({ transform: "translateY(0)" }),
                animate(
                    "300ms ease-in",
                    style({ transform: "translateY(-100%)" })
                )
            ])
        ])
    ]
})
export class PromoCodeModalComponent implements OnInit {
    couponForm: UntypedFormGroup;

    constructor(
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.couponForm = this.formBuilder.group({
            discountCode: new UntypedFormControl("", Validators.required)
        });
    }

    applyCoupon() {
        this.modalCtrl.dismiss(
            this.couponForm.get("discountCode").value.trim()
        );
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }
}
