import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PelipointsContextService } from "src/app/core/services/pelipoints-context.service";

@Component({
    templateUrl: "./my-points.page.html",
    styleUrls: ["./my-points.page.scss"]
})
export class MyPointsPage implements OnInit, OnDestroy {
    public myPointsTabs: Array<MyPointsTab> = [
        { Name: "Earn", Path: "/pelipoints/earn" },
        { Name: "History", Path: "/pelipoints/history" }
    ];
    public selectedTab: MyPointsTab;
    public currentPointsBalance: number;
    private subscriptions: Subscription[] = [];

    constructor(private pelipointsContextService: PelipointsContextService) {}

    ngOnInit() {
        this.selectedTab = this.myPointsTabs[0];
        this.subscribeToPelipointsContext();
    }

    async ionViewWillEnter() {
        await this.pelipointsContextService.getPelipointsContext().toPromise();
    }

    subscribeToPelipointsContext() {
        this.subscriptions.push(
            this.pelipointsContextService.pelipoints$.subscribe((res) => {
                this.currentPointsBalance = res?.Data?.RewardPointsBalance;
            })
        );
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    changeTab(selectedTab: MyPointsTab) {
        this.selectedTab = selectedTab;
    }
}

interface MyPointsTab {
    Name: string;
    Path: string;
}
