import { Pipe, PipeTransform } from "@angular/core";
import { ShippingOptionModel } from "../model/more/shipping-option-model";

@Pipe({
    name: "arrivalText"
})
export class ArrivalTextPipe implements PipeTransform {
    transform(value: ShippingOptionModel): string {
        if (value.MinTransitDays !== value.MaxTransitDays) {
            return `Arrives in ${value.MinTransitDays}-${value.MaxTransitDays} business days after shipped`;
        } else {
            return `Arrives within ${value.MaxTransitDays} business days after shipped`;
        }
    }
}
