<app-modal
    headerText="Multiple photos to share?"
    bodyText="Tap and hold on the next screen to select multiple photos to upload."
    primaryCtaText="OK"
    [primaryAction]="primaryAction"
>
    <div class="d-flex flex-column align-items-center justify-content-between">
        <ion-item class="item item-text-wrap" lines="none">
            <ion-checkbox
                slot="start"
                (ionChange)="doNotShowAgainChanged($event)"
            ></ion-checkbox>
            <ion-label class="checkbox-label">
                Don't show this again.
            </ion-label>
        </ion-item>
    </div>
</app-modal>
