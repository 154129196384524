<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Add a photo?"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template [centerPageContent]="true">
    <section id="page-title">
      <p>You can add a photo of your loved one. It's completely optional, so you don't have to.</p>
    </section>
    <section id="page-content">
      <ion-avatar>
        <img src="{{photoPath}}">
      </ion-avatar>
    </section>
    <app-cta-buttons
      [primaryCtaLabel]="hasPhoto ? 'Continue' : 'Select Photo'"
      primaryCtaId="btn-contact-photo-continue"
      (primaryCtaClick)="continue(true)"
      secondaryCtaLabel="No thanks"
      secondaryCtaId="link-contact-photo-no-thanks"
      (secondaryCtaClick)="continue()"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
