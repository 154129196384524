import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-page-header",
    templateUrl: "./page-header.component.html",
    styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent implements OnInit {
    @Input() hideBorder = false;
    @Input() showBackButton: boolean = true;
    @Input() showHamburgerMenu: boolean = false;
    @Input() showCloseButton: boolean = false;
    @Input() pageTitle: string;
    @Output() backButtonClick = new EventEmitter();
    @Output() closeButtonClick = new EventEmitter();

    constructor() { }

    ngOnInit() { }
}
