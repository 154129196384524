import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CustomerInfo } from "src/app/shared/model/account/login";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private _isLoggedIn: boolean;
    private _isLoggedInSubject = new Subject<boolean>();
    private _token: string;
    private _deviceID: string;
    private _fcmToken: string;
    private _customer: CustomerInfo;
    private _customerSubject = new Subject<CustomerInfo>();
    private _currentFacilityId: number = 0;
    public isLoggedIn$ = this._isLoggedInSubject.asObservable();
    public customer$ = this._customerSubject.asObservable();

    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    set isLoggedIn(val: boolean) {
        this._isLoggedIn = val;
        this._isLoggedInSubject.next(val);
    }

    get token(): string {
        return this._token;
    }

    set token(val: string) {
        this._token = val;
    }

    get deviceID(): string {
        return this._deviceID;
    }

    set deviceID(val: string) {
        this._deviceID = val;
    }

    get fcmToken(): string {
        return this._fcmToken;
    }

    set fcmToken(val: string) {
        this._fcmToken = val;
    }

    get customer(): CustomerInfo {
        return this._customer;
    }

    set customer(val: CustomerInfo) {
        this._customer = val;
        this._customerSubject.next(val);
    }

    get currentFacilityId(): number {
        return this._currentFacilityId;
    }

    set currentFacilityId(val: number) {
        this._currentFacilityId = val;
    }

    constructor() { }
}
