import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { FilterByPipe, NgPipesModule } from "ngx-pipes";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { CreditCardIconComponent } from "./components/credit-card-icon/credit-card-icon.component";
import { CtaButtonsComponent } from "./components/cta-buttons/cta-buttons.component";
import { CustomAttributesComponent } from "./components/custom-attributes/custom-attributes.component";
import { FooterTabsComponent } from "./components/footer-tabs/footer-tabs.component";
import { HeaderComponent } from "./components/header/header.component";
import { ModalComponent } from "./components/modal/modal.component";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { PageTemplateComponent } from "./components/page-template/page-template.component";
import { ContactAvatarComponent } from "./components/contact-avatar/contact-avatar.component";
import { ContactIdentityComponent } from "./components/contact-identity/contact-identity.component";
import { SidemenuComponent } from "./components/sidemenu/sidemenu.component";
import { DiscountPricePipe } from "./pipes/discount-price.pipe";
import { LocalizePipe } from "./pipes/localize.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { ReturnAddressPage } from "./pages/return-address/return-address.page";
import { StripCurrencyFormattingPipe } from "./pipes/strip-currency-formatting.pipe";
import { SmsLinkComponent } from "./components/sms-link/sms-link.component";
import { ArrivalTextPipe } from "./pipes/arrival-text.pipe";
import { AutofillDirective } from "./directives/autofill.directive";

@NgModule({
    declarations: [
        AutofillDirective,
        ModalComponent,
        AddressFormComponent,
        CtaButtonsComponent,
        CustomAttributesComponent,
        ContactAvatarComponent,
        ContactIdentityComponent,
        PageHeaderComponent,
        PageTemplateComponent,
        FooterTabsComponent,
        HeaderComponent,
        SidemenuComponent,
        CreditCardIconComponent,
        LocalizePipe,
        SafeUrlPipe,
        DiscountPricePipe,
        StripCurrencyFormattingPipe,
        ReturnAddressPage,
        SmsLinkComponent,
        ArrivalTextPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        RouterModule,
        NgPipesModule
    ],
    exports: [
        AutofillDirective,
        ModalComponent,
        AddressFormComponent,
        CtaButtonsComponent,
        CustomAttributesComponent,
        ContactAvatarComponent,
        ContactIdentityComponent,
        PageHeaderComponent,
        PageTemplateComponent,
        FooterTabsComponent,
        HeaderComponent,
        SidemenuComponent,
        CreditCardIconComponent,
        LocalizePipe,
        SafeUrlPipe,
        FilterByPipe,
        DiscountPricePipe,
        StripCurrencyFormattingPipe,
        ReturnAddressPage,
        SmsLinkComponent,
        ArrivalTextPipe
    ]
})
export class SharedModule {}
