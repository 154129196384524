import { Component, Input, OnInit } from "@angular/core";
import { CleverTapService } from "src/app/core/services/service-proxies/external-providers/clevertap.service";
import { SmsService } from "src/app/core/services/sms.service";

@Component({
    selector: "app-sms-link",
    templateUrl: "./sms-link.component.html",
    styleUrls: ["./sms-link.component.scss"]
})
export class SmsLinkComponent implements OnInit {
    @Input() label: string;
    @Input() defaultMessage: string | null;

    hrefValue: string;

    constructor(
        private cleverTapService: CleverTapService,
        private smsService: SmsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.hrefValue = await this.smsService.getHrefValue(
            this.defaultMessage
        );
    }

    async supportInitiated(): Promise<void> {
        await this.cleverTapService.recordEvent("Support Initiated", {
            "Support Type": "SMS",
            "Button Label": this.label
        });
    }
}
