import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonInfiniteScroll } from "@ionic/angular";
import { Subscription } from "rxjs";
import { PelipointsContextService } from "src/app/core/services/pelipoints-context.service";
import { OrderService } from "src/app/core/services/service-proxies/order.service";
import { CustomerRewardPoints } from "src/app/shared/model/account/reward-points";
import { InfiniteScrollCustomEvent } from "src/app/shared/model/infiniteScrollCustomEvent";

@Component({
    selector: "app-history",
    templateUrl: "./history.page.html",
    styleUrls: ["./history.page.scss"]
})
export class HistoryPage implements OnInit, OnDestroy {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

    public rewardPointsByYear: RewardPointsByYear[] = [];
    private subscriptions: Subscription[] = [];
    private currentPage: number;
    private totalPages: number;
    private disableInfiniteScroll = false;

    constructor(
        private pelipointsContextService: PelipointsContextService,
        private orderService: OrderService
    ) {}

    ngOnInit() {
        this.subscribeToPelipointsContext();
    }

    private subscribeToPelipointsContext() {
        this.subscriptions.push(
            this.pelipointsContextService.pelipoints$.subscribe((res) => {
                this.populateRewardPointsByYear(res);
            })
        );
    }

    ionViewDidEnter() {
        this.infiniteScroll.disabled = this.disableInfiniteScroll;
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    async refreshHistory($event: any): Promise<void> {
        await this.pelipointsContextService.getPelipointsContext().toPromise();
        $event.target.complete();
    }

    onInfiniteScroll(event: InfiniteScrollCustomEvent) {
        this.populateNextPage();
        event.target.complete();
    }

    private populateNextPage() {
        this.orderService
            .fetchCustomerRewardPoints(this.currentPage + 1)
            .subscribe((res) => this.populateRewardPointsByYear(res));
    }

    private populateRewardPointsByYear(points: CustomerRewardPoints) {
        if (!points?.Data) {
            return;
        }

        if (points.Data.PagerModel.CurrentPage === 1) {
            this.rewardPointsByYear.length = 0;
        }

        this.currentPage = points.Data.PagerModel.CurrentPage;
        this.totalPages = points.Data.PagerModel.TotalPages;

        points.Data.RewardPoints.forEach((reward) => {
            const year = parseInt(reward.CreatedOn.substr(0, 4));
            const rewardPoint = {
                Points: `${reward.Points > 0 ? "+" : ""}${reward.Points}`,
                Date: reward.CreatedOn
            };

            let rewardYear = this.rewardPointsByYear.find(
                (ry) => ry.Year === year
            );
            if (rewardYear) {
                rewardYear.RewardPoints.push(rewardPoint);
            } else {
                rewardYear = {
                    Year: year,
                    RewardPoints: [rewardPoint]
                };
                this.rewardPointsByYear.push(rewardYear);
            }
        });

        this.setInfiniteScrollState();
    }

    private setInfiniteScrollState() {
        this.disableInfiniteScroll = this.currentPage === this.totalPages;

        if (this.infiniteScroll) {
            this.infiniteScroll.disabled = this.disableInfiniteScroll;
        }
    }
}

interface RewardPointsByYear {
    Year: number;
    RewardPoints: {
        Points: string;
        Date: string;
    }[];
}
