import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Subject, firstValueFrom } from "rxjs";
import { LoadingService } from "src/app/core/services";
import { ErrorReason } from "src/app/core/services/analytics.service";
import { LoginWorkflowService } from "src/app/core/services/login-workflow.service";
import { AccountService } from "src/app/core/services/pelipost-service-proxies/account.service";
import { LoginService } from "src/app/core/services/service-proxies/account/login.service";
import {
    ImportOldUserModel,
    LoginGetRes
} from "src/app/shared/model/account/login";
import { BasePage } from "src/app/shared/pages/BasePage";

@Component({
    selector: "login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"]
})
export class LoginFormComponent extends BasePage implements OnInit, OnDestroy {
    public loginGetRes: LoginGetRes;
    public loginForm: UntypedFormGroup;
    public showPassword = false;
    public isLoginLoading = false;

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        private loginService: LoginService,
        private accountService: AccountService,
        private navController: NavController,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private loginWorkflowService: LoginWorkflowService,
        private platform: Platform
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        // Intializing form
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, { updateOn: "change" }),
            username: new UntypedFormControl(null, {
                updateOn: "change"
            }),
            password: new UntypedFormControl(null, {
                updateOn: "change",
                validators: [Validators.required]
            })
        });

        // Http request
        try {
            this.loginGetRes = await firstValueFrom(
                this.loginService.fetchLoginRes()
            );

            if (this.loginGetRes.Data.UsernamesEnabled) {
                this.loginForm
                    .get("username")
                    .setValidators(Validators.required);
            } else {
                this.loginForm.get("email").setValidators(Validators.required);
            }
        } catch (error) {
            this.showErrorModal(this.modalController, error);
            return;
        }

        //HACK: this is necessary to trigger form validation on iOS autofill:
        // https://stackoverflow.com/questions/53555114/ios-autofill-data-in-login-forms-is-empty-in-cordova-application-using-angular
        if (this.platform.is("ios")) {
            /* this.loginForm.controls.password.valueChanges
                .pipe(takeUntil(this.unsubscribeSubject$), debounceTime(500))
                .subscribe((newPassword) => {
                    let passwordEl = document.querySelector(
                        '[formControlName="password"]'
                    ).firstChild as HTMLInputElement;
                    if (passwordEl?.value !== newPassword) {
                        this.loginForm
                            .get("password")
                            .setValue(passwordEl?.value, {emitEvent:false});
                    }
                }); */
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    async login() {
        this.isLoginLoading = true;
        await this.loadingService.show();
        this.loginGetRes.Data.Email = this.loginGetRes.Data.UsernamesEnabled
            ? null
            : this.loginForm.get("email").value.trim();
        this.loginGetRes.Data.Username = !this.loginGetRes.Data.UsernamesEnabled
            ? null
            : this.loginForm.get("username").value.trim();
        this.loginGetRes.Data.Password = this.loginForm
            .get("password")
            .value.trim();

        const parameters = new ImportOldUserModel();
        parameters.email = this.loginGetRes.Data.Email;
        parameters.password = this.loginGetRes.Data.Password;

        try {
            await firstValueFrom(
                this.accountService.checkAndMigrateOldUser(parameters)
            );
            const res = await firstValueFrom(
                this.loginService.postLoginData(this.loginGetRes.Data)
            );
            await this.loginWorkflowService.loginRedirect(res, "email");
        } catch (error) {
            this.loginWorkflowService.loginFailed("email");
            await this.showErrorModal(
                this.modalController,
                error,
                undefined,
                ErrorReason.AuthenticationError
            );
        } finally {
            this.isLoginLoading = false;
            await this.loadingService.dismiss();
        }
    }

    async forgotPassword(): Promise<void> {
        await this.navController.navigateForward("/forgot-password");
    }
}
