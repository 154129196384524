import { Component, OnInit, ViewChild } from "@angular/core";
import { IonInfiniteScroll } from "@ionic/angular";
import { LoadingService } from "src/app/core/services";
import { PelipostOrderService } from "src/app/core/services/pelipost-service-proxies/pelipost-order.service";
import { InfiniteScrollCustomEvent } from "src/app/shared/model/infiniteScrollCustomEvent";
import { MyOrdersModel, Order } from "src/app/shared/model/order";
import { BasePage } from "src/app/shared/pages/BasePage";
@Component({
    templateUrl: "./my-orders.page.html",
    styleUrls: ["./my-orders.page.scss"]
})
export class MyOrdersPage extends BasePage implements OnInit {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

    public ordersByYear: OrdersByYear[] = [];
    private currentPage: number;
    private totalPages: number;
    private disableInfiniteScroll = false;

    constructor(
        private orderService: PelipostOrderService,
        private loadingService: LoadingService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.loadingService.show();
        this.orderService.getMyOrders().subscribe(async (res) => {
            this.populateOrdersByYear(res.Data);
            await this.loadingService.dismiss();
        });
    }

    ionViewDidEnter() {
        this.infiniteScroll.disabled = this.disableInfiniteScroll;
    }

    refreshOrderList($event: any) {
        this.orderService.getMyOrders().subscribe((res) => {
            this.populateOrdersByYear(res.Data);
            $event.target.complete();
        });
    }

    onInfiniteScroll(event: InfiniteScrollCustomEvent) {
        this.populateNextPage();
        event.target.complete();
    }

    private populateNextPage() {
        this.orderService
            .getMyOrders(this.currentPage + 1)
            .subscribe((res) => this.populateOrdersByYear(res.Data));
    }

    populateOrdersByYear(myOrders: MyOrdersModel) {
        let orders = myOrders.Orders;

        if (myOrders.PagerModel.CurrentPage === 1) {
            this.ordersByYear.length = 0;
        }

        this.currentPage = myOrders.PagerModel.CurrentPage;
        this.totalPages = myOrders.PagerModel.TotalPages;

        orders.forEach((order) => {
            const year = parseInt(order.CreatedOn.substr(0, 4));
            let orderYear = this.ordersByYear.find((oy) => oy.Year === year);
            if (orderYear) {
                orderYear.Orders.push(order);
            } else {
                orderYear = {
                    Year: year,
                    Orders: [order]
                };
                this.ordersByYear.push(orderYear);
            }
        });

        this.setInfiniteScrollState();
    }

    private setInfiniteScrollState() {
        this.disableInfiniteScroll = this.currentPage === this.totalPages;

        if (this.infiniteScroll) {
            this.infiniteScroll.disabled = this.disableInfiniteScroll;
        }
    }
}

interface OrdersByYear {
    Year: number;
    Orders: Order[];
}
