import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PelipostPhoto } from "src/app/core/services/photos.service";

@Component({
    selector: "app-photos-gallery",
    templateUrl: "./photos-gallery.component.html",
    styleUrls: ["./photos-gallery.component.scss"]
})
export class PhotosGalleryComponent implements OnInit {
    @Input() photos: PelipostPhoto[];
    @Input() source: string;
    @Input() isEditMode: boolean;
    @Output() deleteClick = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
