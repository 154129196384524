import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-personalization-postcards",
  templateUrl: "./personalization-postcards.component.html",
  styleUrls: ["./personalization-postcards.component.scss"]
})
export class PersonalizationPostcardsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
