import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    OrderConfirmationResponseModel,
    ShoppingCart
} from "src/app/shared/model/cart";
import { SITE_URL } from "../../../../environments/environment";
import { BaseReqBody } from "../../../shared/model/BaseResponse";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root"
})
export class CheckoutService {
    paymentInfoMvcUrl = `${SITE_URL}/nopstationcheckout/paymentinfo`;
    redirectTypeUrl = `${SITE_URL}/nopstationcheckout/redirect`;

    constructor(private baseService: BaseService) {}

    getBillingAddressFormData() {
        const url = `${this.baseService.BASE_URL}checkout/getbilling`;
        return this.baseService.getRequest(url);
    }

    saveBillingAddress(reqBody: BaseReqBody) {
        const url = `${this.baseService.BASE_URL}checkout/savebilling`;
        return this.baseService.postRequest(url, reqBody);
    }

    saveShippingAddress(reqBody: BaseReqBody) {
        const url = `${this.baseService.BASE_URL}checkout/saveshipping`;
        return this.baseService.postRequest(url, reqBody);
    }

    saveShippingMethod(reqBody: BaseReqBody) {
        const url = `${this.baseService.BASE_URL}checkout/saveshippingmethod`;
        return this.baseService.postRequest(url, reqBody);
    }

    savePaymentMethod(reqBody: BaseReqBody) {
        const url = `${this.baseService.BASE_URL}checkout/savepaymentmethod`;
        return this.baseService.postRequest(url, reqBody);
    }

    savePaymentInfoWithMVC(formData: FormData) {
        return this.baseService.postRequestWithFormData(
            this.paymentInfoMvcUrl,
            formData
        );
    }

    savePaymentInfoWithApi(reqBody: BaseReqBody) {
        const url = `${this.baseService.BASE_URL}checkout/savepaymentinfo`;
        return this.baseService.postRequest(url, reqBody);
    }

    getConfirmOrderSummary(): Observable<ShoppingCart> {
        const url = `${this.baseService.BASE_URL}checkout/confirmorder`;
        return this.baseService.getRequest(url);
    }

    confirmOrder(): Observable<OrderConfirmationResponseModel> {
        const url = `${this.baseService.BASE_URL}checkout/confirmorder`;
        return this.baseService.postRequest(url, {});
    }

    orderComplete(): Observable<OrderConfirmationResponseModel> {
        const url = `${this.baseService.BASE_URL}checkout/completed`;
        return this.baseService.getRequest(url);
    }

    fetchStatesByCountry(countryCode: string) {
        const url = `${this.baseService.BASE_URL}country/getstatesbycountryid/${countryCode}`;
        return this.baseService.getRequest(url);
    }
}
