<ion-header>
  <app-page-header
    [hideBorder]="true"
    pageTitle="Additional Info"
  ></app-page-header>
</ion-header>
<ion-content>
  <app-page-template *ngIf="dataForm">
    <section id="page-title">
      <p>This facility requires the following info:</p>
    </section>
    <section id="page-content">
      <form [formGroup]="dataForm" novalidate>
        <app-custom-attributes
          *ngFor="let attribute of attributes"
          [attribute]="attribute"
          [form]="dataForm"
          prefix="facility_attribute_"
        ></app-custom-attributes>
      </form>
    </section>
    <app-cta-buttons
      primaryCtaLabel="Continue"
      primaryCtaId="btn-additional-info-continue"
      (primaryCtaClick)="continue()"
      [primaryCtaDisabled]="!dataForm.valid"
    ></app-cta-buttons>
  </app-page-template>
</ion-content>
