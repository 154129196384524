<ion-header>
    <app-page-header
        [hideBorder]="true"
        [showBackButton]="false"
        [showCloseButton]="true"
        (closeButtonClick)="returnToMainScreen()"
        pageTitle="Confirmation"
    ></app-page-header>
</ion-header>
<ion-content>
    <app-page-template [stickyActionButtons]="false">
        <section id="page-title">
            <p>We received your order and will process it shortly.</p>
            <div class="d-flex justify-content-center">
                <ion-avatar>
                    <img src="assets/icon/check_mark_circle_icon@2x.svg" />
                </ion-avatar>
            </div>
            <h6>Your order number is</h6>
            <div class="order-number">
                {{order?.CustomOrderNumber}}
            </div>
        </section>
        <section id="page-content" class="pt-2">
            <app-order-overview
                [lineItems]="order?.LineItems"
                [orderTotals]="orderTotals"
                [contact]="contact"
                [facility]="facility"
            ></app-order-overview>
        </section>
        <app-cta-buttons
            primaryCtaLabel="Return to Main Screen"
            primaryCtaId="btn-confirmation-return"
            (primaryCtaClick)="returnToMainScreen()"
        ></app-cta-buttons>
    </app-page-template>
</ion-content>
