import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { BaseService } from "src/app/core/services/service-proxies/base.service";
import { DynamicAttribute } from "src/app/shared/model/attribute";
import { BaseReqBody, FormValue } from "src/app/shared/model/BaseResponse";
import {
    AddToCartPostBody,
    AppliedCoupon,
    AppliedGiftCard,
    CartData,
    ShoppingCart,
    UpdateCartPostBody
} from "../../shared/model/cart";

@Injectable({
    providedIn: "root"
})
export class CartService {
    public cartNumber: number;
    private _cart: BehaviorSubject<CartData> = new BehaviorSubject(null);
    public readonly cart$: Observable<CartData> = this._cart.asObservable();

    constructor(private baseService: BaseService) {}

    addToCart(productId: number, data: AddToCartPostBody) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = data.FormValues;

        const url =
            this.baseService.BASE_URL +
            `shoppingCart/AddProductToCart/details/${productId}/1`;
        return this.baseService.postRequest(url, reqBody);
    }

    addToCartFromHomepage(
        productId: number,
        data: AddToCartPostBody,
        isCart: boolean
    ) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = data.FormValues;

        let cartType = 1; // cart
        if (!isCart) {
            cartType = 2; // wishlist
        }

        const url =
            this.baseService.BASE_URL +
            `shoppingCart/addproducttocart/catalog/${productId}/${cartType}`;
        return this.baseService.postRequest(url, reqBody);
    }

    clearCart(): Observable<ShoppingCart> {
        const cart = this._cart.value?.Cart;
        const itemIds = cart?.Items.map((item) => item.Id).join(",");

        return this.updateOrRemoveFromCart({
            FormValues: [
                {
                    Key: "removefromcart",
                    Value: itemIds
                }
            ]
        }).pipe(
            tap(() => {
                this._cart.next(null);
            })
        );
    }

    fetchCartRes(): Observable<ShoppingCart> {
        const url = this.baseService.BASE_URL + `shoppingcart/cart`;
        return this.baseService.getRequest<ShoppingCart>(url).pipe(
            tap((c) => {
                if (c.Data) {
                    this._cart.next(c.Data);
                }
            })
        );
    }

    updateOrRemoveFromCart(data: UpdateCartPostBody): Observable<ShoppingCart> {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = data.FormValues;

        const url = this.baseService.BASE_URL + `shoppingcart/updatecart`;
        return this.baseService.postRequest<ShoppingCart>(url, reqBody).pipe(
            tap((c) => {
                if (c.Data) {
                    this._cart.next(c.Data);
                }
            })
        );
    }

    updateDeliveryAssurance(deliveryAssurance?: DynamicAttribute) {
        const reqBody: BaseReqBody = new BaseReqBody();
        reqBody.FormValues = [];

        if (deliveryAssurance) {
            reqBody.FormValues.push(
                this.parseDeliveryAssuranceAttribute(deliveryAssurance)
            );
        }

        const url =
            this.baseService.BASE_URL + `shoppingcart/checkoutattributechange`;
        return this.baseService.postRequest(url, reqBody);
    }

    applyCoupon(
        couponCode: string,
        deliveryAssurance?: DynamicAttribute
    ): Observable<ShoppingCart> {
        const reqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: "discountcouponcode",
            Value: couponCode
        });

        // Delivery assurance needs to be included or Nop will automatically remove it
        if (deliveryAssurance) {
            reqBody.FormValues.push(
                this.parseDeliveryAssuranceAttribute(deliveryAssurance)
            );
        }

        const url =
            this.baseService.BASE_URL + `shoppingcart/applydiscountcoupon`;
        return this.baseService.postRequest<ShoppingCart>(url, reqBody).pipe(
            tap((c) => {
                if (c.Data) {
                    this._cart.next(c.Data);
                }
            })
        );
    }

    applyGiftCard(giftCardCode: string) {
        const reqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: "giftcardcouponcode",
            Value: giftCardCode
        });

        const url = this.baseService.BASE_URL + `shoppingcart/applygiftcard`;
        return this.baseService.postRequest(url, reqBody);
    }

    removeCoupon(
        coupon: AppliedCoupon,
        deliveryAssurance?: DynamicAttribute
    ): Observable<ShoppingCart> {
        const reqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: `removediscount-${coupon.Id}`,
            Value: coupon.CouponCode
        });

        // Delivery assurance needs to be included or Nop will automatically remove it
        if (deliveryAssurance) {
            reqBody.FormValues.push(
                this.parseDeliveryAssuranceAttribute(deliveryAssurance)
            );
        }

        const url =
            this.baseService.BASE_URL + `shoppingcart/removediscountcoupon`;
        return this.baseService.postRequest<ShoppingCart>(url, reqBody).pipe(
            tap((c) => {
                if (c.Data) {
                    this._cart.next(c.Data);
                }
            })
        );
    }

    removeGiftCard(giftCard: AppliedGiftCard) {
        const reqBody = new BaseReqBody();
        reqBody.FormValues = [];
        reqBody.FormValues.push({
            Key: `removegiftcard-${giftCard.Id}`,
            Value: giftCard.CouponCode
        });

        const url =
            this.baseService.BASE_URL + `shoppingcart/removegiftcardcode`;
        return this.baseService.postRequest(url, reqBody);
    }

    private parseDeliveryAssuranceAttribute(
        attribute: DynamicAttribute
    ): FormValue {
        return {
            Key: `checkout_attribute_${attribute?.Id}`,
            Value: attribute?.Values[0].Id.toString()
        };
    }
}
