import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectedAttributeValueModel } from "src/app/shared/model/facilityAttributeModel";
@Injectable({
    providedIn: "root"
})
export class CustomAttributeService {
    prepFormControls(
        group: any,
        prefix: string,
        attributes: any[],
        attributeIdMap: Map<any, any>
    ): any {
        attributes.forEach((attribute) => {
            const preSelectedValues = [];
            attribute.Values?.forEach((val) => {
                if (val.IsPreSelected) {
                    preSelectedValues.push(val.Id);
                }
            });

            let control: UntypedFormControl;
            if (preSelectedValues.length > 1) {
                control = new UntypedFormControl(preSelectedValues);
            } else if (preSelectedValues.length > 0) {
                control = new UntypedFormControl(preSelectedValues[0]);
            } else if (attribute.IsRequired && attribute.Values?.length > 0) {
                control = new UntypedFormControl(attribute.Values[0].Id);
            } else {
                control = new UntypedFormControl(attribute.DefaultValue || "");
            }

            if (attribute.IsRequired)
                control.setValidators([Validators.required]);

            group[`${prefix}${attribute.Id}`] = control;

            attributeIdMap.set(`${prefix}${attribute.Id}`, attribute);
        });

        return group;
    }

    getSelectedValues(
        dataForm: UntypedFormGroup,
        attributeIdMap: Map<any, any>
    ): SelectedAttributeValueModel[] {
        let values: SelectedAttributeValueModel[] = [];
        for (const attributeId in dataForm.controls) {
            if (attributeIdMap.has(attributeId)) {
                let value = dataForm.get(attributeId).value.toString().trim();
                let attribute = attributeIdMap.get(attributeId);

                if (attribute.AttributeControlTypeId === 1) {
                    values.push({
                        Id: attribute.Id,
                        Name: attribute.Name,
                        Value: attribute.Values.filter((v) => v.Id == value)
                            .Name
                    });
                }
                //TODO: implement checkboxes/radio button
                else if (attribute.AttributeControlTypeId === 3) {
                } else if (
                    attribute.AttributeControlTypeId === 4 ||
                    attribute.AttributeControlTypeId === 10 ||
                    attribute.AttributeControlTypeId === 20
                ) {
                    values.push({
                        Id: attribute.Id,
                        Name: attribute.Name,
                        Value: value
                    });
                }
            }
        }

        return values;
    }
}
