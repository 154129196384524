import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AddEditAddressGetRes,
    AddEditAddressPostBody,
    AddressesGetRes
} from "src/app/shared/model/account/address";
import { States } from "src/app/shared/model/account/register";
import { FormValue } from "src/app/shared/model/BaseResponse";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: "root"
})
export class AddressService {
    constructor(private baseService: BaseService) {}

    // Address list
    fetchAddresses(): Observable<AddressesGetRes> {
        const url = this.baseService.BASE_URL + "customer/addresses";
        return this.baseService.getRequest(url);
    }

    // Add address get response
    fetchAddAddress(): Observable<AddEditAddressGetRes> {
        const url = this.baseService.BASE_URL + "customer/addressadd";
        return this.baseService.getRequest(url);
    }

    // Add address post
    postAddAddress(data: AddEditAddressGetRes, values: FormValue[]) {
        const reqBody: AddEditAddressPostBody = {
            Data: {
                Address: data.Data.Address,
                CustomProperties: data.Data.CustomProperties
            },
            FormValues: values
        };
        const url = this.baseService.BASE_URL + "customer/addressadd";
        return this.baseService.postRequest(url, reqBody);
    }

    // Edit address get response
    fetchEditAddress(id: number): Observable<AddEditAddressGetRes> {
        const url = this.baseService.BASE_URL + `customer/addressedit/${id}`;
        return this.baseService.getRequest(url);
    }

    // Edit address post
    postEditAddress(data: AddEditAddressGetRes, values: FormValue[]) {
        const reqBody: AddEditAddressPostBody = {
            Data: {
                Address: data.Data.Address,
                CustomProperties: data.Data.CustomProperties
            },
            FormValues: values
        };
        const url =
            this.baseService.BASE_URL +
            `customer/addressedit/${data.Data.Address.Id}`;
        return this.baseService.postRequest(url, reqBody);
    }

    // Remove address
    removeAddress(addressId: number) {
        const url =
            this.baseService.BASE_URL + `customer/addressdelete/${addressId}`;
        return this.baseService.postRequest(url, null);
    }

    // Get states by country
    fetchStates(countryId: number): Observable<States> {
        const url =
            this.baseService.BASE_URL +
            `country/getstatesbycountryid/${countryId}`;
        return this.baseService.getRequest(url);
    }
}
