import { Injectable } from "@angular/core";
import { BASE_PEL_URL } from "src/environments/environment";
import { BaseService } from "./service-proxies/base.service";

@Injectable({
  providedIn: "root"
})
export class BasePelipostService extends BaseService {
  public BASE_PEL_URL = BASE_PEL_URL;
}
